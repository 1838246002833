import React from 'react';
import PropTypes from 'prop-types';

import AdminLayout from '../layouts/admin-layout';
import StudentAdmin from '../admin/student-admin/student-admin';

const StudentAdminPage = ({ layout, admin }) => {
  return (
    <AdminLayout {...layout}>
      <StudentAdmin {...admin} />
    </AdminLayout>
  );
};

StudentAdminPage.propTypes = {
  layout: PropTypes.exact(AdminLayout.propTypes),
  admin: PropTypes.exact(StudentAdmin.propTypes)
};

export default StudentAdminPage;
