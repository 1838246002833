import Clicker from 'components/clicker/clicker';
import InputErrorMessage from 'components/input-error-message';
import LoadingSpinner from 'components/loading-spinner';
import GranulatedRequestModal from 'components/modal/granulated-request-modal/granulated-request-modal';
import AlertModal from 'components/redaktor-intro/alert-modal';
import apiHelper from 'js/api-helper';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const RedaktorGrensesnittToolbar = ({
  exportButton,
  exportSingleStepButton,
  importButton,
  publishButton,
  deleteButton,
  exportDiffReportButton
}) => {
  const [toolbarStatus, setToolbarStatus] = useState({
    loading: false,
    error: ''
  });

  const [alertModal, setAlertModal] = useState(null);
  const [granulatedRequestModal, setGranulatedRequestModal] = useState(null);
  const [currentModal, setCurrentModal] = useState('');

  const closeModal = () => {
    setAlertModal(null);
    setGranulatedRequestModal(null);
    setCurrentModal('');
  };

  const handleOnImportClick = async e => {
    setToolbarStatus({
      loading: true,
      error: ''
    });
    const addedFile = e.target.files[0];
    if (addedFile.type === 'application/json') {
      try {
        const fileReader = new FileReader();
        fileReader.readAsText(addedFile, 'UTF-8');
        fileReader.onload = async e => {
          const granulatedRequestModal = await apiHelper.execute(
            importButton.endpoint,
            {
              json: e.target.result,
              filename: addedFile.name
            }
          );
          setGranulatedRequestModal(granulatedRequestModal);
          setCurrentModal('import');
          setToolbarStatus({
            loading: false,
            error: ''
          });
        };
      } catch (err) {
        setToolbarStatus({
          loading: false,
          error: 'Noe gikk falt under importeringen'
        });
      }
    } else {
      setToolbarStatus({
        loading: false,
        error: 'Må være .json filformat'
      });
    }
  };

  const handleImport = async (blockItemList, endpoint) => {
    setToolbarStatus({
      loading: true,
      error: ''
    });

    try {
      await apiHelper.execute(endpoint, {
        ...granulatedRequestModal,
        blockItemList: blockItemList
      });
      setToolbarStatus({
        loading: false,
        error: ''
      });
    } catch (err) {
      setToolbarStatus({
        loading: false,
        error: 'Noe gikk galt uneder importeringen'
      });
    }

    closeModal();
    window.location.reload();
  };

  const handleExport = async (blockItemList, endpoint) => {
    if (exportButton?.alertModal && !alertModal) {
      return setAlertModal({
        ...exportButton.alertModal,
        proceedCallback: handleExport
      });
    }

    setToolbarStatus({
      loading: true,
      error: ''
    });

    try {
      const { json, filename } = await apiHelper.execute(endpoint, {
        ...granulatedRequestModal,
        blockItemList: blockItemList
      });
      // const parsedJSON = typeof json === 'string' ? JSON.parse(json) : json;
      const downloadUrl =
        'data:application/JSON;charset=utf-8,' + encodeURIComponent(json);

      const downloadFilename = /.json/.test(filename)
        ? filename
        : `${filename}.json`;
      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      anchor.target = '_blank';
      anchor.download = downloadFilename;
      document.body.appendChild(anchor); // required for firefox
      anchor.click();
      anchor.remove();
      setToolbarStatus({
        loading: false,
        error: ''
      });
    } catch (err) {
      setToolbarStatus({
        loading: false,
        error: 'Noe gikk galt uneder eksporteringen'
      });
    }

    closeModal();
  };

  const handleSingleStepExport = async () => {
    if (exportButton?.alertModal && !alertModal) {
      return setAlertModal({
        ...exportButton.alertModal,
        proceedCallback: handleExport
      });
    }

    setToolbarStatus({
      loading: true,
      error: ''
    });

    try {
      const { json, filename } = await apiHelper.get(
        exportSingleStepButton.endpoint
      );
      // const parsedJSON = typeof json === 'string' ? JSON.parse(json) : json;
      const downloadUrl =
        'data:application/JSON;charset=utf-8,' + encodeURIComponent(json);

      const downloadFilename = /.json/.test(filename)
        ? filename
        : `${filename}.json`;
      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      anchor.target = '_blank';
      anchor.download = downloadFilename;
      document.body.appendChild(anchor); // required for firefox
      anchor.click();
      anchor.remove();
      setToolbarStatus({
        loading: false,
        error: ''
      });
    } catch (err) {
      setToolbarStatus({
        loading: false,
        error: 'Noe gikk galt uneder eksporteringen'
      });
    }

    closeModal();
  };

  const handleOnExportClick = async () => {
    try {
      const granulatedRequestModal = await apiHelper.get(exportButton.endpoint);
      setGranulatedRequestModal(granulatedRequestModal);

      setCurrentModal('export');
    } catch (err) {
      setToolbarStatus({
        loading: false,
        error: 'Noe gikk galt uneder eksporteringen'
      });
    }
  };

  const handlePublish = async () => {
    if (publishButton?.alertModal && !alertModal) {
      return setAlertModal({
        ...publishButton.alertModal,
        proceedCallback: () => handlePublish() // TODO: Fix denne lagrer en gammel instans av handlepublish
      });
    }
    try {
      setToolbarStatus({
        loading: true,
        error: ''
      });
      await apiHelper.execute(publishButton.endpoint);
      setToolbarStatus({
        loading: false,
        error: ''
      });
    } catch (err) {
      setToolbarStatus({
        loading: false,
        error: 'Noe gikk galt under publiseringen'
      });
    }
  };

  const handleDelete = async () => {
    if (deleteButton?.alertModal && !alertModal) {
      return setAlertModal({
        ...deleteButton.alertModal,
        proceedCallback: handleDelete()
      });
    }
    try {
      setToolbarStatus({
        loading: true,
        error: ''
      });
      await apiHelper.execute(deleteButton.endpoint);
      setToolbarStatus({
        loading: false,
        error: ''
      });
    } catch (err) {
      setToolbarStatus({
        loading: false,
        error: 'Noe gikk galt under slettingen'
      });
    }
  };

  const handleExportDiff = async () => {
    setToolbarStatus({
      loading: true,
      error: ''
    });

    try {
      const { text, filename } = await apiHelper.get(
        exportDiffReportButton.endpoint
      );

      const downloadUrl =
        'data:text/plain;charset=utf-8,' + encodeURIComponent(text);
      const downloadFilename = /.txt/.test(filename)
        ? filename
        : `${filename}.txt`;
      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      anchor.target = '_blank';
      anchor.download = downloadFilename;
      document.body.appendChild(anchor); // required for firefox
      anchor.click();
      anchor.remove();
      setToolbarStatus({
        loading: false,
        error: ''
      });
    } catch (err) {
      setToolbarStatus({
        loading: false,
        error: 'Noe gikk galt uneder eksporteringen av differanserapport'
      });
    }

    closeModal();
  };

  const checkModalOrProceed = (buttonObject, modalType, callback) => {
    if (buttonObject?.alertModal) {
      setAlertModal(buttonObject.alertModal);
      setCurrentModal(modalType);
    } else callback();
  };

  const handleModalProceedButton = e => {
    if (currentModal === 'publish') handlePublish(e);
    else if (currentModal === 'delete') handleDelete(e);
    else if (currentModal === 'import') handleImport(e);
    else if (currentModal === 'export') handleExport(e);
    closeModal();
  };

  return (
    <div className={'redaktor-grensesnitt__info-actions-toolbar'}>
      {alertModal && (
        <AlertModal
          {...alertModal}
          isOpen={!!currentModal}
          onClose={closeModal}
          handleProceed={handleModalProceedButton}
        />
      )}
      {granulatedRequestModal && (
        <GranulatedRequestModal
          {...granulatedRequestModal}
          isOpen={!!currentModal}
          onClose={closeModal}
          handleProceed={
            currentModal === 'export' ? handleExport : handleImport
          }
        />
      )}
      {exportDiffReportButton?.show && (
        <Clicker
          //isLoading={toolbarStatus.loading}
          onClick={handleExportDiff}
          theme={Clicker.themes.secondary}
          text={exportDiffReportButton.text}
        />
      )}
      {deleteButton?.show && (
        <Clicker
          isDisabled={toolbarStatus.loading}
          onClick={() =>
            checkModalOrProceed(deleteButton, 'delete', handleDelete)
          }
          theme={Clicker.themes.secondary}
          text={deleteButton.text}
        />
      )}
      {importButton?.show && (
        <div
          className="redaktor-grensesnitt__import-section"
          style={{ position: 'relative' }}
        >
          <label
            className="redaktor-grensesnitt__import-label"
            htmlFor="import-texts-input"
          >
            <span className="redaktor-grensesnitt__import-label-text">
              {importButton.text}
            </span>
          </label>
          <input
            className="redaktor-grensesnitt__import"
            id="import-texts-input"
            type="file"
            accept="application/JSON"
            onChange={handleOnImportClick}
          />
        </div>
      )}
      {exportButton?.show && (
        <Clicker
          //isLoading={toolbarStatus.loading}
          onClick={() => handleOnExportClick()}
          theme={Clicker.themes.primary}
          text={exportButton.text}
        />
      )}
      {exportSingleStepButton?.show && (
        <Clicker
          //isLoading={toolbarStatus.loading}
          onClick={handleSingleStepExport}
          theme={Clicker.themes.primary}
          text={exportSingleStepButton.text}
        />
      )}
      {publishButton?.show && (
        <Clicker
          //isDisabled={toolbarStatus.loading}
          onClick={() =>
            checkModalOrProceed(publishButton, 'publish', handlePublish)
          }
          theme={Clicker.themes.primary}
          text={publishButton.text}
        />
      )}

      {toolbarStatus.loading && <LoadingSpinner />}
      {toolbarStatus.error && (
        <InputErrorMessage errors={[toolbarStatus.error]} />
      )}
    </div>
  );
};

RedaktorGrensesnittToolbar.buttonProps = {
  endpoint: PropTypes.string,
  text: PropTypes.string,
  show: PropTypes.bool,
  alertModal: PropTypes.exact({
    title: PropTypes.string,
    lead: PropTypes.string,
    cancelText: PropTypes.string,
    okText: PropTypes.string
  })
};
RedaktorGrensesnittToolbar.propTypes = {
  publishButton: PropTypes.exact(RedaktorGrensesnittToolbar.buttonProps),
  deleteButton: PropTypes.exact(RedaktorGrensesnittToolbar.buttonProps),
  exportButton: PropTypes.exact(RedaktorGrensesnittToolbar.buttonProps),
  exportSingleStepButton: PropTypes.exact(
    RedaktorGrensesnittToolbar.buttonProps
  ),
  importButton: PropTypes.exact({
    // doesnt support alertModal because the input field pops up
    endpoint: PropTypes.string,
    text: PropTypes.string,
    show: PropTypes.bool
  }),
  exportDiffReportButton: PropTypes.exact(
    RedaktorGrensesnittToolbar.buttonProps
  )
};

export default RedaktorGrensesnittToolbar;
