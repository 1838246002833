import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import HeaderContext from '../../contexts/header-context';
import api from 'js/api-helper';
import SelectInput from 'components/select-input/select-input';
import LoadingSpinner from 'components/loading-spinner/loading-spinner';
import ProgressionChart from 'components/progression-chart/progression-chart';

const InspectorProgression = ({
  filterEndpoint,
  chartEndpoint,
  progressionDescription,
  buttonGroups,
  checkboxes
}) => {
  const { adminHeaderData } = useContext(HeaderContext);
  const [students, setStudents] = useState(null);
  const [chosenStudent, setChosenStudent] = useState(null);
  const [isLoading, setIsLoading] = useState({
    filters: false,
    student: false
  });

  const handleGetFilterResult = async () => {
    setIsLoading(prev => ({ ...prev, filters: true }));
    setChosenStudent(null);
    const students = await api.execute(
      filterEndpoint,
      adminHeaderData.inputs.map(({ id, value }) => ({
        id,
        value
      }))
    );
    setStudents(students);
    setIsLoading(prev => ({ ...prev, filters: false }));
  };

  useEffect(() => {
    if (adminHeaderData?.errors !== undefined && !adminHeaderData?.errors) {
      handleGetFilterResult();
    }
  }, [adminHeaderData]);

  //for testing, setting student at once
  // useEffect(async () => {
  //   setChosenStudent({
  //     elevId: '1234'
  //   });
  // }, []);

  return (
    <div className="inspector-progression">
      <div className="inspector-progression__student-picker">
        {isLoading.filters ? (
          <LoadingSpinner />
        ) : (
          <>
            {students === null ? (
              'Gjør et søk.'
            ) : students.length ? (
              <>
                <SelectInput
                  label="Elever"
                  placeholder="Velg elev"
                  options={students.map(student => ({
                    text: student.name,
                    value: student.id
                  }))}
                  onChange={id =>
                    id !== ''
                      ? setChosenStudent({
                          name: students.find(s => s.id === id).name,
                          id: id
                        })
                      : setChosenStudent(null)
                  }
                  id={'inspector-progression-select-student'}
                />
                <div className="inspector-progression__student-picker-count">
                  {students.length} elever totalt
                </div>
              </>
            ) : (
              'Beklager, ingen treff.'
            )}
          </>
        )}
      </div>
      <div className="inspector-progression__student-data">
        {!isLoading.student ? (
          <>
            {chosenStudent && (
              <ProgressionChart
                elevId={chosenStudent?.id}
                chartEndpoint={chartEndpoint}
                buttonGroups={buttonGroups}
                progressionDescription={progressionDescription}
                checkboxes={checkboxes}
              />
            )}
          </>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
};

InspectorProgression.propTypes = {
  filterEndpoint: PropTypes.string,
  chartEndpoint: PropTypes.string,
  progressionDescription: PropTypes.string,
  buttonGroups: ProgressionChart.propTypes.buttonGroups,
  checkboxes: ProgressionChart.propTypes.checkboxes
};

InspectorProgression.defaultProps = {};

export default InspectorProgression;
