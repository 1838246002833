import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const CheckboxItem = ({
  id,
  onChange,
  onDisabledCallback,
  isDisabled,
  value,
  isPartiallyChecked,
  editorMode,
  handleEditorModeLabelChange,
  label
}) => {
  const handleChange = e => {
    if (e.target.disabled) onDisabledCallback(e);
    else {
      onChange(e.target.checked, e.target.id);
    }
  };

  return (
    <div className={'checkbox-input'}>
      <input
        id={id}
        disabled={isDisabled}
        onChange={handleChange}
        type="checkbox"
        checked={value}
        className={cn('checkbox-input__input', {
          'checkbox-input__input--partially-checked': isPartiallyChecked
        })}
      />
      {editorMode ? (
        <div className="checkbox-input__label">
          <input
            value={label}
            id={id}
            onChange={e =>
              handleEditorModeLabelChange(id, e.target.value, false)
            }
          />
          <button
            onClick={() => handleEditorModeLabelChange(id, undefined, true)}
          >
            Fjern
          </button>
        </div>
      ) : (
        <label htmlFor={id} className="checkbox-input__label">
          <span className="checkbox-input__label-text">{label}</span>
        </label>
      )}
    </div>
  );
};

CheckboxItem.inputProps = {
  id: PropTypes.string.isRequired,
  inputKey: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  isPartiallyChecked: PropTypes.bool
};

CheckboxItem.propTypes = {
  ...CheckboxItem.inputProps,
  isDisabled: PropTypes.bool, //ignore backend
  onChange: PropTypes.func, //ignore backend
  onDisabledCallback: PropTypes.func, //ignore backend
  editorMode: PropTypes.bool, //ignore backend
  handleEditorModeLabelChange: PropTypes.func //ignore backend
};

CheckboxItem.defaultProps = {
  onChange: () => {},
  onDisabledCallback: () => {},
  checked: false
};

export default CheckboxItem;
