import React from 'react';
import PropTypes from 'prop-types';

import Editor from './editor';

const RichTextEditor = ({
  id,
  value,
  placeholder,
  readOnly,
  onChange,
  onSave,
  onCancel,
  isDisabled,
  hasActionButtons,
  label,
  isLarge,
  hasCustomToolbar
}) => {
  return (
    <div className="rich-text-editor">
      {label && <label className="rich-text-editor__label">{label}</label>}
      <div className="rich-text-editor__canvas">
        <Editor
          id={id}
          value={value}
          onSave={onSave}
          onCancel={onCancel}
          isDisabled={isDisabled}
          readOnly={readOnly}
          isLarge={isLarge}
          hasCustomToolbar={hasCustomToolbar}
          hasActionButtons={hasActionButtons}
          onChange={onChange}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

RichTextEditor.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  isLarge: PropTypes.bool,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  hasCustomToolbar: PropTypes.bool,
  hasActionButtons: PropTypes.bool,
  readOnly: PropTypes.bool
};

RichTextEditor.propTypesMeta = {
  shouldFocusOnMount: 'exclude',
  isDisabled: 'exclude',
  isLarge: 'exclude',
  hasActionButtons: 'exclude',
  hasCustomToolbar: 'exclude'
};

RichTextEditor.defaultProps = {
  hasCustomToolbar: true,
  hasActionButtons: true
};

export default RichTextEditor;
