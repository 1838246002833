import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '../modal';
import Clicker from '../../clicker';
import InputErrorMessage from 'components/input-error-message';
import SearchWithDropdown from 'components/search-with-dropdown/search-with-dropdown';
import Bruker from 'components/bruker/bruker';

import cn from 'classnames';

const AddBrukerrolleModal = ({
  isOpen,
  onClose,
  onConfirm,
  isDisabled,
  errorMessage,
  search,
  closeModalHiddenText,
  confirmButtonText,
  cancelButtonText,
  title,
  lead,
  nrOfSelectedBrukerePostfixText
}) => {
  const [users, setUsers] = useState([]);

  const handleOnConfirm = async () => {
    onConfirm(users);
  };

  const handleOnPersonChoice = item => {
    setUsers(oldUsers => [...oldUsers, item]);
  };

  const handleOnPersonRemove = userId => {
    setUsers(users => users.filter(user => user.id !== userId));
  };

  return (
    <Modal
      themes={[Modal.themes.white]}
      isOpen={isOpen}
      onClose={onClose}
      isDisabled={isDisabled}
      closeButtonLabel={closeModalHiddenText}
    >
      <div className="add-brukerrolle-modal">
        <div className="add-brukerrolle-modal__content">
          {title && <h2 className="add-brukerrolle-modal__title">{title}</h2>}
          {lead && <p className="add-brukerrolle-modal__lead">{lead}</p>}
          <div className="add-brukerrolle-modal__row">
            <SearchWithDropdown
              {...search}
              onChange={handleOnPersonChoice}
              RenderedResultElement={Bruker}
            />
            {users?.length > 0 && (
              <>
                <div
                  className={cn('add-brukerrolle-modal__selected-box', {
                    'add-brukerrolle-modal__selected-box--multiple':
                      users.length >= 2
                  })}
                >
                  <div className="add-brukerrolle-modal__selected-label">
                    {users.length + ' ' + nrOfSelectedBrukerePostfixText}
                  </div>
                  {users.map(user => (
                    <Bruker
                      {...user}
                      key={user.id}
                      className="add-brukerrolle-modal__user"
                      onRemoveSelection={() => handleOnPersonRemove(user.id)}
                    />
                  ))}
                </div>
                <InputErrorMessage
                  errors={errorMessage ? [errorMessage] : []}
                />
              </>
            )}
          </div>
          <div className="add-brukerrolle-modal__actions">
            <div className="add-brukerrolle-modal__action">
              <Clicker
                text={cancelButtonText}
                theme={Clicker.themes.secondary}
                onClick={onClose}
              />
            </div>
            <div className="add-brukerrolle-modal__action">
              <Clicker
                text={confirmButtonText}
                theme={Clicker.themes.primary}
                onClick={handleOnConfirm}
                isDisabled={!users?.length}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddBrukerrolleModal.propTypes = {
  isDisabled: PropTypes.bool, //exclude from backend
  onClose: PropTypes.func, //exclude from backend
  onConfirm: PropTypes.func, //exclude from backend
  isOpen: PropTypes.bool, //exclude from backend
  showTextInput: PropTypes.bool, //exclude from backend
  errorMessage: PropTypes.string, //exclude from backend

  closeModalHiddenText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  title: PropTypes.string,
  lead: PropTypes.string,
  nrOfSelectedBrukerePostfixText: PropTypes.string,
  search: PropTypes.exact(SearchWithDropdown.propTypes)
};

AddBrukerrolleModal.defaultProps = {
  onConfirm: () => {},
  onClose: () => {}
};

export default AddBrukerrolleModal;
