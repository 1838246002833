import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ConfirmationModal from 'components/modal/confirmation-modal';
import ResourceTable from 'components/resource-table/resource-table';
import usePhrases from '../../../hooks/use-phrases';

const StudentAdminTable = ({
  table,
  assignTeacherClick,
  moveStudentClick,
  declineMoveStudentClick,
  confirmationModal,
  editClick,
  onRemove,
  showMenus
}) => {
  const {
    editTeacher,
    editStudent,
    moveStudent,
    declineMoveStudent,
    removeStudent,
    confirmDelete
  } = usePhrases();

  const [modalState, setModalState] = useState({
    modal: confirmationModal,
    pendingIds: []
  });

  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  const decorateWithMenu = resource => {
    let items = [];

    items = [
      { text: editTeacher, onClick: (ids, res) => assignTeacherClick(res) },
      { text: editStudent, onClick: (ids, res) => editClick(res) }
    ];

    items.push({
      text: removeStudent,
      onClick: (ids, res) => {
        setModalState({
          ...modalState,
          pendingIds: ids,
          modal: {
            ...modalState.modal,
            title: `Sletting av ${res[0].displayName}`,
            lead: `Sletting av eleven vil fjerne alle elevens persondata fra tjenesten. Det vil imidlertid ligge igjen anonymiserte aktivitetslogger og tilsvarende tekniske spor, men disse vil ikke kunne knyttes til elevens identitet igjen senere.`
          }
        });

        setConfirmationModalIsOpen(true);
      }
    });

    items.push({
      text: moveStudent,
      onClick: (ids, res) => moveStudentClick(res)
    });

    if (resource.requestMoved) {
      items.push({
        text: declineMoveStudent,
        onClick: (ids, res) => declineMoveStudentClick(res),
        className: 'student-admin__decline-button'
      });
    }

    return {
      ...resource,
      menuItems: items
    };
  };

  return (
    <>
      <div className="student-admin-table">
        <ResourceTable
          {...table}
          showLinks={false}
          showMenus={showMenus}
          resources={table?.resources.map(resource =>
            decorateWithMenu(resource)
          )}
        />
      </div>
      {confirmationModalIsOpen && (
        <ConfirmationModal
          {...modalState.modal}
          confirmText={confirmDelete}
          isOpen={confirmationModalIsOpen}
          onConfirm={() => {
            setConfirmationModalIsOpen(false);

            onRemove(modalState.pendingIds);
          }}
          onClose={() => setConfirmationModalIsOpen(false)}
        />
      )}
    </>
  );
};

StudentAdminTable.propTypes = {
  showMenus: PropTypes.bool, // exclude from backend
  assignTeacherClick: PropTypes.func, // exclude from backend
  moveStudentClick: PropTypes.func, // exclude from backend
  declineMoveStudentClick: PropTypes.func, // exclude from backend
  confirmationModal: PropTypes.exact(ConfirmationModal.propTypes), // exclude from backend
  editClick: PropTypes.func, // exclude from backend
  onRemove: PropTypes.func, // exclude from backend
  table: PropTypes.exact(ResourceTable.propTypes) // exclude from backend
};

StudentAdminTable.defaultProps = {
  showMenus: true
};

export default StudentAdminTable;
