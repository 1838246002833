import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import api from 'js/api-helper';

import SamtaleContext from '../../contexts/samtale-context';

import Clicker from 'components/clicker/clicker';
import Icon from 'components/icon/icon';
import ConfirmationModal from 'components/modal/confirmation-modal';
import LoadingSpinner from 'components/loading-spinner/loading-spinner';

const SamtaleInputSelect = ({
  inputIsActive,
  questionId,
  onChange,
  addedItems,
  placeholder,
  addButtonText,
  removeButtonPreFix,
  searchFieldLabel,
  searchEndpoint,
  checkLanguageHasDataEndpoint,
  addCurrentValuePretext,
  noResultsText
}) => {
  const [currentAddedItems, setCurrentAddedItems] = useState(addedItems);
  const [searchResults, setSearchResults] = useState([]);
  const [noSearchResult, setNoSearchResult] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [
    checkLanguageIsLoadingController,
    setCheckLanguageIsLoadingController
  ] = useState({
    target: '',
    isLoading: false
  });
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(true);
  const [
    removeLanguageConfirmationModal,
    setRemoveLanguageConfirmationModal
  ] = useState(null);
  const [
    removeLanguageConfirmFunction,
    setRemoveLanguageConfirmFunction
  ] = useState(null);

  const searchFieldRef = useRef(null);
  const timeoutRef = useRef(null);

  const { setKnownLanguages, samtaleId } = useContext(SamtaleContext);

  const handleInputChange = e => {
    let newValue = e.target.value;
    setNoSearchResult(false);
    setSearchValue(newValue.replace(/\s\s+/g, ' '));
  };

  const resetSearch = () => {
    setSearchValue('');
    setSearchResults([]);
  };

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      let { items } = await api.get(
        `${searchEndpoint}?languageSearchText=${searchValue}`
      );
      searchIsActive && setSearchResults(items);
      if (!items.length) setNoSearchResult(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const addItem = itemToAdd => {
    if (itemToAdd !== '') {
      const newItems = currentAddedItems.includes(itemToAdd)
        ? currentAddedItems
        : currentAddedItems.concat(itemToAdd);

      if (newItems !== currentAddedItems) {
        setCurrentAddedItems(newItems);

        const dataToSave = newItems.map(item => {
          return { answerId: item, answerValue: item };
        });
        onChange(dataToSave);
      }
    }
    setSearchIsActive(false);
    resetSearch();
  };

  const removeItem = targetLanguage => {
    const newItems = currentAddedItems.includes(targetLanguage)
      ? currentAddedItems.filter(language => language !== targetLanguage)
      : currentAddedItems;

    if (newItems !== currentAddedItems) {
      setCurrentAddedItems(newItems);

      const dataToSave = newItems.map(item => {
        return { answerId: item, answerValue: item };
      });
      onChange(dataToSave);
    }
  };

  const checkRemoveItem = async targetLanguage => {
    setCheckLanguageIsLoadingController({
      target: targetLanguage,
      isLoading: true
    });
    try {
      const res = await api.execute(checkLanguageHasDataEndpoint, {
        samtaleId,
        questionId,
        sprakNavn: targetLanguage
      });

      if (res.removeLanguageConfirmationModal) {
        setRemoveLanguageConfirmationModal(res.removeLanguageConfirmationModal);
        setRemoveLanguageConfirmFunction(() => () => {
          removeItem(targetLanguage);
          setConfirmationModalIsOpen(false);
        });
        setConfirmationModalIsOpen(true);
      } else {
        setRemoveLanguageConfirmationModal(null);
        setRemoveLanguageConfirmFunction(null);
        removeItem(targetLanguage);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setCheckLanguageIsLoadingController({ target: '', isLoading: false });
    }
  };

  const handleSearchSubmit = e => {
    e.preventDefault();

    const newItem = searchFieldRef.current.value;

    addItem(newItem);
  };

  const handleOpenSearchButton = () => {
    resetSearch();
    setSearchIsActive(true);
  };

  const handleSearchFieldKeyDown = e => {
    if (e.key === 'Escape') {
      resetSearch();
      setSearchIsActive(false);
    }
  };

  useEffect(() => {
    if (!searchIsActive) return;
    searchFieldRef.current.focus();
  }, [searchIsActive]);

  useEffect(() => {
    clearTimeout(timeoutRef.current);

    if ([...searchValue].length >= 2) {
      setIsLoading(true);
      timeoutRef.current = setTimeout(() => {
        handleSearch();
      }, 300);
    }

    if (searchValue === '') {
      setSearchResults([]);
      setIsLoading(false);
    }
  }, [searchValue]);

  useEffect(() => {
    setKnownLanguages(currentAddedItems);
  }, [currentAddedItems]);

  return (
    <>
      <div className="samtale-input__select">
        <div
          className={cn('samtale-input__select-inputs', {
            'samtale-input__select-inputs--visible': inputIsActive
          })}
        >
          {currentAddedItems && !!currentAddedItems.length && (
            <ul className="samtale-input__select-language-list">
              {currentAddedItems.map(item => (
                <li key={item} className="samtale-input__select-language">
                  <span className="samtale-input__select-language-text">
                    {item}
                  </span>
                  <div className="samtale-input__select-language-actions-wrapper">
                    {checkLanguageIsLoadingController.isLoading &&
                      checkLanguageIsLoadingController.target === item && (
                        <LoadingSpinner shouldTakeOverScreen={false} />
                      )}
                    {removeButtonPreFix && (
                      <Clicker
                        className="samtale-input__select-language-button"
                        onClick={() => checkRemoveItem(item)}
                        iconName={Clicker.iconNames.closeCircleBlack}
                        iconSize={Clicker.iconSizes.medium}
                      >
                        <span className="samtale-input__select-language-button-text">
                          {removeButtonPreFix + item}
                        </span>
                      </Clicker>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          )}
          <div className="samtale-input__select-input-wrapper">
            <button
              className={cn('samtale-input__select-button', {
                'samtale-input__select-button--visible': !searchIsActive
              })}
              onClick={handleOpenSearchButton}
            >
              <Icon name={Icon.names.plusCircle} size={Icon.sizes.medium} />
              {addButtonText}
            </button>
            <form
              className={cn('samtale-input__select-search-wrapper', {
                'samtale-input__select-search-wrapper--visible': searchIsActive
              })}
              onSubmit={handleSearchSubmit}
            >
              <input
                className={cn('samtale-input__select-search-field', {
                  'samtale-input__select-search-field--active':
                    isLoading || [...searchValue].length >= 2
                })}
                ref={searchFieldRef}
                onChange={e => handleInputChange(e)}
                aria-label={searchFieldLabel}
                aria-describedby={questionId}
                placeholder={placeholder}
                value={searchValue}
                onKeyDown={handleSearchFieldKeyDown}
              />
              {isLoading ? (
                <div className="samtale-input__loading-spinner">
                  <LoadingSpinner />
                </div>
              ) : searchResults.length ? (
                <ul className="samtale-input__select-results">
                  {searchResults
                    .filter((l, i) => i < 5)
                    .map(({ norwegianName, nativeName, iso6391Code }) => (
                      <li
                        key={norwegianName}
                        className="samtale-input__select-result-item"
                      >
                        <button
                          className="samtale-input__select-result-button"
                          type="button"
                          onClick={() => addItem(norwegianName)}
                        >
                          <span className="samtale-input__select-result-text">
                            {norwegianName}&nbsp;
                          </span>
                          <span
                            className="samtale-input__select-result-text"
                            lang={iso6391Code}
                          >
                            ({nativeName})
                          </span>
                        </button>
                      </li>
                    ))}
                </ul>
              ) : noSearchResult && [...searchValue].length >= 2 ? (
                <p className="samtale-input__select-no-result-text">
                  {noResultsText}
                </p>
              ) : null}
              {[...searchValue].length >= 2 && (
                <div className="samtale-input__select-add-button-wrapper">
                  <Clicker
                    className="samtale-input__select-add-button"
                    onClick={handleSearchSubmit}
                    text={`${addCurrentValuePretext} "${searchValue}"`}
                    iconName={Clicker.iconNames.plusCircle}
                    iconSize={Clicker.iconSizes.mediumLarge} // TODO: change to mediumplus when KVM-609_Responsivitet-kort-og-tabell is merged to develop20
                    iconIsBeforeText={true}
                  />
                </div>
              )}
            </form>
          </div>
        </div>
        <div
          className={cn('samtale-input__select-preview', {
            'samtale-input__select-preview--visible': !inputIsActive,
            'samtale-input__select-preview--not-empty':
              currentAddedItems && !!currentAddedItems.length
          })}
        >
          {currentAddedItems && !!currentAddedItems.length && (
            <ul className="samtale-input__select-preview-list">
              {currentAddedItems.map(language => (
                <li
                  key={language}
                  className="samtale-input__select-preview-item"
                >
                  {language}
                </li>
              ))}
            </ul>
          )}
        </div>
        <p
          className={cn('samtale-input__pdf-inputs', {
            'samtale-input__pdf-inputs--visible': inputIsActive
          })}
        >
          {currentAddedItems.join(', ')}
        </p>
      </div>
      {removeLanguageConfirmationModal && (
        <ConfirmationModal
          {...removeLanguageConfirmationModal}
          isOpen={confirmationModalIsOpen}
          onClose={() => setConfirmationModalIsOpen(false)}
          onConfirm={removeLanguageConfirmFunction}
        />
      )}
    </>
  );
};

SamtaleInputSelect.propTypes = {
  inputIsActive: PropTypes.bool, // exclude from BE
  onChange: PropTypes.func, // exclude from BE
  questionId: PropTypes.string, // exclude from BE
  addedItems: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  addButtonText: PropTypes.string,
  removeButtonPreFix: PropTypes.string,
  searchFieldLabel: PropTypes.string,
  searchEndpoint: PropTypes.string,
  checkLanguageHasDataEndpoint: PropTypes.string,
  addCurrentValuePretext: PropTypes.string,
  noResultsText: PropTypes.string
};

export default SamtaleInputSelect;
