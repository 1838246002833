
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import useLocaleDate from '../../hooks/use-locale-date';
import HistoryEntry from '../../components/history-entry';

const PdfKartleggingHistroyPage = ({ heading, subheading, kartleggingEntries }) => {
  return (
    <div className="pdf-kartlegging-history-page">
      <div className="content-header">
        <hgroup className="content-header__heading-group">
          <h2 className="content-header__heading">{heading}</h2>
          <p className="content-header__subheading">{subheading}</p>
        </hgroup>
      </div>
      {kartleggingEntries?.map(({date, name, ferdighetsomradeList}, index) => {
        const { formattedDate } = useLocaleDate(date);
        return (
          <li key={index} className="history-entry pdf-kartlegging-history-page__entry">
            <span className="history-entry__date pdf-kartlegging-history-page__entry-date">{formattedDate}</span>
            <span className="history-entry__name pdf-kartlegging-history-page__entry-name">{name}</span>
            <div className="history-entry__body pdf-kartlegging-history-page__ferdighetsomrade-list">
              {!!ferdighetsomradeList?.length && (
                <ul className="history-entry__kartlegging-list">
                  {ferdighetsomradeList.map(
                    ({ name, color, mestringsnivaList }) => (
                      <li key={name} className="history-entry__list-item">
                        <span
                          className={cn(
                            'history-entry__ferdighetsomrade',
                            'history-entry__ferdighetsomrade--' + color
                          )}
                        >
                          {name}
                        </span>
                        <ul>
                          {mestringsnivaList?.map(({ name, utsagnList }) => (
                            <li key={name} className="history-entry__list-item">
                              {name}
                              <ul>
                                {utsagnList?.map(({ id, changeInfo }) => (
                                  <li
                                    key={id}
                                    className="history-entry__list-item history-entry__entry"
                                  >
                                    <span className="history-entry__entry-id">
                                      {id}
                                    </span>
                                    {changeInfo}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
          </li>
        );
      })}
    </div>
  );
};

PdfKartleggingHistroyPage.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  kartleggingEntries: PropTypes.arrayOf(PropTypes.exact(HistoryEntry.propTypes))
};

export default PdfKartleggingHistroyPage;
