import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import arrayOfObjectValues from 'js/array-of-object-values';

const themes = {
  greenInput: 'green-input'
};

const Radio = ({
  className,
  isInitiallySelected,
  label,
  name,
  onChange,
  value,
  themes,
  disabled,
  isControlledInput
}) => {
  const themeClasses = themes.map(theme => `radio--${theme}`);
  const id = `radio-${name}-${value}`;

  return (
    <div className={cn('radio', themeClasses)}>
      {isControlledInput ? (
        <input
          type="radio"
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          checked={isInitiallySelected}
        />
      ) : (
        <input
          type="radio"
          id={id}
          disabled={disabled}
          name={name}
          onChange={onChange}
          value={value}
          defaultChecked={isInitiallySelected}
        />
      )}
      <label className={className} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  isInitiallySelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  themes: arrayOfObjectValues(themes),
  isControlledInput: PropTypes.bool
};

Radio.defaultProps = {
  themes: [],
  onChange: () => {}
};

Radio.propTypesMeta = {
  className: 'exclude',
  namePostfix: 'exclude',
  themes: 'exclude',
  disabled: 'exclude',
  isControlledInput: 'exclude'
};

Radio.getKey = props => props.value;

Radio.themes = themes;

export default Radio;
