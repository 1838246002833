import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/icon/icon';

const InputErrorMessage = ({
  errors,
  consequenceMessage,
  isDirty,
  children
}) => {
  return (
    <div
      className={cn('input-error-message', {
        'input-error-message--has-error': errors.length
      })}
    >
      {children}
      <div role="alert">
        {errors && !!errors.length && (
          <ul className="input-error-message__error-list">
            {errors.map(errText => (
              <li key={errText} className="input-error-message__error-text">
                {errText}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div role="alert">
        {isDirty && consequenceMessage && (
          <div className="input-error-message__consequence">
            <Icon
              className="input-error-message__consequence-icon"
              name="warning"
            />
            {consequenceMessage}
          </div>
        )}
      </div>
    </div>
  );
};

InputErrorMessage.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  consequenceMessage: PropTypes.string,
  isDirty: PropTypes.bool,
  children: PropTypes.element
};

InputErrorMessage.defaultProps = {
  errors: []
};

export default InputErrorMessage;
