import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import api from '../../../js/api-helper';

import Modal from '../modal';
import Clicker from '../../clicker';
import LoadingSpinner from '../../loading-spinner';
import InputByType from 'components/input-by-type';
import RadioGroup from '../../radio-group';
import StudentCommonFields from '../student-common-fields/student-common-fields';
import SearchWithDropdown from 'components/search-with-dropdown';
import Bruker from 'components/bruker/bruker';
import InputErrorMessage from 'components/input-error-message';

const EditStudentModal = ({
  id,
  isOpen,
  onClose,
  onConfirm,
  isDisabled,
  closeModalHiddenText,
  saveStudentText,
  cancelButtonText,
  title,
  lead,
  requiredFieldDescription,
  editSubmitEndpoint,
  firstNameTextInput,
  lastNameTextInput,
  dNumberTextInput,
  dufNumberTextInput,
  birthNumberTextInput,
  gradeInput,
  assignTeacherInput,
  chooseIdTypeRadioGroup,
  changedIdTypeAriaLiveText,
  chosenIdType
}) => {
  const [state, setState] = useState({
    isLoading: false,
    isDisabled: false,
    isDirty: false,
    gradeInput,
    firstNameTextInput,
    lastNameTextInput,
    dNumberTextInput,
    dufNumberTextInput,
    birthNumberTextInput,
    assignTeacherInput: {
      ...assignTeacherInput,
      value: assignTeacherInput?.value ?? []
    }
  });
  const [currentIdType, setCurrentIdType] = useState(
    chosenIdType || StudentCommonFields.idTypes.fnr
  );
  const [inputErrors, setInputErrors] = useState({});

  const toggleLoading = isLoading => {
    setState(prev => {
      return {
        ...prev,
        isLoading
      };
    });
  };

  const checkGeneralError = (value, key, stateKey) => {
    if (inputErrors[key]) setInputErrors({ ...inputErrors, [key]: [] });
    setState({
      ...state,
      isDirty: true,
      [stateKey]: {
        ...state[stateKey],
        value: value,
        isDirty: true
      }
    });
  };

  const handleOnError = err => {
    setInputErrors(err);
  };

  const handleIsValid = isValid => {
    setState(prevState => ({
      ...prevState,
      isDisabled: !isValid
    }));
  };

  const handleOnTeacherChoice = item => {
    checkGeneralError(
      [...state.assignTeacherInput.value, item],
      'assignedTeachers',
      'assignTeacherInput'
    );
  };

  const handleOnTeacherRemove = userId => {
    checkGeneralError(
      state.assignTeacherInput.value.filter(user => user.id !== userId),
      'assignedTeachers',
      'assignTeacherInput'
    );
  };

  const handleOnEditSubmit = async () => {
    toggleLoading(true);
    setInputErrors({});

    try {
      const resource = await api.execute(editSubmitEndpoint, {
        id,
        firstName: state.firstNameTextInput.value,
        lastName: state.lastNameTextInput.value,
        birthNumber:
          currentIdType === StudentCommonFields.idTypes.fnr
            ? state.birthNumberTextInput.value
            : '',
        dufNumber:
          currentIdType === StudentCommonFields.idTypes.dufnr
            ? state.dufNumberTextInput.value
            : '',
        dNumber:
          currentIdType === StudentCommonFields.idTypes.dnr
            ? state.dNumberTextInput.value
            : '',
        grade: state.gradeInput.value,
        assignedTeachers: state.assignTeacherInput.value?.map(
          teacher => teacher.id
        )
      });

      onConfirm(resource);
    } catch (e) {
      toggleLoading(false);
      // eslint-disable-next-line no-console
      console.log(e);
      if (e.status === 400) {
        handleOnError(e.errors);
      }
    }
  };

  const closeModal = () => {
    onClose();
  };

  useEffect(() => {
    return () => {
      toggleLoading(false);
    };
  }, []);

  useEffect(() => {
    let firstIdWithError;

    if (inputErrors.firstName) firstIdWithError = state?.firstNameTextInput.id;
    else if (inputErrors.lastName)
      firstIdWithError = state?.lastNameTextInput.id;
    else if (inputErrors.birthNumber)
      firstIdWithError = state?.birthNumberTextInput.id;
    else if (inputErrors.dNumber) firstIdWithError = state?.dNumberTextInput.id;
    else if (inputErrors.dufNumber)
      firstIdWithError = state?.dufNumberTextInput.id;
    else if (inputErrors.gradeInput) firstIdWithError = state?.gradeInput.id;

    if (firstIdWithError) {
      const elementToFocus = document.getElementById(firstIdWithError);
      if (elementToFocus) elementToFocus.focus();
    }
  }, [inputErrors]);

  return (
    <Modal
      themes={[Modal.themes.white]}
      isOpen={isOpen}
      onClose={closeModal}
      isDisabled={isDisabled || state.isLoading}
      closeButtonLabel={closeModalHiddenText}
    >
      <div className="edit-student-modal">
        <div className="edit-student-modal__content">
          <div className="edit-student-modal__left">
            {title && <h2 className="edit-student-modal__title">{title}</h2>}

            {requiredFieldDescription && (
              <div className="edit-student-modal__required-field-desc">
                {requiredFieldDescription}
              </div>
            )}

            <StudentCommonFields
              lead={lead}
              firstNameTextInput={state.firstNameTextInput}
              lastNameTextInput={state.lastNameTextInput}
              dNumberTextInput={state.dNumberTextInput}
              dufNumberTextInput={state.dufNumberTextInput}
              birthNumberTextInput={state.birthNumberTextInput}
              gradeInput={state.gradeInput}
              chooseIdTypeRadioGroup={chooseIdTypeRadioGroup}
              changedIdTypeAriaLiveText={changedIdTypeAriaLiveText}
              onChange={checkGeneralError}
              isValidCallback={handleIsValid}
              inputErrors={inputErrors}
              chosenIdType={currentIdType}
              setCurrentIdType={setCurrentIdType}
            />
            {assignTeacherInput && (
              <div className="edit-student-modal__add-teacher-wrapper">
                <InputErrorMessage errors={inputErrors?.as}>
                  <>
                    <SearchWithDropdown
                      {...assignTeacherInput.search}
                      onChange={handleOnTeacherChoice}
                      RenderedResultElement={Bruker}
                      theme={SearchWithDropdown.themes.bordered}
                      elevId={id}
                    />
                    {state.assignTeacherInput.value?.length > 0 && (
                      <>
                        <div
                          className={cn('edit-student-modal__selected-box', {
                            'edit-student-modal__selected-box--multiple':
                              state.assignTeacherInput.value.length >= 2
                          })}
                        >
                          <div className="edit-student-modal__selected-label">
                            {state.assignTeacherInput.value.length +
                              ' ' +
                              assignTeacherInput.nrOfSelectedBrukerePostfixText}
                          </div>
                          {state.assignTeacherInput.value.map(user => (
                            <Bruker
                              {...user}
                              key={user.id}
                              className="edit-student-modal__user"
                              onRemoveSelection={() =>
                                handleOnTeacherRemove(user.id)
                              }
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </>
                </InputErrorMessage>
              </div>
            )}
            <div className="edit-student-modal__actions">
              <div className="edit-student-modal__action-wrapper">
                {cancelButtonText && (
                  <div className="edit-student-modal__action">
                    <Clicker
                      text={cancelButtonText}
                      theme={Clicker.themes.secondary}
                      onClick={closeModal}
                    />
                  </div>
                )}
                {saveStudentText && (
                  <div className="edit-student-modal__action">
                    <Clicker
                      text={saveStudentText}
                      theme={Clicker.themes.primary}
                      onClick={handleOnEditSubmit}
                      isDisabled={state.isDisabled || !state.isDirty}
                    />
                  </div>
                )}
                <div className="edit-student-modal__action">
                  {state.isLoading && <LoadingSpinner />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const textInputProps = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

EditStudentModal.propTypes = {
  isDisabled: PropTypes.bool, //exclude from backend
  onClose: PropTypes.func, //exclude from backend
  onConfirm: PropTypes.func, //exclude from backend
  isOpen: PropTypes.bool, //exclude from backend
  id: PropTypes.string, //exclude from backend
  closeModalHiddenText: PropTypes.string,
  saveStudentText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  title: PropTypes.string,
  lead: PropTypes.string,
  requiredFieldDescription: PropTypes.string,
  editSubmitEndpoint: PropTypes.string,
  firstNameTextInput: PropTypes.exact(textInputProps),
  lastNameTextInput: PropTypes.exact(textInputProps),
  dufNumberTextInput: PropTypes.exact(textInputProps),
  dNumberTextInput: PropTypes.exact(textInputProps),
  birthNumberTextInput: PropTypes.exact(textInputProps),
  gradeInput: PropTypes.exact(InputByType.propTypes),
  assignTeacherInput: PropTypes.exact({
    search: PropTypes.exact(SearchWithDropdown.propTypes),
    nrOfSelectedBrukerePostfixText: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.exact(Bruker.propTypes))
  }),
  chooseIdTypeRadioGroup: PropTypes.exact(RadioGroup.propTypes),
  changedIdTypeAriaLiveText: PropTypes.string,
  chosenIdType: PropTypes.string
};

EditStudentModal.defaultProps = {
  onConfirm: () => {},
  onClose: () => {}
};

export default EditStudentModal;
