import InputErrorMessage from 'components/input-error-message';
import UdirRichTextEditor from 'components/rich-text-editor/rich-text-editor';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useMemo } from 'react';
import Clicker from 'components/clicker/clicker';
import TextInput from 'components/text-input';

export default function GenerellSystemtekstItem({
  handleChange,
  handleUndo,
  errorMessage,
  isRichText,
  systemtekstItem,
  maxLength,
  buttonText,
  sanityImageQuery
}) {
  const requiredInputRef = useRef(null);

  const handleRichTextChange = (_id, _value) => {
    handleChange(_id, _value, systemtekstItem.label);
  };
  const initialSystemtekstValue = useRef(systemtekstItem?.value);

  const undoDisabled = useMemo(() => {
    return initialSystemtekstValue.current === systemtekstItem?.value;
  }, [initialSystemtekstValue, systemtekstItem?.value]);
  useEffect(() => {
    if (requiredInputRef?.current && errorMessage) {
      requiredInputRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [requiredInputRef, errorMessage]);
  return (
    <li
      ref={requiredInputRef}
      className="redaktor-grensesnitt__data-content-group-item"
    >
      {errorMessage && <InputErrorMessage errors={[errorMessage]} />}
      {isRichText ? (
        <UdirRichTextEditor
          hasActionButtons={true}
          maxLength={maxLength}
          key={systemtekstItem.id + systemtekstItem.sprakKode}
          onChange={handleRichTextChange}
          id={systemtekstItem?.id}
          placeholder={'Skriv passende tekst for ' + systemtekstItem.label}
          value={systemtekstItem?.value || ''}
          sanityImageQuery={sanityImageQuery || `*[_type == "hjelpebilder"]`}
          label={systemtekstItem?.label}
        />
      ) : (
        <TextInput
          maxLength={maxLength}
          id={systemtekstItem?.id}
          key={systemtekstItem.id + systemtekstItem.sprakKode}
          value={systemtekstItem?.value || ''}
          label={systemtekstItem?.label}
          onChange={(value, id) => handleChange(id, value)}
        />
      )}
      <div className={'redaktor-grensesnitt__data-content-actions'}>
        <div>
          <Clicker
            isDisabled={undoDisabled}
            theme={Clicker.themes.secondary}
            onClick={() => handleUndo(systemtekstItem.id)}
            text={buttonText.cancel}
          />
        </div>
        <div></div>
      </div>
    </li>
  );
}

GenerellSystemtekstItem.objectProps = PropTypes.exact({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  sprakKode: PropTypes.string.isRequired
});

GenerellSystemtekstItem.propTypes = {
  buttonText: PropTypes.exact({
    cancel: PropTypes.string.isRequired
  }).isRequired,
  handleChange: PropTypes.func.isRequired, //Ignore backend
  handleUndo: PropTypes.func.isRequired, //Ignore backend
  errorMessage: PropTypes.string, // ignore backend
  isRichText: PropTypes.bool, // ignore backend
  maxLength: PropTypes.number, // ignore backend
  systemtekstItem: GenerellSystemtekstItem.objectProps,
  sanityImageQuery: PropTypes.string
};
