import ArrowTooltip from 'components/arrow-tooltip/arrow-tooltip';
import Icon from 'components/icon';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import useClickOutside from '../../hooks/use-click-outside';
import Clicker from '../clicker';
import cn from 'classnames';

const Tooltip = ({
  className,
  title,
  text,
  textList,
  openButtonLabel,
  closeButtonLabel,
  iconName,
  activeIconName,
  iconIsPadded,
  iconSize,
  children
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const iconRef = useRef(null);
  const tooltipRef = useRef(null);

  useClickOutside(tooltipRef, () => setIsOpen(false));

  const renderTooltip = () => {
    const element = iconRef?.current;
    if (isOpen && element) {
      const { offsetLeft, offsetTop } = element;

      return (
        <ArrowTooltip
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          top={offsetTop + 36}
          left={offsetLeft}
          title={title}
          lead={text}
          list={textList}
          closeButtonLabel={closeButtonLabel}
        >
          {children}
        </ArrowTooltip>
      );
    } else return null;
  };

  return (
    <span
      className={cn('tooltip', className)}
      ref={tooltipRef}
      onKeyDown={e => e.key === 'Escape' && setIsOpen(false)}
    >
      <span className="tooltip__button-wrapper" ref={iconRef}>
        <Clicker
          className="tooltip__button"
          onClick={() => setIsOpen(prev => !prev)}
          aria-expanded={isOpen}
          isActive={isOpen}
          text={openButtonLabel || title}
          textIsHidden={true}
          iconName={iconName}
          activeIconName={activeIconName}
          iconSize={iconSize ? iconSize : Clicker.iconSizes.medium}
          iconIsPadded={iconIsPadded}
        />
      </span>
      {renderTooltip()}
    </span>
  );
};

Tooltip.propTypes = {
  className: PropTypes.string, //exclude from backend
  title: PropTypes.string,
  text: PropTypes.string,
  textList: PropTypes.arrayOf(PropTypes.string),
  openButtonLabel: PropTypes.string,
  closeButtonLabel: PropTypes.string,
  iconName: PropTypes.oneOf(Object.values(Icon.names)),
  activeIconName: PropTypes.oneOf(Object.values(Icon.names)),
  iconIsPadded: PropTypes.bool,
  iconSize: PropTypes.oneOf(Object.values(Icon.sizes)),
  children: PropTypes.any
};

Tooltip.defaultProps = {
  title: '',
  iconName: Icon.names.questionCircle
};

export default Tooltip;
