import React from 'react';
import PropTypes from 'prop-types';

const Breadcrumbs = ({ label, links }) => {
  return (
    <div className="breadcrumbs">
      <div className="breadcrumbs__content">
        {label && <span className="breadcrumbs__label">{label}</span>}
        {links && (
          <ul className="breadcrumbs__list">
            {links.map((link, index) => {
              return (
                <li className="breadcrumbs__item" key={index}>
                  {!link.isActive ? (
                    <a href={link.href} className="breadcrumbs__link">
                      {link.text}
                    </a>
                  ) : (
                    <span>{link.text}</span>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

Breadcrumbs.propTypes = {
  label: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      isActive: PropTypes.bool
    })
  )
};

Breadcrumbs.defaultProps = {
  links: []
};

export default Breadcrumbs;
