import React from 'react';
import PropTypes from 'prop-types';

import TextArea from 'components/text-area/text-area';
import CheckboxInputGroup from 'components/checkbox-input-group/checkbox-input-group';
import RadioGroupInput from 'components/radio-group-input/radio-group-input';
import TextInput from 'components/text-input';
import Tooltip from 'components/tooltip/tooltip';
import LanguageSelect from 'components/language-select';
import NumberInput from 'components/number-input';
import DateInput from 'components/date-input';
import NumberGroupInput from 'components/number-group-input';
import Clicker from 'components/clicker/clicker';
import InputErrorMessage from 'components/input-error-message';
import SelectInput from 'components/select-input/select-input';

InputByType.types = {
  hidden: 'hidden',
  text: 'text',
  date: 'date',
  number: 'number',
  numberGroup: 'numberGroup',
  checkbox: 'checkbox',
  radio: 'radio',
  textarea: 'textarea',
  dropdown: 'dropdown',
  languageSelect: 'languageSelect'
};

export default function InputByType({
  value,
  valueArray,
  id,
  inputKey,
  type,
  title,
  questionText,
  placeholder,
  tooltip,
  group,
  onBlur,
  errors,
  consequenceMessage,
  onChange,
  isDisabled,
  isReadOnly,
  maxLength,
  label,
  removeValueLabelPrefix,
  hrefLink,
  options,
  textInputTheme,
  isDirty,
  editorMode,
  handleEditorModeLabelChange,
  languageSearchEndpoint,
  addCurrentValuePretext,
  addLanguageButtonText,
  noResultsText,
  removeSearchValueButtonLabel
}) {
  const renderInputByType = () => {
    const props = {
      options,
      maxLength,
      value,
      valueArray,
      id,
      label,
      title,
      addCurrentValuePretext,
      addLanguageButtonText,
      removeValueLabelPrefix,
      inputKey,
      type,
      questionText,
      placeholder,
      tooltip,
      group,
      onChange,
      onBlur,
      isDisabled: isDisabled || editorMode,
      editorMode,
      handleEditorModeLabelChange,
      languageSearchEndpoint,
      noResultsText,
      removeSearchValueButtonLabel
    };

    switch (type) {
      case InputByType.types.hidden:
        return null;
      case InputByType.types.dropdown: {
        return (
          <SelectInput
            theme={textInputTheme || SelectInput.themes.default}
            key={id}
            {...props}
            onBlur={onBlur}
            onChange={onChange}
            isDisabled={isDisabled}
          />
        );
      }
      case InputByType.types.languageSelect: {
        if (isReadOnly) {
          return (
            <>
              <LanguageSelect {...props} />
              {hrefLink && !isDisabled && (
                <div className={'input-by-type__prev-link-wrapper'}>
                  <Clicker
                    text={hrefLink.text}
                    href={hrefLink.href}
                    theme={Clicker.themes.secondary}
                    iconIsBeforeText
                    iconName={Clicker.iconNames.arrowLeft}
                  />
                </div>
              )}
            </>
          );
        }
        return <LanguageSelect {...props} />;
      }
      case InputByType.types.date: {
        return (
          <DateInput
            theme={textInputTheme || DateInput.themes.default}
            {...props}
          />
        );
      }
      case InputByType.types.number: {
        return (
          <NumberInput
            maxLength={3}
            theme={NumberInput.themes.rowSmall}
            {...props}
          />
        );
      }
      case InputByType.types.text: {
        return (
          <TextInput
            theme={textInputTheme || TextInput.themes.default}
            {...props}
          />
        );
      }
      case InputByType.types.textarea: {
        return <TextArea {...props} />;
      }
      case InputByType.types.numberGroup: {
        return <NumberGroupInput {...props} />;
      }
      case InputByType.types.checkbox: {
        return <CheckboxInputGroup {...props} />;
      }
      case InputByType.types.radio: {
        return <RadioGroupInput {...props} />;
      }
      default:
        return 'no input match';
    }
  };

  return (
    <InputErrorMessage
      errors={errors}
      consequenceMessage={consequenceMessage}
      isDirty={isDirty}
    >
      {renderInputByType()}
    </InputErrorMessage>
  );
}

// This is the model used for all questions on the backend. It's quite flexible because questions + input can have a lot of different data
InputByType.propTypes = {
  onBlur: PropTypes.func, //exclude from backend
  onChange: PropTypes.func, //exclude from backend
  isDisabled: PropTypes.bool, //exclude from backend
  title: PropTypes.string, // exclude from backend
  editorMode: PropTypes.bool, // ignore backend
  isDirty: PropTypes.bool, // exclude from backend
  handleEditorModeLabelChange: PropTypes.func, // ignore backend
  textInputTheme: PropTypes.oneOf(Object.values(TextInput.themes)), // exclude from backend
  valueArray: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  maxLength: PropTypes.number, // maxLength is currently only used with type: 'textarray'
  id: PropTypes.string.isRequired,
  inputKey: PropTypes.string,
  // The type should be the same as an ENUM on backend, where these are defining which input should be rendered
  type: PropTypes.oneOf(Object.values(InputByType.types)),
  questionText: PropTypes.string,
  hrefLink: PropTypes.exact(Clicker.link), // Used with textArray if readOnly
  placeholder: PropTypes.string,
  tooltip: PropTypes.exact(Tooltip.propTypes),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string
    })
  ),
  defaultValueLabel: PropTypes.string,
  label: PropTypes.string,
  addCurrentValuePretext: PropTypes.string,
  addLanguageButtonText: PropTypes.string,
  removeValueLabelPrefix: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  consequenceMessage: PropTypes.string,
  isReadOnly: PropTypes.bool,
  group: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      inputKey: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]) // string in numberGroup, bool in checkbox/radio
    })
  ),
  languageSearchEndpoint: PropTypes.string,
  noResultsText: PropTypes.string,
  removeSearchValueButtonLabel: PropTypes.string
};

InputByType.defaultProps = {
  onBlur: () => {},
  onChange: () => {}
};
