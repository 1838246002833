import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import sanityClient from '../../../sanityClient';
import { serializers } from '../sanity-content-modal/serializers';

import { PortableText } from '@portabletext/react';
import Modal from '../modal';
import SanityModalEntry from './sanity-modal-entry';
import Clicker from 'components/clicker/clicker';

const SanityContentModal = ({
  id,
  isOpen,
  onClose,
  isDisabled,
  sanityTags,
  proceedText,
  closeModalHiddenText
}) => {
  const [sanityData, setSanityData] = useState(null);
  const [saniyError, setSanityError] = useState(null);

  useEffect(() => {
    function getQueryOrNothing(key) {
      if (!sanityTags) return;
      if (sanityTags[key]) return `&& ${key} == '${sanityTags[key]}' `;
      else return '';
    }
    function getTrinnQueryOrNothing(key) {
      if (!sanityTags) return;
      if (sanityTags[key]) return `&& '${sanityTags[key]}' in ${key} `;
      else return '';
    }

    const sanityQuery =
      '*[' +
      getQueryOrNothing('kategori') +
      getTrinnQueryOrNothing('trinn') +
      getQueryOrNothing('tekstType') +
      getQueryOrNothing('sprak') +
      ']';

    if (isOpen) {
      sanityClient
        .fetch(sanityQuery.replace('&&', ''))
        .then(data => {
          setSanityData(data);
        })
        .catch(() => {
          setSanityError('Noe gikk ved innlasting av data');
        });
    }
  }, [isOpen]);

  return (
    <Modal
      themes={[Modal.themes.gray]}
      isOpen={isOpen}
      onClose={onClose}
      isDisabled={isDisabled}
      closeButtonLabel={closeModalHiddenText}
    >
      <div className="sanity-content-modal">
        <div className="sanity-content-modal__content">
          {saniyError && <p>{saniyError}</p>}
          {sanityData?.map(({ general, chapters }, i) => {
            return (
              <div key={i}>
                <div className="sanity-content-modal__html-general-modal-introduction">
                  <h2>{general?.title}</h2>
                  {general?.brodtekst && (
                    <PortableText
                      value={general?.brodtekst}
                      components={serializers}
                    />
                  )}
                </div>
                <menu className="sanity-content-modal__list">
                  {chapters?.map(({ chapter, underChapters }, ind) => {
                    return (
                      <li
                        id={ind + chapter?.title}
                        key={ind + chapter?.title}
                        className="sanity-content-modal__item"
                      >
                        <SanityModalEntry
                          title={chapter?.title}
                          brodtekst={chapter?.brodtekst}
                          underChapters={underChapters}
                        />
                      </li>
                    );
                  })}
                </menu>
              </div>
            );
          })}
        </div>
        <div className="sanity-content-modal__action-bar">
          <Clicker
            text={proceedText}
            onClick={onClose}
            theme={Clicker.themes.primary}
          />
        </div>
      </div>
    </Modal>
  );
};

SanityContentModal.propTypes = {
  isDisabled: PropTypes.bool, //exclude from backend
  onClose: PropTypes.func, //exclude from backend
  isOpen: PropTypes.bool, //exclude from backend
  id: PropTypes.string, // Required if you want the toggleOnLoadEndpoint to work
  proceedText: PropTypes.string,
  closeModalHiddenText: PropTypes.string,
  sanityTags: PropTypes.exact({
    kategori: PropTypes.string,
    trinn: PropTypes.string.isRequired,
    tekstType: PropTypes.string.isRequired,
    sprak: PropTypes.string.isRequired
  })
};

SanityContentModal.defaultProps = {
  onConfirm: () => {},
  onClose: () => {},
  entries: []
};

export default SanityContentModal;
