import React from 'react';
import PropTypes from 'prop-types';

import BaseLayout from './base-layout';
import Header from '../header';
import Footer from '../footer';
import AdminHeader from '../admin-header/admin-header';
import ProfileHeader from '../profile-header/profile-header';
import NoticeBar from 'components/notice-bar/notice-bar';

const AdminLayout = ({
  children,
  header,
  footer,
  adminHeader,
  profileHeader,
  noticeBarBlock,
  ...rest
}) => {
  return (
    <BaseLayout color={BaseLayout.colors.gray} {...rest}>
      <Header {...header} theme={Header.themes.dark} />
      {adminHeader && <AdminHeader {...adminHeader} />}
      {profileHeader && (
        <ProfileHeader {...profileHeader} color={ProfileHeader.colors.gray} />
      )}
      {noticeBarBlock &&
        noticeBarBlock.noticeBars &&
        noticeBarBlock.noticeBars.map((noticeBar, index) => (
          <NoticeBar key={index} {...noticeBar} />
        ))}

      <div className="admin-layout">
        <main id="main-content" className="admin-layout__content">
          {children}
        </main>
      </div>
      <Footer {...footer} />
    </BaseLayout>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node, //excluded from backend
  header: PropTypes.exact(Header.propTypes),
  footer: PropTypes.exact(Footer.propTypes),
  adminHeader: PropTypes.exact(AdminHeader.propTypes),
  profileHeader: PropTypes.exact(ProfileHeader.propTypes),
  noticeBarBlock: PropTypes.exact({
    noticeBars: PropTypes.arrayOf(PropTypes.exact(NoticeBar.propTypes))
  }), //This is not just an array because backend can't send an array to this component
  ...BaseLayout.propTypes
};

export default AdminLayout;
