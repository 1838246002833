import React from 'react';
import PropTypes from 'prop-types';

import ResourceTable from 'components/resource-table';

const SearchAdminTable = ({ table, handleSearchItemClick }) => {
  const decorateWithButton = resource => {
    return {
      ...resource,
      nodeButton: {
        text: resource.buttonText,
        onClick: () => handleSearchItemClick({ nodeId: resource.nodeId })
      }
    };
  };

  return (
    <>
      <div className="user-admin-table">
        <ResourceTable
          showLinks={true}
          resources={table?.resources.map(resource =>
            decorateWithButton(resource)
          )}
          headerColumnMobileIndex={table?.headerColumnMobileIndex}
          headerColumns={table?.headerColumns}
        />
      </div>
    </>
  );
};

SearchAdminTable.propTypes = {
  table: PropTypes.exact(ResourceTable.propTypes), // exclude from backend
  handleSearchItemClick: () => {}
};

export default SearchAdminTable;
