import React from 'react';
import PropTypes from 'prop-types';

import SamtaleContextProvider from '../../contexts/samtale-context-provider';

import MainLayout from 'components/main-layout';
import StructuralLayout from 'components/structural-layout/structural-layout';
import SamtaleBlock from 'components/samtale-block';

const SamtalePage = ({
  structuralLayout,
  mainLayout,
  samtalePageGlobalProps,
  lead,
  blocks
}) => {
  return (
    <StructuralLayout {...structuralLayout}>
      <MainLayout {...mainLayout} thinLayout>
        <SamtaleContextProvider {...samtalePageGlobalProps}>
          <div className="samtale-page">
            {lead && (
              <div
                dangerouslySetInnerHTML={{ __html: lead }}
                className="samtale-page__lead"
              />
            )}
            {blocks &&
              !!blocks.length &&
              blocks.map((block, index) => (
                <SamtaleBlock key={index} {...block} />
              ))}
          </div>
        </SamtaleContextProvider>
      </MainLayout>
    </StructuralLayout>
  );
};

SamtalePage.propTypes = {
  structuralLayout: PropTypes.shape(StructuralLayout.propTypes),
  mainLayout: PropTypes.shape(MainLayout.propTypes),
  samtalePageGlobalProps: PropTypes.exact({
    saveEndpoint: PropTypes.string,
    samtaleId: PropTypes.string,
    readOnly: PropTypes.bool,
    initLanguages: PropTypes.arrayOf(PropTypes.string)
  }),
  lead: PropTypes.string,
  blocks: PropTypes.arrayOf(PropTypes.exact(SamtaleBlock.propTypes))
};

export default SamtalePage;
