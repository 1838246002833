import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/modal';
import LoadingSpinner from 'components/loading-spinner';
import Clicker from 'components/clicker';
import TextInput from 'components/text-input';
import InputByType from 'components/input-by-type';
import SelectInput from 'components/select-input/select-input';
import useQuestionnaireFormControl from '../../hooks/use-questionnaire-form-control';

const EditInfoModal = ({
  isOpen,
  onClose,
  onConfirm,
  isDisabled,
  closeModalHiddenText,
  title,
  lead,
  inputs,
  submitEndpoint,
  saveButtonText,
  cancelButtonText
}) => {
  const {
    questions,
    isLoading,
    isDirty,
    setQuestions,
    resetSections,
    handleOnInputChange,
    handleOnSubmit
  } = useQuestionnaireFormControl(inputs, submitEndpoint);

  const disableSave = React.useMemo(() => {
    return !isDirty || isLoading;
  }, [isDirty, isLoading]);

  const handleConfirm = async () => {
    try {
      const res = await handleOnSubmit();
      if (res.errors) {
        throw res;
      }
      const { infoList, title } = res;
      onConfirm(infoList, title);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const handleClose = () => {
    resetSections();
    onClose();
  };

  const handleIfSprakInputChange = (value, id) => {
    // 15.09 - ASK: Matches on inputKey must correspond to value from BE
    const sprakQuestion = questions.find(
      q => q.id === id && q.inputKey === 'sprak'
    );
    if (sprakQuestion) {
      const newQuestions = questions.map(question => {
        if (question.inputKey === 'morsmal') {
          const newCheckBoxGroup = question.group.filter(groupItem =>
            value.includes(groupItem.label)
          );
          if (newCheckBoxGroup.length !== value.length) {
            const filledCheckBoxGroup = value.map(sprak => {
              const checkboxForSprak = newCheckBoxGroup.find(
                checkbox => checkbox.label === sprak
              );
              if (checkboxForSprak) {
                return checkboxForSprak;
              }

              return {
                id: `newCheckboxId-${sprak}`,
                label: sprak,
                name: sprak,
                value: false
              };
            });
            return {
              ...question,
              group: filledCheckBoxGroup
            };
          }
          return {
            ...question,
            group: newCheckBoxGroup
          };
        }
        return question;
      });
      setQuestions(newQuestions);
    }
    handleOnInputChange(value, id);
  };

  return (
    <Modal
      themes={[Modal.themes.gray]}
      isOpen={isOpen}
      onClose={onClose}
      isDisabled={isDisabled}
      closeButtonLabel={closeModalHiddenText}
    >
      <div className="edit-info-modal">
        <div className="edit-info-modal__content">
          {title && <h2 className="edit-info-modal__title">{title}</h2>}
          {lead && <p className="edit-info-modal__lead">{lead}</p>}
          {questions && (
            <div className="edit-info-modal__inputs">
              {questions.map(input => {
                return (
                  <InputByType
                    textInputTheme={TextInput.themes.underlined}
                    key={input.id}
                    isDisabled={isDisabled}
                    onChange={handleIfSprakInputChange}
                    {...input}
                  />
                );
              })}
            </div>
          )}
          <div className="edit-info-modal__actions">
            <div className="edit-info-modal__actions">
              <div className="edit-info-modal__action">
                <Clicker
                  isDisabled={isDisabled}
                  onClick={handleClose}
                  text={cancelButtonText}
                  theme={Clicker.themes.secondary}
                />
              </div>
              <div className="edit-info-modal__action">
                <Clicker
                  isDisabled={disableSave}
                  onClick={handleConfirm}
                  text={saveButtonText}
                  theme={Clicker.themes.primary}
                />
              </div>
              {isLoading && (
                <div className="edit-info-modal__loading">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

EditInfoModal.propTypes = {
  isDisabled: PropTypes.bool, //exclude from backend
  disableSave: PropTypes.bool, //exclude from backend
  isLoading: PropTypes.bool, //exclude from backend
  onClose: PropTypes.func, //exclude from backend
  onConfirm: PropTypes.func, //exclude from backend
  isOpen: PropTypes.bool, //exclude from backend
  onInputChange: PropTypes.func, //exclude from backend
  closeModalHiddenText: PropTypes.string,
  title: PropTypes.string,
  lead: PropTypes.string,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      ...TextInput.propTypes,
      ...SelectInput.propTypes,
      type: PropTypes.string,
      value: PropTypes.string,
      valueArray: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  submitEndpoint: PropTypes.string,
  saveButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string
};

EditInfoModal.defaultProps = {
  onConfirm: () => {},
  onClose: () => {},
  inputs: []
};

export default EditInfoModal;
