/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React, {
  useMemo,
  forwardRef,
  useEffect,
  useContext,
  useState
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import TableStateContext from '../../contexts/table-state-context';

import { useTable, useSortBy, useRowSelect } from 'react-table';

import Icon from '../icon';
import Clicker from '../clicker';
import Resource from './resource';
import MenuButton from './menu-button';

import 'core-js/stable';
import { useWindowSize } from '../../hooks/useWindowSize';
import Modal from 'components/modal/modal';
import InfoBubbleButton from 'components/info-bubble-button/info-bubble-button';
import PhrasesContext from '../../contexts/phrases-context';

const columnName = index => {
  return `col${index}`;
};

const TableCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;
  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

const ResourceTable = ({
  resources,
  headerColumnMobileIndex,
  headerColumns,
  infoText,
  showMenus,
  showLinks,
  showCheckboxes,
  onSortChangeCallback
}) => {
  const { width } = useWindowSize();
  const [modalData, setModalData] = useState(null);

  const { currentSort, setCurrentSort } = useContext(TableStateContext);
  const { requestDeleteText, moreInfo } = useContext(PhrasesContext);

  const isMobile = useMemo(() => width < 768, [width]);
  const closeModal = () => setModalData(null);

  const uniqueTableHeader = useMemo(() => {
    return headerColumns.join().replace(/,/g, '');
  }, [headerColumns]);

  const data = useMemo(() => {
    return resources.map(resource => {
      return {
        ...resource,
        columns: resource.columns.reduce((acc, column, index) => {
          acc[columnName(index + 1)] = column;

          return acc;
        }, {})
      };
    }, []);
  }, [resources, isMobile]);

  const { columns, defaultSortAccessor } = useMemo(() => {
    const nonSortable = ['epost', 'email', 'mobil', 'telefon', 'dato', 'orgnr'];
    let defaultSortAccessor;

    const columns = headerColumns.reduce((acc, column, index) => {
      const disableSort = nonSortable.some(nonSort =>
        column.toLowerCase().includes(nonSort)
      );

      const accessor = columnName(index + 1);
      if (!disableSort && !defaultSortAccessor) {
        defaultSortAccessor = accessor;
      }
      const col = acc.concat({
        Header: column,
        accessor,
        isVisible: isMobile
          ? accessor === columnName(headerColumnMobileIndex + 1)
            ? true
            : false
          : true,
        sortType: (a, b, columnId) => {
          return a.original.columns[columnId].text >
            b.original.columns[columnId].text
            ? 1
            : -1;
        },
        disableSortBy: disableSort
      });
      return col;
    }, []);
    return { columns, defaultSortAccessor };
  }, [resources, isMobile]);

  useEffect(() => {
    if (onSortChangeCallback) {
      onSortChangeCallback(defaultSortAccessor);
    }
  }, [defaultSortAccessor]);

  // const hiddenColumns = isMobile
  //   ? columns
  //       .filter(col => col.accessor !== 'col1')
  //       .map(shownCols => shownCols.accessor)
  //   : [];
  const table = useTable(
    {
      columns,
      data,
      initialState: {
        // hiddenColumns,
        sortBy: [
          {
            id: currentSort[uniqueTableHeader] || defaultSortAccessor,
            desc: false
          }
        ]
      }
    },
    useSortBy,
    useRowSelect,
    hooks => {
      return hooks.visibleColumns.push(columns => {
        const mobileColumns =
          isMobile && columns[headerColumnMobileIndex]
            ? [columns[headerColumnMobileIndex]]
            : columns;

        let conditionalColumns = [];

        if (showCheckboxes)
          conditionalColumns.push({
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => {
              return <TableCheckbox {...getToggleAllRowsSelectedProps()} />;
            },
            Cell: ({ row }) => {
              const { onChange, ...rest } = row.getToggleRowSelectedProps();

              return <TableCheckbox {...rest} onChange={onChange} />;
            }
          });

        conditionalColumns.push(
          ...mobileColumns.map(column => {
            return {
              ...column,
              Cell: ({ row }) => {
                const { original } = row;

                if (!original.columns[column.id]) return <></>;

                if (original.columns[column.id].href)
                  return (
                    <Clicker
                      className={'resource-table__clicker-text'}
                      theme={Clicker.themes.underlined}
                      href={original.columns[column.id].href}
                      text={original.columns[column.id].text}
                    />
                  );

                return (
                  <span className="resource-table__table-cell-data">
                    {original.columns[column.id].text}
                    {original.columns[column.id].hasNotification ? (
                      <InfoBubbleButton
                        infoText={original.columns[column.id].notificationText}
                        buttonText={moreInfo}
                      />
                    ) : (
                      ''
                    )}
                  </span>
                );
              }
            };
          })
        );

        if (showMenus || isMobile) {
          conditionalColumns.push({
            id: 'menu',
            canSort: false,
            Header: undefined,
            Cell: ({ row, selectedFlatRows }) => {
              const { original } = row;

              if (original.menuItems?.length === 0) return <></>;
              if (!original.id) return <></>;
              if (!original.menuItems?.length) return null;
              return (
                <span style={{ display: 'flex' }}>
                  {isMobile && (
                    <>
                      <Clicker
                        iconName={
                          original.roleId === 'SL' && original.requestDelete
                            ? Clicker.iconNames.warningRed
                            : Clicker.iconNames.infoCircle
                        }
                        onClick={() =>
                          setModalData({
                            row: row,
                            columns: columns
                          })
                        }
                        textIsHidden
                        text={'Informasjon'}
                      />
                    </>
                  )}
                  {showMenus && (
                    <MenuButton
                      items={original.menuItems.map(item => {
                        if (item?.link) return item;

                        const resources = // If multiple are selected via checkboxes, send whole array. If not, only send the currently clicked item in the array.
                          selectedFlatRows.length === 0
                            ? [original]
                            : selectedFlatRows.map(row => row.original);

                        return {
                          ...item,
                          onClick: () => {
                            item.onClick(
                              // We pass both a simple array of id's AND an array of resources/columns in the click handler
                              resources.map(resource => resource.id),
                              resources.reduce(
                                (
                                  acc,
                                  {
                                    id,
                                    orgId,
                                    userId,
                                    roleId,
                                    displayName,
                                    columns
                                  }
                                ) => {
                                  return acc.concat({
                                    id,
                                    orgId,
                                    roleId,
                                    userId,
                                    displayName,
                                    columns: Object.values(columns)
                                  });
                                },
                                []
                              )
                            );
                          }
                        };
                      })}
                    />
                  )}
                </span>
              );
            }
          });
        }

        if (showLinks)
          conditionalColumns.push({
            id: 'link',
            canSort: false,
            Header: undefined,
            Cell: ({ row }) => {
              const { original } = row;

              if (!original?.link) return <></>;

              return (
                <Clicker
                  text={original?.link?.text}
                  href={original?.link?.href}
                  className="resource-table__button"
                  iconName={Icon.names.arrowCircleBlack}
                  textIsHidden={true}
                  iconSize={Clicker.iconSizes.medium}
                />
              );
            }
          });

        conditionalColumns.push({
          id: 'button',
          canSort: false,
          Header: undefined,
          Cell: ({ row }) => {
            const { original } = row;
            if (!original?.nodeButton?.text) return <></>;

            return (
              <Clicker
                {...original?.nodeButton}
                className="resource-table__button"
                iconName={Icon.names.arrowCircleBlack}
                textIsHidden={true}
                iconSize={Clicker.iconSizes.medium}
              />
            );
          }
        });

        return conditionalColumns;
      });
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = table;

  useEffect(() => {
    if (uniqueTableHeader && table?.state?.sortBy[0]?.id) {
      setCurrentSort(currentSort => ({
        ...currentSort,
        [uniqueTableHeader]: table?.state?.sortBy[0]?.id
      }));
    }
  }, [uniqueTableHeader, table?.state?.sortBy]);

  if (typeof width !== 'number') return null;
  return (
    <div className="resource-table">
      <table
        {...getTableProps({ role: false })}
        className="resource-table__table"
      >
        <thead>
          {headerGroups.map((headerGroup, hgIdx) => (
            <tr
              key={`header-group-${hgIdx}`}
              {...headerGroup.getHeaderGroupProps({ role: false })}
              className="resource-table__table-header-row"
            >
              {headerGroup.headers.map((column, hIdx) => {
                return column.Header ? (
                  <th
                    key={`header-group-header-${hgIdx}-${hIdx}`}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ role: false })
                    )}
                    className="resource-table__table-heading"
                  >
                    {column.canSort ? (
                      <button className="resource-table__sorter">
                        {column.isSorted ? (
                          <>
                            {column.isSortedDesc ? (
                              <span className="resource-table__sorter-icon resource-table__sorter-icon--desc">
                                <Icon
                                  name={Icon.names.sortActive}
                                  size={Icon.sizes.medium}
                                />
                              </span>
                            ) : (
                              <span className="resource-table__sorter-icon resource-table__sorter-icon--asc">
                                <Icon
                                  name={Icon.names.sortActive}
                                  size={Icon.sizes.medium}
                                />
                              </span>
                            )}
                          </>
                        ) : (
                          <span className="resource-table__sorter-icon">
                            <Icon
                              name={Icon.names.sort}
                              size={Icon.sizes.medium}
                            />
                          </span>
                        )}
                        <span>{column.render('Header')}</span>
                      </button>
                    ) : (
                      <span>{column.render('Header')}</span>
                    )}
                  </th>
                ) : (
                  <td
                    key={`header-group-header-${hgIdx}-${hIdx}`}
                    className="resource-table__table-heading"
                  ></td>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps({ role: false })}
          className="resource-table__table-body"
        >
          {rows.map((row, rIdx) => {
            prepareRow(row);

            return (
              <tr
                key={`row-${rIdx}`}
                {...row.getRowProps({ role: false })}
                className={cn('resource-table__table-row', {
                  [`resource-table__table-row--${
                    Resource.statuses[row.original.status]
                  }`]: Resource.statuses[row.original.status]
                })}
              >
                {row.cells.map((cell, cIdx) => {
                  return (
                    <td
                      key={`cell-${rIdx}-${cIdx}`}
                      {...cell.getCellProps({ role: false })}
                      className={cn('resource-table__table-cell', {
                        'resource-table__table-cell--menu':
                          cell.column.id === 'menu',
                        'resource-table__table-cell--link':
                          cell.column.id === 'link' ||
                          cell.column.id === 'button'
                      })}
                    >
                      <span className="resource-table__table-cell-inner">
                        {cell.render('Cell')}
                      </span>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {infoText && (
        <div className="resource-table__info-text">
          <Icon name={Icon.names.warningRed} size={Icon.sizes.medium} />
          <span>{infoText}</span>
        </div>
      )}
      <Modal isOpen={!!modalData} onClose={closeModal}>
        {modalData?.row.original.roleId === 'SL' &&
          modalData?.row.original.requestDelete && (
            <div className="resource-table__modal-notification">
              <Icon name={Icon.names.warningRed} size={Icon.sizes.medium} />
              <span>{requestDeleteText}</span>
            </div>
          )}
        <ul>
          {modalData?.columns.map(column => {
            const { Header, id } = column;
            const originalData = modalData?.row?.original;
            const columnById = originalData?.columns[id];
            if (columnById) {
              return (
                <li key={id} style={{ marginBottom: '1rem' }}>
                  <p>
                    <b>{Header}</b>
                  </p>
                  {columnById?.text}
                </li>
              );
            }
            return null;
          })}
        </ul>
      </Modal>
    </div>
  );
};

ResourceTable.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.exact(Resource.propTypes)),
  headerColumns: PropTypes.arrayOf(PropTypes.string),
  headerColumnMobileIndex: PropTypes.number,
  infoText: PropTypes.string,
  showMenus: PropTypes.bool, //exclude from backend
  showLinks: PropTypes.bool, //exclude from backend
  showCheckboxes: PropTypes.bool //exclude from backend
};

ResourceTable.defaultProps = {
  resources: [],
  headerColumns: [],
  showMenus: false,
  showLinks: false,
  showCheckboxes: false,
  headerColumnMobileIndex: 0
};

export default ResourceTable;
