/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from '../../../sanityClient';

const builder = imageUrlBuilder(sanityClient);
export function urlFor(source) {
  return builder.image(source);
}

export const serializers = {
  types: {
    value: props => {
      const { style = 'normal' } = props.value;

      if (/^h\d/.test(style)) {
        const level = style.replace(/[^\d]/g, '');
        return React.createElement(
          style,
          { className: `heading-${level}` },
          props.children
        );
      }

      if (style === 'blockquote') {
        return <blockquote>- {props.children}</blockquote>;
      }
    },
    imageObject: props => (
      <img
        src={urlFor(props.value.asset)
          .auto('format')
          .width(500)
          .quality(80)
          .url()}
        alt={props.value.caption}
      />
    ),
    code: props => (
      <pre data-language={props.value.language}>
        <code>{props.value.code}</code>
      </pre>
    )
  },

  list: props =>
    props.type === 'bullet' ? (
      <ul>{props.children}</ul>
    ) : (
      <ol>{props.children}</ol>
    ),
  listItem: props =>
    props.type === 'bullet' ? (
      <li>{props.children}</li>
    ) : (
      <li>{props.children}</li>
    ),
  marks: {
    strong: props => <strong>{props.children}</strong>,
    em: props => <em>{props.children}</em>,
    code: props => <code>{props.children}</code>,
    link: ({ value, children }) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = value;
      return blank ? (
        <a href={href} target="_blank" rel="noreferrer">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    },
    internalLink: ({ mark, children }) => {
      const { slug = {} } = mark;
      const href = `/${slug.current}`;
      return (
        <a target="_blank" rel="noreferrer" href={href}>
          {children}
        </a>
      );
    }
  }
};
