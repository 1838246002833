import Clicker from 'components/clicker/clicker';
import Icon from 'components/icon';
import LoadingSpinner from 'components/loading-spinner/loading-spinner';
import SlideInChildren from 'components/slide-in-children';
import apiHelper from 'js/api-helper';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const RedaktorIntroImport = ({
  title,
  lead,
  importHealthCheckEndpoint,
  isOpen,
  setIsOpen,
  inputPlaceholder,
  actionButtonText
}) => {
  const [importData, setImportData] = useState({
    loading: false,
    message: ''
  });
  const [proceedButton, setProceedButton] = useState({
    endpoint: '',
    text: ''
  });

  const handleImportCheck = async e => {
    setImportData({
      loading: true,
      message: ''
    });
    const addedFile = e.target.files[0];
    console.log(addedFile);
    if (addedFile.type === 'application/json') {
      try {
        const fileReader = new FileReader();
        fileReader.readAsText(addedFile, 'UTF-8');
        fileReader.onload = async e => {
          const res = await apiHelper.execute(importHealthCheckEndpoint, {
            json: e.target.result,
            filename: addedFile.name
          });
          if (res?.error) {
            setImportData({
              loading: false,
              message: res.error
            });
          } else {
            setProceedButton({
              endpoint: res?.endpoint,
              text: res?.text
            });
            setImportData({
              loading: false,
              message: res?.message || '',
              json: e.target.result,
              filename: addedFile.name
            });
          }
        };
        fileReader.onabort = () => {
          setImportData({
            loading: false,
            message: ''
          });
        };
      } catch (err) {
        setImportData({
          loading: false,
          message: err.message
        });
      }
    } else {
      setImportData({
        loading: false,
        message: 'Må være JSON filformat'
      });
    }
  };

  const handleProceedButton = async () => {
    if (!proceedButton?.endpoint || !importData.json) return;
    try {
      await apiHelper.execute(proceedButton?.endpoint, {
        json: importData.json,
        filename: importData.filename
      });
      setImportData({
        loading: false,
        message: ''
      });
    } catch (err) {
      setImportData({
        loading: false,
        message: 'Noe gikk galt'
      });
    }
  };

  return (
    <div
      className={'redaktor-intro__box'}
      onClick={() => !isOpen && setIsOpen()}
      style={!isOpen ? { cursor: 'pointer' } : {}}
    >
      <div className={'redaktor-intro__box-text-wrapper'}>
        <h2 className={'redaktor-intro__box-title'}>{title}</h2>
        <p className={'redaktor-intro__box-lead'}>{lead}</p>
      </div>
      <SlideInChildren open={isOpen}>
        <div className={'redaktor-intro__box-slide-in-wrapper'}>
          <div>
            <label className={'redaktor-intro__import-input-visual'}>
              {importData?.filename || inputPlaceholder}
              <input
                className={'redaktor-intro__import-input'}
                type="file"
                accept="application/JSON"
                onChange={handleImportCheck}
                onFocus={() => !isOpen && setIsOpen()}
              />
              <span style={{ position: 'absolute', right: 10 }}>
                <Icon name={Icon.names.upload} />
              </span>
            </label>
          </div>
          <div className={'redaktor-intro__import-status'}>
            {importData?.loading && <LoadingSpinner />}
            {importData?.message && <p>{importData.message}</p>}
          </div>

          <div className={'redaktor-intro__button-wrapper'}>
            <Clicker
              isDisabled={!proceedButton?.text || importData?.loading}
              text={proceedButton?.text || actionButtonText}
              onClick={handleProceedButton}
              theme={Clicker.themes.primary}
            />
          </div>
        </div>
      </SlideInChildren>
    </div>
  );
};

RedaktorIntroImport.propTypes = {
  title: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  actionButtonText: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  importHealthCheckEndpoint: PropTypes.string.isRequired,
  // "Returns 'Fil ok' || 'Filformat feil. Prøv på nytt!' "
  // Skal denne også returnere newButton objekt?
  isOpen: PropTypes.bool, // ignore backend
  setIsOpen: PropTypes.func // ignore backend
};

RedaktorIntroImport.defaultProps = {
  inputPlaceholder: 'Last opp fil (json)',
  actionButtonText: 'Lag versjon'
};
export default RedaktorIntroImport;
