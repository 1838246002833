import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import apiHelper from 'js/api-helper';

import EleverContentContext from '../../contexts/elever-content-context';

import Clicker from 'components/clicker';
import LoadingSpinner from 'components/loading-spinner';

const LINK_TOKEN = '$link';

const Notification = ({
  id,
  timeSince,
  notificationText,
  notificationLinkText,
  notificationLinkUrl,
  updateNotifications,
  declineButtonText,
  declineEndpoint,
  acceptButtonText,
  acceptEndpoint
}) => {
  const c = useContext(EleverContentContext);

  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const replaceTokens = (stringToParse, link) => {
    const split = stringToParse.split(LINK_TOKEN);

    if (split.length === 1 || !link) return stringToParse;

    split.splice(
      1,
      0,
      <Clicker
        key={link.href}
        theme={Clicker.themes.underlinedThin}
        disabled={!link.href}
        className="notification__link"
        {...link}
      />
    );

    return split;
  };

  const handleButtonClick = async endpoint => {
    setIsLoading(true);
    setIsDisabled(true);

    try {
      const res = await apiHelper.execute(endpoint, { id });
      setIsLoading(false);
      setIsDisabled(false);
      c && c.setElementToUpdate(res);
      updateNotifications(id);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="notification">
      {timeSince && <p className="notification__time-since">{timeSince}</p>}
      <div className="notification__plan-text">
        {notificationText && (
          <p className="notification__text">
            {replaceTokens(notificationText, {
              text: notificationLinkText,
              href: notificationLinkUrl
            })}
          </p>
        )}
        {declineEndpoint && acceptEndpoint && (
          <div className="notification__button-group">
            <Clicker
              className="notification__decline-button"
              onClick={() => handleButtonClick(declineEndpoint)}
              isDisabled={isDisabled}
              theme={Clicker.themes.secondary}
            >
              {declineButtonText}
            </Clicker>
            <Clicker
              className="notification__accept-button"
              onClick={() => handleButtonClick(acceptEndpoint)}
              isDisabled={isDisabled}
              theme={Clicker.themes.primary}
            >
              {acceptButtonText}
            </Clicker>
            {isLoading && <LoadingSpinner className="invite-modal__loading" />}
          </div>
        )}
      </div>
    </div>
  );
};

Notification.propTypes = {
  id: PropTypes.string,
  timeSince: PropTypes.string,
  notificationLinkUrl: PropTypes.string,
  notificationLinkText: PropTypes.string,
  notificationText: PropTypes.string,
  updateNotifications: PropTypes.func,
  declineButtonText: PropTypes.string,
  declineEndpoint: PropTypes.string,
  acceptButtonText: PropTypes.string,
  acceptEndpoint: PropTypes.string
};

Notification.propTypesMeta = {
  removed: 'exclude'
};

Notification.defaultProps = {
  notificationText: '',
  planLinks: [],
  showMoreText: 'Vis mer',
  showLessText: 'Vis mindre'
};

export default Notification;
