import React from 'react';
import PropTypes from 'prop-types';

import BasicLayout from '../layouts/basic-layout';
import MissingProfile from '../missing-profile';

const MissingProfilePage = ({ layout, missingProfile }) => {
  return (
    <BasicLayout {...layout}>
      <MissingProfile {...missingProfile} />
    </BasicLayout>
  );
};

MissingProfilePage.propTypes = {
  layout: PropTypes.exact(BasicLayout.propTypes),
  missingProfile: PropTypes.exact(MissingProfile.propTypes)
};

export default MissingProfilePage;
