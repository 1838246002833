/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import HeaderContext from './header-context';

const HeaderContextProvider = ({ children }) => {
  const [adminHeaderData, setAdminHeaderData] = useState(null);
  const [profileHeaderData, setProfileHeaderData] = useState(null);

  return (
    <HeaderContext.Provider
      value={{
        profileHeaderData,
        setProfileHeaderData,
        adminHeaderData,
        setAdminHeaderData
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

HeaderContextProvider.propTypes = {
  children: PropTypes.node // exclude from backend
};

export default HeaderContextProvider;
