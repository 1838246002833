import LoadingSpinner from 'components/loading-spinner';
import Tooltip from 'components/tooltip/tooltip';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import GenerellDashboardDisplayData from './modules/generell-dashboard/generell-dashboard-display-data';
import GenerellSystemtekstDisplayData from './modules/generell-systemtekst/generell-systemtekst-display-data';
import KartleggingExpressionDisplayData from './modules/kartlegging-expression/kartlegging-expression-display-data';
import KartleggingPreviewDisplayData from './modules/kartlegging-preview/kartlegging-preview-display-data';
import KartleggingUtsagnDisplayData from './modules/kartlegging-utsagn/kartlegging-utsagn-display-data';
import SamtaleEditItemDisplayData from './modules/samtale-edit/samtale-edit-display-data';
import SamtalePreviewDisplayData from './modules/samtale-preview/samtale-preview-display-data';
import InfoBubbleButton from 'components/info-bubble-button/info-bubble-button';

const RedaktorDataDisplay = ({ displayData }) => {
  const uniqueNodeId = useMemo(
    () =>
      `${displayData?.breadcrumbText?.replace(/ /g, '')}-${
        displayData?.data?.type
      }-${displayData?.data?.id}`,
    [
      displayData?.breadcrumbText,
      displayData?.data?.type,
      displayData?.data?.id
    ]
  );

  const getDataByType = useCallback(() => {
    if (!displayData) return null;
    const { data } = displayData;
    switch (data?.type) {
      case 'dashboard': {
        return (
          <GenerellDashboardDisplayData
            key={uniqueNodeId}
            uniqueNodeId={uniqueNodeId}
            {...data}
          />
        );
      }
      case 'samtale-preview': {
        return (
          <SamtalePreviewDisplayData
            key={uniqueNodeId}
            uniqueNodeId={uniqueNodeId}
            {...data}
          />
        );
      }
      case 'samtale': {
        return (
          <SamtaleEditItemDisplayData
            key={uniqueNodeId}
            uniqueNodeId={uniqueNodeId}
            {...data}
          />
        );
      }
      case 'preview': {
        return (
          <KartleggingPreviewDisplayData
            key={uniqueNodeId}
            uniqueNodeId={uniqueNodeId}
            {...data}
          />
        );
      }
      case 'utsagn': {
        return (
          <KartleggingUtsagnDisplayData
            key={uniqueNodeId}
            uniqueNodeId={uniqueNodeId}
            {...data}
          />
        );
      }
      case 'expression': {
        return (
          <KartleggingExpressionDisplayData
            key={uniqueNodeId}
            uniqueNodeId={uniqueNodeId}
            {...data}
          />
        );
      }
      case 'systemtekst': {
        return (
          <GenerellSystemtekstDisplayData
            key={uniqueNodeId}
            uniqueNodeId={uniqueNodeId}
            {...data}
          />
        );
      }
      default: {
        return 'No type';
      }
    }
  }, [displayData, uniqueNodeId]);

  const hideTitleData = useMemo(() => {
    return displayData?.data?.type.includes('samtale');
  });
  return (
    <>
      {displayData?.error && <div>Error</div>}
      {displayData?.loading && (
        <div className={'spinner-center'}>
          <LoadingSpinner />
        </div>
      )}
      {displayData?.data && (
        <div className="redaktor-grensesnitt__sticky-data">
          {!hideTitleData && (
            <>
              <div className="redaktor-grensesnitt__title-container">
                <h1
                  style={{ marginBottom: 0, minHeight: 60 }}
                  className={'redaktor-grensesnitt__title'}
                >
                  {displayData?.data?.title}
                </h1>
                {displayData?.data?.infoText && (
                  <div className="redaktor-grensesnitt__title-bubble-button">
                    <InfoBubbleButton
                      buttonText="info-tekst"
                      infoText={displayData.data.infoText}
                      theme="info"
                      zIndex="120"
                    />
                  </div>
                )}
              </div>
              <p>{displayData?.data?.subtitle}</p>
              <p className={'redaktor-grensesnitt__breadcrumb-print'}>
                {displayData?.breadcrumbText}
              </p>
            </>
          )}
          <div className="redaktor-grensesnitt__data-content">
            {getDataByType()}
          </div>
        </div>
      )}
    </>
  );
};

const TypesProps = ['utsagn', 'preview', 'expression'];

RedaktorDataDisplay.UtsagnProps = {
  questions: PropTypes.arrayOf({
    id: PropTypes.string,
    position: PropTypes.number,
    index: PropTypes.string,
    questionText: PropTypes.shape({
      text: PropTypes.string,
      definitions: PropTypes.arrayOf(
        PropTypes.shape({
          matchWord: PropTypes.string,
          title: PropTypes.string,
          lead: PropTypes.string
        })
      )
    }),
    tooltip: PropTypes.shape(Tooltip.propTypes)
  }),
  type: PropTypes.oneOf(TypesProps)
};

/**
 * response data for utsagn og preview er Questions fordi vi trenger alle verdiene som også finnes i question.jsx
 * response data for expression er annerledes
 */
const ResponseDataProps = PropTypes.oneOfType([
  // Response data from endpoint. Should also include a type
  PropTypes.shape(KartleggingUtsagnDisplayData.propTypes),
  PropTypes.shape(KartleggingPreviewDisplayData.propTypes),
  PropTypes.shape(KartleggingExpressionDisplayData.propTypes),
  PropTypes.shape(GenerellSystemtekstDisplayData.propTypes),
  PropTypes.shape(GenerellDashboardDisplayData.propTypes)
]);

RedaktorDataDisplay.propTypes = {
  displayData: PropTypes.shape({
    data: ResponseDataProps,
    breadcrumbText: PropTypes.string,
    error: PropTypes.bool,
    loading: PropTypes.bool
  }),
  currentTab: PropTypes.string,
  setCurrentTab: PropTypes.func
};
export default RedaktorDataDisplay;
