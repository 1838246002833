import cn from 'classnames';
import Icon from 'components/icon';
import InfoBubbleButton from 'components/info-bubble-button/info-bubble-button';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const themes = {
  default: 'default',
  column: 'column',
  readOnlyRow: 'readOnlyRow'
};

const colors = {
  white: 'white',
  default: 'default'
};

const TextInputList = ({
  placeholder,
  id,
  valueArray,
  label,
  onBlur,
  color,
  theme,
  isDisabled,
  onChange,
  inEditMode
}) => {
  const [listOfText, setListOfText] = useState(valueArray);
  const inputRef = useRef(null);

  // If value changes from parent, reset it
  useEffect(() => {
    setListOfText(valueArray);
  }, [valueArray]);

  useEffect(() => {
    setListOfText(prevList => prevList.filter(item => item.match !== ''));
  }, [inEditMode]);

  const removeVal = val => {
    const index = listOfText.indexOf(val);
    const textArray = [...listOfText];
    textArray.splice(index, 1);
    setListOfText(textArray);
    onChange(textArray, id);
  };

  const handleChange = (e, index) => {
    const { value } = e.target;
    const updatedList = listOfText.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          match: value
        };
      } else return item;
    });
    setListOfText(updatedList);
    onChange(updatedList, id);
  };

  const handleBlur = () => {
    onBlur(listOfText, id);
  };

  const addNewRow = () => {
    const lastElement = listOfText[listOfText.length - 1];
    const hasEmptyRow = lastElement && lastElement.match === '';
    if (!hasEmptyRow) {
      setListOfText(list => [...list, { match: '' }]);
    }
  };
  return (
    <div
      className={cn('text-input-list', {
        [`text-input-list--${theme}`]: theme,
        [`text-input-list--${color}`]: color
      })}
    >
      <div className="text-input-list__wrapper">
        {label && (
          <label className={'text-input-list__label'} htmlFor={id}>
            {label}
          </label>
        )}
        {listOfText?.map((value, index) => {
          return (
            <div key={index} className={'text-input-list__input-wrapper'}>
              <input
                autoComplete={'off'}
                type={'text'}
                id={id}
                className={cn('text-input-list__input', {})}
                placeholder={placeholder}
                ref={inputRef}
                disabled={isDisabled}
                value={value.match}
                autoFocus={value.match === ''}
                onChange={e => handleChange(e, index)}
                onBlur={handleBlur}
              />
              {!inEditMode && (
                <InfoBubbleButton
                  buttonText="info-boks"
                  infoText={`
                        <p><b>${
                          valueArray[index]?.matchInfoHeader ?? ''
                        }</b></p>
                        ${
                          valueArray[index]?.matchInfoItemList
                            ?.map(item => `<p>${item}</p>`)
                            .join('') ?? ''
                        }
                    `}
                  theme="info"
                  error={value.error}
                />
              )}
              {!isDisabled && (
                <button
                  disabled={isDisabled}
                  className={'text-input-list__erease-button'}
                  onClick={() => removeVal(value)}
                >
                  <Icon
                    size={Icon.sizes.medium}
                    name={Icon.names.closeCircle}
                  />
                </button>
              )}
            </div>
          );
        })}
        {!isDisabled && (
          <button
            onClick={addNewRow}
            className={'text-input-list__add-input-button'}
          >
            <div className={'text-input-list__add-button-container'}>
              <Icon
                className={'text-input-list__plus-icon'}
                size={Icon.sizes.medium}
                name={Icon.names.plusCircle}
              />
              {placeholder}
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

TextInputList.themes = themes;
TextInputList.colors = colors;

TextInputList.propTypes = {
  theme: PropTypes.oneOf(Object.values(TextInputList.themes)),
  color: PropTypes.oneOf(Object.values(TextInputList.colors)),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  valueArray: PropTypes.arrayOf(
    PropTypes.shape({
      match: PropTypes.string,
      matchInfoHeader: PropTypes.string,
      matchInfoItemList: PropTypes.arrayOf(PropTypes.string),
      error: PropTypes.bool
    })
  ),
  onBlur: PropTypes.func, //exclude from backend
  onChange: PropTypes.func, //exclude from backend
  isDisabled: PropTypes.bool, //exclude from backend
  inEditMode: PropTypes.bool //exclude from backend
};

TextInputList.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  input: {
    id: '',
    value: []
  },
  theme: TextInputList.themes.default,
  color: TextInputList.colors.default
};

export default TextInputList;
