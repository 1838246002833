import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Clicker from 'components/clicker/clicker';
import cn from 'classnames';
import EditInfoModal from 'components/edit-info-modal/edit-info-modal';
import apiHelper from 'js/api-helper';
import Tooltip from 'components/tooltip/tooltip';
import Icon from 'components/icon/icon';

const InfoHeader = ({
  title,
  mainAlert,
  openHeaderText,
  closeHeaderText,
  openEditInfoModalText,
  editInfoModal,
  infoList,
  updateInfoListEndpoint
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editInfoModalIsOpen, setEditInfoModalIsOpen] = useState(false);
  const [titleState, setTitleState] = useState(title);
  const [infoState, setInfoState] = useState(infoList);

  const onEditModalConfirm = (infoList, title) => {
    if (infoList) setInfoState(infoList);
    if (title) setTitleState(title);
    setEditInfoModalIsOpen(false);
  };

  const handleOpenInfoModal = () => {
    setIsOpen(!isOpen);
    if (!isOpen && updateInfoListEndpoint)
      apiHelper
        .get(updateInfoListEndpoint)
        .then(response => setInfoState(response.infoList))
        .catch(_error =>
          // eslint-disable-next-line no-console
          console.log(_error)
        );
  };

  return (
    <div className={cn('info-header', { 'info-header--open': isOpen })}>
      <div className="info-header__header">
        <div className="info-header__heading-wrapper">
          <h2 className="info-header__title">{titleState}</h2>
          {mainAlert && (
            <Tooltip
              {...mainAlert}
              iconName={Icon.names.warningRed}
              iconSize={Icon.sizes.mediumPlus}
              className="info-header__tooltip"
            />
          )}
        </div>
        <div className="info-header__button-wrapper">
          <Clicker
            className="info-header__arrow-button"
            iconName="arrow-circle"
            textIsHidden={true}
            aria-label={isOpen ? closeHeaderText : openHeaderText}
            aria-expanded={isOpen}
            onClick={handleOpenInfoModal}
            iconSize={Clicker.iconSizes.mediumPlus}
          />
        </div>
      </div>
      <div className="info-header__dropdown-content" inert={isOpen ? null : ''}>
        {openEditInfoModalText && editInfoModal && (
          <Clicker
            className="info-header__edit-button"
            text={openEditInfoModalText}
            textIsHidden={true}
            iconName={Clicker.iconNames.edit}
            onClick={() => setEditInfoModalIsOpen(!editInfoModalIsOpen)}
          />
        )}
        <ul className="info-header__list">
          {infoState &&
            infoState.map(({ infoTitle, info, alert }, i) => (
              <li key={i} className="info-header__list-item">
                <span className="info-header__info-title">
                  {infoTitle + ' '}
                </span>
                <span className="info-header__info">{info}</span>
                {alert && (
                  <Tooltip
                    className="info-header__info-alert"
                    {...alert}
                    iconName={Icon.names.warningRed}
                  />
                )}
              </li>
            ))}
        </ul>
      </div>
      {editInfoModal && (
        <EditInfoModal
          {...editInfoModal}
          isOpen={editInfoModalIsOpen}
          onClose={() => setEditInfoModalIsOpen(false)}
          onConfirm={onEditModalConfirm}
        ></EditInfoModal>
      )}
    </div>
  );
};

InfoHeader.propTypes = {
  title: PropTypes.string,
  mainAlert: PropTypes.exact(Tooltip.propTypes),
  openHeaderText: PropTypes.string,
  closeHeaderText: PropTypes.string,
  openEditInfoModalText: PropTypes.string,
  editInfoModal: PropTypes.exact(EditInfoModal.propTypes),
  infoList: PropTypes.arrayOf(
    PropTypes.exact({
      infoTitle: PropTypes.string,
      info: PropTypes.string,
      alert: PropTypes.exact(Tooltip.propTypes)
    })
  ),
  updateInfoListEndpoint: PropTypes.string
};

export default InfoHeader;
