import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import usePhrases from '../../hooks/use-phrases';

const SearchItem = ({
  name,
  description,
  orgnr,
  email,
  phoneNumber,
  onClick,
  isDisabled,
  ...rest
}) => {
  const Element = onClick ? 'button' : 'div';
  const { orgNr: orgNrLabel } = usePhrases();

  return (
    <Element
      className={cn('search-item', {
        'search-item--button': !!onClick,
        'search-item--disabled': isDisabled
      })}
      onClick={onClick}
      disabled={isDisabled}
      {...rest}
    >
      <div className="search-item__content">
        {name && <span className="search-item__name">{name}</span>}
        {description && (
          <span className="search-item__description">{description}</span>
        )}
        {orgnr && (
          <span className="search-item__org-nr">
            {orgNrLabel} : {orgnr}
          </span>
        )}
        {email && <span className="search-item__email">{email}</span>}
        {phoneNumber && (
          <span className="search-item__phone-number">{phoneNumber}</span>
        )}
      </div>
    </Element>
  );
};

SearchItem.propTypes = {
  onClick: PropTypes.func, // ignore from backend
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  description: PropTypes.string,
  nodeId: PropTypes.string,
  orgnr: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string
};

export default SearchItem;
