import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import api from 'js/api-helper';

import EleverContentContext from '../../contexts/elever-content-context';

import GridTableWrapper from 'components/grid-table-wrapper/grid-table-wrapper';
import AddStudentsFromFile from 'components/add-students-from-file/add-students-from-file';
import LoadingSpinner from 'components/loading-spinner';
import AddStudentModal from 'components/modal/add-student-modal/add-student-modal';
import EditStudentModal from 'components/modal/edit-student-modal/edit-student-modal';
import MoveStudentModal from 'components/modal/move-student-modal/move-student-modal';
import RejectMoveStudentModal from 'components/modal/reject-move-student-modal/reject-move-student-modal';
import ConfirmationModal from 'components/modal/confirmation-modal';

const roles = Object.freeze({
  larer: 'larer',
  skoleleder: 'skoleleder'
});

const Elever = ({
  content,
  role,
  addButtonText,
  addStudentsFromFileButtonText,

  deleteButtonText,
  deleteEndpoint,
  removeConfirmationModal,

  editButtonText,
  editStudentEndpoint,
  getStudentEndpoint,
  addStudentModal,
  editStudentModal,

  moveButtonText,
  moveStudentModal,

  rejectMoveButtonText,
  getAllFlyttingerForElevEndpoint,
  rejectMoveStudentModal,

  requestStudentButtonText,
  removeStudentButtonText,
  requestOrRemoveStudentEndpoint,
  requestStudentConfirmationModal,
  addStudentsFromFile
}) => {
  const { elementToUpdate } = useContext(EleverContentContext);

  const [isLoading, setIsLoading] = useState(false);
  const [currentStudents, setCurrentStudents] = useState(content.elements);
  const [addStudentsFromFileIsOpen, setAddStudentsFromFileIsOpen] = useState(
    false
  );

  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [
    removeConfirmationModalIsOpen,
    setRemoveConfirmationModalIsOpen
  ] = useState(false);

  const [moveStudenModalIsOpen, setMoveStudenModalIsOpen] = useState(false);
  const [
    rejectMoveStudenModalIsOpen,
    setRejectMoveStudenModalIsOpen
  ] = useState(false);

  const [editModal, setEditModal] = useState(editStudentModal);
  const [moveModal, setMoveModal] = useState(moveStudentModal);
  const [rejectMoveModal, setRejectMoveModal] = useState(
    rejectMoveStudentModal
  );
  const [
    requestStudentConfirmationModalIsOpen,
    setRequestStudentConfirmationModalIsOpen
  ] = useState(false);

  const [removeStudentMethod, setRemoveStudentMethod] = useState(() => {});
  const [requestStudentMethod, setRequestStudentMethod] = useState(() => {});

  const handleOnEditClick = async id => {
    setIsLoading(true);

    const {
      firstName,
      lastName,
      birthNumber,
      dufNumber,
      dNumber,
      grade,
      teachers,
      idType
    } = await api.execute(editStudentEndpoint, { id });

    setIsLoading(false);

    setEditModal({
      ...editModal,
      id,
      firstNameTextInput: {
        ...editModal?.firstNameTextInput,
        value: firstName
      },
      lastNameTextInput: {
        ...editModal?.lastNameTextInput,
        value: lastName
      },
      dNumberTextInput: {
        ...editModal?.dNumberTextInput,
        value: dNumber
      },
      birthNumberTextInput: {
        ...editModal?.birthNumberTextInput,
        value: birthNumber
      },
      dufNumberTextInput: {
        ...editModal?.dufNumberTextInput,
        value: dufNumber
      },
      gradeInput: {
        ...editModal?.gradeInput,
        value: grade
      },
      assignTeacherInput: {
        ...editModal?.assignTeacherInput,
        value: teachers
      },
      chosenIdType: idType
    });

    setEditModalIsOpen(true);
  };

  const handleOnAddConfirm = resources => {
    setCurrentStudents([...currentStudents, ...resources]);
    setAddModalIsOpen(false);
  };

  const handleOnEditConfirm = editedStudent => {
    setCurrentStudents(students =>
      students.map(student => {
        if (student.id === editedStudent.id) return editedStudent;

        return student;
      })
    );
    setEditModalIsOpen(false);
  };

  const handleOnRemoveConfirm = async id => {
    setIsLoading(true);

    try {
      await api.execute(deleteEndpoint, { ids: [id] });

      setCurrentStudents(students =>
        students.filter(resource => {
          return resource.id !== id;
        })
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
    setRemoveConfirmationModalIsOpen(false);
  };
  const handleOnRemoveClick = id => {
    setRemoveStudentMethod(() => () => handleOnRemoveConfirm(id));
    setRemoveConfirmationModalIsOpen(true);
  };

  const handleOnMoveStudentClick = async id => {
    setIsLoading(true);

    try {
      const payload = await api.execute(getStudentEndpoint, {
        id
      });

      setMoveModal({
        ...moveModal,
        ...payload,
        studentId: id
      });

      setMoveStudenModalIsOpen(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
  };
  const handleOnMoveStudentConfirm = id => {
    setCurrentStudents(students =>
      students.filter(oldResource => oldResource.id !== id)
    );
  };

  const handleOnRejectMoveStudentClick = async id => {
    setIsLoading(true);

    try {
      const payload = await api.execute(getAllFlyttingerForElevEndpoint, {
        id
      });

      if (payload.length === 1) {
        const orgid = payload[0].onskesFlyttetTil;
        const orgidAsArray = [orgid];

        await api.execute(rejectMoveModal.rejectMoveStudentEndpoint, {
          studentId: id,
          orgIds: orgidAsArray
        });
        window.location.reload();
      } else {
        setRejectMoveModal({
          ...rejectMoveModal,
          payload,
          studentId: id
        });

        setRejectMoveStudenModalIsOpen(true);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
  };
  const handleOnMoveStudentReject = async schoolNames => {
    setCurrentStudents(students =>
      students.map(resource => ({
        ...resource,
        requestedMoved: schoolNames !== null
      }))
    );
    setRejectMoveStudenModalIsOpen(false);
    window.location.reload();
  };

  const handleRequestStudentConfirm = async studentId => {
    setIsLoading(true);

    try {
      const payload = await api.execute(requestOrRemoveStudentEndpoint, {
        id: studentId
      });
      setCurrentStudents(s =>
        s?.map(student => {
          if (student.id === studentId) {
            return {
              ...student,
              studentIsRequestedFromTeacher:
                payload.studentIsRequestedFromTeacher,
              infoTooltip: payload.infoTooltip
            };
          } else {
            return student;
          }
        })
      );
    } catch (_error) {
      // eslint-disable-next-line no-console
      console.log(_error);
    } finally {
      setIsLoading(false);
    }

    setRequestStudentConfirmationModalIsOpen(false);
  };
  const handleOnRequestStudentClick = id => {
    setRequestStudentMethod(() => () => handleRequestStudentConfirm(id));
    setRequestStudentConfirmationModalIsOpen(true);
  };

  const softRemoveStudentBelongsToTeacher = async studentId => {
    try {
      const payload = await api.execute(requestOrRemoveStudentEndpoint, {
        id: studentId
      });
      setCurrentStudents(s =>
        s?.map(student => {
          if (student.id === studentId) {
            return {
              ...student,
              studentBelongsToTeacher: payload.studentBelongsToTeacher,
              infoTooltip: payload.infoTooltip
            };
          } else {
            return student;
          }
        })
      );
    } catch (_error) {
      // eslint-disable-next-line no-console
      console.log(_error);
    }
  };

  const hardRemoveStudentBelongsToTeacher = async studentId => {
    try {
      await api.execute(requestOrRemoveStudentEndpoint, {
        id: studentId
      });
      setCurrentStudents(s => s.filter(student => student.id !== studentId));
    } catch (_error) {
      // eslint-disable-next-line no-console
      console.log(_error);
    }
  };

  const addElementMenuToStudents = () => {
    if (!(content.elements && !!content.elements.length)) return [];
    return [...currentStudents].map(student => {
      if (role === roles.larer) {
        let menuItems = [];
        if (requestOrRemoveStudentEndpoint) {
          if (requestStudentButtonText) {
            menuItems = [
              {
                text: student.studentBelongsToTeacher
                  ? removeStudentButtonText
                  : requestStudentButtonText,
                onClick: student.studentBelongsToTeacher
                  ? softRemoveStudentBelongsToTeacher
                  : handleOnRequestStudentClick
              }
            ];
          } else if (removeStudentButtonText) {
            menuItems = [
              {
                text: removeStudentButtonText,
                onClick: hardRemoveStudentBelongsToTeacher
              }
            ];
          }
        }

        return {
          ...student,
          link: student.studentBelongsToTeacher ? student.link : null,
          menuItems: menuItems
        };
      } else {
        const nonTeacherMenu = [];
        editButtonText &&
          nonTeacherMenu.push({
            text: editButtonText,
            onClick: handleOnEditClick
          });

        moveButtonText &&
          nonTeacherMenu.push({
            text: moveButtonText,
            onClick: handleOnMoveStudentClick
          });

        deleteButtonText &&
          nonTeacherMenu.push({
            text: deleteButtonText,
            onClick: handleOnRemoveClick
          });

        rejectMoveButtonText &&
          student.requestedMoved &&
          nonTeacherMenu.push({
            text: rejectMoveButtonText,
            onClick: handleOnRejectMoveStudentClick
          });

        return {
          ...student,
          menuItems: nonTeacherMenu
        };
      }
    });
  };

  const getAddActions = () => {
    const addActions = [];
    addButtonText &&
      addActions.push({
        text: addButtonText,
        onClick: () => setAddModalIsOpen(true)
      });
    addStudentsFromFileButtonText &&
      addActions.push({
        text: addStudentsFromFileButtonText,
        onClick: () => setAddStudentsFromFileIsOpen(true)
      });

    return addActions;
  };

  useEffect(() => {
    if (!elementToUpdate) return;

    setCurrentStudents(students =>
      students.map(student => {
        if (student.id === elementToUpdate.elevId) {
          return {
            ...student,
            studentBelongsToTeacher: elementToUpdate.studentBelongsToTeacher,
            infoTooltip: elementToUpdate.infoTooltip,
            warningTooltip: elementToUpdate.warningTooltip
          };
        }
        return student;
      })
    );
  }, [elementToUpdate]);

  return (
    <div className="elever">
      {addStudentsFromFileIsOpen ? (
        <AddStudentsFromFile
          {...addStudentsFromFile}
          onClickGoBack={() => {
            setAddStudentsFromFileIsOpen(false);
            window.location.reload();
          }}
        />
      ) : (
        <GridTableWrapper
          {...content}
          elements={addElementMenuToStudents()}
          addActions={getAddActions()}
        />
      )}
      {isLoading && <LoadingSpinner shouldTakeOverScreen={true} />}
      {addModalIsOpen && addStudentModal && (
        <AddStudentModal
          {...addStudentModal}
          isOpen={addModalIsOpen}
          onConfirm={handleOnAddConfirm}
          onClose={() => setAddModalIsOpen(false)}
        />
      )}
      {editModalIsOpen && (
        <EditStudentModal
          {...editModal}
          isOpen={editModalIsOpen}
          onConfirm={handleOnEditConfirm}
          onClose={() => setEditModalIsOpen(false)}
        />
      )}
      {moveStudenModalIsOpen && (
        <MoveStudentModal
          {...moveModal}
          isOpen={moveStudenModalIsOpen}
          onConfirm={handleOnMoveStudentConfirm}
          onClose={() => setMoveStudenModalIsOpen(false)}
        />
      )}
      {rejectMoveStudenModalIsOpen && (
        <RejectMoveStudentModal
          {...rejectMoveModal}
          isOpen={rejectMoveStudenModalIsOpen}
          onConfirm={handleOnMoveStudentReject}
          onClose={() => setRejectMoveStudenModalIsOpen(false)}
        />
      )}
      {removeConfirmationModalIsOpen && (
        <ConfirmationModal
          {...removeConfirmationModal}
          isOpen={removeConfirmationModalIsOpen}
          onConfirm={removeStudentMethod}
          onClose={() => setRemoveConfirmationModalIsOpen(false)}
        />
      )}
      {requestStudentConfirmationModalIsOpen && (
        <ConfirmationModal
          {...requestStudentConfirmationModal}
          isOpen={requestStudentConfirmationModalIsOpen}
          onConfirm={requestStudentMethod}
          onClose={() => setRequestStudentConfirmationModalIsOpen(false)}
        />
      )}
    </div>
  );
};

Elever.propTypes = {
  content: PropTypes.exact(GridTableWrapper.propTypes),
  role: PropTypes.oneOf(Object.values(roles)),
  addButtonText: PropTypes.string,

  deleteEndpoint: PropTypes.string,
  removeConfirmationModal: PropTypes.exact(ConfirmationModal.propTypes),

  editStudentEndpoint: PropTypes.string,
  getStudentEndpoint: PropTypes.string,
  addStudentModal: PropTypes.exact(AddStudentModal.propTypes),
  editStudentModal: PropTypes.exact(EditStudentModal.propTypes),

  moveStudentModal: PropTypes.exact(MoveStudentModal.propTypes),

  getAllFlyttingerForElevEndpoint: PropTypes.string,
  rejectMoveStudentModal: PropTypes.exact(RejectMoveStudentModal.propTypes),

  editButtonText: PropTypes.string,
  deleteButtonText: PropTypes.string,
  moveButtonText: PropTypes.string,
  rejectMoveButtonText: PropTypes.string,

  requestStudentButtonText: PropTypes.string,
  removeStudentButtonText: PropTypes.string,
  requestOrRemoveStudentEndpoint: PropTypes.string,
  requestStudentConfirmationModal: PropTypes.exact(ConfirmationModal.propTypes),

  addStudentsFromFileButtonText: PropTypes.string,
  addStudentsFromFile: PropTypes.exact(AddStudentsFromFile.propTypes)
};

Elever.defaultProps = {
  role: roles.larer
};

export default Elever;
