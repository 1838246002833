import ClickerPrevNext from 'components/clicker-prev-next';
import Clicker from 'components/clicker/clicker';
import LoadingSpinner from 'components/loading-spinner/loading-spinner';
import MainHeading from 'components/main-heading/main-heading';
import PropTypes from 'prop-types';
import React from 'react';
import useQuestionnaireFormControl from '../../hooks/use-questionnaire-form-control';
import SamtaleSectionWithHeading from './samtale-section-with-heading';

const SamtaleChapter = ({
  header,
  lead,
  sections,
  submitEndpoint,
  submitData,
  readOnly,
  abortHref,
  linkPrev,
  linkNext,
  buttonText
}) => {
  const {
    questions,
    isLoading,
    handleOnInputChange,
    isDirty,
    handleOnSubmit
  } = useQuestionnaireFormControl(sections, submitEndpoint, submitData);

  const disableSave = React.useMemo(() => {
    return !isDirty || isLoading;
  }, [isDirty, isLoading]);

  return (
    <div className={'samtale-chapter'}>
      {header && <MainHeading {...header} />}
      {lead && (
        <div
          dangerouslySetInnerHTML={{ __html: lead }}
          className="samtale-chapter__lead"
        />
      )}

      <SamtaleSectionWithHeading
        questions={questions}
        isLoading={isLoading}
        readOnly={readOnly}
        handleOnInputChange={handleOnInputChange}
      />

      {!readOnly && (
        <div className={'samtale-chapter__actions'}>
          <div />
          <div className={'samtale-chapter__actions-right'}>
            <div className="samtale-chapter__loading">
              {isLoading && <LoadingSpinner />}
            </div>
            {buttonText?.global?.closeWithoutSaving && (
              <Clicker
                href={abortHref}
                text={buttonText.global.closeWithoutSaving}
                isDisabled={isLoading}
                className="samtale-chapter__save-button"
                theme={Clicker.themes.secondary}
              />
            )}
            {buttonText?.global?.save && (
              <Clicker
                onClick={e => handleOnSubmit(e, true)}
                text={buttonText?.global?.save}
                isDisabled={disableSave}
                className="samtale-chapter__save-button"
                theme={Clicker.themes.primary}
              />
            )}
          </div>
        </div>
      )}

      <ClickerPrevNext linkNext={linkNext} linkPrev={linkPrev} />
    </div>
  );
};

SamtaleChapter.propTypes = {
  header: PropTypes.exact(MainHeading.propTypes),
  lead: PropTypes.string,
  readOnly: PropTypes.bool,
  submitEndpoint: PropTypes.string,
  submitData: PropTypes.exact({
    samtaleId: PropTypes.string,
    sideId: PropTypes.string
  }),
  abortHref: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      showChapterSeparator: PropTypes.bool,
      lead: PropTypes.string,
      inputs: PropTypes.arrayOf(
        PropTypes.shape({
          isReadOnly: PropTypes.bool,
          hrefLink: PropTypes.exact({
            text: PropTypes.string,
            href: PropTypes.string
          }),
          valueArray: PropTypes.bool,
          id: PropTypes.string,
          type: PropTypes.string,
          questionText: PropTypes.bool,
          group: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              label: PropTypes.string,
              boolValue: PropTypes.bool,
              stringValue: PropTypes.string
            })
          )
        })
      ),
      isSectionFirstItem: PropTypes.bool // exclude from backend
    })
  ),
  buttonText: PropTypes.exact({
    global: PropTypes.exact({
      save: PropTypes.string,
      closeWithoutSaving: PropTypes.string
    })
  }),
  ...ClickerPrevNext.propTypes
};

SamtaleChapter.defaultProps = {
  sections: []
};

export default SamtaleChapter;
