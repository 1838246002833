import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const QuickSearchSchool = ({
  name,
  address,
  orgNr,
  onClick,
  isDisabled,
  orgNrLabel
}) => {
  const Element = onClick ? 'button' : 'div';

  return (
    <Element
      className={cn('person', { 'person--button': !!onClick })}
      onClick={onClick}
      disabled={isDisabled}
    >
      <div className="person__content">
        {name && <span className="person__name">{name}</span>}
        <span className="person__email">{address && address}</span>
        {orgNr && (
          <span className="person__phone-number">
            {orgNrLabel + ': ' + orgNr}
          </span>
        )}
      </div>
    </Element>
  );
};

QuickSearchSchool.propTypes = {
  onClick: PropTypes.func, // ignore from backend
  isDisabled: PropTypes.bool, // ignore from backend
  name: PropTypes.string,
  orgNr: PropTypes.string,
  address: PropTypes.string,
  orgNrLabel: PropTypes.string
};

export default QuickSearchSchool;
