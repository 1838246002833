import React from 'react';
import PropTypes from 'prop-types';

import Chart from '../chart';
import MainHeading from '../main-heading';
import Timeline from 'components/timeline/timeline';

const Progression = ({ chart }) => {
  return (
    <div className="progression">
      <div className="progression__content">
        <div className="progression__container">
          <div className="progression__chart">
            <Chart {...chart} />
          </div>
        </div>
      </div>
    </div>
  );
};

Progression.propTypes = {
  header: PropTypes.exact(MainHeading.propTypes),
  timeline: PropTypes.exact(Timeline.propTypes),
  chart: PropTypes.exact(Chart.propTypes)
};

export default Progression;
