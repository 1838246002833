import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import api from 'js/api-helper';

import LanguageList from 'components/language-list/language-list';
import LanguageSearchResult from './language-search-result';
import LoadingSpinner from 'components/loading-spinner';
import Clicker from 'components/clicker/clicker';

const LanguageSelect = ({
  placeholder,
  id,
  valueArray,
  label,
  onBlur,
  languageSearchEndpoint,
  isDisabled,
  onChange,
  addLanguageButtonText,
  removeValueLabelPrefix,
  addCurrentValuePretext,
  noResultsText,
  removeSearchValueButtonLabel
}) => {
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [isNoSearchResult, setIsNoSearchResult] = useState(false);
  const [currentInput, setCurrentInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [listOfText, setListOfText] = useState(valueArray);
  const [isLoading, setIsLoading] = useState(false);

  const inputRef = useRef(null);
  const timeoutRef = useRef(null);

  // If value changes from parent, reset it
  useEffect(() => {
    setListOfText(valueArray);
  }, [valueArray]);

  const removeVal = val => {
    const newList = listOfText.filter(item => item !== val);
    setListOfText(newList);
    onChange(newList, id);
  };

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      let { items } = await api.get(
        `${languageSearchEndpoint}?languageSearchText=${currentInput}`
      );
      setSuggestions(items);
      if (!items.length) setIsNoSearchResult(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = e => {
    setIsNoSearchResult(false);
    const { value } = e.target;
    setCurrentInput(value.replace(/\s\s+/g, ' '));
  };

  const resetSearch = () => {
    setCurrentInput('');
    setSuggestions([]);
  };

  const handleOpenSearchButton = () => {
    resetSearch();
    setSearchIsActive(true);
  };

  const handleAddByButton = (e, val) => {
    const _val = val.trim();

    if (_val.length && listOfText.indexOf(_val) === -1) {
      const updatedListOfText = [...listOfText, _val];
      setListOfText(updatedListOfText);
      onChange(updatedListOfText, id);
    }

    resetSearch();
    setSearchIsActive(false);
  };

  const handleFormSubmit = e => {
    e.preventDefault();

    handleAddByButton(e, inputRef.current.value);
  };

  useEffect(() => {
    if (!searchIsActive) return;
    inputRef.current.focus();
  }, [searchIsActive]);

  useEffect(() => {
    clearTimeout(timeoutRef.current);

    if ([...currentInput].length >= 2) {
      setIsLoading(true);
      timeoutRef.current = setTimeout(() => {
        handleSearch();
      }, 300);
    } else {
      setIsLoading(false);
    }

    if (currentInput === '') {
      setSuggestions([]);
    }
  }, [currentInput]);

  const handleBlur = () => {
    onBlur(listOfText, id);
  };

  return (
    <div className="language-select">
      <div className="language-select__wrapper">
        {label && (
          <label className="language-select__label" htmlFor={id}>
            {label}
          </label>
        )}
        <LanguageList
          listOfText={listOfText}
          removeValue={removeVal}
          removeValueLabelPrefix={removeValueLabelPrefix}
        />
        <div className="language-select__input-wrapper">
          {!isDisabled && (
            <>
              <Clicker
                className={cn('language-select__select-button', {
                  'language-select__select-button--visible': !searchIsActive
                })}
                onClick={handleOpenSearchButton}
                text={addLanguageButtonText}
                iconName={Clicker.iconNames.plusCircle}
                iconSize={Clicker.iconSizes.medium}
                iconIsBeforeText={true}
              />
              <form
                className={cn('language-select__form', {
                  'language-select__form--visible': searchIsActive
                })}
                onSubmit={handleFormSubmit}
              >
                <input
                  id={id}
                  autoComplete="off"
                  type="text"
                  className={cn('language-select__input', {
                    'language-select__input--active':
                      [...currentInput].length >= 2 || isLoading
                  })}
                  placeholder={placeholder}
                  ref={inputRef}
                  disabled={isDisabled}
                  value={currentInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {currentInput && (
                  <>
                    {isLoading ? (
                      <div className="language-select__loading-spinner">
                        <LoadingSpinner />
                      </div>
                    ) : suggestions.length ? (
                      <LanguageSearchResult
                        suggestions={suggestions}
                        isDisabled={isDisabled}
                        handleAddByButton={handleAddByButton}
                        isLoading={isLoading}
                      />
                    ) : isNoSearchResult && [...currentInput].length >= 2 ? (
                      <p className="language-select__no-result-text">
                        {noResultsText}
                      </p>
                    ) : null}
                    {currentInput !== '' && (
                      <div className="language-select__add-button-wrapper">
                        <Clicker
                          disabled={isDisabled}
                          className="language-select__add-button"
                          onClick={e => handleAddByButton(e, currentInput)}
                          text={`${addCurrentValuePretext} "${currentInput}"`}
                          iconName={Clicker.iconNames.plusCircle}
                          iconSize={Clicker.iconSizes.mediumPlus}
                          iconIsBeforeText={true}
                        />
                      </div>
                    )}
                  </>
                )}
              </form>

              {currentInput && (
                <Clicker
                  className="language-select__erase-button"
                  disabled={isDisabled}
                  text={removeSearchValueButtonLabel}
                  textIsHidden={true}
                  onClick={resetSearch}
                  iconIsBeforeText={true}
                  iconName={Clicker.iconNames.close}
                  iconSize={Clicker.iconSizes.medium}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
LanguageSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  valueArray: PropTypes.arrayOf(PropTypes.string),
  onBlur: PropTypes.func, //exclude from backend
  onChange: PropTypes.func, //exclude from backend
  isDisabled: PropTypes.bool, //exclude from backend,
  languageSearchEndpoint: PropTypes.string,
  addLanguageButtonText: PropTypes.string,
  removeValueLabelPrefix: PropTypes.string, //Format: "{removeValueLabelPrefix} + " " + {value}"
  addCurrentValuePretext: PropTypes.string, //Format: `{addCurrentValuePretext} "{value}"`
  noResultsText: PropTypes.string,
  removeSearchValueButtonLabel: PropTypes.string
};

LanguageSelect.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  input: {
    id: '',
    value: []
  }
};

export default LanguageSelect;
