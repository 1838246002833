import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import StatistikkNode from './statistikk-node';

const StatistikkDataDisplayItem = ({
  isSingleItem,
  fullWidth,
  statusIndicator,
  statusOk,
  label,
  text
}) => {
  return (
    <div
      className={cn('statistikk-admin__data-content-group-item', {
        'statistikk-admin__data-content-group-item--single-item': isSingleItem,
        'statistikk-admin__data-content-group-item--full-width': fullWidth,
        'statistikk-admin__data-content-group-item--positive':
          statusIndicator && statusOk,
        'statistikk-admin__data-content-group-item--negative':
          statusIndicator && !statusOk
      })}
    >
      <h3>{label}</h3>
      <p className="statistikk-admin__data-header">{text}</p>
    </div>
  );
};

StatistikkDataDisplayItem.propTypes = {
  ...StatistikkNode.LabelTextProps, // ignore backend
  isSingleItem: PropTypes.bool // ignore backend
};
export default StatistikkDataDisplayItem;
