import React from 'react';
import PropTypes from 'prop-types';
import NumberInput from 'components/number-input';
import cn from 'classnames';
import NumberInputWithDebounce from 'components/number-input/number-input-with-debounce';

/**
 * The advanced styling of this file assures that a group will always be styled taking up the
 * whole width, and if the input is an odd, the last three inputs will be styled in one row.
 *
 * If it's less than 3 inputs, only one row is used.
 * @param {*} param0
 */
const NumberGroupInput = ({
  group,
  onChange,
  isDisabled,
  onBlur,
  editorMode,
  handleEditorModeLabelChange
}) => {
  return (
    <div
      className={cn('number-group-input', {
        'number-group-input--one-row': 4 > group?.length,
        'number-group-input--multiple-rows': 4 <= group?.length
      })}
    >
      {group.map((item, i) => (
        <div
          key={item.id}
          className={cn('number-group-input__input', {
            'number-group-input__input--odd':
              item.group?.length % 2 &&
              item.group?.length > 4 &&
              i >= item.group?.length - 3
          })}
        >
          <NumberInputWithDebounce
            {...item}
            editorMode={editorMode}
            handleEditorModeLabelChange={handleEditorModeLabelChange}
            isDisabled={isDisabled}
            theme={NumberInput.themes.rowSmall}
            onBlur={onBlur}
            onChange={onChange}
            maxLength={'2'}
          />
        </div>
      ))}
    </div>
  );
};

NumberGroupInput.propTypes = {
  group: PropTypes.arrayOf(PropTypes.exact(NumberInput.inputProps)).isRequired,
  editorMode: PropTypes.bool, // ignore backend
  handleEditorModeLabelChange: PropTypes.func, // ignore backend
  onChange: PropTypes.func, // ignore backend
  isDisabled: PropTypes.bool, // ignore backend
  onBlur: PropTypes.func // ignore backend
};

NumberGroupInput.defaultProps = {
  onChange: () => {}
};

export default NumberGroupInput;
