import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import TextInput from '../../text-input';
import NumberInput from 'components/number-input';
import InputErrorMessage from 'components/input-error-message';
import InputByType from 'components/input-by-type';
import RadioGroup from 'components/radio-group';

const ID_TYPES = {
  fnr: 'fnr',
  dnr: 'dnr',
  dufnr: 'dufnr'
};

const StudentCommonFields = ({
  id,
  className,
  lead,
  firstNameTextInput,
  lastNameTextInput,
  dNumberTextInput,
  dufNumberTextInput,
  birthNumberTextInput,
  gradeInput,
  chooseIdTypeRadioGroup,
  changedIdTypeAriaLiveText,
  onChange,
  isValidCallback,
  inputErrors,
  heightChangeCallback,
  chosenIdType,
  setCurrentIdType
}) => {
  const inputFieldsRef = useRef(null);

  const { dufDisabled, birthDisabled, dDisabled } = React.useMemo(() => {
    return {
      dufDisabled: chosenIdType !== ID_TYPES.dufnr,
      birthDisabled: chosenIdType !== ID_TYPES.fnr,
      dDisabled: chosenIdType !== ID_TYPES.dnr
    };
  }, [chosenIdType]);

  const handleOnFirstNameChange = value => {
    onChange(value, 'firstName', 'firstNameTextInput');
  };
  const handleOnLastNameChange = value => {
    onChange(value, 'lastName', 'lastNameTextInput');
  };
  const handleOnDNumberChange = value => {
    onChange(value, 'dNumber', 'dNumberTextInput');
  };
  const handleOnDufNumberChange = value => {
    onChange(value, 'dufNumber', 'dufNumberTextInput');
  };
  const handleOnBirthNumberChange = value => {
    onChange(value, 'birthNumber', 'birthNumberTextInput');
  };
  const handleOnGradeChange = value => {
    onChange(value, 'grade', 'gradeInput');
  };

  const getNumberInputProps = ({ isDisabled, value, label, maxValue }) => {
    return {
      maxLength: maxValue,
      minLength: maxValue,
      isDisabled: isDisabled,
      value: isDisabled ? '' : value,
      label: `${label} ${
        value?.length && !isDisabled ? value?.length + '/' + maxValue : ''
      }`
    };
  };

  const handleChangeIdTypeRadio = type => {
    if (type === chosenIdType) return;
    setCurrentIdType(type);
  };

  const getIdTypeAriaLiveText = () => {
    if (!chosenIdType) return '';

    switch (chosenIdType) {
      case 'fnr':
        if (birthNumberTextInput) {
          return changedIdTypeAriaLiveText + ' ' + birthNumberTextInput?.label;
        }
        return '';
      case 'dnr':
        if (dNumberTextInput) {
          return changedIdTypeAriaLiveText + ' ' + dNumberTextInput?.label;
        }
        return '';
      case 'dufnr':
        if (dufNumberTextInput) {
          return changedIdTypeAriaLiveText + ' ' + dufNumberTextInput?.label;
        }
        return '';
      default:
        return '';
    }
  };

  useEffect(() => {
    const handleIsValid = () => {
      let isValid =
        gradeInput?.value?.length &&
        firstNameTextInput?.value?.length &&
        lastNameTextInput?.value?.length;

      if (isValid) {
        switch (chosenIdType) {
          case ID_TYPES.fnr:
            isValid = birthNumberTextInput?.value?.length === 11;
            isValidCallback(isValid);
            break;
          case ID_TYPES.dnr:
            isValid = dNumberTextInput?.value?.length === 11;
            isValidCallback(isValid);
            break;
          case ID_TYPES.dufnr:
            isValid = dufNumberTextInput?.value?.length === 12;
            isValidCallback(isValid);
            break;
          default:
            isValidCallback(isValid);
        }
      } else {
        isValidCallback(isValid);
      }
    };
    handleIsValid();
  }, [
    firstNameTextInput,
    lastNameTextInput,
    gradeInput,
    birthNumberTextInput,
    dNumberTextInput,
    dufNumberTextInput,
    chosenIdType
  ]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const target = entries[0].target;
      if (target && heightChangeCallback) {
        heightChangeCallback(target.scrollHeight);
      }
    });

    if (inputFieldsRef.current) {
      resizeObserver.observe(inputFieldsRef.current);
    }

    return () => {
      if (inputFieldsRef.current) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  return (
    <div
      ref={inputFieldsRef}
      className={cn('student-common-fields', className)}
    >
      <div className="student-common-fields__name-wrapper">
        {firstNameTextInput && (
          <div className="student-common-fields__first-name">
            <InputErrorMessage errors={inputErrors?.firstName}>
              <TextInput
                autoFocus
                theme={TextInput.themes.underlined}
                alphabeticOnly
                {...firstNameTextInput}
                isDisabled={firstNameTextInput?.isDisabled}
                onChange={handleOnFirstNameChange}
              />
            </InputErrorMessage>
          </div>
        )}
        {lastNameTextInput && (
          <div className="student-common-fields__last-name">
            <InputErrorMessage errors={inputErrors?.lastName}>
              <TextInput
                theme={TextInput.themes.underlined}
                alphabeticOnly
                {...lastNameTextInput}
                isDisabled={lastNameTextInput?.isDisabled}
                onChange={handleOnLastNameChange}
              />
            </InputErrorMessage>
          </div>
        )}
      </div>
      <fieldset className="student-common-fields__id-choice-wrapper">
        {lead && (
          <legend className="student-common-fields__lead">{lead}</legend>
        )}
        {chooseIdTypeRadioGroup && (
          <RadioGroup
            {...chooseIdTypeRadioGroup}
            onChange={handleChangeIdTypeRadio}
            selectedItem={chosenIdType}
            className="student-common-fields__radio-group"
          />
        )}
        <div
          aria-live="polite"
          aria-atomic={true}
          className="student-common-fields__hidden-live-text"
        >
          {getIdTypeAriaLiveText()}
        </div>
        {birthNumberTextInput && chosenIdType === ID_TYPES.fnr && (
          <div className="student-common-fields__input">
            <InputErrorMessage errors={inputErrors?.birthNumber}>
              <NumberInput
                theme={NumberInput.themes.underlined}
                {...birthNumberTextInput}
                onChange={handleOnBirthNumberChange}
                {...getNumberInputProps({
                  maxValue: 11,
                  isDisabled: birthDisabled,
                  value: birthNumberTextInput?.value,
                  label: birthNumberTextInput?.label
                })}
                maskWithAsterix={birthNumberTextInput?.value.includes(
                  '**********'
                )}
                allowNegative={false}
                isDisabled={birthDisabled}
              />
            </InputErrorMessage>
          </div>
        )}
        {dNumberTextInput && chosenIdType === ID_TYPES.dnr && (
          <div className="student-common-fields__input">
            <InputErrorMessage errors={inputErrors?.dNumber}>
              <NumberInput
                theme={NumberInput.themes.underlined}
                {...dNumberTextInput}
                {...getNumberInputProps({
                  maxValue: 11,
                  isDisabled: dDisabled,
                  value: dNumberTextInput?.value,
                  label: dNumberTextInput?.label
                })}
                maskWithAsterix={dNumberTextInput?.value.includes('*******')}
                allowNegative={false}
                onChange={handleOnDNumberChange}
              />
            </InputErrorMessage>
          </div>
        )}
        {dufNumberTextInput && chosenIdType === ID_TYPES.dufnr && (
          <div className="student-common-fields__input">
            <InputErrorMessage errors={inputErrors?.dufNumber}>
              <TextInput
                theme={TextInput.themes.underlined}
                alphanumericOnly
                {...dufNumberTextInput}
                {...getNumberInputProps({
                  maxValue: 12,
                  isDisabled: dufDisabled,
                  value: dufNumberTextInput?.value,
                  label: dufNumberTextInput?.label
                })}
                maskWithAsterix={dufNumberTextInput?.value.includes(
                  '**********'
                )}
                onChange={handleOnDufNumberChange}
              />
            </InputErrorMessage>
          </div>
        )}
      </fieldset>
      {gradeInput && (
        <div className="student-common-fields__grade-wrapper">
          <InputByType
            textInputTheme={TextInput.themes.underlined}
            errors={inputErrors.grade}
            onChange={handleOnGradeChange}
            {...gradeInput}
          />
        </div>
      )}
    </div>
  );
};

const textInputProps = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isDirty: PropTypes.bool
};

StudentCommonFields.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  isValidCallback: PropTypes.func,
  lead: PropTypes.string,
  firstNameTextInput: PropTypes.exact(textInputProps),
  lastNameTextInput: PropTypes.exact(textInputProps),
  dufNumberTextInput: PropTypes.exact(textInputProps),
  dNumberTextInput: PropTypes.exact(textInputProps),
  birthNumberTextInput: PropTypes.exact(textInputProps),
  gradeInput: PropTypes.exact(InputByType.propTypes),
  chooseIdTypeRadioGroup: PropTypes.exact(RadioGroup.propTypes),
  changedIdTypeAriaLiveText: PropTypes.string,
  inputErrors: PropTypes.shape({
    firstName: PropTypes.arrayOf(PropTypes.string),
    lastName: PropTypes.arrayOf(PropTypes.string),
    birthNumber: PropTypes.arrayOf(PropTypes.string),
    dNumber: PropTypes.arrayOf(PropTypes.string),
    dufNumber: PropTypes.arrayOf(PropTypes.string),
    grade: PropTypes.arrayOf(PropTypes.string)
  }),
  heightChangeCallback: PropTypes.func,
  chosenIdType: PropTypes.string,
  setCurrentIdType: PropTypes.func
};

StudentCommonFields.defaultProps = {};

StudentCommonFields.idTypes = ID_TYPES;

export default StudentCommonFields;
