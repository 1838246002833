import cn from 'classnames';
import Clicker from 'components/clicker/clicker';
import PropTypes from 'prop-types';
import React from 'react';

const colors = {
  green: 'green',
  blue: 'blue',
  black: 'black'
};

const RedaktorLinkHeader = ({ links, color }) => {
  return (
    <div
      className={cn('redaktor-link-header', {
        'redaktor-link-header--columns': links?.length > 2,
        [`redaktor-link-header--${colors[color]}`]: colors[color]
      })}
    >
      <div className="redaktor-link-header__content">
        {links && (
          <ul className="redaktor-link-header__list">
            {links.map(({ text, href, isActive }, index) => (
              <li
                className={cn('redaktor-link-header__item', {
                  'redaktor-link-header__item--is-active': isActive
                })}
                key={index}
              >
                <Clicker href={href} text={text} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

RedaktorLinkHeader.propTypes = {
  color: PropTypes.oneOf(Object.values(colors)), //exclude from backend
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      isActive: PropTypes.bool
    })
  )
};

RedaktorLinkHeader.colors = colors;

RedaktorLinkHeader.defaultProps = {
  infos: [],
  color: colors.blue
};

export default RedaktorLinkHeader;
