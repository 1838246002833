/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SamtaleContext from './samtale-context';

const SamtaleContextProvider = ({
  children,
  samtaleId,
  saveEndpoint,
  readOnly,
  initLanguages
}) => {
  const [activeBlock, setActiveBlock] = useState(null);
  const [knownLanguages, setKnownLanguages] = useState(initLanguages ?? []);

  const updateActiveBlock = newBlock => {
    if (newBlock !== activeBlock) setActiveBlock(newBlock);
  };

  return (
    <SamtaleContext.Provider
      value={{
        activeBlock,
        updateActiveBlock,
        knownLanguages,
        setKnownLanguages,
        samtaleId,
        saveEndpoint,
        readOnly
      }}
    >
      {children}
    </SamtaleContext.Provider>
  );
};

SamtaleContextProvider.propTypes = {
  children: PropTypes.node, // exclude from backend,
  saveEndpoint: PropTypes.string, //exclude from BE
  samtaleId: PropTypes.string, //exclude from BE
  readOnly: PropTypes.bool, //exclude from BE
  initLanguages: PropTypes.arrayOf(PropTypes.string)
};

export default SamtaleContextProvider;
