import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Clicker from 'components/clicker/clicker';
import SlideInChildren from 'components/slide-in-children';

const themes = {
  systemtext: 'systemtext',
  green: 'green',
  default: 'default',
  uploadStudentsFromFile: 'uploadStudentsFromFile'
};

const Accordion = ({ className, children, title, theme, checkbox, headingLevel }) => {
  const [isToggled, setIsToggled] = useState(false);
  const toggleSetIsToggled = () => setIsToggled(!isToggled);

  const HeadingTag = 'h' + headingLevel;

  return (
    <div
      className={cn(
        'accordion',
        {
          'accordion--toggled': isToggled
        },
        {
          [`accordion--${themes[theme]}`]: themes[theme]
        },
        { [className]: className }
      )}
    >
      <HeadingTag className="accordion__heading">
        {checkbox && checkbox}
        <Clicker
          className="accordion__toggle-button"
          iconName={
            themes[theme] === 'systemtext'
              ? Clicker.iconNames.arrowUp
              : Clicker.iconNames.arrowUpCircle
          }
          theme={Clicker.themes.accordion}
          aria-expanded={isToggled}
          onClick={toggleSetIsToggled}
          iconSize={Clicker.iconSizes.medium}
        >
          <span className="accordion__heading-text">{title}</span>
        </Clicker>
      </HeadingTag>

      <SlideInChildren open={isToggled}>{children}</SlideInChildren>
    </div>
  );
};

Accordion.propTypes = {
  children: PropTypes.node,
  checkbox: PropTypes.node,
  title: PropTypes.string,
  theme: PropTypes.string,
  headingLevel: PropTypes.number
};

Accordion.defaultProps = {
  headingLevel: 2
};

Accordion.themes = themes;

export default Accordion;
