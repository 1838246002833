import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Flipped } from 'react-flip-toolkit';
import 'core-js/stable';

import useLocaleDate from '../../hooks/use-locale-date';

import ElementPropShape from 'components/grid-table-wrapper/element-prop-shape';
import GridCardIllustration from 'components/grid-card-illustration/grid-card-illustration';
import Clicker from 'components/clicker/clicker';
import Tooltip from 'components/tooltip/tooltip';
import Icon from 'components/icon/icon';
import GridCardColumn from './grid-card-column';

const checkForDate = text => {
  const isDate = !isNaN(Date.parse(text));
  let parsedPotentialDate = text;
  if (isDate) {
    const { formattedDate } = useLocaleDate(text);
    parsedPotentialDate = formattedDate;
  }
  return parsedPotentialDate;
};

const GridCard = ({
  isActive,
  handleOnClick,
  id,
  columnHeaders,
  columns,
  illustration,
  link,
  menuItems,
  infoTooltip,
  warningTooltip,
  zIndexPosition,
  showButtons,
  closeDropdownText
}) => {
  const cardRef = useRef(null);

  const handleExpand = e => {
    if (e.key === 'Enter' || e.key === ' ') {
      if (cardRef.current !== e.target) return;
      e.preventDefault();
      handleOnClick(id);
    }
  };

  const checkedTitle = checkForDate(columns[0].text);

  return (
    <Flipped key={id} flipId={id}>
      <li
        className={cn('grid-card', {
          'grid-card--active': isActive
        })}
        style={{ zIndex: zIndexPosition }}
      >
        <div
          className="grid-card__wrapper-link"
          ref={cardRef}
          role="button"
          onClick={() => handleOnClick(id)}
          aria-expanded={isActive}
          tabIndex={0}
          onKeyDown={handleExpand}
        >
          <div className="grid-card__content">
            <div className="grid-card__title-wrapper">
              <h2 className="grid-card__title">{checkedTitle}</h2>
              {infoTooltip && (
                <Tooltip
                  {...infoTooltip}
                  iconIsPadded={false}
                  iconName={Icon.names.warning}
                  activeIconName={Icon.names.warningBlue}
                />
              )}
              {warningTooltip && (
                <Tooltip
                  {...warningTooltip}
                  iconIsPadded={false}
                  iconName={Icon.names.warningRed}
                  activeIconName={Icon.names.warningBlue}
                />
              )}
            </div>
            <div className="grid-card__left-column">
              {columns && !!columns.length && (
                <ul className="grid-card__meta-list">
                  {columns.map((col, index) => {
                    if (index === 0) return false;
                    return (
                      <GridCardColumn
                        key={col.text + index}
                        isActive={isActive}
                        index={index}
                        columnHeaders={columnHeaders}
                        closeDropdownText={closeDropdownText}
                        {...col}
                      />
                    );
                  })}
                </ul>
              )}
            </div>
            <div className="grid-card__right-column">
              <GridCardIllustration {...illustration} isActive={isActive} />
            </div>
            {isActive && showButtons && (
              <div className="grid-card__actions-wrapper">
                {menuItems && menuItems.length > 1 && (
                  <div className="grid-card__actions">
                    {menuItems.slice(1).map((button, index) => {
                      return (
                        <Clicker
                          key={index}
                          theme={
                            !link && index === 0
                              ? Clicker.themes.primary
                              : Clicker.themes.secondary
                          }
                          {...button}
                          onClick={() => {
                            button.onClick(id);
                          }}
                          className="grid-card__action"
                        />
                      );
                    })}
                  </div>
                )}
                <div className="grid-card__actions">
                  {link && (
                    <Clicker
                      theme={Clicker.themes.primary}
                      {...link}
                      className="grid-card__action"
                    />
                  )}
                  {menuItems && menuItems.length && (
                    <Clicker
                      {...menuItems[0]}
                      theme={
                        !link
                          ? Clicker.themes.primary
                          : Clicker.themes.secondary
                      }
                      onClick={() => {
                        menuItems[0].onClick(id);
                      }}
                      className="grid-card__action"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </li>
    </Flipped>
  );
};

GridCard.propTypes = {
  // FE
  isActive: PropTypes.bool,
  handleOnClick: PropTypes.func,
  showButtons: PropTypes.bool,

  // BE
  ...ElementPropShape.propTypes
};

GridCard.defaultProps = {};

export default GridCard;
