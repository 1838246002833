import React from 'react';
import PropTypes from 'prop-types';

import MainLayout from 'components/main-layout';
import StructuralLayout from 'components/structural-layout/structural-layout';
import Progression from '../progression';
import KartleggingPlaceholder from '../kartlegging-placeholder';
import ChartContextProvider from '../../contexts/chart-context-provider';
import OversiktChartTable from 'components/oversikt-chart-table/oversikt-chart-table';

const ProgressionPage = ({
  structuralLayout,
  mainLayout,
  progression,
  kartleggingPlaceholder,
  pdfChartSvg,
  oversiktChartTable
}) => {
  return (
    <StructuralLayout {...structuralLayout}>
      <ChartContextProvider>
        <div className='progression-page'>
          <MainLayout {...mainLayout}>
            {progression && !kartleggingPlaceholder && (
              <Progression {...progression} />
            )}
            {!progression && kartleggingPlaceholder && (
              <KartleggingPlaceholder {...kartleggingPlaceholder} />
            )}
            {pdfChartSvg && (
              <div
                className="pdf-chart-svg"
                dangerouslySetInnerHTML={{ __html: pdfChartSvg }}
              />
            )}
            {oversiktChartTable && <OversiktChartTable {...oversiktChartTable} />}
          </MainLayout>
        </div>
      </ChartContextProvider>
    </StructuralLayout>
  );
};

ProgressionPage.propTypes = {
  structuralLayout: PropTypes.shape(StructuralLayout.propTypes),
  mainLayout: PropTypes.shape(MainLayout.propTypes),
  progression: PropTypes.exact(Progression.propTypes),
  kartleggingPlaceholder: PropTypes.exact(KartleggingPlaceholder.propTypes),
  pdfChartSvg: PropTypes.string,
  oversiktChartTable: PropTypes.exact(OversiktChartTable.propTypes)
};

export default ProgressionPage;
