import React from 'react';
import PropTypes from 'prop-types';

import Person from '../person';

const Results = ({ items, label }) => {
  return (
    <div className="results">
      {label && <div>{label}</div>}
      <ul className="results__list">
        {items.map((item, index) => {
          return (
            <li key={index} className="results__item">
              <Person {...item} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Results.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string
    })
  )
};

Results.defaultProps = {
  items: []
};

export default Results;
