import React from 'react';
import PropTypes from 'prop-types';

import MainHeading from '../main-heading';
import ProgressBars from '../progress-bars';
import Timeline from '../timeline/timeline';

const MappingOverview = ({ header, timeline, progressBars }) => {
  return (
    <div className="mapping-overview">
      <div className="mapping-overview__content">
        {header && <MainHeading {...header} />}
        <div className="mapping-overview__row">
          <ProgressBars {...progressBars} />
        </div>
        {timeline && (
          <div className="mapping-overview__row">
            <Timeline {...timeline} theme={Timeline.themes.horizontal} />
          </div>
        )}
      </div>
    </div>
  );
};

MappingOverview.propTypes = {
  header: PropTypes.exact(MainHeading.propTypes),
  timeline: PropTypes.exact(Timeline.propTypes),
  progressBars: PropTypes.exact(ProgressBars.propTypes)
};

export default MappingOverview;
