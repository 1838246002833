import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import RedaktorGrensesnittContext from '../../../../contexts/redaktor-grensesnitt-context';

import CheckboxInput from 'components/checkbox-input/checkbox-input';
import Clicker from 'components/clicker/clicker';
import Icon from 'components/icon';
import InputErrorMessage from 'components/input-error-message';
import RichTextEditor from 'components/rich-text-editor/rich-text-editor';
import TextInput from 'components/text-input';
import SamtaleEditItem from './samtale-edit-item';

export default function SamtaleEditItemDisplayData({
  title,
  readOnlyTitle,
  lead,
  id,
  inputs,
  uniqueNodeId,
  infoBanner,
  apiData,
  showChapterSeparator,
  hideShowChapterSeparatorCheckbox,
  type,
  readOnly,
  fixedStructure,
  saveEndpoint,
  buttonText,
  placeholderText,
  labelText,
  newQuestionTypeText
}) {
  const {
    state: {
      otherText,
      currentDataType,
      isLoading,
      isDirty,
      apiErrMessage,
      recentlySaved,
      redaktorDataList: samtaleguideList
    },
    functions: {
      setCurrentData,
      setOtherText,
      reorder,
      saveSamtaleChanges,
      deleteItem,
      addSamtaleItem,
      handleSamtaleChange,
      undoAll,
      handleSamtaleUndo
    }
  } = useContext(RedaktorGrensesnittContext);
  useEffect(() => {
    setCurrentData({
      apiData,
      saveEndpoint,
      initialDataList: inputs,
      type,
      title,
      lead,
      showChapterSeparator
    });
    setOtherText({
      title,
      lead,
      showChapterSeparator
    });
    return () => {
      setCurrentData({
        apiData: {},
        saveEndpoint: '',
        initialDataList: [],
        type: '',
        title: undefined,
        lead: undefined,
        showChapterSeparator: undefined
      });
      setOtherText({
        title: undefined,
        lead: undefined,
        showChapterSeparator: undefined
      });
    };
  }, []);
  const handleOtherTextChange = (key, value) => {
    setOtherText({
      ...otherText,
      [key]: value
    });
  };

  return (
    <>
      {!readOnly && (
        <div
          className={
            'redaktor-grensesnitt__data-content-actions sticky-actions'
          }
        >
          <div>
            <Clicker
              theme={Clicker.themes.secondary}
              onClick={undoAll}
              isDisabled={!isDirty}
              text={buttonText.global.cancel}
            />
            <Clicker
              theme={Clicker.themes.secondary}
              isDisabled={fixedStructure}
              onClick={addSamtaleItem}
              text={buttonText.global.add}
            />
          </div>
          <div>
            <span className={'redaktor-grensesnitt__save-status'}>
              {isLoading ? 'Lagrer ... ' : isDirty ? 'Ulagrede endringer' : ''}
            </span>
            <Clicker
              theme={Clicker.themes.primary}
              onClick={saveSamtaleChanges}
              isLoading={isLoading}
              isDisabled={!isDirty}
              text={buttonText.global.save}
            />
          </div>
        </div>
      )}
      {infoBanner && (
        <div className={'redaktor-grensesnitt__info-banner'}>
          <Icon name={Icon.names.infoCircle} />
          <span className={'redaktor-grensesnitt__info-banner-text'}>
            {infoBanner}
          </span>
        </div>
      )}
      {apiErrMessage?.globalMessage && (
        <InputErrorMessage errors={[apiErrMessage?.globalMessage]} />
      )}

      <div className={'redaktor-grensesnitt__other-text-input'}>
        {!hideShowChapterSeparatorCheckbox &&
          otherText.showChapterSeparator !== undefined && (
            <CheckboxInput
              isDisabled={readOnly}
              id={'showChapterSeparator'}
              onChange={(id, value) => handleOtherTextChange(id, value)}
              label={labelText.showChapterSeparator}
              value={
                otherText.showChapterSeparator !== undefined
                  ? otherText.showChapterSeparator
                  : showChapterSeparator
              }
            />
          )}
        {otherText.title !== undefined && (
          <TextInput
            isDisabled={readOnlyTitle || readOnly}
            value={otherText.title || ''}
            name={'title'}
            label={labelText.title}
            placeholder={placeholderText.title}
            id={`title-${id}`}
            onChange={value => handleOtherTextChange('title', value)}
          />
        )}

        {otherText.lead !== undefined && (
          <RichTextEditor
            isDisabled={readOnly}
            readOnly={readOnly}
            className={'redaktor-grensesnitt__lead'}
            value={otherText.lead || ''}
            id={`lead-${id}`}
            placeholder={placeholderText.lead}
            label={labelText.lead}
            name={'lead'}
            onChange={(id, htmlValue) =>
              handleOtherTextChange('lead', htmlValue)
            }
          />
        )}
      </div>
      <ul
        key={title + ' ' + lead}
        className="redaktor-grensesnitt__data-content-group"
      >
        {currentDataType === type &&
          samtaleguideList.map((samtaleQuestion, index) => (
            <SamtaleEditItem
              key={samtaleQuestion.id + uniqueNodeId}
              readOnly={readOnly}
              recentlySaved={recentlySaved}
              placeholderText={placeholderText}
              labelText={labelText}
              buttonText={buttonText.item}
              newQuestionTypeText={newQuestionTypeText}
              handleChange={handleSamtaleChange}
              handleUndo={handleSamtaleUndo}
              uniqueNodeId={index + uniqueNodeId}
              reorder={reorder}
              fixedStructure={fixedStructure}
              onDeleteCallback={deleteItem}
              listLength={samtaleguideList?.length || 0}
              samtaleQuestion={samtaleQuestion}
              parentIndex={index}
              index={index}
              errorMessage={
                apiErrMessage?.specificMessage?.id === samtaleQuestion.id
                  ? apiErrMessage?.specificMessage?.message
                  : ''
              }
            />
          ))}
      </ul>
    </>
  );
}

SamtaleEditItemDisplayData.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  readOnlyTitle: PropTypes.bool,
  hideShowChapterSeparatorCheckbox: PropTypes.bool,
  lead: PropTypes.string,
  infoBanner: PropTypes.string,
  showChapterSeparator: PropTypes.bool,
  saveEndpoint: PropTypes.string,
  fixedStructure: PropTypes.bool,
  inputs: PropTypes.arrayOf(PropTypes.shape(SamtaleEditItem.questionProps)),
  apiData: PropTypes.exact({
    versionId: PropTypes.string,
    arstrinngruppeId: PropTypes.string,
    sideId: PropTypes.string,
    kapittelIndex: PropTypes.string,
    underKapittelIndex: PropTypes.string
  }),
  buttonText: PropTypes.exact({
    global: PropTypes.exact({
      save: PropTypes.string.isRequired,
      cancel: PropTypes.string.isRequired,
      add: PropTypes.string.isRequired
    }).isRequired,
    item: PropTypes.exact({
      edit: PropTypes.string.isRequired,
      cancelEdit: PropTypes.string.isRequired,
      cancel: PropTypes.string.isRequired,
      moveDown: PropTypes.string.isRequired,
      moveUp: PropTypes.string.isRequired,
      delete: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  placeholderText: PropTypes.exact({
    question: PropTypes.string,
    lead: PropTypes.string,
    title: PropTypes.string
  }),
  labelText: PropTypes.exact({
    question: PropTypes.string,
    questionType: PropTypes.string,
    lead: PropTypes.string,
    title: PropTypes.string,
    showChapterSeparator: PropTypes.string
  }),
  newQuestionTypeText: PropTypes.string,
  uniqueNodeId: PropTypes.string // ignore backend
};

SamtaleEditItemDisplayData.defaultProps = {
  placeholderText: {
    question: 'Skriv inn et spørsmål',
    lead: 'Skriv inn ledetekst',
    title: 'Skriv inn tittel for seksjonen'
  },
  labelText: {
    title: 'Navngi tittel',
    question: 'Skriv inn et nytt spørsmål',
    questionType: 'Velg spørsmålstype',
    lead: 'Ledetekst',
    showChapterSeparator: 'Kapittel skal ha separator'
  },
  buttonText: {
    global: {
      add: 'Legg til',
      save: 'Lagre',
      cancel: 'Tilbakestill/Avbryt'
    },
    item: {
      cancelEdit: 'Avslutt redigering',
      edit: 'Rediger',
      cancel: 'Tilbakestill',
      moveDown: 'Flytt nedover',
      moveUp: 'Flytt oppover',
      delete: 'Slett'
    }
  }
};
