import React from 'react';
import PropTypes from 'prop-types';

const KartleggingPlaceholder = ({ title, text }) => {
  return (
    <div className="kartlegging-placeholder">
      <div className="kartlegging-placeholder__image-wrapper" />
      <div className="kartlegging-placeholder__text-wrapper">
        {title && <h2 className="kartlegging-placeholder__title">{title}</h2>}
        {text && (
          <div
            className="kartlegging-placeholder__text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
      </div>
    </div>
  );
};

KartleggingPlaceholder.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};

export default KartleggingPlaceholder;
