import React, { Fragment, useEffect, useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Accordion from 'components/accordion/accordion';
import { possibleSteps } from './add-students-from-file';

const RenderStep = ({ steps, currentStep, response, getButton }) => {
  const currentStepTitleRef = useRef(null);

  useEffect(() => {
    if (currentStepTitleRef.current) {
      currentStepTitleRef.current.focus();
    }
  }, [currentStep]);

  return (
    <div className="add-students-from-file__content">
      {steps?.map((s, i) => {
        if (i === currentStep) {
          return (
            <Fragment key={i}>
              {s?.title && (
                <h2
                  ref={currentStepTitleRef}
                  className="add-students-from-file__step-title"
                  tabIndex={-1}
                >
                  {s.title}
                </h2>
              )}
              {s?.description && (
                <div
                  className="add-students-from-file__step-description"
                  dangerouslySetInnerHTML={{ __html: s.description }}
                />
              )}
              {(currentStep === possibleSteps.AddStudents ||
                currentStep === possibleSteps.Summary) &&
                Object.entries({
                  success: response?.success,
                  error: response?.error
                })
                  ?.sort((a, b) => (a[0] > b[0] ? 1 : -1))
                  ?.map((val, i) => {
                    const categoryTitle = val[0];
                    const data = val[1];
                    return (
                      <div
                        className={cn(
                          'add-students-from-file__result-category',
                          `add-students-from-file__result-category--${categoryTitle}`,
                          `step--${currentStep}`
                        )}
                        key={categoryTitle + i}
                      >
                        {data?.title && (
                          <div className="add-students-from-file__result-category-title">
                            {data.title}
                          </div>
                        )}
                        {data?.elevLists?.map(({ title, elevList }) => {
                          let amount = 0;
                          elevList.forEach(
                            elevGroup => (amount += elevGroup.length)
                          );
                          return (
                            <Accordion
                              title={title + ` (${amount})`}
                              className="add-students-from-file__accordion"
                              key={title}
                              theme={Accordion.themes.uploadStudentsFromFile}
                              headingLevel={3}
                            >
                              <ul>
                                {elevList?.map((elevGroup, i) => {
                                  return (
                                    <li
                                      className={cn(
                                        'add-students-from-file__result-elev-group',
                                        {
                                          'add-students-from-file__result-elev-group--double':
                                            elevGroup.length >= 2
                                        }
                                      )}
                                      key={i}
                                    >
                                      {elevGroup?.map(
                                        ({
                                          fornavn,
                                          etternavn,
                                          idnummer,
                                          arstrinn,
                                          elevinfo,
                                          status
                                        }) => {
                                          return (
                                            <div
                                              className="add-students-from-file__result-elev"
                                              key={fornavn + etternavn}
                                            >
                                              {currentStep ===
                                                possibleSteps.AddStudents && (
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                      'space-between',
                                                    gap: '10px',
                                                    color:
                                                      categoryTitle === 'error'
                                                        ? '#AC071B'
                                                        : '#303030'
                                                  }}
                                                >
                                                  <div>
                                                    <span>
                                                      {etternavn +
                                                        ', ' +
                                                        fornavn}
                                                    </span>
                                                    |<span>{idnummer}</span>|
                                                    <span>{arstrinn}</span>
                                                  </div>
                                                  <div>
                                                    <span
                                                      style={{
                                                        color: '#303030',
                                                        fontStyle: 'italic'
                                                      }}
                                                    >
                                                      {status}
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                              {currentStep ===
                                                possibleSteps.Summary && (
                                                <div
                                                  style={{
                                                    color:
                                                      categoryTitle === 'error'
                                                        ? '#AC071B'
                                                        : '#303030'
                                                  }}
                                                >
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontWeight: '500'
                                                      }}
                                                    >
                                                      {fornavn +
                                                        ' ' +
                                                        etternavn}
                                                    </span>
                                                  </div>
                                                  <span>{elevinfo}</span>
                                                  <span
                                                    style={{
                                                      fontStyle: 'italic'
                                                    }}
                                                  >
                                                    {status && ', ' + status}
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </li>
                                  );
                                })}
                              </ul>
                            </Accordion>
                          );
                        })}
                      </div>
                    );
                  })}
              <div className="add-students-from-file__buttons">
                {s?.buttons?.map(b => {
                  return getButton(b.type, b.title);
                })}
              </div>
            </Fragment>
          );
        }
      })}
    </div>
  );
};

export default RenderStep;

RenderStep.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      errorDescription: PropTypes.string,
      buttons: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          type: PropTypes.string
        })
      )
    })
  ),
  currentStep: PropTypes.number,
  response: PropTypes.shape({
    fileError: PropTypes.string,
    importSessionId: PropTypes.string,
    success: PropTypes.object,
    error: PropTypes.object
  }),
  getButton: PropTypes.func
};
