/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import PhrasesContext from './phrases-context';

const PhrasesContextProvider = ({ children, ...rest }) => (
  <PhrasesContext.Provider value={{ ...rest }}>
    {children}
  </PhrasesContext.Provider>
);

PhrasesContextProvider.propTypes = {
  children: PropTypes.node, // exclude from backend
  done: PropTypes.string.isRequired,
  readOnly: PropTypes.string.isRequired,
  moreInfo: PropTypes.string.isRequired,
  remove: PropTypes.string.isRequired,
  save: PropTypes.string.isRequired,
  exit: PropTypes.string.isRequired,
  cancel: PropTypes.string.isRequired,
  showMore: PropTypes.string.isRequired,
  menu: PropTypes.string.isRequired,
  moveStudent: PropTypes.string.isRequired,
  declineMoveStudent: PropTypes.string.isRequired,
  confirm: PropTypes.string.isRequired,
  closeWithoutSaving: PropTypes.string.isRequired,
  saveAndClose: PropTypes.string.isRequired,
  toggle: PropTypes.string.isRequired,
  confirmDelete: PropTypes.string.isRequired,
  confirmDeleteMapping: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  orgNummer: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  add: PropTypes.string.isRequired,
  addUser: PropTypes.string.isRequired,
  more: PropTypes.string.isRequired,
  close: PropTypes.string.isRequired,
  edit: PropTypes.string.isRequired,
  reset: PropTypes.string.isRequired,
  activateMappingStatus: PropTypes.string.isRequired,
  deactivateMappingStatus: PropTypes.string.isRequired,
  assignTeacher: PropTypes.string.isRequired,
  editTeacher: PropTypes.string.isRequired,
  editStudent: PropTypes.string.isRequired,
  removePerson: PropTypes.string.isRequired,
  removeMapping: PropTypes.string.isRequired,
  removeStudent: PropTypes.string.isRequired,
  removeRole: PropTypes.string.isRequired,
  requestDeleteText: PropTypes.string.isRequired,
  addRole: PropTypes.string.isRequired,
  assignOwner: PropTypes.string.isRequired,
  assignRole: PropTypes.string.isRequired,
  addStudent: PropTypes.string.isRequired,
  addStudentFromFile: PropTypes.string.isRequired,
  addOwner: PropTypes.string.isRequired,
  timeline: PropTypes.string.isRequired,
  history: PropTypes.string.isRequired,
  download: PropTypes.string.isRequired,
  back: PropTypes.string.isRequired,
  dontShowAgain: PropTypes.string.isRequired,
  continueOn: PropTypes.string.isRequired,
  saveAndFinish: PropTypes.string.isRequired,
  exitWithoutSave: PropTypes.string.isRequired,
  orgNr: PropTypes.string.isRequired,
  notificationsMenuLabel: PropTypes.string.isRequired,
  rejectRemoveText: PropTypes.string.isRequired,
  undoRemoveRequestText: PropTypes.string.isRequired
};

export default PhrasesContextProvider;
