import PropTypes from 'prop-types';
import React from 'react';
import StatistikkDataDisplayItem from './statistikk-data-display-item';

const StatistikkDataDisplay = ({ dataList, parentIndex }) => {
  if (!Array.isArray(dataList)) return null;
  if (dataList.length === 1) {
    return <StatistikkDataDisplayItem isSingleItem={true} {...dataList[0]} />;
  }
  return (
    <div
      key={'parent' + parentIndex}
      className={'statistikk-admin__data-content-group'}
    >
      {dataList.map(d => {
        return (
          <StatistikkDataDisplayItem
            isSingleItem={dataList.length === 1}
            {...d}
          />
        );
      })}
    </div>
  );
};

StatistikkDataDisplay.propTypes = {
  dataList: PropTypes.array,
  parentIndex: PropTypes.number
};
export default StatistikkDataDisplay;
