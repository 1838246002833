import React from 'react';
import PropTypes from 'prop-types';

import api from 'js/api-helper';

import LoadingSpinner from 'components/loading-spinner';
import Tabs from 'components/tabs/tabs';
import AdminNode from './admin-node';
import OwnerAdmin from './owner-admin/owner-admin';
import SchoolAdmin from './school-admin/school-admin';
import StudentAdmin from './student-admin/student-admin';
import Toggle from 'components/toggle/toggle';
import SearchAdmin from './search-admin/search-admin';

const AdminDataDisplay = ({
  displayData,
  currentTab,
  setCurrentTab,
  updateNestedValue,
  handleSearchItemClick,
  handleRefreshData
}) => {
  const handleUpdateNestedValue = updatedData => {
    updateNestedValue({
      nodeId: displayData?.id,
      tabData: {
        ...displayData.data,
        ...updatedData
      },
      forceUpdateStatus: true
    });
  };

  const getAdminDataComponent = data => {
    const uniqueNodeId = `${displayData?.breadcrumbText}-${currentTab}`?.replace(
      / /g,
      ''
    );

    switch (data?.type) {
      case 'school': {
        return (
          <SchoolAdmin
            updateNestedValue={handleUpdateNestedValue}
            key={`${uniqueNodeId}-${data?.addButtonText}-${data?.type}-${data?.title}`}
            {...data}
          />
        );
      }
      case 'student': {
        return (
          <StudentAdmin
            updateNestedValue={handleUpdateNestedValue}
            handleRefreshData={handleRefreshData}
            key={`${uniqueNodeId}-${data?.addButtonText}-${data?.type}-${data?.title}`}
            {...data}
          />
        );
      }
      case 'owner': {
        return (
          <OwnerAdmin
            updateNestedValue={handleUpdateNestedValue}
            key={`${uniqueNodeId}-${data?.addButtonText}-${data?.type}-${data?.title}`}
            {...data}
          />
        );
      }
      case 'search': {
        return (
          <SearchAdmin
            handleSearchItemClick={handleSearchItemClick}
            key={`${uniqueNodeId}-${data?.addButtonText}-${data?.type}-${data?.title}`}
            {...data}
          />
        );
      }
      default:
        return null;
    }
  };

  const handleToggle = active => {
    if (!displayData?.endpointToggler?.endpoint) return;
    try {
      updateNestedValue({
        nodeId: displayData?.id,
        isExcluded: active
      });
      api.execute(displayData?.endpointToggler?.endpoint, {
        active: !!active,
        id: displayData?.id
      });
    } catch (err) {
      updateNestedValue({
        nodeId: displayData?.id,
        isExcluded: !active
      });
    }
  };

  const renderTabs = () => {
    const TabsElement = (
      <div className="admin__tabs">
        <Tabs
          tabs={displayData?.tabList?.map(tab => ({
            text: tab.text,
            onClick: () => setCurrentTab(tab.text),
            isActive: currentTab === tab.text
          }))}
        />
      </div>
    );
    if (displayData?.tabList?.length > 1) return TabsElement;
    else if (
      displayData?.tabList?.length === 1 &&
      displayData.tabList[0].text?.length
    )
      return TabsElement;
    else return null;
  };

  return (
    <div className="admin__data-wrapper">
      {displayData?.endpointToggler?.show && (
        <div className={'admin__toggler_wrapper'}>
          <Toggle {...displayData?.endpointToggler} onToggle={handleToggle} />
        </div>
      )}

      {renderTabs()}

      {displayData?.error && <div>Error</div>}
      {displayData?.loading && (
        <div className={'spinner-center'}>
          <LoadingSpinner />
        </div>
      )}
      {displayData?.data && (
        <div className="admin__sticky-data">
          <p className={'admin__breadcrumb-print'}>
            {displayData?.breadcrumbText}
          </p>

          <div className="admin__data-content">
            {displayData?.data && getAdminDataComponent(displayData.data)}
          </div>
        </div>
      )}
    </div>
  );
};

AdminDataDisplay.propTypes = {
  displayData: PropTypes.shape({
    id: PropTypes.string,
    breadcrumbText: PropTypes.string,
    endpointToggler: PropTypes.shape(Toggle.propTypes),
    data: PropTypes.oneOfType([
      PropTypes.shape(SchoolAdmin.propTypes),
      PropTypes.shape(StudentAdmin.propTypes),
      PropTypes.shape(OwnerAdmin.propTypes)
    ]),
    tabList: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        endpoint: PropTypes.string,
        isActive: PropTypes.bool
      })
    ),
    error: PropTypes.bool,
    loading: PropTypes.bool
  }),
  currentData: PropTypes.shape(AdminNode.RecursiveNodeProps),
  currentTab: PropTypes.string,
  setCurrentTab: PropTypes.func,
  updateNestedValue: PropTypes.func, // ignore backend
  handleSearchItemClick: PropTypes.func, // ignore backend
  handleRefreshData: PropTypes.func // ignore backend
};
export default AdminDataDisplay;
