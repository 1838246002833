import React from 'react';
import PropTypes from 'prop-types';

import BaseLayout from './base-layout';
import Header from '../header';
import Footer from '../footer';
import AdminHeader from '../admin-header';
import RedaktorLinkHeader from 'components/redaktor-link-header/redaktor-link-header';

const RedaktorLayout = ({
  children,
  header,
  footer,
  adminHeader,
  redaktorLinkHeader,
  ...rest
}) => {
  return (
    <BaseLayout color={BaseLayout.colors.gray} {...rest}>
      <Header {...header} theme={Header.themes.dark} />
      {redaktorLinkHeader && <RedaktorLinkHeader {...redaktorLinkHeader} />}
      {adminHeader && <AdminHeader {...adminHeader} color={'blue'} />}
      <div className="admin-layout">
        <main id="main-content" className="admin-layout__content">
          {children}
        </main>
      </div>
      <Footer {...footer} />
    </BaseLayout>
  );
};

RedaktorLayout.propTypes = {
  children: PropTypes.node, //excluded from backend
  header: PropTypes.exact(Header.propTypes),
  footer: PropTypes.exact(Footer.propTypes),
  adminHeader: PropTypes.exact(AdminHeader.propTypes),
  redaktorLinkHeader: PropTypes.exact(RedaktorLinkHeader.propTypes),
  ...BaseLayout.propTypes
};

export default RedaktorLayout;
