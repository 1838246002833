import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import cn from 'classnames';

import api from 'js/api-helper';
import Chart from 'components/chart/chart';
import Icon from 'components/icon/icon';

import topicColors from '../topic-colors';
import CheckboxItem from 'components/checkbox-input/checkbox-input';

const labelStyle = {
  fontSize: '18px',
  fontWeight: 'regular',
  color: '#303030',
  fontFamily: 'Roboto'
};

const ProgressionChart = ({
  chartEndpoint,
  elevId,
  checkboxes,
  buttonGroups,
  progressionDescription
}) => {
  const [activeFilters, setActiveFilters] = useState(
    buttonGroups.map(({ id }) => ({ id, buttons: [] }))
  );
  const [checkboxValues, setCheckboxValues] = useState(checkboxes ?? null);
  const [chartData, setChartData] = useState(null);
  const [series, setSeries] = useState([]);
  const [startMonth, setStartMonth] = useState(null);
  const [xAxisCategories, setXAxisCategories] = useState([]);
  const [kartleggingList, setKartleggingList] = useState([]);

  const handleChangeFilters = (
    groupId,
    buttonId,
    mutuallyExclusive,
    masterExclusive
  ) => {
    let newActiveFilters = [...activeFilters];
    newActiveFilters.forEach(filterGroup =>
      filterGroup.id === groupId
        ? (filterGroup.buttons = [
            {
              id: buttonId,
              mutuallyExclusive,
              masterExclusive
            }
          ])
        : (filterGroup.buttons[0]?.mutuallyExclusive && mutuallyExclusive) ||
          masterExclusive ||
          filterGroup.buttons[0]?.masterExclusive
        ? (filterGroup.buttons = [])
        : null
    );
    setActiveFilters(newActiveFilters);
  };

  const handleChangeCheckBoxes = id => {
    setCheckboxValues(prev => {
      let newCheckBoxes = [...prev];
      const checkBoxItem = newCheckBoxes.find(cb => cb.id === id);
      checkBoxItem.checked = !checkBoxItem.checked;
      return newCheckBoxes;
    });
  };

  const getChartData = async () => {
    const data = await api.execute(chartEndpoint, {
      elevId,
      checkboxes: checkboxValues,
      buttonGroups: activeFilters
    });
    setChartData(data);
    setStartMonth({
      ...data.completePeriodMonthOfYearList[
        data.completePeriodMonthOfYearList.length - 1 - 11
      ],
      index: data.completePeriodMonthOfYearList.length - 1 - 11
    });
    setKartleggingList([]);
  };

  useEffect(() => {
    // Reset filter to 'Sammenlagt progresjon' and checkboxes to false
    handleChangeFilters(
      buttonGroups[0]?.id,
      buttonGroups[0].buttons[0]?.id,
      buttonGroups[0].buttons[0]?.mutuallyExclusive,
      buttonGroups[0].buttons[0]?.masterExclusive
    );
    setCheckboxValues(prev => prev?.map(cb => ({ ...cb, checked: false })));
  }, [elevId]);

  useEffect(() => {
    getChartData();
  }, [activeFilters, checkboxValues]);

  function tooltipFormatter() {
    return `
          <p><b>${this.point.tooltip.category}</b></p><br/>
          ${this.point.tooltip.nameValueItemList
            ?.map(({ name, value }) => `<p><b>${name}: </b>${value}</p>`)
            .join('')}
      `;
  }

  const getNewKartleggingList = () => {
    let newList = [];
    chartData?.kartleggingProgresjonPeriodMonthOfYearItemList?.forEach(
      k =>
        !newList.find(i => i.id === k.kartleggingName) &&
        newList.push({ id: k.kartleggingName, checked: true })
    );
    return newList;
  };

  const getSeries = newKartleggingList => {
    // Create the right amount of series based on the amount of elements in the first kartleggingProgresjonPeriodMonthOfYearItemList
    let series = chartData?.kartleggingProgresjonPeriodMonthOfYearItemList[0]?.kartleggingProgresjonItemList.map(
      item => ({
        name: item.title,
        data: [],
        color: topicColors[item.color?.name?.toLowerCase()],
        opacity: item.color?.opacity || 1
      })
    );
    const appliedKartleggingFilters = newKartleggingList?.filter(
      kL => kL.checked
    );

    // For each month out of all months, check if the months is within the view and if we have any data
    chartData?.completePeriodMonthOfYearList?.forEach(({ id }, i) => {
      // If the month is out of view, do nothing
      if (i < startMonth.index || i > startMonth.index + 11) {
        return;
      }
      // Find the items in the list for the given month (there can be multiple)
      let kartleggingProgresjonPeriodMonthOfYearItems = chartData?.kartleggingProgresjonPeriodMonthOfYearItemList?.filter(
        ({ periodMonthOfYear }) => periodMonthOfYear?.id === id
      );

      // If the items doesn't exist (i.e. there's no data), the value for all the series will be 0
      if (kartleggingProgresjonPeriodMonthOfYearItems.length === 0) {
        series?.forEach(s => s.data.push({ y: 0 }));
      } else {
        // Choose which of the items to show in the chart, based on the chosen kartlegging in kartleggingList. if multiple kartlegging is chosen, we show only data for the first item (at index 0).
        let kIndex = 0;
        if (kartleggingProgresjonPeriodMonthOfYearItems.length > 1) {
          let relevantKartlegginger = [];
          kartleggingProgresjonPeriodMonthOfYearItems.forEach(i =>
            appliedKartleggingFilters.forEach(
              kF =>
                i.kartleggingName === kF.id &&
                relevantKartlegginger.push(i.kartleggingName)
            )
          );
          if (relevantKartlegginger.length === 1) {
            kIndex = kartleggingProgresjonPeriodMonthOfYearItems.indexOf(
              kartleggingProgresjonPeriodMonthOfYearItems.find(
                item => item.kartleggingName === relevantKartlegginger[0]
              )
            );
          }
        }

        // fallback
        if (kIndex === -1) kIndex = 0;

        // for each item in kartleggingProgresjonItemList in the given month at the given index, add data to the series
        kartleggingProgresjonPeriodMonthOfYearItems[
          kIndex
        ].kartleggingProgresjonItemList.forEach((k, i) => {
          series[i].data.push({
            y: kartleggingList?.find(
              k =>
                k.id ===
                  kartleggingProgresjonPeriodMonthOfYearItems[kIndex]
                    ?.kartleggingName && !k.checked
            )
              ? 0
              : Number(k?.mestringsgrad) || 0,
            kartleggingName:
              kartleggingProgresjonPeriodMonthOfYearItems[kIndex]
                ?.kartleggingName,
            kartleggingShortName:
              kartleggingProgresjonPeriodMonthOfYearItems[kIndex]
                ?.kartleggingShortName,
            tooltip: {
              category: k?.title,
              nameValueItemList: k?.nameValueItemList
            }
          });
        });
      }
    });

    setSeries(series);
  };

  const handleToggleKartlegging = id => {
    setKartleggingList(prev => {
      let newKartleggingList = [...prev];
      const kartleggingItem = newKartleggingList.find(k => k.id === id);
      kartleggingItem.checked = !kartleggingItem.checked;
      return newKartleggingList;
    });
  };

  const navigateBack = () => {
    setStartMonth(prev => ({
      ...chartData.completePeriodMonthOfYearList[prev.index - 1],
      index: prev.index - 1
    }));
  };

  const navigateForth = () => {
    setStartMonth(prev => ({
      ...chartData.completePeriodMonthOfYearList[prev.index + 1],
      index: prev.index + 1
    }));
  };

  const getXAxisCategories = () => {
    let cat = [];
    chartData?.completePeriodMonthOfYearList?.forEach(
      ({ shortName }, i) => i >= startMonth?.index && cat.push(shortName)
    );
    setXAxisCategories(cat);
  };

  useEffect(() => {
    if (startMonth) {
      if (
        chartData?.kartleggingProgresjonPeriodMonthOfYearItemList.length === 0
      ) {
        setSeries([]);
      } else {
        const newKartleggingList = getNewKartleggingList();
        getSeries(newKartleggingList);
        getXAxisCategories();
        setKartleggingList(newKartleggingList);
      }
    }
  }, [chartData, startMonth]);

  // When we toggle kartlegging checkboxes, filter out data points that belong to a kartlegging which is toggled to "off" (happens in getSeries)
  useEffect(() => {
    if (chartData) {
      getSeries(kartleggingList);
    }
  }, [kartleggingList]);

  const chart = {
    options: {
      chart: {
        type: 'column',
        plotBackgroundColor: '#FFF',
        backgroundColor: '#f7f7f7', //$color--layout-gray
        borderColor: '#ffffff',
        height: '700px'
      },
      title: { text: '' },
      credits: false,
      legend: true,
      xAxis: {
        categories: xAxisCategories,
        gridLineWidth: 1,
        gridLineDashStyle: 'longdash',
        lineColor: '#DDDDDD',
        labels: {
          style: labelStyle,
          y: -20
        },
        opposite: true
      },
      yAxis: {
        min: 0,
        max: 2,
        tickInterval: 0.5,
        title: {
          text: ''
        },
        opposite: true,
        labels: {
          style: labelStyle
        }
      },
      plotOptions: {
        column: {
          //   borderWidth: 0.2,
          //   borderWidth: 12
          //   pointPadding: 0.05
          //   groupPadding: 0.05
        }
      },
      rangeSelector: {
        selected: 1
      },
      series: series,
      tooltip: {
        useHTML: true,
        formatter: tooltipFormatter
      }
    },
    comprehensionLevelLabel: 'Nivå',
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: '768px' // scss $breakpoint--sm
          },
          chartOptions: {}
        }
      ]
    }
  };

  return (
    <div className="progression-chart">
      <div className="progression-chart__title-container">
        <h2 className="progression-chart__title">{chartData?.title}</h2>
      </div>
      <div className="progression-chart__container">
        {chartData && series && (
          <div className="progression-chart__navigation">
            <button
              className="progression-chart__nav-back"
              onClick={() => navigateBack()}
              disabled={startMonth?.index - 1 < 0}
            >
              <Icon
                className="progression-chart-nav-back--icon"
                name="arrow-down"
                size={Icon.sizes.medium}
                isPadded={true}
              />
            </button>
            <button
              className="progression-chart__nav-forth"
              onClick={() => navigateForth()}
              disabled={
                startMonth?.index + 1 >
                chartData.completePeriodMonthOfYearList?.length - 1 - 11
              }
            >
              <Icon
                className="progression-chart-nav-forth--icon"
                name="arrow-down"
                size={Icon.sizes.medium}
                isPadded={true}
              />
            </button>
          </div>
        )}
        <Chart {...chart} />
        {!series && (
          <div className="progression-chart__no-data">Ingen data å vise...</div>
        )}
      </div>
      <div className="progression-chart__percent">
        {chartData?.completePeriodMonthOfYearList?.map(({ id }, i) => {
          if (i < startMonth?.index || i > startMonth?.index + 11) {
            return null;
          }
          const items = chartData.kartleggingProgresjonPeriodMonthOfYearItemList?.filter(
            ({ periodMonthOfYear }) => periodMonthOfYear.id === id
          );
          return (
            <div
              key={id}
              className="progression-chart__percent-item"
              style={{
                width: 100 / 12 + '%'
              }}
            >
              {items?.map(
                item =>
                  kartleggingList.find(
                    k => k.id === item?.kartleggingName && k.checked
                  ) && item.prosentKartlagt
              )}
              <div className="progression-chart__percent-item--kartlegging">
                {items?.map((item, i) => {
                  const kName =
                    kartleggingList.find(
                      k => k.id === item?.kartleggingName && k.checked
                    ) && item?.kartleggingShortName;
                  if (kName) {
                    return (
                      <Fragment key={`percent-item-kartlegging-${kName}-${i}`}>
                        {kName}
                        <br />
                      </Fragment>
                    );
                  }
                })}
              </div>
            </div>
          );
        })}
      </div>
      {kartleggingList && (
        <div className="progression-chart__toggle-kartlegging">
          {kartleggingList?.map(({ id, checked }, i) => {
            return (
              <div
                className="progression-chart__button-group"
                key={'toggle-kartlegging-checkbox-' + i}
              >
                <CheckboxItem
                  id={'toggle-kartlegging-checkbox-' + i}
                  onChange={() => handleToggleKartlegging(id)}
                  value={checked}
                  label={'Vis ' + id}
                />
              </div>
            );
          })}
        </div>
      )}

      <div className="progression-chart__description">
        {progressionDescription}
      </div>
      <div className="progression-chart__buttons">
        {checkboxValues?.map(({ id, label, checked }) => {
          return (
            <div className="progression-chart__button-group" key={id}>
              <CheckboxItem
                id={id}
                onChange={() => handleChangeCheckBoxes(id)}
                value={checked}
                label={label}
              />
            </div>
          );
        })}
        {buttonGroups?.map(({ id, buttons }) => {
          const groupId = id;
          return (
            <div key={groupId} className="progression-chart__button-group">
              {buttons?.map(
                ({
                  id,
                  title,
                  colorList,
                  mutuallyExclusive,
                  masterExclusive
                }) => {
                  return (
                    <button
                      key={id}
                      className={cn('progression-chart__filter-button', {
                        'progression-chart__filter-button--active':
                          activeFilters.find(
                            filterGroup => filterGroup.id === groupId
                          )?.buttons[0]?.id === id
                      })}
                      onClick={() =>
                        handleChangeFilters(
                          groupId,
                          id,
                          mutuallyExclusive,
                          masterExclusive
                        )
                      }
                    >
                      <div
                        className="progression-chart__filter-button--color"
                        style={{
                          background:
                            colorList.length > 1
                              ? `conic-gradient(${colorList.map(
                                  (c, i) =>
                                    `${topicColors[c.name.toLowerCase()]}${(
                                      c.opacity * 255
                                    ).toString(16)} ${
                                      (i / colorList.length) * 100
                                    }% ${((i + 1) / colorList.length) * 100}%`
                                )})`
                              : topicColors[colorList[0].name.toLowerCase()] +
                                  (colorList[0].opacity
                                    ? (colorList[0].opacity * 255).toString(16)
                                    : '') || 'mangenta'
                        }}
                      />
                      {title}
                    </button>
                  );
                }
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

ProgressionChart.propTypes = {
  chartEndpoint: PropTypes.string,
  elevId: PropTypes.string,
  checkboxes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      checked: PropTypes.bool
    })
  ),
  visBareFullforteKartlegginger: PropTypes.bool,
  brukBareKartlagteUtsagn: PropTypes.bool,
  buttonGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      buttons: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          colorList: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              opacity: PropTypes.number
            })
          ),
          mutuallyExclusive: PropTypes.bool,
          masterExclusive: PropTypes.bool
        })
      )
    })
  ),
  progressionDescription: PropTypes.string
};

export default ProgressionChart;
