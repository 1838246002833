import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const LoadingSpinner = ({ className, large, shouldTakeOverScreen }) => {
  useEffect(() => {
    if (shouldTakeOverScreen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (shouldTakeOverScreen) {
        document.body.style.overflow = '';
      }
    };
  }, []);

  return (
    <div
      className={cn(
        className,
        'loading-spinner',
        {
          'loading-spinner--large': large
        },
        {
          'loading-spinner--full': shouldTakeOverScreen
        }
      )}
    >
      <div className="loading-spinner__image" />
    </div>
  );
};

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  large: PropTypes.bool,
  shouldTakeOverScreen: PropTypes.bool
};

export default LoadingSpinner;
