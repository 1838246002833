import React, { useState } from 'react';
import PropTypes from 'prop-types';

import api from 'js/api-helper';

import GridTableWrapper from 'components/grid-table-wrapper/grid-table-wrapper';
import LoadingSpinner from 'components/loading-spinner';
import ConfirmationModal from 'components/modal/confirmation-modal';
import AddBrukerrolleModal from 'components/modal/add-brukerrolle-modal/add-brukerrolle-modal';

const UndervisningsPersonell = ({
  content,
  addButtonText,
  removeButtonText,

  addPersonellEndpoint,
  removeEndpoint,
  addBrukerrolleModal,
  confirmationModal,
  showMenus
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPersonell, setCurrentPersonell] = useState(content.elements);

  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  const [deleteMethod, setDeleteMethod] = useState(() => {});

  const handleOnAddConfirm = async userList => {
    setIsLoading(true);
    setAddModalIsOpen(false);

    try {
      const newPersonell = await api.execute(
        addPersonellEndpoint,
        userList.map(user => user.id)
      );

      setCurrentPersonell([...currentPersonell, ...newPersonell]);
    } catch (e) {
      setIsLoading(false);
      // eslint-disable-next-line no-console
      console.log(e);
    }
    setIsLoading(false);
  };

  const handleRemove = async id => {
    setIsLoading(true);

    try {
      await api.execute(removeEndpoint, { roleId: id });

      setCurrentPersonell(personell =>
        personell.filter(resource => {
          return resource.id !== id;
        })
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
    setConfirmationModalIsOpen(false);
  };
  const handleOnRemoveClick = id => {
    setConfirmationModalIsOpen(true);
    setDeleteMethod(() => () => handleRemove(id));
  };

  const addElementMenuToPersonell = () => {
    if (!(content.elements && !!content.elements.length)) return [];
    return [...currentPersonell].map(personell => {
      return {
        ...personell,
        menuItems: [
          {
            text: removeButtonText,
            onClick: handleOnRemoveClick,
            isDisabled: personell.isCurrentUser
          }
        ]
      };
    });
  };

  return (
    <div className="undervisnings-personell">
      <GridTableWrapper
        {...content}
        elements={addElementMenuToPersonell()}
        addActions={[
          addButtonText
            ? {
                text: addButtonText,
                onClick: () => setAddModalIsOpen(true)
              }
            : undefined
        ]}
        showMenus={showMenus}
      />
      {isLoading && <LoadingSpinner shouldTakeOverScreen={true} />}
      {addModalIsOpen && addBrukerrolleModal && (
        <AddBrukerrolleModal
          {...addBrukerrolleModal}
          isOpen={addModalIsOpen}
          onConfirm={handleOnAddConfirm}
          onClose={() => setAddModalIsOpen(false)}
        />
      )}
      {confirmationModalIsOpen && (
        <ConfirmationModal
          {...confirmationModal}
          isOpen={confirmationModalIsOpen}
          onConfirm={deleteMethod}
          onClose={() => setConfirmationModalIsOpen(false)}
        />
      )}
    </div>
  );
};

UndervisningsPersonell.propTypes = {
  content: PropTypes.exact(GridTableWrapper.propTypes),
  showMenus: PropTypes.bool,

  addButtonText: PropTypes.string,
  removeButtonText: PropTypes.string,

  removeEndpoint: PropTypes.string,
  confirmationModal: PropTypes.exact(ConfirmationModal.propTypes),

  addPersonellEndpoint: PropTypes.string,
  addBrukerrolleModal: PropTypes.exact(AddBrukerrolleModal.propTypes),
  roleAssignmentCode: PropTypes.string.isRequired,
  orgId: PropTypes.string
};

UndervisningsPersonell.defaultProps = {};

export default UndervisningsPersonell;
