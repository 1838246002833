import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Clicker from '../clicker';
import Checkbox from 'components/checkbox/checkbox';
import RadioGroup from 'components/radio-group/radio-group';
import LoadingSpinner from 'components/loading-spinner';

const DownloadSamtale = ({
  endpoint,
  radioGroup,
  checkbox,
  buttonText,
  onSubmit,
  onFinish
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    onSubmit();
    let filename = '';
    fetch(endpoint, {
      method: 'post',
      body: JSON.stringify({
        [radioGroup.name]: formData.get(radioGroup.name)
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        filename = response.headers
          .get('Content-Disposition')
          .split('filename=')[1]
          .split(';')[0];
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        onFinish();
      })
      .catch(error => {
        onFinish();
        // eslint-disable-next-line no-console
        console.log('Error:', error);
      });
  };

  return (
    <form className="download-samtale" onSubmit={handleSubmit}>
      {endpoint ? (
        <>
          <RadioGroup
            className="download-samtale__radio-group"
            {...radioGroup}
          />
          {checkbox && (
            <Checkbox
              {...checkbox}
              id="download-samtale-report-checkbox"
              checked={isConfirmed}
              onChange={setIsConfirmed}
              required={true}
            />
          )}
          <Clicker
            className="download-samtale__submit"
            type="submit"
            theme={
              !checkbox || isConfirmed
                ? Clicker.themes.primary
                : Clicker.themes.disabled
            }
            text={buttonText}
            aria-disabled={!isConfirmed}
          />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </form>
  );
};

DownloadSamtale.propTypes = {
  endpoint: PropTypes.string,
  radioGroup: PropTypes.exact(RadioGroup.propTypes),
  checkbox: PropTypes.exact(Checkbox.propTypes),
  buttonText: PropTypes.string,
  onSubmit: PropTypes.func,
  onFinish: PropTypes.func
};

export default DownloadSamtale;
