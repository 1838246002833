import React, { useContext } from 'react';

const ChartContext = React.createContext();
export function useChartContext() {
  return useContext(ChartContext);
}

ChartContext.propTypes = {};

export default ChartContext;
