import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import cn from 'classnames';
import Clicker from 'components/clicker/clicker';
import Icon from 'components/icon';

const themes = {
  default: 'default',
  underlined: 'underlined',
  rowSmall: 'rowSmall'
};
const NumberInput = forwardRef(
  (
    {
      maskWithAsterix,
      theme,
      label,
      value,
      id,
      onBlur,
      onChange,
      placeholder,
      isDisabled,
      error,
      thousandSeparator,
      prefix,
      suffix,
      editorMode,
      handleEditorModeLabelChange,
      ...rest
    },
    ref
  ) => {
    const handleBlur = e => {
      onBlur(e.target.value, id);
    };

    const removeContent = () => {
      if (maskWithAsterix) {
        onChange('', id);
      }
    };

    const props = {
      ...rest,
      isNumericString: true,
      type: 'text',
      prefix: prefix,
      suffix: suffix,
      thousandSeparator: thousandSeparator,
      format: maskWithAsterix ? value : rest?.format,
      value: value,
      id: id,
      ref: null,
      getInputRef: ref,
      placeholder: placeholder,
      disabled: isDisabled,
      onBlur: handleBlur,
      onChange: e => onChange(e.target.value, id),
      className: cn('number-input__input', {
        'number-input__input--has-value': value?.length,
        'number-input__input--disabled': isDisabled
      })
    };
    return (
      <div
        className={cn('number-input', {
          [`number-input--${themes[theme]}`]: themes[theme]
        })}
      >
        {maskWithAsterix && (
          <div className={'number-input__close-button-wrapper'}>
            <Clicker
              textIsHidden
              text="Slett innhold"
              iconName={Icon.names.closeCircle}
              onClick={removeContent}
            />
          </div>
        )}
        <div className={'number-input__field'}>
          <NumberFormat {...props} />

          {editorMode ? (
            <div className="number-input__label">
              <input
                value={label}
                id={id}
                onChange={e =>
                  handleEditorModeLabelChange(id, e.target.value, false)
                }
              />
            </div>
          ) : (
            <>
              <label htmlFor={id} className="number-input__label">
                {label}
              </label>
              {Boolean(error) && (
                <span
                  aria-errormessage={error}
                  className={'number-input__error'}
                >
                  {error}
                </span>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
);

NumberInput.inputProps = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inputKey: PropTypes.string,
  value: PropTypes.string.isRequired
};

NumberInput.propTypes = {
  ...NumberInput.inputProps,
  placeholder: PropTypes.string,
  error: PropTypes.string, // ignore backend
  onBlur: PropTypes.func, // ignore backend
  onChange: PropTypes.func, // ignore backend
  min: PropTypes.number, // ignore backend
  max: PropTypes.number, // ignore backend
  maskWithAsterix: PropTypes.bool, // ignore backend
  isDisabled: PropTypes.bool, // ignore backend
  thousandSeparator: PropTypes.string, // ignore backend
  prefix: PropTypes.string, // ignore backend
  suffix: PropTypes.string // ignore backend
};

NumberInput.defaultProps = {
  theme: themes.default,
  thousandSeparator: '',
  allowLeadingZeros: true,
  prefix: '',
  suffix: '',
  onBlur: () => {},
  onChange: () => {}
};

NumberInput.themes = themes;

export default NumberInput;
