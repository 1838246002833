import { PortableText } from '@portabletext/react';
import Clicker from 'components/clicker/clicker';
import NumberInput from 'components/number-input';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import usePhrases from '../../../hooks/use-phrases';
import sanityClient from '../../../sanityClient';
import Modal from '../modal';
import { serializers, urlFor } from './serializers';
import cn from 'classnames';

const SanityImagePickerModal = ({
  isOpen,
  onClose,
  isDisabled,
  title,
  selectImageCallback,
  groqQuery = `
  *[_type == "informasjonstekst" && general.title == "Informasjon om lesing"]
  `
}) => {
  const { add, close } = usePhrases();
  const [sanityData, setSanityData] = useState(null);
  const [imageSize, setImageSize] = useState({ width: 320, height: 250 });
  const [currentImage, setCurrentImage] = useState('');

  useEffect(() => {
    sanityClient
      .fetch(groqQuery)
      .then(data => {
        setSanityData(data);
      })
      .catch(() => {
        setSanityData({ errorMessage: 'Noe gikk galt med Sanity fetch' });
      });
  }, []);

  const handleProceed = () => {
    selectImageCallback(
      urlFor(currentImage).width(imageSize.width).height(imageSize.height),
      imageSize,
      currentImage?.caption
    );
    onClose();
  };

  const renderImage = image => {
    return (
      <div
        className={cn('sanity-image-picker-modal__image')}
        onClick={() => setCurrentImage(image)}
      >
        <PortableText components={serializers} value={image} />
        <p className={'sanity-image-picker-modal__image-caption'}>
          {image?.caption}
        </p>
      </div>
    );
  };

  return (
    <Modal
      themes={[Modal.themes.gray]}
      isOpen={isOpen}
      onClose={onClose}
      isDisabled={isDisabled}
    >
      <div className="sanity-image-picker-modal">
        <div className="sanity-image-picker-modal__content">
          {title && (
            <h2 className="sanity-image-picker-modal__title">{title}</h2>
          )}
          {sanityData?.errorMessage && <p>{sanityData.errorMessage}</p>}
          {selectImageCallback && (
            <div className={'sanity-image-picker-modal__size-input-row'}>
              <NumberInput
                max={1920}
                label={'Bredde'}
                theme={NumberInput.themes.rowSmall}
                value={imageSize.width}
                onChange={value => {
                  setImageSize(s => ({ ...s, width: value }));
                }}
              />
              <NumberInput
                max={700}
                theme={NumberInput.themes.rowSmall}
                label={'Høyde'}
                value={imageSize.height}
                onChange={value => setImageSize(s => ({ ...s, height: value }))}
              />
            </div>
          )}
          {currentImage && (
            <div className={'sanity-image-picker-modal__current-image-wrapper'}>
              {renderImage(currentImage)}
            </div>
          )}
          {!currentImage && (
            <div className={'sanity-image-picker-modal__image-wrapper'}>
              {sanityData?.map(({ image }, i) => {
                return <div key={i}>{renderImage(image)}</div>;
              })}
            </div>
          )}
        </div>
        <div className="sanity-image-picker-modal__action-bar">
          {currentImage && (
            <div style={{ marginRight: 'auto' }}>
              <Clicker
                theme={Clicker.themes.secondary}
                onClick={() => setCurrentImage(null)}
                text={'Tilbake'}
              />
            </div>
          )}

          <Clicker
            text={close}
            onClick={onClose}
            theme={Clicker.themes.secondary}
          />
          <Clicker
            text={add}
            onClick={handleProceed}
            theme={Clicker.themes.primary}
          />
        </div>
      </div>
    </Modal>
  );
};

SanityImagePickerModal.propTypes = {
  isDisabled: PropTypes.bool, //exclude from backend
  selectImageCallback: PropTypes.func, //exclude from backend
  onClose: PropTypes.func, //exclude from backend
  isOpen: PropTypes.bool, //exclude from backend
  title: PropTypes.string.isRequired,
  groqQuery: PropTypes.string
};

SanityImagePickerModal.defaultProps = {
  onConfirm: () => {},
  onClose: () => {},
  entries: []
};

export default SanityImagePickerModal;
