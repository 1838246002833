import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ResourceTable from 'components/resource-table';
import usePhrases from '../../../hooks/use-phrases';
import ConfirmationModal from 'components/modal/confirmation-modal';

const OwnerAdminTable = ({
  table,
  setOwnerModal,
  onRemoveRole,
  confirmationModal,
  onRemove,
  showAssignAction,
  showRemoveAction
}) => {
  const { removeRole, assignRole, confirmDelete } = usePhrases();

  const [modalState, setModalState] = useState({
    modal: confirmationModal,
    pendingIds: []
  });

  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  const decorateWithMenu = resource => {
    return {
      ...resource,
      menuItems: (function () {
        let items = [];

        if (showAssignAction)
          items.push({
            text: assignRole,
            onClick: () =>
              setOwnerModal({
                id: resource?.id,
                orgId: resource?.orgId,
                roleId: resource?.roleId
              })
          });

        if (showRemoveAction === 1) {
          items.push({
            text: removeRole,
            isDisabled: resource.isCurrentUser,
            onClick: (_ids, resources) => {
              setModalState({
                ...modalState,
                pendingIds: resources.map(res => {
                  return { id: res?.id, roleId: res?.roleId };
                }),
                modal: {
                  ...modalState.modal,
                  title: `${removeRole} ${resources[0]?.displayName}`
                }
              });

              setConfirmationModalIsOpen(true);
            }
          });
        } else if (showRemoveAction === 2) {
          items.push({
            text: removeRole,
            onClick: () => onRemoveRole(resource),
            isDisabled: !resource?.roleId
          });
        }

        return items;
      })()
    };
  };

  return (
    <>
      <div className="owner-admin-table">
        <ResourceTable
          {...table}
          showMenus={true}
          showLinks={false}
          resources={table?.resources.map(resource =>
            decorateWithMenu(resource)
          )}
        />
      </div>
      {confirmationModalIsOpen && (
        <ConfirmationModal
          {...modalState.modal}
          confirmText={confirmDelete}
          isOpen={confirmationModalIsOpen}
          onConfirm={() => {
            setConfirmationModalIsOpen(false);

            onRemove(modalState.pendingIds);
          }}
          onClose={() => setConfirmationModalIsOpen(false)}
        />
      )}
    </>
  );
};

OwnerAdminTable.propTypes = {
  showAssignAction: PropTypes.bool, // exclude from backend
  confirmationModal: PropTypes.exact(ConfirmationModal.propTypes), // exclude from backend
  showRemoveAction: PropTypes.oneOf([0, 1, 2]).isRequired, // exclude from backend
  setOwnerModal: PropTypes.func, // exclude from backend
  onRemoveRole: PropTypes.func, // exclude from backend
  onRemove: PropTypes.func, // exclude from backend
  table: PropTypes.exact(ResourceTable.propTypes) // exclude from backend
};

export default OwnerAdminTable;
