import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import SamtaleContext from '../../contexts/samtale-context';
import SamtaleQuestion from 'components/samtale-question/samtale-question';
import Clicker from 'components/clicker/clicker';

const SamtaleBlock = ({
  blockId,
  title,
  lead,
  questionGroups,
  closeButtonText
}) => {
  const { activeBlock, updateActiveBlock } = useContext(SamtaleContext);

  return (
    <div
      className={cn('samtale-block', {
        'samtale-block--active': activeBlock === blockId
      })}
    >
      {title && (
        <h2 id={blockId} className="samtale-block__title">
          {title}
        </h2>
      )}
      {lead && (
        <div
          dangerouslySetInnerHTML={{ __html: lead }}
          className="samtale-block__lead"
        />
      )}
      {closeButtonText && (
        <Clicker
          className={cn('samtale-block__button', {
            'samtale-block__button--visible': activeBlock === blockId
          })}
          onClick={() => updateActiveBlock(null)}
          text={closeButtonText}
          iconName={Clicker.iconNames.closeCircleBorderless}
          iconSize={Clicker.iconSizes.large}
          textIsHidden={true}
        />
      )}
      {questionGroups &&
        questionGroups.map(
          ({ groupTitle, groupLead, questions, showGroupSeparator }, index) => (
            <div
              key={blockId + 'group' + index}
              className="samtale-block__group"
            >
              {showGroupSeparator && (
                <div className="samtale-block__group-separator" />
              )}
              {groupTitle && (
                <h3 className="samtale-block__group-title">{groupTitle}</h3>
              )}
              {groupLead && (
                <div
                  dangerouslySetInnerHTML={{ __html: groupLead }}
                  className="samtale-block__group-lead"
                />
              )}
              {questions &&
                questions.map((question, questionIndex) => (
                  <SamtaleQuestion
                    key={questionIndex}
                    blockId={blockId}
                    {...question}
                  />
                ))}
            </div>
          )
        )}
    </div>
  );
};

SamtaleBlock.propTypes = {
  blockId: PropTypes.string,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  lead: PropTypes.string,
  questionGroups: PropTypes.arrayOf(
    PropTypes.exact({
      groupTitle: PropTypes.string,
      groupLead: PropTypes.string,
      questions: PropTypes.arrayOf(PropTypes.exact(SamtaleQuestion.propTypes)),
      showGroupSeparator: PropTypes.bool
    })
  ),
  closeButtonText: PropTypes.string
};

export default SamtaleBlock;
