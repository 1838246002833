/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ChartContext from './chart-context';

const ChartContextProvider = ({ children }) => {
  const [SVGFromChart, setSVGFromChart] = useState();

  return (
    <ChartContext.Provider
      value={{
        SVGFromChart,
        setSVGFromChart
      }}
    >
      {children}
    </ChartContext.Provider>
  );
};

ChartContextProvider.propTypes = {
  children: PropTypes.node, // exclude from backend
  finishAndCompleteButton: PropTypes.exact({
    text: PropTypes.string,
    endpoint: PropTypes.string,
    disabled: PropTypes.bool,
    isActive: PropTypes.bool,
    id: PropTypes.string
  })
};

export default ChartContextProvider;
