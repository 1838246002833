import Clicker from 'components/clicker/clicker';
import InputErrorMessage from 'components/input-error-message';
import LoadingSpinner from 'components/loading-spinner/loading-spinner';
import SelectInput from 'components/select-input/select-input';
import SlideInChildren from 'components/slide-in-children';
import apiHelper from 'js/api-helper';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AlertModal from './alert-modal';

const RedaktorIntroReadOnly = ({
  getActionsEndpoint,
  title,
  lead,
  versions,
  isOpen,
  setIsOpen,
  actionButtonText,
  dropdownPlaceholder
}) => {
  const [currentSelectedVersion, setCurrentSelectedVersion] = useState(null);
  const [alertModal, setAlertModal] = useState(null);

  const [proceedButton, setProceedButton] = useState({
    endpoint: '',
    text: ''
  });
  const [{ isLoading, error }, setFetchStatus] = useState({
    isLoading: false,
    error: ''
  });
  const closeAlertModal = () => {
    setAlertModal(aM => ({ ...aM, isOpen: false }));
  };
  const openAlertModal = () => {
    setAlertModal(aM => ({ ...aM, isOpen: true }));
  };

  const handleSelectVersion = async value => {
    if (!value) {
      setProceedButton({
        endpoint: '',
        text: ''
      });
      return;
    }
    setFetchStatus({ isLoading: true, error: '' });
    try {
      const selectedVersion = versions.find(v => v.value === value);
      setCurrentSelectedVersion(selectedVersion);
      const res = await apiHelper.execute(getActionsEndpoint, {
        versionId: selectedVersion.value
      });
      setProceedButton({
        endpoint: res.endpoint,
        text: res.text
      });
      if (res.alertModal) setAlertModal({ ...res.alertModal, isOpen: false });
      setFetchStatus({ isLoading: false, error: '' });
    } catch (err) {
      setFetchStatus({
        isLoading: false,
        error: err?.message || 'Noe gikk galt'
      });
    }
  };

  const handleProceed = async () => {
    if (!proceedButton?.endpoint) return;
    if (alertModal && !alertModal.isOpen) return openAlertModal();
    setFetchStatus({
      isLoading: true,
      error: ''
    });
    try {
      await apiHelper.execute(proceedButton?.endpoint, {
        versionId: currentSelectedVersion.value
      });
      setFetchStatus({
        isLoading: false,
        error: ''
      });
    } catch (err) {
      setFetchStatus({
        isLoading: false,
        error: err?.message || 'Noe gikk galt'
      });
    }
  };

  return (
    <div
      className={'redaktor-intro__box'}
      onClick={() => !isOpen && setIsOpen()}
      style={!isOpen ? { cursor: 'pointer' } : {}}
    >
      {alertModal && (
        <AlertModal
          {...alertModal}
          onClose={closeAlertModal}
          handleProceed={handleProceed}
        />
      )}
      <div className={'redaktor-intro__box-text-wrapper'}>
        <h2
          id="redaktor-intro-read-only-heading"
          className={'redaktor-intro__box-title'}
        >
          {title}
        </h2>
        <p className={'redaktor-intro__box-lead'}>{lead}</p>
      </div>
      <SlideInChildren open={isOpen}>
        <div
          style={{ minHeight: 100 }}
          className={'redaktor-intro__box-slide-in-wrapper'}
        >
          <SelectInput
            ariaLabelledby="redaktor-intro-read-only-heading"
            id={'readonly-version'}
            onChange={handleSelectVersion}
            onFocus={() => !isOpen && setIsOpen()}
            placeholder={dropdownPlaceholder}
            options={versions.map(({ text, value }) => ({ text, value }))}
          />
          {error && <InputErrorMessage errors={[error]} />}
          <div className={'redaktor-intro__button-wrapper'}>
            {isLoading && <LoadingSpinner />}
            <Clicker
              isDisabled={!proceedButton?.text || isLoading}
              onClick={handleProceed}
              text={proceedButton?.text || actionButtonText}
              theme={Clicker.themes.primary}
            />
          </div>
        </div>
      </SlideInChildren>
    </div>
  );
};

RedaktorIntroReadOnly.propTypes = {
  getActionsEndpoint: PropTypes.string.isRequired,
  // takes id of selected version and returns list of radio buttons with corresponding object with proceed button text and endpoint
  title: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  versions: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      isPublished: PropTypes.bool.isRequired
    })
  ),
  actionButtonText: PropTypes.string,
  dropdownPlaceholder: PropTypes.string,
  isOpen: PropTypes.bool, // ignore backend
  setIsOpen: PropTypes.func // ignore backend
};

RedaktorIntroReadOnly.defaultProps = {
  actionButtonText: 'Se kartleggingsspesifikasjon',
  dropdownPlaceholder: 'Velg versjon'
};

export default RedaktorIntroReadOnly;
