import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../layouts/admin-layout';
import SchoolAdmin from '../admin/school-admin';

const SchoolAdminPage = ({ layout, admin }) => {
  return (
    <Layout {...layout}>
      <SchoolAdmin {...admin} />
    </Layout>
  );
};

SchoolAdminPage.propTypes = {
  layout: PropTypes.exact(Layout.propTypes),
  admin: PropTypes.exact(SchoolAdmin.propTypes)
};

export default SchoolAdminPage;
