import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import HeaderContext from '../../contexts/header-context';
import usePhrases from '../../hooks/use-phrases';
import InfoBubbleButton from 'components/info-bubble-button/info-bubble-button';
import Clicker from 'components/clicker/clicker';
import EditAdminModal from 'components/edit-admin-modal/edit-admin-modal';

const colors = {
  green: 'green',
  blue: 'blue',
  black: 'black'
};

const AdminHeader = ({
  name,
  infos,
  color,
  submitEndpoint = '/static-site/api/on-edit-profile.json',
  editAdminModal,
  headingLevel
}) => {
  const { adminHeaderData } = useContext(HeaderContext);
  const { edit } = usePhrases();

  const [isOpen, setIsOpen] = useState(false);

  const currentName = useMemo(() => {
    return adminHeaderData?.name || name;
  }, [adminHeaderData?.name, name]);
  const currentInfos = useMemo(() => {
    return adminHeaderData?.infos || infos;
  }, [adminHeaderData?.infos, infos]);
  const currentEditModal = useMemo(
    () => adminHeaderData?.editAdminModal || editAdminModal,
    [adminHeaderData?.editAdminModal, editAdminModal]
  );

  const HeadingElement = headingLevel ? 'h' + headingLevel : 'h2';

  return (
    <>
      <div
        className={cn('admin-header', {
          'admin-header--columns': currentInfos?.length > 2,
          [`admin-header--${colors[color]}`]: colors[color]
        })}
      >
        <div className="admin-header__content">
          <div className="admin-header__heading-wrapper">
            <HeadingElement className="admin-header__title">
              {currentName}
            </HeadingElement>
            {currentEditModal && (
              <div className="profile-header__actions">
                <div className="profile-header__edit">
                  {(adminHeaderData.submitEndpoint || submitEndpoint) && (
                    <Clicker
                      text={edit}
                      textIsHidden={true}
                      iconName={Clicker.iconNames.edit}
                      onClick={() => setIsOpen(true)}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="admin-header__info">
            {currentInfos && (
              <ul className="admin-header__list">
                {currentInfos.map(({ label, value, infoBubble }, index) => (
                  <li className="admin-header__item" key={index}>
                    <span className="admin-header__label">{`${label}`}</span>
                    <span className="admin-header__value">{`${value}`}</span>
                    <span className="admin-header__wrapper">
                      {infoBubble && <InfoBubbleButton {...infoBubble} />}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      <EditAdminModal
        {...currentEditModal}
        inputs={adminHeaderData?.inputs || editAdminModal?.inputs}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

AdminHeader.propTypes = {
  color: PropTypes.oneOf(Object.values(colors)), //exclude from backend
  name: PropTypes.string,
  infos: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      infoBubble: PropTypes.exact(InfoBubbleButton.propTypes)
    })
  ),
  submitEndpoint: PropTypes.string,
  editAdminModal: PropTypes.exact(EditAdminModal.propTypes),
  headingLevel: PropTypes.number
};

AdminHeader.colors = colors;

AdminHeader.defaultProps = {
  infos: [],
  color: colors.green
};

export default AdminHeader;
