import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import api from 'js/api-helper';

import OwnerAdminTable from './owner-admin-table';
import LoadingSpinner from 'components/loading-spinner';
import ResourceTable from 'components/resource-table';
import AddResourceModal from 'components/modal/add-resource-modal';
import ConfirmationModal from 'components/modal/confirmation-modal';
import Clicker from 'components/clicker';
import filterArraysWithoutDuplicatesByKey from 'js/filter-arrays-without-duplicates-by-key';
import sortKeyInObject from 'js/sortKeyInObject';

import UserContext from '../../../contexts/user-context';
import Tabs from 'components/tabs/tabs';

const OwnerAdmin = ({
  updateNestedValue,
  roleAssignmentCode,
  orgId,
  title,
  tabs,
  addButtonText,
  table,
  ownerAssignmentModal,
  confirmationModal,
  addOwnerModal,
  showAddAction,
  showAssignAction,
  removeRoleEndpoint,
  getRolesEndpoint,
  removeRoleAndRowEndpoint,
  addOwnerEndpoint,
  updateOwnerRoleEndpoint
}) => {
  const [tableState, setTableState] = useState(table);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage] = useState('');

  const [addOwnerModalIsOpen, setAddOwnerModalIsOpen] = useState(false);
  const [ownerAssignmentModalIsOpen, setOwnerAssignmentModalIsOpen] = useState(
    false
  );

  const { updateRoles } = useContext(UserContext);

  const handleOnRemoveClick = async resourceIds => {
    setIsLoading(true);

    try {
      await api.execute(removeRoleAndRowEndpoint, {
        ...resourceIds[0]
      });

      const { roles } = await api.execute(getRolesEndpoint, {});

      if (roles?.length > 0) updateRoles(roles);

      const updatedTable = {
        ...tableState,
        resources: tableState.resources.filter(resource => {
          return resourceIds[0].id.indexOf(resource.id) === -1;
        })
      };
      updateNestedValue({ table: updatedTable });
      setTableState(updatedTable);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
  };

  const handleOnRemoveRoleClick = async resource => {
    setIsLoading(true);

    const { id: idToReplace, orgId, roleId } = resource; // we select the id of the resource that we want to replace

    const multipleRows = tableState.resources.filter(
      resource => resource.orgId === orgId
    )[1];

    try {
      await api.execute(removeRoleEndpoint, { roleId });

      const { roles } = await api.execute(getRolesEndpoint, {});

      if (roles?.length > 0) updateRoles(roles);

      const { resources } = await api.execute(updateOwnerRoleEndpoint, {
        id: idToReplace,
        orgId // we expect the newly created resource to have this id
      });

      const updatedResources = multipleRows
        ? tableState.resources
            .filter(oldResource => oldResource.id !== idToReplace)
            .sort((a, b) => sortKeyInObject(a, b, 'displayName'))
        : tableState.resources.map(oldResource => {
            if (oldResource.id !== idToReplace) return oldResource;

            const newResource = resources.find(
              newResource => newResource.id === idToReplace
            );

            return newResource || oldResource;
          });
      const updatedTable = {
        ...tableState,
        resources: updatedResources
      };
      updateNestedValue({ table: updatedTable });

      setTableState({
        ...tableState,
        table: updatedTable
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
  };

  const handleOnAddOwnerConfirm = async (owners, selectedRole) => {
    setIsLoading(true);

    setAddOwnerModalIsOpen(false);

    try {
      const { resources } = await api.execute(addOwnerEndpoint, {
        roleAssignmentCode: selectedRole || roleAssignmentCode,
        orgId,
        ownerId: owners.map(owner => owner.id)[0]
      });

      const { roles } = await api.execute(getRolesEndpoint, {});

      if (roles?.length > 0) updateRoles(roles);

      const updatedTable = {
        ...tableState,
        resources: filterArraysWithoutDuplicatesByKey(
          tableState.resources,
          resources,
          'id'
        )
      };
      updateNestedValue({ table: updatedTable });
      setTableState(updatedTable);
    } catch (e) {
      setIsLoading(false);
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
  };

  const handleOnOpenAssignmentModal = () => {
    setOwnerAssignmentModalIsOpen(true);
  };

  const showRemove = React.useMemo(() => {
    if (!removeRoleAndRowEndpoint && !removeRoleEndpoint) return 0;
    else if (removeRoleAndRowEndpoint) return 1;
    else if (removeRoleEndpoint) return 2;
    else return 0;
  }, [removeRoleAndRowEndpoint, removeRoleEndpoint]);

  useEffect(() => {
    setTableState(table);
  }, [table]);

  return (
    <>
      <div className="owner-admin">
        <div className="owner-admin__content">
          {tabs && (
            <div className={'owner-admin__tabs'}>
              <Tabs {...tabs} />
            </div>
          )}
          {title && <h1 className="owner-admin__title">{title}</h1>}
          {showAddAction && (
            <div className="owner-admin__actions">
              <div className="owner-admin__action">
                <Clicker
                  text={addButtonText}
                  theme={Clicker.themes.primary}
                  onClick={() => setAddOwnerModalIsOpen(true)}
                />
              </div>
            </div>
          )}
          <div className="owner-admin__row">
            <OwnerAdminTable
              table={tableState}
              showAssignAction={showAssignAction}
              showRemoveAction={showRemove}
              setOwnerModal={handleOnOpenAssignmentModal}
              onRemoveRole={handleOnRemoveRoleClick}
              confirmationModal={confirmationModal}
              onRemove={handleOnRemoveClick}
            />
          </div>
        </div>
      </div>
      {isLoading && <LoadingSpinner shouldTakeOverScreen={true} />}
      {ownerAssignmentModalIsOpen && (
        //TODO: Er denne i bruk?
        <AddResourceModal
          {...ownerAssignmentModal}
          roleAssignmentCode={roleAssignmentCode}
          orgId={orgId}
          errorMessage={errorMessage}
          isOpen={ownerAssignmentModalIsOpen}
          onClose={() => setOwnerAssignmentModalIsOpen(false)}
        />
      )}
      {addOwnerModalIsOpen && (
        <AddResourceModal
          {...addOwnerModal}
          roleAssignmentCode={roleAssignmentCode}
          orgId={orgId}
          errorMessage={errorMessage}
          isOpen={addOwnerModalIsOpen}
          onConfirm={handleOnAddOwnerConfirm}
          onClose={() => setAddOwnerModalIsOpen(false)}
        />
      )}
    </>
  );
};

OwnerAdmin.propTypes = {
  roleAssignmentCode: PropTypes.string.isRequired,
  orgId: PropTypes.string,
  title: PropTypes.string,
  tabs: PropTypes.exact(Tabs.propTypes),
  addButtonText: PropTypes.string,
  ownerAssignmentModal: PropTypes.exact(AddResourceModal.propTypes),
  addOwnerModal: PropTypes.exact(AddResourceModal.propTypes),
  confirmationModal: PropTypes.exact(ConfirmationModal.propTypes),
  showAddAction: PropTypes.bool,
  showAssignAction: PropTypes.bool,
  removeRoleEndpoint: PropTypes.string,
  assignOwnerEndpoint: PropTypes.string,
  getRolesEndpoint: PropTypes.string.isRequired,
  removeRoleAndRowEndpoint: PropTypes.string,
  updateOwnerRoleEndpoint: PropTypes.string,
  addOwnerEndpoint: PropTypes.string,
  table: PropTypes.exact(ResourceTable.propTypes),
  updateNestedValue: PropTypes.func // ignore backend
};

OwnerAdmin.defaultProps = {
  showAddAction: true,
  showAssignAction: true,
  showRemoveAction: true,
  updateNestedValue: () => {}
};
export default OwnerAdmin;
