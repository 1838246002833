/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import EleverContentContext from './elever-content-context';

const EleverContentContextProvider = ({ children }) => {
  const [elementToUpdate, setElementToUpdate] = useState({});

  return (
    <EleverContentContext.Provider
      value={{ elementToUpdate, setElementToUpdate }}
    >
      {children}
    </EleverContentContext.Provider>
  );
};

EleverContentContextProvider.propTypes = {
  children: PropTypes.node // exclude from backend
};

export default EleverContentContextProvider;
