import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import colors from '../topic-colors';
import { useWindowSize } from '../../hooks/useWindowSize';
import Clicker from 'components/clicker/clicker';

const themes = {
  horizontal: 'horizontal',
  vertical: 'vertical'
};

const Timeline = ({ links, title, theme, color }) => {
  const { width } = useWindowSize();
  const { isMobile, isTablet, isDesktop } = useMemo(() => {
    return {
      isMobile: width < 768,
      isTablet: width > 768 && width < 1024,
      isDesktop: width > 1024
    };
  }, [width]);

  const amountOfItems = useMemo(
    () => (isMobile || isTablet ? 3 : isDesktop ? 5 : 5),
    [isMobile, isTablet, isDesktop]
  );

  const currentIndex = useMemo(() => {
    return links?.findIndex(link => link.isActive);
  }, [links]);

  const [modifier, setModifier] = useState(0);
  // const dependencyArray = [amountOfItems, links, currentIndex, modifier];

  const { displayedLinks, additionalModifier } = useMemo(() => {
    // if there's no use for an arrow in the timeline, return as-is
    if (links.length < amountOfItems) {
      return {
        displayedLinks: links.map((link, i) => ({ ...link, index: i })),
        additionalModifier: 0
      };
    }
    // currentIndex is the bolded item, while modifier is however many times a user pressed the next/prev arrow
    let indexWithModifier = currentIndex + modifier;
    // For different screens, different amount of items should be shown.
    // This value tells us how many times we should go to the sides of the currentIndex
    let spreadNumber = amountOfItems === 3 ? 1 : amountOfItems === 5 ? 2 : 0;

    let additionalModifier = 0,
      displayedLinks = [],
      remainingLinks = [];

    links
      .map((link, i) => ({ ...link, index: i })) // add an index to every item
      .forEach(link => {
        // Find which items should be displayed and not
        const lower = indexWithModifier >= link.index - spreadNumber;
        const higher = indexWithModifier <= link.index + spreadNumber;

        if (lower && higher) {
          displayedLinks.push(link);
        } else remainingLinks.push(link);
      });

    // If there's not enough items displayed (because currentIndex was too far to either side)
    if (displayedLinks.length !== amountOfItems) {
      const linksMissingAmount = amountOfItems - displayedLinks.length;

      const displayedIndexSum = displayedLinks.reduce(
        (num, link) => num + link?.index,
        0
      );
      const remainingIndexSum = remainingLinks.reduce(
        (num, link) => num + link?.index,
        0
      );

      // reduce are used here to find out if there's missing items at the beginning or end
      const missingLinksAtEnd = remainingIndexSum > displayedIndexSum;

      // If missing at the end we add to the end of the displayLinks
      if (displayedLinks.length) {
        if (missingLinksAtEnd) {
          additionalModifier = linksMissingAmount;

          const higherLinkDisplayed = displayedLinks[displayedLinks.length - 1];
          const linksToEnd = remainingLinks.filter(link => {
            return higherLinkDisplayed.index + linksMissingAmount >= link.index;
          });
          displayedLinks = displayedLinks.concat(linksToEnd);
        } else {
          // else we add to the beginning
          additionalModifier = -linksMissingAmount;

          const lowerLinkDisplayed = displayedLinks[0];
          const linksToStart = remainingLinks.filter(link => {
            return link.index >= lowerLinkDisplayed.index - linksMissingAmount;
          });
          displayedLinks = linksToStart.concat(displayedLinks);
        }
      }
    }
    // Return all the displayedLinks together with an additionalModifier which is used in the effect under
    return { displayedLinks, additionalModifier };
  }, [modifier, currentIndex, amountOfItems, links]);

  // This effect fixes a problem when currentIndex doesn't start in the middle, and clicking the prev/next arrows requires additional indexation
  useEffect(() => {
    setModifier(_modifier => _modifier + additionalModifier);
  }, [additionalModifier]);

  if (!links?.length) return null;
  return (
    <>
      {title && <h2 className="timeline-heading">{title}</h2>}
      <div
        className={cn('timeline', {
          [`timeline--${themes[theme]}`]: themes[theme],
          [`timeline--${colors[color]}`]: colors[color]
        })}
      >
        {displayedLinks[0]?.href !== links[0]?.href && (
          <div className={'timeline__left-button'}>
            <Clicker
              isDisabled={displayedLinks[0]?.href === links[0]?.href}
              iconName={
                theme === themes.horizontal
                  ? Clicker.iconNames.arrowLeft
                  : Clicker.iconNames.arrowUp
              }
              text={'Forrige'}
              textIsHidden
              onClick={() => setModifier(modifier - 1)}
            />
          </div>
        )}

        <ul className="timeline__list">
          {displayedLinks.map(link => {
            return (
              <li key={link.index} className="timeline__item">
                <a
                  className={cn('timeline__link', {
                    'timeline__link--active': link.isActive
                  })}
                  href={link?.href}
                >
                  <div className="timeline__text">
                    <div>{link.text}</div>
                    <div>{link.description}</div>
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
        {displayedLinks[displayedLinks?.length - 1]?.href !==
          links[links?.length - 1]?.href && (
          <div className={'timeline__right-button'}>
            <Clicker
              iconName={
                theme === themes.horizontal
                  ? Clicker.iconNames.arrowRight
                  : Clicker.iconNames.arrowDown
              }
              text={'Neste'}
              textIsHidden
              isDisabled={
                displayedLinks[displayedLinks?.length - 1]?.href ===
                links[links?.length - 1]?.href
              }
              onClick={() => setModifier(modifier + 1)}
            />
          </div>
        )}
      </div>
    </>
  );
};

Timeline.propTypes = {
  theme: PropTypes.oneOf(Object.values(themes)), // ignore backend
  color: PropTypes.oneOf(Object.keys(colors)),
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      description: PropTypes.string,
      isActive: PropTypes.bool
    })
  )
};

Timeline.defaultProps = {
  links: [],
  theme: themes.horizontal
};

Timeline.themes = themes;

Timeline.colors = colors;

export default Timeline;
