import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const themes = {
  default: 'default',
  defaultDisabled: 'defaultDisabled',
  underlined: 'underlined',
  minimal: 'minimal'
};

const TextInput = forwardRef(
  (
    {
      theme,
      label,
      value,
      maxLength,
      id,
      onBlur,
      onFocus,
      onEnter,
      inputKey,
      onChange,
      placeholder,
      isDisabled,
      hasAutoComplete,
      alphabeticOnly,
      alphanumericOnly,
      autoFocus
    },
    ref
  ) => {
    const handleChange = e => {
      if (alphabeticOnly) {
        if (/^([^0-9<>!*/?+@¨%#()=&"'$^§]*)$/.test(e.target.value))
          onChange(e.target.value, id);
      } else if (alphanumericOnly) {
        if (/^([^<>!*/?+@¨%#()=&"'$^§]*)$/.test(e.target.value))
          onChange(e.target.value.toUpperCase(), id);
      } else onChange(e.target.value, id);
    };

    const handleEnterPress = e => {
      if (e.key === 'Enter') onEnter();
    };
    return (
      <div
        className={cn('text-input', {
          [`text-input--${themes[theme]}`]: themes[theme]
        })}
      >
        <div className="text-input__field">
          <input
            autoFocus={autoFocus}
            ref={ref}
            key={inputKey}
            id={id}
            maxLength={maxLength}
            autoComplete={hasAutoComplete ? 'on' : 'off'}
            placeholder={placeholder}
            disabled={isDisabled}
            onBlur={e => onBlur(e.target.value, id)}
            onFocus={onFocus}
            onChange={handleChange}
            onKeyDown={handleEnterPress}
            type="text"
            value={value}
            className={cn('text-input__input', {
              'text-input__input--has-value': value?.length
            })}
          />
          <label htmlFor={id} className="text-input__label">
            {label}
          </label>
        </div>
      </div>
    );
  }
);

TextInput.propTypes = {
  theme: PropTypes.oneOf(Object.values(themes)), //exclude from backend
  onBlur: PropTypes.func, //exclude from backend
  onFocus: PropTypes.func, //exclude from backend
  onChange: PropTypes.func, //exclude from backend
  onEnter: PropTypes.func, //exclude from backend
  isDisabled: PropTypes.bool, //exclude from backend
  alphabeticOnly: PropTypes.bool, //exclude from backend
  alphanumericOnly: PropTypes.bool, //exclude from backend
  autoFocus: PropTypes.bool, //exclude from backend
  hasAutoComplete: PropTypes.bool, //exclude from backend
  id: PropTypes.string.isRequired,
  inputKey: PropTypes.string,
  maxLength: PropTypes.number, //exclude from backend
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string
};

TextInput.displayName = 'TextInput';

TextInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  theme: themes.default,
  hasAutoComplete: true,
  errors: []
};

TextInput.themes = themes;

export default TextInput;
