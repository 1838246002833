import InputErrorMessage from 'components/input-error-message';
import MenuButton from 'components/resource-table/menu-button';
import UdirRichTextEditor from 'components/rich-text-editor/rich-text-editor';
import TextInputList from 'components/text-input-list/text-input-list';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

export default function KartleggingExpressionItem({
  expression,
  onDeleteCallback,
  handleChange,
  handleExpressionUndo,
  errorMessage,
  readOnly,
  buttonText,
  labelText,
  recentlySaved,
  placeholderText
}) {
  const requiredInputRef = useRef(null);

  const isNew = useMemo(() => {
    return (
      expression?.matchItemList?.length === 1 &&
      expression?.matchItemList[0]?.match === '' &&
      expression?.description === ''
    );
  }, [expression]);

  const [inEditMode, setInEditMode] = useState(isNew);

  useEffect(() => {
    if (recentlySaved) {
      setInEditMode(false);
    }
  });

  const initialMatchItemList = useRef(
    JSON.stringify(expression?.matchItemList)
  );
  const initialDescription = useRef(expression?.description);

  const undoDisabled = useMemo(() => {
    if (
      JSON.parse(initialMatchItemList?.current)?.length === 0 &&
      initialDescription === ''
    )
      return true;
    return (
      initialMatchItemList.current ===
        JSON.stringify(expression?.matchItemList) &&
      initialDescription.current === expression?.description
    );
  }, [
    initialMatchItemList,
    initialDescription,
    expression?.matchItemList,
    expression?.description
  ]);

  useEffect(() => {
    if (requiredInputRef?.current && errorMessage) {
      requiredInputRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [requiredInputRef, errorMessage]);

  const hideTools = !inEditMode || readOnly;

  return (
    <li
      ref={requiredInputRef}
      className="redaktor-grensesnitt__data-content-group-item-wrapper"
    >
      <div className="redaktor-grensesnitt__data-content-group-item">
        {!readOnly && (
          <div style={{ position: 'absolute', right: '0.5rem', top: '0.5rem' }}>
            <MenuButton
              items={[
                {
                  text: inEditMode ? buttonText.cancelEdit : buttonText.edit,
                  onClick: () => setInEditMode(!inEditMode)
                },
                {
                  text: buttonText.delete,
                  onClick: () => onDeleteCallback(expression.id)
                },
                {
                  isDisabled: undoDisabled,
                  onClick: () => handleExpressionUndo(expression?.id),
                  text: buttonText.cancel
                }
              ]}
            />
          </div>
        )}
        {errorMessage && (
          <div style={{ marginBottom: '1rem' }}>
            <InputErrorMessage errors={[errorMessage]} />
          </div>
        )}
        <div className={'redaktor-grensesnitt__margin-bottom'}>
          <TextInputList
            theme={TextInputList.themes.row}
            color={TextInputList.colors.white}
            isDisabled={hideTools}
            label={labelText?.expression}
            placeholder={placeholderText?.expression}
            onChange={(value, id) => handleChange(id, value, 'matchItemList')}
            valueArray={expression?.matchItemList || []}
            id={expression.id}
            inEditMode={inEditMode}
          />
        </div>
        <UdirRichTextEditor
          onChange={(id, value) => handleChange(id, value, 'description')}
          hasActionButtons={true}
          id={expression.id}
          readOnly={hideTools}
          label={labelText.description}
          placeholder={placeholderText.description}
          value={expression?.description || ''}
        />
      </div>
    </li>
  );
}

KartleggingExpressionItem.expressionObjectProps = PropTypes.shape({
  id: PropTypes.string,
  matchItemList: PropTypes.arrayOf(
    PropTypes.shape({
      match: PropTypes.string,
      matchInfoHeader: PropTypes.string,
      matchInfoItemList: PropTypes.arrayOf(PropTypes.string),
      error: PropTypes.bool
    })
  ),
  description: PropTypes.string // html
});

KartleggingExpressionItem.propTypes = {
  buttonText: PropTypes.exact({
    edit: PropTypes.string.isRequired, // ignore backend
    cancelEdit: PropTypes.string.isRequired, // ignore backend
    cancel: PropTypes.string.isRequired, // ignore backend
    delete: PropTypes.string.isRequired // ignore backend
  }).isRequired, // ignore backend
  placeholderText: PropTypes.exact({
    expression: PropTypes.string, // ignore backend
    description: PropTypes.string // ignore backend
  }),
  labelText: PropTypes.exact({
    expression: PropTypes.string, // ignore backend
    description: PropTypes.string // ignore backend
  }),
  recentlySaved: PropTypes.bool, // ignore backend
  onDeleteCallback: PropTypes.func.isRequired, //Ignore backend
  handleChange: PropTypes.func.isRequired, //Ignore backend
  handleExpressionUndo: PropTypes.func.isRequired, //Ignore backend
  errorMessage: PropTypes.string, // ignore backend
  readOnly: PropTypes.bool, // ignore backend
  expression: KartleggingExpressionItem.expressionObjectProps
};
