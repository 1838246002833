import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { iconHoverNames } from '../icon/icon.types';

import Icon from '../icon';

const elements = {
  link: 'a',
  button: 'button'
};

const themes = {
  default: 'default',
  navigation: 'navigation',
  accordion: 'accordion',
  submit: 'submit',
  primary: 'primary',
  secondary: 'secondary',
  underlined: 'underlined',
  transparentUnderline: 'transparentUnderline',
  underlinedThin: 'underlinedThin',
  underlinedDecoration: 'underlinedDecoration',
  save: 'save',
  primarySmall: 'primarySmall',
  defaultUnderline: 'defaultUnderline',
  alignedRight: 'alignedRight',
  alignedLeft: 'alignedLeft',
  hoverBlueNoUnderline: 'hoverBlueNoUnderline',
  blueBgHover: 'blueBgHover',
  sortOption: 'sortOption',
  disabled: 'disabled'
};

const Clicker = ({
  children,
  className,
  tag,
  href,
  alt,
  theme,
  text,
  iconName,
  iconSize,
  activeIconName,
  textIsHidden,
  iconIsBeforeText,
  onClick,
  isDisabled,
  isActive,
  hasGhostIcon,
  iconIsPadded,
  ...rest
}) => {
  const Element = href ? elements.link : elements[tag];

  const [isHovered, setIsHovered] = useState(false);

  const getIconHoverVersion = () => {
    return Object.values(iconHoverNames).indexOf(`hover-${iconName}`) !== -1
      ? `hover-${iconName}` // We assume that the hover version of any given icon is prefixed with "hover-". Needs to match icons.scss.
      : undefined;
  };

  const handleOnClick = e => {
    e.stopPropagation();
    onClick && onClick(e);
  };

  let props = {
    href: !isDisabled ? href : undefined,
    onClick: e => handleOnClick(e),
    type: href
      ? undefined
      : theme === themes.submit || theme === themes.save
      ? 'submit'
      : 'button',
    disabled: Element === elements.button ? isDisabled : undefined,
    'aria-disabled': isDisabled,
    'aria-label': text,
    className: cn(
      'clicker',
      {
        [`clicker--${themes[theme]}`]: themes[theme],
        'clicker--hidden-text': textIsHidden,
        'clicker--icon-left': iconIsBeforeText,
        'clicker--hovered': isHovered,
        'clicker--has-ghost-icon': hasGhostIcon && iconName === '',
        'clicker--active': isActive
      },
      className
    ),
    ...rest
  };

  if (alt) {
    if (href) {
      props = Object.assign({}, props, {
        alt: alt
      });
    } else {
      props = Object.assign({}, props, {
        title: alt
      });
    }
  }

  if (iconName && getIconHoverVersion())
    props = Object.assign({}, props, {
      onMouseEnter: () => setIsHovered(true),
      onMouseLeave: () => setIsHovered(false)
    });

  return (
    <>
      <Element {...props}>
        <span className="clicker__content">
          <span className="clicker__text">{children || text}</span>
          {iconName && (
            <span className="clicker__icon">
              <span className="clicker__icon-default">
                <Icon
                  name={iconName}
                  size={iconSize}
                  isPadded={
                    iconIsPadded !== undefined ? iconIsPadded : textIsHidden
                  }
                />
              </span>
              {getIconHoverVersion() && (
                <span className="clicker__icon-hover">
                  <Icon
                    name={getIconHoverVersion()}
                    size={iconSize}
                    isPadded={
                      iconIsPadded !== undefined ? iconIsPadded : textIsHidden
                    }
                  />
                </span>
              )}
              {isActive && (
                <span className="clicker__icon-active">
                  <Icon
                    name={activeIconName ? activeIconName : iconName}
                    size={iconSize}
                    isPadded={
                      iconIsPadded !== undefined ? iconIsPadded : textIsHidden
                    }
                  />
                </span>
              )}
            </span>
          )}
        </span>
      </Element>
    </>
  );
};

Clicker.link = {
  text: PropTypes.string,
  href: PropTypes.string
};
Clicker.propTypes = {
  children: PropTypes.node,
  tag: PropTypes.oneOf(Object.values(elements)),
  href: PropTypes.string,
  text: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  iconName: PropTypes.string,
  iconSize: PropTypes.string,
  iconDirection: PropTypes.string,
  textIsHidden: PropTypes.bool,
  iconIsBeforeText: PropTypes.bool,
  isDisabled: PropTypes.bool,
  iconIsPadded: PropTypes.bool,
  className: PropTypes.string,
  hasGhostIcon: PropTypes.bool,
  theme: PropTypes.oneOf(Object.values(themes)),
  activeIconName: PropTypes.string,
  isActive: PropTypes.bool
};

Clicker.defaultProps = {
  tag: elements.button,
  theme: themes.default
};

Clicker.iconSizes = Icon.sizes;
Clicker.iconNames = Icon.names;

Clicker.themes = themes;

Clicker.elements = elements;

export default Clicker;
