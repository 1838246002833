import InputErrorMessage from 'components/input-error-message';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import KartleggingExpressionItem from './kartlegging-expression-item';
import Clicker from 'components/clicker/clicker';
import RedaktorGrensesnittContext from '../../../../contexts/redaktor-grensesnitt-context';

export default function KartleggingExpressionDisplayData({
  expressions,
  saveEndpoint,
  apiData,
  fixedStructure,
  uniqueNodeId,
  type,
  buttonText,
  labelText,
  placeholderText,
  readOnly,
  title,
  subtitle
}) {
  const {
    state: {
      currentDataType,
      apiErrMessage,
      isLoading,
      isDirty,
      recentlySaved,
      redaktorDataList: expressionList
    },
    functions: {
      setCurrentData,
      reorder,
      saveExpressionChanges,
      deleteItem,
      addExpressionItem,
      handleExpressionChange,
      undoAll,
      handleExpressionUndo
    }
  } = useContext(RedaktorGrensesnittContext);

  const sortAlphabetically = (a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) {
      return -1;
    }
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    setCurrentData({
      apiData: {
        versionId: apiData?.versionId,
        sprakKode: apiData?.sprakKode,
        ferdighetsomradeId: apiData?.ferdighetsomradeId
      },
      saveEndpoint,
      initialDataList: expressions
        .map(expression => ({
          ...expression,
          matchItemList: expression.matchItemList.sort((a, b) =>
            sortAlphabetically(a?.match, b?.match)
          )
        }))
        .sort((a, b) =>
          sortAlphabetically(
            a?.matchItemList[0].match,
            b?.matchItemList[0].match
          )
        ),
      type: type
    });
    return () => {
      setCurrentData({
        apiData: {},
        saveEndpoint: '',
        initialDataList: [],
        type: ''
      });
    };
  }, []);

  return (
    <>
      {!readOnly && (
        <div
          key={title + ' ' + subtitle}
          className={
            'redaktor-grensesnitt__data-content-actions sticky-actions'
          }
        >
          <div>
            <Clicker
              theme={Clicker.themes.secondary}
              onClick={undoAll}
              isDisabled={!isDirty}
              text={buttonText.global.cancel}
            />
            <Clicker
              theme={Clicker.themes.secondary}
              disabled={fixedStructure}
              onClick={addExpressionItem}
              text={buttonText.global.add}
            />
          </div>
          <div>
            <span className={'redaktor-grensesnitt__save-status'}>
              {isLoading ? 'Lagrer ... ' : isDirty ? 'Ulagrede endringer' : ''}
            </span>
            <Clicker
              theme={Clicker.themes.primary}
              onClick={saveExpressionChanges}
              text={buttonText.global.save}
              isDisabled={!isDirty || isLoading}
            />
          </div>
        </div>
      )}

      {apiErrMessage?.globalMessage && (
        <InputErrorMessage errors={[apiErrMessage?.globalMessage]} />
      )}

      <ul className="redaktor-grensesnitt__data-content-group">
        {currentDataType === type &&
          expressionList.map((expression, index) => (
            <div key={expression.id + uniqueNodeId + type}>
              <KartleggingExpressionItem
                readOnly={readOnly}
                buttonText={buttonText.item}
                labelText={labelText}
                placeholderText={placeholderText}
                fixedStructure={fixedStructure}
                reorder={reorder}
                recentlySaved={recentlySaved}
                handleExpressionUndo={handleExpressionUndo}
                handleChange={handleExpressionChange}
                onDeleteCallback={deleteItem}
                listLength={expressionList?.length || 0}
                expression={expression}
                errorMessage={
                  apiErrMessage?.specificMessage?.id === expression.id
                    ? apiErrMessage?.specificMessage?.message
                    : ''
                }
                parentIndex={index} // TODO: replace
              />
            </div>
          ))}
      </ul>
    </>
  );
}

KartleggingExpressionDisplayData.propTypes = {
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  placeholderText: PropTypes.exact({
    expression: PropTypes.string,
    description: PropTypes.string
  }),
  labelText: PropTypes.exact({
    expression: PropTypes.string,
    description: PropTypes.string
  }),
  buttonText: PropTypes.exact({
    global: PropTypes.exact({
      save: PropTypes.string.isRequired,
      cancel: PropTypes.string.isRequired,
      add: PropTypes.string.isRequired
    }).isRequired,
    item: PropTypes.exact({
      edit: PropTypes.string.isRequired,
      cancelEdit: PropTypes.string.isRequired,
      cancel: PropTypes.string.isRequired,
      delete: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  uniqueNodeId: PropTypes.string, // ignore backend
  type: PropTypes.string,
  fixedStructure: PropTypes.bool,
  apiData: PropTypes.exact({
    versionId: PropTypes.string,
    sprakKode: PropTypes.string,
    ferdighetsomradeId: PropTypes.string
  }),
  saveEndpoint: PropTypes.string,
  expressions: PropTypes.arrayOf(
    KartleggingExpressionItem.expressionObjectProps
  )
};

KartleggingExpressionDisplayData.defaultProps = {
  labelText: {
    expression: 'Ord/Uttrykk/Begrep',
    description: 'Forklaring'
  },
  placeholderText: {
    expression: 'Ord/Uttrykk/Begrep',
    description: 'Skriv en beskrivelse til ordene i listen'
  },
  buttonText: {
    global: {
      add: 'Legg til',
      save: 'Lagre',
      cancel: 'Tilbakestill/Avbryt'
    },
    item: {
      cancelEdit: 'Avslutt redigering',
      edit: 'Rediger',
      cancel: 'Tilbakestill',
      delete: 'Slett'
    }
  }
};
