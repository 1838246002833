import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Flipper } from 'react-flip-toolkit';
import { useWindowSize } from '../../hooks/useWindowSize';

import GridCard from 'components/grid-card/grid-card';
import ElementPropShape from 'components/grid-table-wrapper/element-prop-shape';

const swapElements = (arr, targetIndex) => {
  if (arr.length < 2) return;
  [arr[targetIndex], arr[targetIndex - 1]] = [
    arr[targetIndex - 1],
    arr[targetIndex]
  ];
  return arr;
};

const BaseGrid = ({
  elements,
  columnHeaders,
  showButtons,
  filteredListEmptyText
}) => {
  const { width } = useWindowSize();

  const [activeCardId, setActiveCard] = useState(null);
  const [currentElements, setCurrentElements] = useState([...elements]);

  const handleOnClick = cardId => {
    const newElements = [...elements];
    const indexOfTargetCard = newElements.findIndex(
      resource => resource.id === cardId
    );
    if (
      (indexOfTargetCard + 1) % (width > 1024 ? 3 : 2) === 0 &&
      cardId !== activeCardId
    ) {
      setCurrentElements(swapElements(newElements, indexOfTargetCard));
    } else {
      setCurrentElements(newElements);
    }

    setActiveCard(prev => (prev === cardId ? null : cardId));
  };

  useEffect(() => {
    setCurrentElements([...elements]);
    setActiveCard(null);
  }, [elements]);

  if (typeof width !== 'number') return null;

  return (
    <div className="base-grid">
      {currentElements && !!currentElements.length ? (
        <Flipper flipKey={activeCardId}>
          <ul className="base-grid__cards-wrapper">
            {currentElements.map((gridCardData, index) => {
              return (
                <GridCard
                  key={gridCardData.id}
                  isActive={activeCardId === gridCardData.id}
                  handleOnClick={handleOnClick}
                  columnHeaders={columnHeaders}
                  zIndexPosition={currentElements.length - index}
                  showButtons={showButtons}
                  {...gridCardData}
                />
              );
            })}
          </ul>
        </Flipper>
      ) : (
        <div className="base-grid__filtered-list-empty-text-wrapper">
          <p className="base-grid__filtered-list-empty-text">
            {filteredListEmptyText}
          </p>
        </div>
      )}
    </div>
  );
};

BaseGrid.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.exact(ElementPropShape.propTypes)),
  columnHeaders: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      text: PropTypes.string
    })
  ),
  showButtons: PropTypes.bool,
  filteredListEmptyText: PropTypes.string
};

BaseGrid.defaultProps = {};

export default BaseGrid;
