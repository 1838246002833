import CheckboxInput from 'components/checkbox-input';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import Tabs from '../tabs';
import StatistikkDataDisplay from './statistikk-data-display';
import StatistikkNode from './statistikk-node';
import cn from 'classnames';
import useClickOutside from '../../hooks/use-click-outside';

const StatistikkAdmin = ({ title, filter, tabs, nodes, statusIndicator }) => {
  const [currentData, setCurrentData] = useState(null);
  const [detailedStatusFilter, setDetailedStatusFilter] = useState(false);
  const handleFilterChange = value => {
    setDetailedStatusFilter(value);
  };
  const listRef = useRef();
  const clickedOutside = useClickOutside(listRef);
  const newNodes = React.useMemo(() => {
    function isStatusOk(node) {
      if (node.isExcluded) return true;
      const noChildNodes = !node?.nodes?.length;
      const nodeStatus = detailedStatusFilter
        ? Boolean(node.detaljertStatusOk && node.overordnetStatusOk)
        : Boolean(node.overordnetStatusOk);

      return noChildNodes
        ? nodeStatus
        : !nodeStatus
        ? nodeStatus
        : node.nodes.every(childNode => isStatusOk(childNode));
    }

    function searchTree(nodes) {
      if (!statusIndicator) return nodes;
      return nodes.map(node => {
        return {
          ...node,
          statusOk: isStatusOk(node, detailedStatusFilter),
          nodes: node.nodes ? searchTree(node.nodes) : undefined
        };
      });
    }

    return searchTree(nodes);
  }, [nodes, detailedStatusFilter]);

  return (
    <>
      <div className="statistikk-admin">
        <div className="statistikk-admin__content">
          {title && <h1 className="statistikk-admin__title">{title}</h1>}
          <div className="statistikk-admin__tabs">
            <Tabs {...tabs} />
          </div>
        </div>

        {filter && (
          <fieldset className={'statistikk-admin__filter-wrapper'}>
            <legend className={'statistikk-admin__filter-header'}>
              {filter.title}
            </legend>
            <CheckboxInput
              id={'detailedStatusFilter'}
              value={detailedStatusFilter}
              onChange={handleFilterChange}
              label={filter.detailedFilterLabel}
            />
          </fieldset>
        )}
        <div className="statistikk-admin__wrapper">
          <div
            ref={listRef}
            className={cn('statistikk-admin__list-wrapper', {
              'statistikk-admin__list-wrapper--active-mobile': !clickedOutside
            })}
          >
            {newNodes.map((node, i) => (
              <StatistikkNode
                key={i + node.title}
                outer
                breadcrumbText={node.title}
                isFirstInList={i === 0}
                singleTopNode={newNodes?.length === 1}
                statusIndicator={statusIndicator}
                currentData={currentData}
                setCurrentData={setCurrentData}
                {...node}
              />
            ))}
          </div>
          {currentData && (
            <div className="statistikk-admin__data-wrapper">
              <div className="statistikk-admin__sticky-data">
                {currentData?.title && (
                  <h2 className="statistikk-admin__data-header">
                    {currentData?.title}
                  </h2>
                )}
                <p className={'statistikk-admin__breadcrumb-print'}>
                  {currentData?.breadcrumbText}
                </p>
                {currentData?.subTitle && (
                  <p style={{ marginTop: 10 }}>{currentData?.subTitle}</p>
                )}
                <div className="statistikk-admin__data-content">
                  {currentData?.data.map((dataItem, i) => {
                    if (!Array.isArray(dataItem)) return null;
                    const removeNullArray = dataItem.filter(
                      d => d.text || d.label
                    );
                    if (
                      Array.isArray(removeNullArray) &&
                      removeNullArray.length
                    ) {
                      return (
                        <StatistikkDataDisplay
                          dataList={removeNullArray}
                          parentIndex={i}
                        />
                      );
                    } else return null;
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

StatistikkAdmin.propTypes = {
  title: PropTypes.string,
  filter: PropTypes.exact({
    title: PropTypes.string,
    detailedFilterLabel: PropTypes.string
  }),
  statusIndicator: PropTypes.bool,
  filterTitle: PropTypes.string,
  tabs: PropTypes.exact(Tabs.propTypes),
  nodes: PropTypes.arrayOf(PropTypes.shape(StatistikkNode.propTypes))
};

StatistikkAdmin.defaultProps = {
  showRemoveAction: true
};

export default StatistikkAdmin;
