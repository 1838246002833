import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfoHeader from 'components/info-header/info-header';
import NavigationLinkList from 'components/navigation-link-list/navigation-link-list';
import ContentHeader from 'components/content-header/content-header';
import NoticeBar from 'components/notice-bar';

import Clicker from '../clicker';
import cn from 'classnames';
import Icon from '../icon';

const MainLayout = ({
  children,
  noticeBarList,
  infoHeader,
  backLink,
  navigationButtonText,
  navigationLinkList,
  contentHeader,
  thinLayout
}) => {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  return (
    <div
      id="main-content"
      className={cn('main-layout', { 'main-layout--thin-layout': thinLayout })}
    >
      {noticeBarList?.map(noticeBar => (
        <NoticeBar {...noticeBar} />
      ))}
      <main className="main-layout__content">
        <div className="main-layout__left">
          <InfoHeader {...infoHeader} />
          <div
            className={cn('main-layout__navigation', {
              'main-layout__navigation--open': isNavigationOpen
            })}
          >
            <button
              id="navigation-button"
              className="main-layout__navigation-button"
              onClick={() => setIsNavigationOpen(!isNavigationOpen)}
              aria-expanded={isNavigationOpen}
              aria-controls="navigation-list"
            >
              {navigationButtonText}
              <Icon
                className="main-layout__navigation-icon"
                name={Icon.names.hamburger}
                size={Icon.sizes.medium}
              />
              <Icon
                className="main-layout__navigation-icon main-layout__navigation-icon--opened"
                name={Icon.names.closeThin}
                size={Icon.sizes.medium}
              />
            </button>
            <nav
              id="navigation-list"
              className="main-layout__navigation-list"
              aria-labelledby="navigation-button"
            >
              <div className="main-layout__back-link-wrapper">
                {backLink && (
                  <Clicker
                    {...backLink}
                    className="main-layout__back-link"
                    theme={Clicker.themes.navigation}
                    iconName={Clicker.iconNames.arrowLeft}
                    iconSize={Clicker.iconSizes.medium}
                    iconIsBeforeText={true}
                  />
                )}
              </div>
              <NavigationLinkList {...navigationLinkList} />
            </nav>
          </div>
        </div>
        <div className="main-layout__right">
          <ContentHeader {...contentHeader} />
          {children}
        </div>
      </main>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node, //excluded from backend
  noticeBarList: PropTypes.arrayOf(PropTypes.exact(NoticeBar.propTypes)),
  infoHeader: PropTypes.exact(InfoHeader.propTypes),
  backLink: PropTypes.exact(Clicker.propTypes),
  navigationButtonText: PropTypes.string,
  navigationLinkList: PropTypes.exact(NavigationLinkList.propTypes),
  contentHeader: PropTypes.exact(ContentHeader.propTypes),
  thinLayout: PropTypes.bool
};

export default MainLayout;
