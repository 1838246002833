import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../layouts/admin-layout';
import Admin from '../admin/admin';

const AdminPage = ({ layout, admin }) => {
  return (
    <Layout {...layout}>
      <Admin {...admin} />
    </Layout>
  );
};

AdminPage.propTypes = {
  layout: PropTypes.exact(Layout.propTypes),
  admin: PropTypes.exact(Admin.propTypes)
};

export default AdminPage;
