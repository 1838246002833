import React, { useContext } from 'react';

const RedaktorGrensesnittContext = React.createContext();
export function useRedaktorGrensesnittContext() {
  return useContext(RedaktorGrensesnittContext);
}

RedaktorGrensesnittContext.propTypes = {};

export default RedaktorGrensesnittContext;
