import PropTypes from 'prop-types';
import React, { useState } from 'react';
import RedaktorIntroEdit from './redaktor-intro-edit';
import RedaktorIntroImport from './redaktor-intro-import';
import RedaktorIntroReadOnly from './redaktor-intro-read-only';

const RedaktorIntro = ({ title, readOnlyBox, editBox, importBox }) => {
  const [isOpen, setIsOpen] = useState('');
  return (
    <div className={'redaktor-intro'}>
      {title && <h1>{title}</h1>}
      <div className={'redaktor-intro__box-wrapper'}>
        {readOnlyBox && (
          <RedaktorIntroReadOnly
            isOpen={isOpen === 'readOnly'}
            setIsOpen={() => setIsOpen('readOnly')}
            {...readOnlyBox}
          />
        )}
        {editBox && (
          <RedaktorIntroEdit
            isOpen={isOpen === 'edit'}
            setIsOpen={() => setIsOpen('edit')}
            {...editBox}
          />
        )}
        {importBox && (
          <RedaktorIntroImport
            isOpen={isOpen === 'import'}
            setIsOpen={() => setIsOpen('import')}
            {...importBox}
          />
        )}
      </div>
    </div>
  );
};

RedaktorIntro.propTypes = {
  title: PropTypes.string,
  readOnlyBox: PropTypes.exact(RedaktorIntroReadOnly.propTypes),
  editBox: PropTypes.exact(RedaktorIntroEdit.propTypes),
  importBox: PropTypes.exact(RedaktorIntroImport.propTypes)
};

export default RedaktorIntro;
