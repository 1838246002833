import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const colors = {
  green: 'green',
  gray: 'gray'
};

const PdfFrontPage = ({
  isFullPage,
  heading,
  typeHeading,
  infoTitle,
  infoList,
  subInfoList,
  color
}) => {
  return (
    <div
      className={cn('pdf-front-page', {
        'pdf-front-page--is-full-page': isFullPage, 
        [`pdf-front-page--${colors[color]}`]: color
      })}
    >
      {isFullPage && (
        <>
          {heading && (
            <h1 className="pdf-front-page__heading-wrapper">
              <span className="pdf-front-page__heading">{heading}</span>
              <span className="pdf-front-page__subheading">{typeHeading}</span>
            </h1>
          )}
          {infoTitle && <h2 className="pdf-front-page__name">{infoTitle}</h2>}
        </>
      )}
      <div className="pdf-front-page__info-block">
        <dl className="pdf-front-page__info-list">
          {infoList?.map(({ infoTitle, info }, i) => (
            <div key={i}>
              <dt className="pdf-front-page__info-title">{infoTitle + ' '}</dt>
              <dd className="pdf-front-page__info">{info}</dd>
            </div>
          ))}
        </dl>
        <dl className="pdf-front-page__sub-info-list">
          {subInfoList?.map(({ infoTitle, info }, i) => (
            <div key={i}>
              <dt className="pdf-front-page__sub-info-title">
                {infoTitle + ' '}
              </dt>
              <dd className="pdf-front-page__sub-info">{info}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

PdfFrontPage.propTypes = {
  isFullPage: PropTypes.bool,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  typeHeading: PropTypes.string,
  infoTitle: PropTypes.string,
  infoList: PropTypes.arrayOf(
    PropTypes.exact({
      infoTitle: PropTypes.string,
      info: PropTypes.string
    })
  ),
  subInfoList: PropTypes.arrayOf(
    PropTypes.exact({
      infoTitle: PropTypes.string,
      info: PropTypes.string
    })
  ),
  color: PropTypes.oneOf(Object.keys(colors))
};

export default PdfFrontPage;
