import React from 'react';
import PropTypes from 'prop-types';

const ListOfParagraphs = ({ list, avsnitt }) => {
  return (
    <div className={'list-of-paragraphs'}>
      {avsnitt && <p className="list-of-paragraphs__paragraph">{avsnitt}</p>}
      {list && (
        <ul>
          {list?.map((arrayItem, index) => {
            return (
              <li key={index} className="list-of-paragraphs__list-item">
                {arrayItem}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

ListOfParagraphs.propTypes = {
  avsnitt: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string)
};

export default ListOfParagraphs;
