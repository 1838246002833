import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/modal';
import LoadingSpinner from 'components/loading-spinner';
import Clicker from 'components/clicker';
import TextInput from 'components/text-input';
import InputByType from 'components/input-by-type';
import SelectInput from 'components/select-input/select-input';
import useQuestionnaireFormControl from '../../hooks/use-questionnaire-form-control';
import HeaderContext from '../../contexts/header-context';

const EditAdminModal = ({
  isOpen,
  onClose,
  isDisabled,
  closeModalHiddenText,
  title,
  lead,
  inputs,
  buttonText,
  saveEndpoint
}) => {
  const { adminHeaderData } = useContext(HeaderContext);

  const { save, closeWithoutSaving } = buttonText.global;

  const [activeInputs, setActiveInputs] = useState(inputs);

  const {
    questions,
    isLoading,
    isDirty,
    handleOnInputChange,
    handleOnSubmit
  } = useQuestionnaireFormControl(activeInputs, saveEndpoint);

  const disableSave = React.useMemo(() => {
    return !isDirty || isLoading;
  }, [isDirty, isLoading]);

  const handleOnConfirm = async () => {
    try {
      const res = await handleOnSubmit();
      if (res.errors) {
        throw res;
      }
      window.location.reload();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const otherInputs = React.useMemo(
    () =>
      questions.filter(
        i =>
          i.type !== InputByType.types.date &&
          i.type !== InputByType.types.languageSelect
      ),
    [questions]
  );

  useEffect(() => {
    setActiveInputs(adminHeaderData?.editAdminModal?.inputs || inputs);
  }, [adminHeaderData]);

  return (
    <Modal
      themes={[Modal.themes.gray]}
      isOpen={isOpen}
      onClose={onClose}
      isDisabled={isDisabled}
      closeButtonLabel={closeModalHiddenText}
    >
      <div className="edit-admin-modal">
        <form className="edit-admin-modal__content">
          {title && <h2 className="edit-admin-modal__title">{title}</h2>}
          {lead && <p className="edit-admin-modal__lead">{lead}</p>}
          {questions && (
            <div className="edit-admin-modal__inputs">
              {otherInputs.map((input, i) => {
                return (
                  <div className="edit-admin-modal__input" key={i}>
                    <InputByType
                      textInputTheme={TextInput.themes.underlined}
                      key={input.id}
                      isDisabled={isDisabled}
                      onChange={handleOnInputChange}
                      {...input}
                    />
                  </div>
                );
              })}
            </div>
          )}
          <div className="edit-admin-modal__actions">
            <div className="edit-admin-modal__action">
              <Clicker
                isDisabled={isDisabled}
                onClick={onClose}
                text={closeWithoutSaving}
                theme={Clicker.themes.secondary}
              />
            </div>

            <div className="edit-admin-modal__actions-right">
              <div className="edit-admin-modal__action">
                <Clicker
                  isDisabled={disableSave}
                  onClick={() => handleOnConfirm(true)}
                  text={save}
                  theme={Clicker.themes.primary}
                />
              </div>
              {isLoading && (
                <div className="edit-admin-modal__loading">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

EditAdminModal.propTypes = {
  isDisabled: PropTypes.bool, //exclude from backend
  onClose: PropTypes.func, //exclude from backend
  isOpen: PropTypes.bool, //exclude from backend
  closeModalHiddenText: PropTypes.string,
  title: PropTypes.string,
  lead: PropTypes.string,
  saveEndpoint: PropTypes.string,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      ...TextInput.propTypes,
      ...SelectInput.propTypes,
      type: PropTypes.string,
      value: PropTypes.string,
      valueArray: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  buttonText: PropTypes.exact({
    global: PropTypes.exact({
      save: PropTypes.string,
      saveAndClose: PropTypes.string,
      closeWithoutSaving: PropTypes.string
    })
  })
};

EditAdminModal.defaultProps = {
  onConfirm: () => {},
  onClose: () => {},
  inputs: []
};

export default EditAdminModal;

EditAdminModal.defaultProps = {
  buttonText: {
    global: {
      save: 'Lagre',
      saveAndClose: 'Lagre og lukk',
      closeWithoutSaving: 'Lukk uten å lagre'
    }
  }
};
