import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import useClickOutside from '../../hooks/use-click-outside';
import Clicker from '../clicker';

import Icon from '../icon';

import usePhrases from '../../hooks/use-phrases';

const MenuButton = ({ items, isDisabled }) => {
  const { menu } = usePhrases();

  const [isOpen, setIsOpen] = useState(false);

  const menuRef = useRef();
  useClickOutside(menuRef, () => setIsOpen(false));

  return (
    <span
      ref={menuRef}
      className={cn('menu-button', {
        'menu-button--active': isOpen,
        'menu-button--disabled': isDisabled
      })}
    >
      <Clicker
        onClick={() => setIsOpen(true)}
        text={menu}
        disabled={isDisabled}
        aria-expanded={isOpen}
        textIsHidden={true}
        iconName={isOpen ? Icon.names.moreCircle : Icon.names.more}
        iconSize={Clicker.iconSizes.medium}
      />

      {isOpen && (
        <span className="menu-button__menu">
          <menu className="menu-button__list">
            {items.map((item, index) => {
              const isLink = !!item?.href;

              return item.text ? (
                <li key={index} className="menu-button__item">
                  <Clicker
                    {...item}
                    theme={
                      isLink
                        ? Clicker.themes.underlined
                        : Clicker.themes.default
                    }
                    onClick={
                      isLink
                        ? undefined
                        : () => {
                            setIsOpen(false);
                            item.onClick();
                          }
                    }
                  />
                </li>
              ) : undefined;
            })}
          </menu>
        </span>
      )}
    </span>
  );
};

MenuButton.propTypes = {
  isDisabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.exact(Clicker.propTypes)).isRequired
};

MenuButton.defaultProps = {
  items: []
};

export default MenuButton;
