import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/icon';

const themes = {
  default: 'default',
  underlined: 'underlined'
};

const SelectInput = ({
  theme,
  label,
  id,
  inputKey,
  placeholder,
  defaultValueLabel,
  onChange,
  onFocus,
  isDisabled,
  options,
  value,
  ariaLabelledby
}) => {
  return (
    <div
      className={cn('select-input', {
        [`select-input--${themes[theme]}`]: themes[theme]
      })}
    >
      <div className={'select-input__wrapper'}>
        <select
          aria-labelledby={ariaLabelledby}
          key={inputKey}
          id={id}
          disabled={isDisabled}
          className={cn('select-input__select', {
            'select-input__select--has-value': value?.length,
            'select-input__select--no-label': !label?.length
          })}
          value={value}
          onChange={e => onChange(e.target.value, e.target.id)}
          onFocus={onFocus}
        >
          <option
            key={placeholder}
            value={''}
            disabled={Boolean(value)}
            // hidden={theme === themes.underlined}
          >
            {theme === themes.underlined
              ? ''
              : defaultValueLabel || placeholder}
          </option>
          {options?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
        {label && (
          <label htmlFor={id} className="select-input__label">
            {label}
          </label>
        )}
        <Icon
          size={Icon.sizes.medium}
          name={Icon.names.arrowDown}
          className="select-input__arrow"
        />
      </div>
    </div>
  );
};

SelectInput.propTypes = {
  theme: PropTypes.oneOf(Object.values(themes)), //exclude from backend
  onChange: PropTypes.func, //exclude from backend
  onFocus: PropTypes.func, //exclude from backend
  isDisabled: PropTypes.bool,
  inputKey: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValueLabel: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string
    })
  ),
  id: PropTypes.string.isRequired,
  ariaLabelledby: PropTypes.string //exclude from backend
};

SelectInput.defaultProps = {
  onBlur: () => {},
  theme: themes.default
};

SelectInput.themes = themes;

export default SelectInput;
