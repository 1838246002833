import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import api from 'js/api-helper';

import Clicker from 'components/clicker/clicker';
import Icon from 'components/icon/icon';
import LoadingSpinner from 'components/loading-spinner/loading-spinner';

const themes = {
  bordered: 'bordered'
};

const SearchWithDropdown = ({
  onChange,
  searchFieldLabel,
  searchEndpoint,
  resetSearchLabel,
  RenderedResultElement,
  theme,
  elevId
}) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [responseMessage, setResponseMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const searchFieldRef = useRef(null);

  const handleInputChange = e => {
    const newValue = e.target.value;
    setSearchValue(newValue.replace(/\s\s+/g, ' '));
    setResponseMessage(undefined);
  };

  const resetSearch = () => {
    setSearchValue('');
    setSearchResults([]);
    setResponseMessage(undefined);
  };

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      let { items, message } = await api.execute(searchEndpoint, {
        value: searchValue,
        elevId: elevId
      });

      if (items) setSearchResults(items);
      else if (message) setResponseMessage(message);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleItemClick = item => {
    onChange(item);
    resetSearch();
  };

  const handleSearchFieldKeyDown = e => {
    if (e.key === 'Escape') {
      resetSearch();
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if ([...searchValue].length >= 2) {
        handleSearch();
      }
    }, 300);
    if (searchValue?.length < 2) {
      setSearchResults([]);
      setResponseMessage(undefined);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return (
    <div
      className={cn('search-with-dropdown', {
        [`search-with-dropdown--${themes[theme]}`]: themes[theme]
      })}
    >
      <div className="search-with-dropdown__inputs">
        <div
          className={cn('search-with-dropdown__input-wrapper', {
            'search-with-dropdown__input-wrapper--active': !!searchValue?.length
          })}
        >
          <label className="search-with-dropdown__label">
            <div className="search-with-dropdown__label-text">
              {searchFieldLabel}
            </div>
            <input
              className="search-with-dropdown__search-field"
              ref={searchFieldRef}
              onChange={e => handleInputChange(e)}
              value={searchValue}
              onKeyDown={handleSearchFieldKeyDown}
            />
            <div className="search-with-dropdown__icon-wrapper">
              {isLoading ? (
                <LoadingSpinner />
              ) : !searchValue?.length ? (
                <Icon name={Icon.names.search} />
              ) : (
                <Clicker
                  aria-label={resetSearchLabel}
                  iconName={Icon.names.close}
                  onClick={resetSearch}
                />
              )}
            </div>
          </label>
          <div role="alert">
            {responseMessage && searchValue?.length > 1 && (
              <div className="search-with-dropdown__response-message">
                {responseMessage}
              </div>
            )}
          </div>
          {!!searchResults?.length && searchValue?.length > 1 && (
            <ul className="search-with-dropdown__results">
              {searchResults.map(result => (
                <li
                  key={result.id}
                  className="search-with-dropdown__result-item"
                >
                  <button
                    className="search-with-dropdown__result-button"
                    type="button"
                    onClick={() => handleItemClick(result)}
                    disabled={result?.isDisabled}
                  >
                    <RenderedResultElement {...result} />
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

SearchWithDropdown.propTypes = {
  onChange: PropTypes.func, // exclude from BE
  searchFieldLabel: PropTypes.string,
  searchEndpoint: PropTypes.string,
  resetSearchLabel: PropTypes.string,
  RenderedResultElement: PropTypes.node
};

SearchWithDropdown.themes = themes;

export default SearchWithDropdown;
