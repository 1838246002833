import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';
import Clicker from '../clicker';
import HistoryModal from '../modal/history-modal';
import ConfirmationModal from 'components/modal/confirmation-modal';
import SanityContentModal from 'components/modal/sanity-content-modal/sanity-content-modal';
import LoadingSpinner from 'components/loading-spinner/loading-spinner';
import DropdownBubble from 'components/dropdown-bubble/dropdown-bubble';

import useClickOutside from '../../hooks/use-click-outside';
import apiHelper from 'js/api-helper';
import DownloadKartleggingReport from './download-kartlegging-report';
import DownloadSamtale from './download-samtale';

const ActionBar = ({
  downloadKartleggingReportMenu,
  downloadFerdighetsomradeHref,
  getDownloadSamtaleMenuEndpoint,
  downloadPdfText,
  guidanceText,
  guidanceModal,
  infoText,
  infoModal,
  printButtonText,
  moreOptionsText,
  historyButtonText,
  historyModal,
  archiveButtonText,
  archiveConfirmationModal,
  archiveConfirmEndpoint,
  archiveLink,
  deleteButtonText,
  deleteConfirmationModal,
  deleteConfirmEndpoint,
  closeDropdownButtonLabel
}) => {
  const [isGuidanceModalOpen, setIsGuidanceModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isDownloadReportOpen, setIsDownloadReportOpen] = useState(false);
  const [downloadSamtaleMenu, setDownloadSamtaleMenu] = useState();
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen
  ] = useState(false);

  const bubbleRef = useRef();
  const downloadBubbleRef = useRef();
  useClickOutside(bubbleRef, () => setIsMoreOptionsOpen(false));
  useClickOutside(downloadBubbleRef, () => setIsDownloadReportOpen(false));

  const handleArchiveConfirm = () => {
    apiHelper
      .get(archiveConfirmEndpoint)
      .then(({ redirectUrl }) => (window.location.href = redirectUrl));
  };

  const handleDeleteConfirm = async () => {
    try {
      await apiHelper
        .get(deleteConfirmEndpoint)
        .then(({ redirectUrl }) => (window.location.href = redirectUrl));
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteClick = () => {
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleOpenDownloadSamtale = () => {
    if (getDownloadSamtaleMenuEndpoint && !isDownloadReportOpen) {
      apiHelper
        .get(getDownloadSamtaleMenuEndpoint)
        .then(downloadSamtaleMenu =>
          setDownloadSamtaleMenu(downloadSamtaleMenu)
        );
    }
    setIsDownloadReportOpen(!isDownloadReportOpen);
  };

  return (
    <>
      <div className="action-bar">
        <div className="action-bar__actions">
          {guidanceText && (
            <div className="action-bar__action">
              <Clicker
                className="action-bar__button"
                text={guidanceText}
                onClick={() => setIsGuidanceModalOpen(true)}
                iconIsBeforeText={true}
                iconName={Icon.names.infoCircle}
                iconSize={Clicker.iconSizes.medium}
                theme={Clicker.themes.hoverBlueNoUnderline}
              />
            </div>
          )}
          {infoText && (
            <div className="action-bar__action">
              <Clicker
                className="action-bar__button"
                text={infoText}
                onClick={() => setIsInfoModalOpen(true)}
                iconIsBeforeText={true}
                iconName={Icon.names.infoCircle}
                iconSize={Clicker.iconSizes.medium}
                theme={Clicker.themes.hoverBlueNoUnderline}
              />
            </div>
          )}
          {downloadKartleggingReportMenu && (
            <div
              ref={downloadBubbleRef}
              className="action-bar__action action-bar__dropdown"
              onKeyDown={e =>
                e.key === 'Escape' && setIsDownloadReportOpen(false)
              }
            >
              <Clicker
                className="action-bar__button action-bar__button-with-spinner"
                text={downloadPdfText}
                onClick={() => setIsDownloadReportOpen(!isDownloadReportOpen)}
                iconIsBeforeText={true}
                iconName={Icon.names.download}
                iconSize={Clicker.iconSizes.medium}
                theme={Clicker.themes.hoverBlueNoUnderline}
              />
              {isDownloadingReport && <LoadingSpinner />}
              <DropdownBubble isOpen={isDownloadReportOpen} rightMargin="">
                <div className="action-bar__dropdown-content">
                  <DownloadKartleggingReport
                    {...downloadKartleggingReportMenu}
                    onSubmit={() => {
                      setIsDownloadingReport(true);
                      setIsDownloadReportOpen(false);
                    }}
                    onFinish={() => setIsDownloadingReport(false)}
                  />
                </div>
              </DropdownBubble>
            </div>
          )}
          {downloadFerdighetsomradeHref && (
            <div className="action-bar__action">
              <Clicker
                className="action-bar__button"
                text={downloadPdfText}
                href={downloadFerdighetsomradeHref}
                iconIsBeforeText={true}
                iconName={Icon.names.download}
                iconSize={Clicker.iconSizes.medium}
                theme={Clicker.themes.hoverBlueNoUnderline}
              />
            </div>
          )}
          {getDownloadSamtaleMenuEndpoint && (
            <div
              ref={downloadBubbleRef}
              className="action-bar__action action-bar__dropdown"
              onKeyDown={e =>
                e.key === 'Escape' && setIsDownloadReportOpen(false)
              }
            >
              <Clicker
                className="action-bar__button action-bar__button-with-spinner"
                text={downloadPdfText}
                onClick={handleOpenDownloadSamtale}
                iconIsBeforeText={true}
                iconName={Icon.names.download}
                iconSize={Clicker.iconSizes.medium}
                theme={Clicker.themes.hoverBlueNoUnderline}
                aria-expanded={isDownloadingReport}
              />
              {isDownloadingReport && <LoadingSpinner />}
              <DropdownBubble isOpen={isDownloadReportOpen} rightMargin="">
                <div className="action-bar__dropdown-content">
                  <DownloadSamtale
                    {...downloadSamtaleMenu}
                    onSubmit={() => {
                      setIsDownloadingReport(true);
                      setIsDownloadReportOpen(false);
                    }}
                    onFinish={() => setIsDownloadingReport(false)}
                  />
                </div>
              </DropdownBubble>
            </div>
          )}
          {printButtonText && (
            <div className="action-bar__action">
              <Clicker
                className="action-bar__button"
                text={printButtonText}
                onClick={() => window.print()}
                iconIsBeforeText={true}
                iconName={Icon.names.print}
                iconSize={Clicker.iconSizes.medium}
                theme={Clicker.themes.hoverBlueNoUnderline}
              />
            </div>
          )}
          {moreOptionsText && (
            <div
              ref={bubbleRef}
              className="action-bar__action action-bar__dropdown"
              onKeyDown={e => e.key === 'Escape' && setIsMoreOptionsOpen(false)}
            >
              <Clicker
                className="action-bar__button"
                aria-expanded={isMoreOptionsOpen}
                isActive={isMoreOptionsOpen}
                text={moreOptionsText}
                onClick={() => setIsMoreOptionsOpen(!isMoreOptionsOpen)}
                iconIsBeforeText={true}
                iconName={Icon.names.more}
                iconSize={Clicker.iconSizes.medium}
                activeIconName={Icon.names.moreBlue}
                theme={Clicker.themes.hoverBlueNoUnderline}
              />
              <DropdownBubble isOpen={isMoreOptionsOpen} rightMargin="">
                <div className="action-bar__dropdown-content">
                  <Clicker
                    text={closeDropdownButtonLabel}
                    textIsHidden={true}
                    iconName={Clicker.iconNames.closeCircleBlack}
                    onClick={() => setIsMoreOptionsOpen(false)}
                    className="action-bar__dropdown-close-button"
                  />
                  {historyButtonText && (
                    <Clicker
                      className="action-bar__dropdown-button"
                      text={historyButtonText}
                      onClick={() => setIsHistoryOpen(true)}
                      iconIsBeforeText={true}
                      iconName={Icon.names.historyCircle}
                      iconSize={Clicker.iconSizes.medium}
                    />
                  )}
                  {archiveButtonText && (
                    <Clicker
                      className="action-bar__dropdown-button"
                      onClick={() => setIsConfirmationOpen(true)}
                      text={archiveButtonText}
                      iconIsBeforeText={true}
                      iconName={Icon.names.sendToArchive}
                      iconSize={Clicker.iconSizes.medium}
                    />
                  )}
                  {archiveLink && (
                    <Clicker
                      className="action-bar__dropdown-button"
                      {...archiveLink}
                      iconIsBeforeText={true}
                      iconName={Icon.names.openArchive}
                      iconSize={Clicker.iconSizes.medium}
                    />
                  )}
                  {deleteButtonText && (
                    <div className="action-bar__action">
                      <Clicker
                        className="action-bar__button"
                        text={deleteButtonText}
                        onClick={handleDeleteClick}
                        iconIsBeforeText={true}
                        iconName={Icon.names.delete}
                        iconSize={Clicker.iconSizes.medium}
                        theme={Clicker.themes.hoverBlueNoUnderline}
                      />
                    </div>
                  )}
                </div>
              </DropdownBubble>
            </div>
          )}
        </div>
      </div>
      {guidanceModal && (
        <SanityContentModal
          {...guidanceModal}
          isOpen={isGuidanceModalOpen}
          onClose={() => setIsGuidanceModalOpen(false)}
        />
      )}
      {infoModal && (
        <SanityContentModal
          {...infoModal}
          isOpen={isInfoModalOpen}
          onClose={() => setIsInfoModalOpen(false)}
        />
      )}
      {historyModal && (
        <HistoryModal
          {...historyModal}
          isOpen={isHistoryOpen}
          onClose={() => setIsHistoryOpen(false)}
        />
      )}
      {archiveConfirmationModal && (
        <ConfirmationModal
          {...archiveConfirmationModal}
          isOpen={isConfirmationOpen}
          onClose={() => setIsConfirmationOpen(false)}
          onConfirm={handleArchiveConfirm}
        />
      )}
      {deleteConfirmationModal && (
        <ConfirmationModal
          {...deleteConfirmationModal}
          isOpen={isDeleteConfirmationModalOpen}
          onClose={() => setIsDeleteConfirmationModalOpen(false)}
          onConfirm={handleDeleteConfirm}
        />
      )}
    </>
  );
};

ActionBar.propTypes = {
  downloadKartleggingReportMenu: PropTypes.exact(
    DownloadKartleggingReport.propTypes
  ),
  downloadFerdighetsomradeHref: PropTypes.string,
  getDownloadSamtaleMenuEndpoint: PropTypes.string,
  downloadPdfText: PropTypes.string,
  guidanceText: PropTypes.string,
  guidanceModal: PropTypes.exact(SanityContentModal.propTypes),
  infoModal: PropTypes.exact(SanityContentModal.propTypes),
  infoText: PropTypes.string,
  printButtonText: PropTypes.string,
  moreOptionsText: PropTypes.string,
  historyButtonText: PropTypes.string,
  historyModal: PropTypes.exact(HistoryModal.propTypes),
  archiveButtonText: PropTypes.string,
  archiveConfirmationModal: PropTypes.exact(ConfirmationModal.propTypes),
  archiveConfirmEndpoint: PropTypes.string,
  archiveLink: PropTypes.exact(Clicker.propTypes),
  closeDropdownButtonLabel: PropTypes.string,
  deleteButtonText: PropTypes.string,
  deleteConfirmationModal: PropTypes.exact(ConfirmationModal.propTypes),
  deleteConfirmEndpoint: PropTypes.string
};

ActionBar.defaultProps = {
  showHistoryButton: true
};

export default ActionBar;
