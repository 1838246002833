import React from 'react';
import PropTypes from 'prop-types';
import Clicker from 'components/clicker/clicker';

/**
 * This page can work as a static text display page, if you remove formPostAction from backend.
 *
 * If that happens, the lead text and the submit button is hidden.
 * @param {} param0
 */
const Personvern = ({ title, lead, postAction, htmlBody, paragraphs }) => {
  return (
    <div className="personvern">
      {title && <h1 className="personvern__title">{title}</h1>}
      {postAction && lead && <p className="personvern__lead">{lead}</p>}
      {htmlBody && (
        <div
          className={'personvern__paragarph-html'}
          dangerouslySetInnerHTML={{ __html: htmlBody }}
        />
      )}
      {!!paragraphs &&
        paragraphs.map((p, i) => {
          return (
            <p key={i} className="personvern__paragraph">
              {p}
            </p>
          );
        })}
      {postAction && (
        <form
          className="personvern__submit"
          method="post"
          action={postAction.href}
        >
          <Clicker text={postAction.text} theme={Clicker.themes.submit} />
        </form>
      )}
    </div>
  );
};

Personvern.propTypes = {
  title: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  postAction: PropTypes.exact({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
  }),
  paragraphs: PropTypes.arrayOf(PropTypes.string),
  htmlBody: PropTypes.string
};

export default Personvern;
