/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React, { useMemo, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import 'core-js/stable';

import { useTable, useRowSelect } from 'react-table';
import { useWindowSize } from '../../hooks/useWindowSize';
import useLocaleDate from '../../hooks/use-locale-date';

import ElementPropShape from 'components/grid-table-wrapper/element-prop-shape';
import Icon from '../icon';
import Clicker from '../clicker';
import TableMenuButton from './table-menu-button';
import Modal from 'components/modal/modal';
import InfoBubbleButton from 'components/info-bubble-button/info-bubble-button';
import Tooltip from 'components/tooltip/tooltip';
import DataOverflowHandler from 'components/data-overflow-handler/data-overflow-handler';

const columnName = index => {
  return `col${index}`;
};

const TableCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;
  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

const BaseTable = ({
  elements,
  headerColumnMobileIndex,
  specificHeaderColumnTabletIndex,
  columnHeaders,
  infoText,
  showMenus,
  showLinks,
  showCheckboxes,
  elementMenuDropdown,
  filteredListEmptyText
}) => {
  const { width } = useWindowSize();
  const [modalData, setModalData] = useState(null);

  const isMobile = useMemo(() => width < 800, [width]);
  const isTablet = useMemo(() => width < 1100, [width]);
  const headerColumnTabletIndex = useMemo(
    () =>
      specificHeaderColumnTabletIndex
        ? specificHeaderColumnTabletIndex
        : Math.floor(columnHeaders.length / 2),
    [specificHeaderColumnTabletIndex]
  );
  const closeModal = () => setModalData(null);

  const data = useMemo(() => {
    return elements.map(resource => {
      return {
        ...resource,
        columns: resource.columns.reduce((acc, column, index) => {
          acc[columnName(index + 1)] = column;

          return acc;
        }, {})
      };
    }, []);
  }, [elements, isMobile, isTablet]);

  const { columns } = useMemo(() => {
    const columns = columnHeaders.reduce((acc, column, index) => {
      const accessor = columnName(index + 1);

      const col = acc.concat({
        Header: column.text,
        accessor,
        isVisible: isTablet
          ? accessor === columnName(headerColumnMobileIndex + 1)
            ? true
            : false
          : true
      });
      return col;
    }, []);
    return { columns };
  }, [elements, isMobile, isTablet]);

  const table = useTable(
    {
      columns,
      data,
      initialState: {}
    },
    useRowSelect,
    hooks => {
      return hooks.visibleColumns.push(columns => {
        const mobileColumns = isMobile
          ? [columns[headerColumnMobileIndex]]
          : isTablet
          ? columns.slice(0, headerColumnTabletIndex + 1)
          : columns;

        let conditionalColumns = [];

        if (showCheckboxes)
          conditionalColumns.push({
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => {
              return <TableCheckbox {...getToggleAllRowsSelectedProps()} />;
            },
            Cell: ({ row }) => {
              const { onChange, ...rest } = row.getToggleRowSelectedProps();

              return <TableCheckbox {...rest} onChange={onChange} />;
            }
          });

        conditionalColumns.push(
          ...mobileColumns.map(column => {
            return {
              ...column,
              Cell: ({ row }) => {
                const { original } = row;

                if (!original.columns[column.id]) return <></>;

                if (original.columns[column.id].href)
                  return (
                    <Clicker
                      className={'base-table__clicker-text'}
                      theme={Clicker.themes.underlined}
                      href={original.columns[column.id].href}
                      text={original.columns[column.id].text}
                    />
                  );

                const isDate = !isNaN(
                  Date.parse(original.columns[column.id].text)
                );
                let parsedPotentialDate = original.columns[column.id].text;
                if (isDate) {
                  const { formattedDate } = useLocaleDate(
                    original.columns[column.id].text
                  );
                  parsedPotentialDate = formattedDate;
                }

                return (
                  <span className="base-table__table-cell-data">
                    {original.columns[column.id].isList ? (
                      <DataOverflowHandler
                        dataString={original.columns[column.id].text}
                      />
                    ) : (
                      parsedPotentialDate
                    )}

                    {original.columns[column.id].hasNotification ? (
                      <InfoBubbleButton
                        infoText={original.columns[column.id].notificationText}
                        buttonText={'Mer info'}
                      />
                    ) : (
                      ''
                    )}
                  </span>
                );
              }
            };
          })
        );

        if (showMenus || isTablet) {
          conditionalColumns.push({
            id: 'menu',
            Header: undefined,
            Cell: ({ row, selectedFlatRows }) => {
              const { original } = row;

              if (!original) return <></>;
              if (original.menuItems?.length === 0) return <></>;
              if (!original.id) return <></>;
              if (!original.menuItems?.length) return null;
              return (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {isTablet && (
                    <Clicker
                      iconName={
                        original.roleId === 'SL' && original.requestDelete
                          ? Clicker.iconNames.warningRed
                          : Clicker.iconNames.infoCircle
                      }
                      onClick={() =>
                        setModalData({
                          row: row,
                          columns: columns
                        })
                      }
                      textIsHidden
                      text={'Informasjon'}
                    />
                  )}
                  {showMenus && (
                    <TableMenuButton
                      items={original.menuItems.map(item => {
                        if (item?.link) return item;
                        return {
                          ...item,
                          onClick: () => item.onClick(original.id)
                        };
                      })}
                      {...elementMenuDropdown}
                    />
                  )}
                </span>
              );
            }
          });
        }

        if (showLinks)
          conditionalColumns.push({
            id: 'link',

            Header: undefined,
            Cell: ({ row }) => {
              const { original } = row;

              if (!original?.link) return <></>;

              return (
                <Clicker
                  text={original?.link?.text}
                  href={original?.link?.href}
                  className="base-table__button"
                  iconName={Icon.names.arrowCircleBlack}
                  textIsHidden={true}
                  iconSize={Clicker.iconSizes.mediumPlus}
                />
              );
            }
          });

        conditionalColumns.push({
          id: 'button',
          Header: undefined,
          Cell: ({ row }) => {
            const { original } = row;
            if (!original?.nodeButton?.text) return <></>;

            return (
              <Clicker
                {...original?.nodeButton}
                className="base-table__button"
                iconName={Icon.names.arrowCircleBlack}
                textIsHidden={true}
                iconSize={Clicker.iconSizes.mediumPlus}
              />
            );
          }
        });

        return conditionalColumns;
      });
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = table;

  if (typeof width !== 'number') return null;
  return (
    <div className="base-table">
      <table
        {...getTableProps({ role: undefined })}
        className="base-table__table"
      >
        <thead>
          {headerGroups.map((headerGroup, hgIdx) => (
            <tr
              key={`header-group-${hgIdx}`}
              {...headerGroup.getHeaderGroupProps({ role: undefined })}
              className="base-table__table-header-row"
            >
              {headerGroup.headers.map((column, hIdx) => {
                return column.Header ? (
                  <th
                    key={`header-group-header-${hgIdx}-${hIdx}`}
                    {...column.getHeaderProps()}
                    className="base-table__table-heading"
                  >
                    <span>{column.render('Header')}</span>
                  </th>
                ) : (
                  <td
                    key={`header-group-header-${hgIdx}-${hIdx}`}
                    className="base-table__table-heading"
                  ></td>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps({ role: undefined })}
          className="base-table__table-body"
        >
          {rows.map((row, rIdx) => {
            prepareRow(row);

            return (
              <tr
                key={`row-${rIdx}`}
                {...row.getRowProps({ role: undefined })}
                className="base-table__table-row"
              >
                {row.cells.map((cell, cIdx) => {
                  return (
                    <td
                      key={`cell-${rIdx}-${cIdx}`}
                      {...cell.getCellProps({ role: undefined })}
                      className={cn('base-table__table-cell', {
                        'base-table__table-cell--menu':
                          cell.column.id === 'menu',
                        'base-table__table-cell--link':
                          cell.column.id === 'link' ||
                          cell.column.id === 'button'
                      })}
                    >
                      <span className="base-table__table-cell-inner">
                        {cell.render('Cell')}
                        {cIdx === 0 &&
                          (cell.row.original.infoTooltip ? (
                            <Tooltip
                              {...cell.row.original.infoTooltip}
                              iconIsPadded={false}
                              iconName={Icon.names.warning}
                              activeIconName={Icon.names.warningBlue}
                            />
                          ) : cell.row.original.warningTooltip ? (
                            <Tooltip
                              {...cell.row.original.warningTooltip}
                              iconIsPadded={false}
                              iconName={Icon.names.warningRed}
                              activeIconName={Icon.names.warningBlue}
                            />
                          ) : (
                            <></>
                          ))}
                      </span>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {infoText && (
        <div className="base-table__info-text">
          <Icon name={Icon.names.warningRed} size={Icon.sizes.medium} />
          <span>{infoText}</span>
        </div>
      )}
      {elements && !elements.length && (
        <div className="base-table__filtered-list-empty-text-wrapper">
          <p className="base-table__filtered-list-empty-text">
            {filteredListEmptyText}
          </p>
        </div>
      )}
      <Modal isOpen={!!modalData} onClose={closeModal}>
        <div className="base-table__modal-content">
          {modalData?.row.original.roleId === 'SL' &&
            modalData?.row.original.requestDelete && (
              <div className="base-table__modal-notification">
                <Icon name={Icon.names.warningRed} size={Icon.sizes.medium} />
                <span>ønskes fjernes</span>
              </div>
            )}
          <ul>
            {modalData?.columns.map(column => {
              const { Header, id } = column;
              const originalData = modalData?.row?.original;
              const columnById = originalData?.columns[id];
              if (columnById) {
                return (
                  <li key={id} style={{ marginBottom: '1rem' }}>
                    <p>
                      <b>{Header}</b>
                    </p>
                    {columnById?.text}
                  </li>
                );
              }
              return null;
            })}
          </ul>
          {modalData && (
            <Clicker
              {...modalData?.row.original.link}
              theme={Clicker.themes.primary}
              className="base-table__modal-action"
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

BaseTable.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.exact(ElementPropShape.propTypes)),
  columnHeaders: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      text: PropTypes.string
    })
  ),
  elementMenuDropdown: PropTypes.shape(TableMenuButton)
};

BaseTable.defaultProps = {
  showMenus: true,
  showLinks: true,
  headerColumnMobileIndex: 0
};

export default BaseTable;
