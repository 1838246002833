import React from 'react';
import PropTypes from 'prop-types';

const MissingProfile = ({ title, htmlLead }) => {
  return (
    <div className="missing-profile">
      {title && <h1 className="missing-profile__title">{title}</h1>}
      {htmlLead && (
        <div
          dangerouslySetInnerHTML={{ __html: htmlLead }}
          className="missing-profile__lead"
        />
      )}
    </div>
  );
};

MissingProfile.propTypes = {
  title: PropTypes.string,
  htmlLead: PropTypes.string
};

MissingProfile.defaultProps = {
  selections: []
};

export default MissingProfile;
