import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ImageLoader from 'js/responsive-images';

const FluidImage = ({ alt, src, className, focusPoint, initialSize }) => {
  const [imageSrc, setImageSrc] = useState(
    src + `?transform=DownFit&width=${initialSize}`
  );

  const imageRef = useRef();

  useEffect(() => {
    imageRef &&
      setImageSrc(
        ImageLoader.getNewSrc(src, imageRef.current.offsetWidth, initialSize)
      );
  }, []);

  return !src ? null : (
    <div
      ref={imageRef}
      className={cn('fluid-image', className)}
      style={{
        backgroundImage: `url(${imageSrc})`,
        backgroundPosition: focusPoint
          ? `${focusPoint.x}% ${focusPoint.y}%`
          : null
      }}
    >
      <img src={imageSrc} alt={alt} className="fluid-image__image" />
    </div>
  );
};

FluidImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  focusPoint: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }),
  initialSize: PropTypes.number,
  src: PropTypes.string
};

FluidImage.defaultProps = {
  initialSize: 200
};

export default FluidImage;
