const handleTabNavigationEvents = (event, tabIdList) => {
  const tabId = event.target.id;
  const tabIndex = tabIdList.indexOf(tabId);

  switch (event.key) {
    case 'ArrowLeft':
      document
        .getElementById(
          tabIndex === 0
            ? tabIdList[tabIdList.length - 1]
            : tabIdList[tabIndex - 1]
        )
        .focus();
      break;

    case 'ArrowRight':
      document
        .getElementById(
          tabIndex === tabIdList.length - 1
            ? tabIdList[0]
            : tabIdList[tabIndex + 1]
        )
        .focus();
      break;

    case 'Home':
      document.getElementById(tabIdList[0]).focus();
      break;

    case 'End':
      document.getElementById(tabIdList[tabIdList.length - 1]).focus();
      break;

    default:
      break;
  }
};

export default handleTabNavigationEvents;
