/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TableStateContext from './table-state-context';

const TableStateContextProvider = ({ children }) => {
  const [currentSort, setCurrentSort] = useState({});

  return (
    <TableStateContext.Provider value={{ currentSort, setCurrentSort }}>
      {children}
    </TableStateContext.Provider>
  );
};

TableStateContextProvider.propTypes = {
  children: PropTypes.node // exclude from backend
};

export default TableStateContextProvider;
