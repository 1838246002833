import React, { useMemo } from 'react';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';
import noNb from './no-nb';

const ToolbarList = ({ config, onChange, currentState }) => {
  const id = useMemo(() => uuid(), [config]);
  const availableStyles = config?.options;
  return (
    <div className="rdw-list-wrapper" aria-label="listestiler" role="group">
      {availableStyles?.map((style, i) => (
        <button
          key={`${id}-${i}`}
          className={cn('rdw-option-wrapper', config[style]?.className, {
            'rdw-option-active': currentState?.listType === style
          })}
          aria-label={noNb[`components.controls.list.${style}`]}
          role="checkbox"
          aria-checked={currentState?.listType === style}
          onClick={() => onChange(style)}
        >
          <img alt="" src={config[style]?.icon} />
        </button>
      ))}
    </div>
  );
};

export default ToolbarList;
