import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Alternative = ({
  index,
  label,
  labelledbyId,
  name,
  isChecked,
  colorValue,
  isDisabled,
  onClick,
  questionLegendId
}) => {
  return (
    <label
      className={cn('alternative', {
        'alternative--disabled': isDisabled
      })}
    >
      <div className="alternative__input-wrapper">
        <input
          className="alternative__input"
          aria-labelledby={labelledbyId}
          aria-describedby={questionLegendId}
          type="radio"
          name={name}
          defaultChecked={isChecked}
          disabled={isDisabled}
          onClick={() => onClick(index)}
        />
        <span className="alternative__fake-input" />
        <span
          className={cn('alternative__dot', {
            [`alternative__dot--${colorValue}`]: colorValue
          })}
        />
      </div>
      <span className="alternative__label" aria-hidden>
        {label}
      </span>
    </label>
  );
};

Alternative.propTypes = {
  name: PropTypes.string, //excluded from backend
  onClick: PropTypes.func, //excluded from backend
  colorValue: PropTypes.string, //excluded from backend
  isDisabled: PropTypes.bool, //excluded from backend
  label: PropTypes.string, //excluded from backend
  labelledbyId: PropTypes.string, //excluded from backend
  questionLegendId: PropTypes.string, //excluded from backend
  index: PropTypes.number.isRequired, // exluded from backend
  isChecked: PropTypes.bool // exlcude from BE
};

Alternative.defaultProps = {
  isChecked: false
};

export default Alternative;
