import InputErrorMessage from 'components/input-error-message';
import PropTypes from 'prop-types';
import Clicker from 'components/clicker/clicker';
import GenerellSystemtekstItem from './generell-systemtekst-item';
import React, { useContext, useEffect } from 'react';
import RedaktorGrensesnittContext from '../../../../contexts/redaktor-grensesnitt-context';
import Tooltip from '../../../tooltip';
import Icon from 'components/icon';

export default function GenerellSystemtekstDisplayData({
  systemtekster,
  isRichText,
  apiData,
  saveEndpoint,
  uniqueNodeId,
  type,
  textDisplayName,
  tooltip,
  maxLength,
  buttonText,
  infoBanner,
  sanityImageQuery
}) {
  const {
    state: {
      currentDataType,
      apiErrMessage,
      redaktorDataList: systemteksterList,
      isLoading,
      isDirty
    },
    functions: {
      setCurrentData,
      saveSystemtekstChanges,
      handleSystemtekstChange,
      undoAll,
      handleSystemtekstUndo
    }
  } = useContext(RedaktorGrensesnittContext);
  useEffect(() => {
    setCurrentData({
      apiData: {
        blockName: apiData?.blockName,
        textName: apiData?.textName
      },
      saveEndpoint,
      initialDataList: systemtekster,
      type: type
    });
    return () => {
      setCurrentData({
        apiData: {},
        saveEndpoint: '',
        initialDataList: [],
        type: ''
      });
    };
  }, []);

  return (
    <>
      <div
        className={'redaktor-grensesnitt__data-content-actions sticky-actions'}
      >
        <div>
          <Clicker
            theme={Clicker.themes.secondary}
            onClick={undoAll}
            isDisabled={!isDirty}
            text={buttonText.global.cancel}
          />
        </div>

        <div>
          <span className={'redaktor-grensesnitt__save-status'}>
            {isLoading ? 'Lagrer ... ' : isDirty ? 'Ulagrede endringer' : ''}
          </span>
          <Clicker
            isLoading={isLoading}
            theme={Clicker.themes.primary}
            onClick={saveSystemtekstChanges}
            text={buttonText.global.save}
            isDisabled={!isDirty}
          />
        </div>
      </div>

      {infoBanner && (
        <div className={'redaktor-grensesnitt__info-banner'}>
          <Icon name={Icon.names.infoCircle} />
          <span className={'redaktor-grensesnitt__info-banner-text'}>
            {infoBanner}
          </span>
        </div>
      )}

      {apiErrMessage?.globalMessage && (
        <InputErrorMessage errors={[apiErrMessage?.globalMessage]} />
      )}

      <div className={'redaktor-grensesnitt__title-wrapper'}>
        <h2 className={'redaktor-grensesnitt__title-wrapper-text'}>
          {textDisplayName}
        </h2>
        <Tooltip {...tooltip} />
      </div>
      <ul className="redaktor-grensesnitt__data-content-group">
        {currentDataType === type &&
          systemteksterList.map((systemtekstItem, index) => (
            <GenerellSystemtekstItem
              key={systemtekstItem.id + uniqueNodeId + type}
              buttonText={buttonText.item}
              maxLength={maxLength}
              systemtekstItem={systemtekstItem}
              handleChange={handleSystemtekstChange}
              handleUndo={handleSystemtekstUndo}
              index={index}
              isRichText={isRichText}
              errorMessage={
                apiErrMessage?.specificMessage?.id === systemtekstItem.id
                  ? apiErrMessage?.specificMessage?.message
                  : ''
              }
              sanityImageQuery={sanityImageQuery}
            />
          ))}
      </ul>
    </>
  );
}

GenerellSystemtekstDisplayData.propTypes = {
  buttonText: PropTypes.exact({
    global: PropTypes.exact({
      save: PropTypes.string.isRequired,
      cancel: PropTypes.string.isRequired
    }).isRequired,
    item: PropTypes.exact({
      cancel: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  uniqueNodeId: PropTypes.string, // ignore backend
  type: PropTypes.string, // systemtekst
  apiData: PropTypes.exact({
    blockName: PropTypes.string,
    textName: PropTypes.string
  }),
  infoBanner: PropTypes.string,
  saveEndpoint: PropTypes.string,
  isRichText: PropTypes.bool,
  textDisplayName: PropTypes.string,
  tooltip: PropTypes.exact(Tooltip.propTypes),
  maxLength: PropTypes.number,
  systemtekster: PropTypes.arrayOf(GenerellSystemtekstItem.objectProps),
  sanityImageQuery: PropTypes.string
};

GenerellSystemtekstDisplayData.defaultProps = {
  buttonText: {
    global: {
      save: 'Lagre',
      cancel: 'Tilbakestill/Avbryt'
    },
    item: {
      cancel: 'Avbryt'
    }
  }
};
