import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isRunningOnClient from '@creuna/utils/is-running-on-client';
import arrayOfObjectValues from 'js/array-of-object-values';

import Icon from '../icon';

let ReactModal = undefined;

if (isRunningOnClient) {
  ReactModal = require('react-modal');
  ReactModal.setAppElement(`#mount-point`);
}

const themes = {
  gray: 'gray',
  white: 'white',
  smallWidth: 'small-width',
  mediumWidth: 'medium-width',
  largeWidth: 'large-width',
  extraLargeWidth: 'extra-large-width'
};

const Modal = ({
  children,
  isOpen,
  onClose,
  isDisabled,
  themes,
  closeButtonLabel,
  className
}) => {
  useEffect(() => {
    isOpen
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = '');
  }, [isOpen]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const themeClasses =
    themes && !!themes.length ? themes.map(theme => `modal--${theme}`) : [];

  return (
    <>
      {ReactModal && (
        <ReactModal
          isOpen={isOpen}
          contentLabel="modal"
          shouldCloseOnOverlayClick={false}
          shouldFocusAfterRender={true}
          style={{ overlay: { zIndex: 100 } }}
          shouldCloseOnEsc={!isDisabled}
          onRequestClose={onClose}
          className={cn('modal', className, themeClasses)}
        >
          <button
            disabled={isDisabled}
            onClick={onClose}
            className="modal__close"
            aria-label={closeButtonLabel}
          >
            <Icon name={Icon.names.closeCircleBlack} size={Icon.sizes.medium} />
          </button>
          <div tabIndex={1} className="modal__content">
            {children}
          </div>
        </ReactModal>
      )}
    </>
  );
};

Modal.propTypes = {
  className: PropTypes.string, //exclude from backend
  children: PropTypes.node, //exclude from backend
  isOpen: PropTypes.bool, //exclude from backend
  onClose: PropTypes.func, //exclude from backend
  themes: arrayOfObjectValues(themes), //exclude from backend
  isDisabled: PropTypes.bool, //exclude from backend,
  closeButtonLabel: PropTypes.string //exclude from backend,
};

Modal.defaultProps = {};

Modal.themes = themes;

export default Modal;
