import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Clicker from 'components/clicker/clicker';
import apiHelper from 'js/api-helper';
import LoadingSpinner from 'components/loading-spinner/loading-spinner';
import RenderStep from './render-step';
import Icon from 'components/icon/icon';

export const possibleSteps = {
  Example: 0,
  Upload: 1,
  AddStudents: 2,
  Summary: 3
};

const AddStudentsFromFile = ({
  onClickGoBack,
  goBackText,
  title,
  fileuploadEndpoint,
  filedownloadEndpoint,
  exampleFiledownloadEndpoint,
  fileregisterEndpoint,
  steps
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [error, setError] = useState(false);
  const [response, setResponse] = useState(null);

  const fileInputRef = useRef(null);

  const handleOnImportButtonClick = () => {
    if (!fileInputRef.current) return;

    fileInputRef.current.click();
  };

  const handleOnImportClick = async e => {
    setIsLoading(true);
    setError(false);
    const addedFile = e.target.files[0];

    if (
      addedFile.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      const formData = new FormData();
      formData.append('file', addedFile);

      try {
        const response = await apiHelper.executeWithCustomOptions(
          fileuploadEndpoint,
          formData,
          {
            headers: {
              Accept: 'application/json'
            },
            credentials: 'include'
          }
        );
        if (response?.fileError) {
          setError(response.fileError);
        } else {
          setError(null);
          setResponse(response);
          setCurrentStep(prev => prev + 1);
        }
      } catch (err) {
        setError('Noe gikk galt under importeringen');
        console.error(err);
      }
    } else {
      setError('Filen må være .xlsx');
    }
    setIsLoading(false);
  };

  const handleOnRegisterClick = async () => {
    setIsLoading(true);
    setError(false);
    try {
      const newResponse = await apiHelper.execute(fileregisterEndpoint, {
        importSessionId: response?.importSessionId
      });
      setResponse({
        ...newResponse,
        importSessionId: response?.importSessionId
      });
      setCurrentStep(prev => prev + 1);
    } catch (err) {
      setError('Noe gikk galt under registreringen av elever');
      console.error(err);
    }
    setIsLoading(false);
  };

  const handleOnAbortClick = () => {
    setCurrentStep(0);
  };

  const handleOnNextClick = () => {
    if (currentStep === possibleSteps.AddStudents) {
      handleOnRegisterClick();
    } else if (currentStep === steps.length - 1) {
      // Last step
      onClickGoBack();
    } else {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleOnPreviousClick = () => {
    if (currentStep !== 0) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const handleOnDownloadClick = async () => {
    setIsDownloadLoading(true);
    try {
      const { text, filename } = await apiHelper.execute(filedownloadEndpoint, {
        importSessionId: response?.importSessionId
      });
      const downloadUrl =
        'data:text/plain;charset=utf-8,' + encodeURIComponent(text);
      const downloadFilename = /.txt/.test(filename)
        ? filename
        : `${filename}.txt`;
      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      anchor.target = '_blank';
      anchor.download = downloadFilename;
      document.body.appendChild(anchor); // required for firefox
      anchor.click();
      anchor.remove();
      setError(false);
    } catch (err) {
      setError('Noe gikk galt under nedlastningen');
    }
    setIsDownloadLoading(false);
  };

  const handleOnExampleDownloadClick = async () => {
    setIsDownloadLoading(true);
    try {
      const res = await fetch(exampleFiledownloadEndpoint, {
        headers: {
          Accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        credentials: 'include',
        responseType: 'blob'
      });

      const blob = await res.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;

      const contentDisposition = res.headers.get('Content-Disposition');
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1].split(';')[0].trim()
        : 'download.xlsx';

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError('Noe gikk galt under nedlastningen');
      console.error(err);
    } finally {
      setIsDownloadLoading(false);
    }
  };

  const getButton = (type, title) => {
    if (type === 'fileupload') {
      return (
        <div
          style={{ position: 'relative' }}
          className={cn(
            'add-students-from-file__button',
            'add-students-from-file__fileinput'
          )}
          key={title}
        >
          <input
            ref={fileInputRef}
            className="add-students-from-file__fileinput-input"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={handleOnImportClick}
          />
          <Clicker
            theme={Clicker.themes.primary}
            text={title}
            onClick={handleOnImportButtonClick}
          />
        </div>
      );
    } else if (type === 'abort') {
      return (
        <Clicker
          className="add-students-from-file__button"
          theme={Clicker.themes.secondary}
          text={title}
          onClick={handleOnAbortClick}
          key={title}
        />
      );
    } else if (type === 'next') {
      let shoudldBeDisabled = false;

      if (currentStep === possibleSteps.AddStudents && !response.success) {
        shoudldBeDisabled = true;
      }

      return (
        <Clicker
          className="add-students-from-file__button"
          theme={Clicker.themes.primary}
          text={title}
          onClick={handleOnNextClick}
          isDisabled={shoudldBeDisabled}
          key={title}
        />
      );
    } else if (type === 'previous') {
      return (
        <Clicker
          className="add-students-from-file__button"
          theme={Clicker.themes.secondary}
          text={title}
          onClick={handleOnPreviousClick}
          key={title}
        />
      );
    } else if (type === 'filedownload') {
      return isDownloadLoading ? (
        <div className="add-students-from-file__download-loading" key={title}>
          <LoadingSpinner />
        </div>
      ) : (
        <Clicker
          className="add-students-from-file__button"
          theme={Clicker.themes.secondary}
          text={title}
          onClick={handleOnDownloadClick}
          key={title}
        />
      );
    } else if (type === 'examplefiledownload') {
      return isDownloadLoading ? (
        <div className="add-students-from-file__download-loading" key={title}>
          <LoadingSpinner />
        </div>
      ) : (
        <Clicker
          className="add-students-from-file__button"
          theme={Clicker.themes.secondary}
          text={title}
          onClick={handleOnExampleDownloadClick}
          key={title}
        />
      );
    }
  };

  return (
    <div className="add-students-from-file">
      {currentStep !== possibleSteps.Summary ? (
        <Clicker
          onClick={onClickGoBack}
          text={goBackText}
          iconIsBeforeText={true}
          iconName={Clicker.iconNames.arrowLeft}
          className="add-students-from-file__back-button"
        />
      ) : (
        <div className="add-students-from-file__back-button-spacer" />
      )}
      {title && <h2 className="add-students-from-file__title">{title}</h2>}
      <div className="add-students-from-file__steps">
        {steps?.map((s, i) => {
          return (
            <div
              className={cn(
                'add-students-from-file__step',
                { '--active': currentStep === i },
                { '--past': currentStep > i }
              )}
              key={s?.title}
              style={{ zIndex: steps.length - i }}
              aria-current={currentStep === i}
            >
              {s?.title}
            </div>
          );
        })}
      </div>
      {error && (
        <div className="add-students-from-file__error">
          <Icon name="warning-red" /> {error}
        </div>
      )}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <RenderStep
          steps={steps}
          currentStep={currentStep}
          response={response}
          getButton={getButton}
          error={error}
        />
      )}
    </div>
  );
};

export default AddStudentsFromFile;

AddStudentsFromFile.propTypes = {
  onClickGoBack: PropTypes.func,
  goBackText: PropTypes.string,
  title: PropTypes.string,
  fileuploadEndpoint: PropTypes.string,
  filedownloadEndpoint: PropTypes.string,
  fileregisterEndpoint: PropTypes.string,
  exampleFiledownloadEndpoint: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      errorDescription: PropTypes.string,
      buttons: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          type: PropTypes.string
        })
      )
    })
  )
};
