import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../layouts/redaktor-layout';
import RedaktorIntro from '../redaktor-intro/redaktor-intro';
import RedaktorGrensesnittContextProvider from '../../contexts/redaktor-grensesnitt-context-provider';

const RedktorIntroPage = ({ layout, redaktorIntro }) => {
  return (
    <RedaktorGrensesnittContextProvider>
      <Layout {...layout}>
        <RedaktorIntro {...redaktorIntro} />
      </Layout>
    </RedaktorGrensesnittContextProvider>
  );
};

RedktorIntroPage.propTypes = {
  layout: PropTypes.exact(Layout.propTypes),
  redaktorIntro: PropTypes.exact(RedaktorIntro.propTypes)
};

export default RedktorIntroPage;
