import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../layouts/navigation-layout';
import SamtaleSummary from 'components/samtale-summary/samtale-summary';

const SamtaleSummaryPage = ({ layout, samtaleSummary }) => {
  return (
    <Layout {...layout}>
      <SamtaleSummary {...samtaleSummary} />
    </Layout>
  );
};

SamtaleSummaryPage.propTypes = {
  layout: PropTypes.exact(Layout.propTypes),
  samtaleSummary: PropTypes.exact(SamtaleSummary.propTypes)
};

export default SamtaleSummaryPage;
