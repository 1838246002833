import React from 'react';
import PropTypes from 'prop-types';

/**
 * A class component becomes an error boundary if it defines either (or both) of the lifecycle methods static getDerivedStateFromError() or componentDidCatch().
 * Use static getDerivedStateFromError() to render a fallback UI after an error has been thrown. Use componentDidCatch() to log error information.
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorInfo: null, error: null };
  }

  static getDerivedStateFromError(error, errorInfo) {
    // Update state so the next render will show the fallback UI.
    return { errorInfo: errorInfo, error: error };
  }

  resetErrors = () => {
    this.setState({ errorInfo: null, error: null });
  };

  render() {
    if (this.state.errorInfo) {
      // You can render any custom fallback UI
      return (
        <div className="error-boundary">
          <h1>Noe gikk galt:</h1>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
          <button onClick={this.resetErrors}>Tilbakestill</button>
        </div>
      );
    }

    return <div className="error-boundary">{this.props.children}</div>;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node
};

export default ErrorBoundary;
