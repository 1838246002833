import React from 'react';
import PropTypes from 'prop-types';
import RadioInput from 'components/radio-button-input';

const RadioGroupInput = ({
  id,
  inputKey,
  isDisabled,
  group,
  label,
  onChange,
  ...rest
}) => {
  return (
    <div
      key={inputKey}
      className="radio-group-input"
      role={label && 'radiogroup'}
      aria-labelledby={label && 'group-label'}
    >
      {label && (
        <label className="radio-group-input__label" id="group-label">
          {label}
        </label>
      )}
      {group.map(item => {
        return (
          <RadioInput
            {...rest}
            {...item}
            onChange={onChange}
            key={item.id}
            isDisabled={isDisabled || rest?.editorMode}
            groupId={id}
          />
        );
      })}
    </div>
  );
};

RadioGroupInput.propTypes = {
  group: PropTypes.arrayOf(PropTypes.exact(RadioInput.inputProps)),
  label: PropTypes.string,
  inputKey: PropTypes.string,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func // ignore backend
};

RadioGroupInput.defaultProps = {
  onChange: () => {}
};

export default RadioGroupInput;
