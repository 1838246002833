/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import UserContext from './user-context';

const UserContextProvider = ({ children }) => {
  const [roles, setRoles] = useState([]);

  const updateRoles = roles => {
    setRoles(roles);
  };

  return (
    <UserContext.Provider value={{ roles, updateRoles }}>
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node // exclude from backend
};

export default UserContextProvider;
