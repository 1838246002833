import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import useLocaleDate from '../../hooks/use-locale-date';

import DataOverflowHandler from 'components/data-overflow-handler/data-overflow-handler';

const GridCardColumn = ({
  isActive,
  index,
  columnHeaders,
  closeDropdownText,
  smallCardHeader,
  isList,
  text
}) => {
  const checkForDate = text => {
    const isDate = !isNaN(Date.parse(text));
    let parsedPotentialDate = text;
    if (isDate) {
      const { formattedDate } = useLocaleDate(text);
      parsedPotentialDate = formattedDate;
    }
    return parsedPotentialDate;
  };

  return (
    <li
      className={cn('grid-card__meta-item', {
        'grid-card__meta-item--last-child': smallCardHeader && !isActive
      })}
    >
      {columnHeaders && isActive && (
        <span className="grid-card__meta-item-pretext">
          {columnHeaders[index].text + ': '}
        </span>
      )}
      {isList ? (
        isActive && text.split(',').length < 5 ? (
          text
        ) : (
          <DataOverflowHandler
            dataString={text}
            closeButtonLabel={closeDropdownText}
          />
        )
      ) : smallCardHeader && !isActive ? (
        `${smallCardHeader}: ${checkForDate(text)}`
      ) : (
        checkForDate(text)
      )}
    </li>
  );
};

GridCardColumn.propTypes = {
  isActive: PropTypes.bool,
  index: PropTypes.number,
  smallCardHeader: PropTypes.string,
  columnHeaders: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      text: PropTypes.string
    })
  ),
  isList: PropTypes.bool,
  text: PropTypes.string,
  closeDropdownText: PropTypes.string
};

GridCardColumn.defaultProps = {};

export default GridCardColumn;
