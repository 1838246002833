import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { iconNames, iconHoverNames } from './icon.types';

const sizes = {
  small: 'small',
  smallPlus: 'smallPlus',
  medium: 'medium',
  mediumPlus: 'mediumPlus',
  mediumLarge: 'mediumLarge',
  large: 'large',
  largePlus: 'largePlus',
  xl: 'xl',
  xxl: 'xxl'
};

export default function Icon({ className, name, size, isPadded }) {
  return !name ? null : (
    <span
      className={cn(
        'icon',
        `icon--${name}`,
        {
          'icon--padded': isPadded,
          [`icon--${size}`]: size
        },
        className
      )}
    />
  );
}

Icon.propTypes = {
  className: PropTypes.string,
  isPadded: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(sizes)),
  name: PropTypes.oneOf(Object.values({ ...iconNames, ...iconHoverNames }))
};

Icon.sizes = sizes;

Icon.names = iconNames;

Icon.hoverNames = iconHoverNames;

Icon.defaultProps = {
  size: sizes.medium
};
