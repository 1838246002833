import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from 'components/icon';
import NavLink from './nav-link';

const ariaId = 'nav-menu';

const NavMenuMobile = ({ links }) => {
  const [isOpen, setIsOpen] = useState(false);

  const activeLink = links.filter(link => link.isActive)[0];

  return (
    <div className={cn('nav-menu-mobile', { 'nav-menu-mobile--open': isOpen })}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="nav-menu-mobile__toggle"
        aria-controls={ariaId}
        aria-expanded={isOpen}
      >
        <span className="nav-menu-mobile__toggle-text">{activeLink?.text}</span>
        <span className="nav-menu-mobile__icon">
          <Icon name={Icon.names.open} size={Icon.sizes.medium} />
        </span>
      </button>
      <menu id={ariaId} className="nav-menu-mobile__menu">
        {links.map((link, index) => {
          return (
            <li key={index} className="nav-menu-mobile__item">
              <NavLink {...link} />
            </li>
          );
        })}
      </menu>
    </div>
  );
};

NavMenuMobile.propTypes = {
  links: PropTypes.arrayOf(PropTypes.exact(NavLink.propTypes))
};

NavMenuMobile.defaultProps = {
  links: []
};

export default NavMenuMobile;
