import React from 'react';
import PropTypes from 'prop-types';

const RadioInput = ({
  groupId,
  id,
  onChange,
  isDisabled,
  label,
  value,
  editorMode,
  handleEditorModeLabelChange
}) => {
  const handleChange = e => {
    onChange(e.target.checked, id, value);
  };
  return (
    <div className="radio-button-input">
      <div className="radio-button-input__radio-border">
        <input
          id={id}
          name={groupId} // set common id from question to create radio-group
          disabled={isDisabled} // disable all radio buttons if isDisabled
          type="radio"
          onChange={handleChange}
          checked={value}
          value={id} // id used here to set the current value of question
          className="radio-button-input__input"
        />
        {editorMode ? (
          <div className="radio-button-input__label">
            <input
              value={label}
              id={id}
              onChange={e =>
                handleEditorModeLabelChange(id, e.target.value, false)
              }
            />
            <button
              onClick={() => handleEditorModeLabelChange(id, undefined, true)}
            >
              Fjern
            </button>
          </div>
        ) : (
          <label htmlFor={id} className="radio-button-input__label">
            {label}
          </label>
        )}

        <span className="radio-button-input__dot" />
      </div>
    </div>
  );
};

RadioInput.inputProps = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  inputKey: PropTypes.string
};

RadioInput.propTypes = {
  groupId: PropTypes.string.isRequired, // ignore backend
  isDisabled: PropTypes.bool, // ignore backend
  onChange: PropTypes.func.isRequired, // ignore backend
  ...RadioInput.inputProps
};

RadioInput.defaultProps = {
  onChange: () => {}
};
export default RadioInput;
