import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

const themes = {
  lightBackground: 'lightBackground',
  darkBackground: 'darkBackground'
};
const Toggler = ({
  leftText,
  rightText,
  isLeft,
  onToggle,
  isLarge,
  theme,
  href,
  endpoint,
  show
}) => {
  const [currentIsLeft, setCurrentIsLeft] = React.useState(isLeft);
  const handleSlide = () => {
    setCurrentIsLeft(!currentIsLeft);
    setTimeout(() => {
      if (onToggle) {
        onToggle(currentIsLeft);
      }
    }, 500);
  };

  if (!show) return null;
  return (
    <div
      key={`${href + endpoint}`}
      className={cn('toggle-switch', {
        'toggle-switch--large': isLarge,
        [`toggle-switch--${themes[theme]}`]: themes[theme]
      })}
    >
      <input
        className="toggle-switch__input"
        name={leftText}
        type={'checkbox'}
        id={leftText}
        value={true}
        checked={!currentIsLeft}
        onChange={handleSlide}
      />
      <label
        className="toggle-switch__label"
        data-content={currentIsLeft ? leftText : rightText}
        htmlFor={leftText}
      >
        <span
          className={cn('toggle-switch__inactive-text', {
            'toggle-switch__inactive-text--right-side': currentIsLeft
          })}
        >
          {currentIsLeft ? rightText : leftText}
        </span>
      </label>
    </div>
  );
};

Toggler.themes = themes;

Toggler.defaultProps = {
  theme: themes.lightBackground
};
Toggler.propTypes = {
  rightText: PropTypes.string,
  leftText: PropTypes.string,
  isLeft: PropTypes.bool,
  href: PropTypes.string,
  endpoint: PropTypes.string,
  show: PropTypes.bool,
  onToggle: PropTypes.func, // ignore backend
  theme: PropTypes.string, // ignore backend
  isLarge: PropTypes.bool // ignore backend
};

export default Toggler;
