import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../layouts/admin-layout';
import StatistikkAdmin from '../statistikk-admin';

const StatistikkAdminPage = ({ layout, statistikk }) => {
  return (
    <Layout {...layout}>
      <StatistikkAdmin {...statistikk} />
    </Layout>
  );
};

StatistikkAdminPage.propTypes = {
  layout: PropTypes.exact(Layout.propTypes),
  statistikk: PropTypes.exact(StatistikkAdmin.propTypes)
};

export default StatistikkAdminPage;
