// kvm specific topic colors, matches vars.scss.

export default {
  black: '#303030', // $color--layout-black
  red: '#ddaa95', // $color--topic-red
  green: '#7dbf9d', // $color--topic-green
  blue: '#6d889d', // $color--topic-blue
  purple: '#999cbf', // $color--topic-purple
  brown: '#bfa687', // $color--topic-brown,
  gray: '#545353'
};
