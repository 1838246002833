import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Tab = ({ text, href, onClick, isActive }) => {
  const renderTab = () => {
    if (onClick)
      return (
        <button onClick={onClick} className={'tab__link'}>
          {text}
        </button>
      );
    else
      return (
        <a className="tab__link" href={href}>
          {text}
        </a>
      );
  };
  return (
    <div
      className={cn('tab', {
        'tab--active': isActive
      })}
    >
      {renderTab()}
    </div>
  );
};

Tab.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool
};

Tab.defaultProps = {};

export default Tab;
