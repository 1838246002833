import React from 'react';
import PropTypes from 'prop-types';

import Clicker from '../clicker';
import TextInput from '../text-input';
import LoadingSpinner from '../loading-spinner';

const SearchInput = ({
  textInput,
  onBlur,
  onChange,
  onClick,
  buttonText,
  isDisabled,
  isLoading
}) => {
  return (
    <div className="search-input">
      <div className="search-input__content">
        <div className="search-input__input">
          <TextInput
            {...textInput}
            theme={TextInput.themes.underlined}
            onBlur={onBlur}
            onChange={onChange}
            isDisabled={isDisabled}
          />
          {isLoading && (
            <div className="search-input__loading">
              <LoadingSpinner />
            </div>
          )}
        </div>
        <div className="search-input__action">
          <Clicker
            text={buttonText}
            theme={Clicker.themes.primary}
            onClick={onClick}
            isDisabled={isDisabled}
          />
        </div>
      </div>
    </div>
  );
};

SearchInput.propTypes = {
  onBlur: PropTypes.func, //exclude from backend
  onChange: PropTypes.func, //exclude from backend
  onClick: PropTypes.func, //exclude from backend
  isDisabled: PropTypes.bool, //exclude from backend
  isLoading: PropTypes.bool, //exclude from backend
  buttonText: PropTypes.string,
  textInput: PropTypes.exact(TextInput.propTypes)
};

SearchInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onClick: () => {}
};

export default SearchInput;
