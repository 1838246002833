import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import api from 'js/api-helper';

import Alternative from './alternative';
import Tooltip from '../tooltip';
import DefinitionText from 'components/definition-text/definition-text';
import Icon from '../icon';

const Question = ({
  id,
  questionIndex,
  questionText,
  indexSelected,
  hideAlternatives,
  tooltip,
  colorValue,
  isDisabled,
  baseData,
  saveEndpoint,
  radioHeaderLabels,
  radioHeaderIds,
  questionPreText
}) => {
  const [currentSaved, setCurrentSaved] = useState(
    indexSelected !== undefined ? indexSelected : -1
  );

  const saveTimeoutId = useRef(null);

  const FIELDSET_ID = (id + questionIndex).replace(/\s+/g, '');

  const save = target => {
    if (target === currentSaved) return;

    clearTimeout(saveTimeoutId.current);

    let prevSaved = currentSaved;
    setCurrentSaved(target);

    saveTimeoutId.current = setTimeout(async () => {
      const payload = {
        ...baseData,
        questionId: questionIndex,
        answerId: target
      };

      try {
        await api.execute(saveEndpoint, payload);
      } catch (error) {
        setCurrentSaved(prevSaved);
      }
    }, 200);
  };

  return (
    <div className="question">
      <fieldset id={FIELDSET_ID} className="question__fieldset">
        <div className="question__fieldset-content-wrapper">
          <div className="question__content">
            <div className="question__question">
              <p className="question__text-index">{`${questionIndex} `}</p>
              <legend className="question__text">
                {questionPreText && (
                  <span className="question__pre-text">{questionPreText}</span>
                )}
                <DefinitionText
                  {...questionText}
                  underlineColorValue={colorValue}
                >
                  {tooltip && (
                    <span className="question__tooltip">
                      <Tooltip
                        {...tooltip}
                        activeIconName={Icon.names.questionCircleBlue}
                      />
                    </span>
                  )}
                </DefinitionText>
              </legend>
            </div>
          </div>
          {!hideAlternatives && (
            <div className="question__alternatives">
              {Array.from({ length: radioHeaderIds.length }, (_, index) => {
                let checkedProp = {};
                if (currentSaved === index) {
                  checkedProp = { isChecked: true };
                }
                return (
                  <div key={index} className="question__item">
                    <Alternative
                      index={index}
                      name={FIELDSET_ID}
                      isDisabled={isDisabled}
                      onClick={save}
                      colorValue={colorValue}
                      label={radioHeaderLabels[index].labelShort}
                      labelledbyId={radioHeaderIds[index]}
                      questionLegendId={FIELDSET_ID}
                      {...checkedProp}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </fieldset>
    </div>
  );
};

Question.propTypes = {
  onChange: PropTypes.func, //excluded from backend
  colorValue: PropTypes.string, //excluded from backend
  isDisabled: PropTypes.bool, //excluded from backend
  hideAlternatives: PropTypes.bool, //excluded from backend
  saveEndpoint: PropTypes.string, // exclude from BE
  baseData: PropTypes.exact({
    mestringsnivaid: PropTypes.string.isRequired,
    ferdighetsomradeid: PropTypes.string.isRequired,
    kartleggingid: PropTypes.string.isRequired
  }), // exclude from BE
  radioHeaderLabels: PropTypes.arrayOf(
    PropTypes.exact({
      labelShort: PropTypes.string,
      labelLong: PropTypes.string
    })
  ),
  radioHeaderIds: PropTypes.arrayOf(PropTypes.string), //exclude from BE
  id: PropTypes.string.isRequired,
  questionIndex: PropTypes.string.isRequired,
  questionText: PropTypes.exact(DefinitionText.propTypes),
  questionPreText: PropTypes.string,
  tooltip: PropTypes.exact(Tooltip.propTypes),
  indexSelected: PropTypes.number
};

Question.defaultProps = {
  alternatives: []
};

export default Question;
