import InputByType from 'components/input-by-type';
import { useState, useEffect, useCallback, useMemo } from 'react';
import api from '../js/api-helper';
import useBeforeUnload from './use-before-unload';

/**
 * Differanciate on valueArray or value.
 * ValueArray is only used for the input type samtaleSelect, which sends an array of strings
 * instead of a single string.
 * @param {*} question
 * @param {*} value
 */
export const getValueOrValueArray = (question, value) => {
  return question.valueArray
    ? { valueArray: value }
    : question.value !== undefined
    ? { value }
    : {};
};

export const updateNestedValue = (question, value, id) => {
  const hasChildMatch = question.group.some(group => group.id === id);

  if (!hasChildMatch) {
    //no match
    return question;
  }
  return {
    ...question,
    isDirty: true,
    group: question.group.map(groupItem => {
      if (question.type === InputByType.types.radio) {
        if (groupItem.id === id) {
          return {
            ...groupItem,
            value
          };
        }
        return {
          ...groupItem,
          value: false
        };
      } else {
        if (groupItem.id === id) {
          return {
            ...groupItem,
            value
          };
        }
        return groupItem;
      }
    })
  };
};

/**
 * Within the input groups, backend sends us stringValue (numberGroup) or boolValue (radioGroup/checkboxGroup)
 * These are formated at a top level to make components further down keep less code
 * @param {*} _group
 */
const convertStringBoolValue = _group => {
  return _group.map(g => {
    if (typeof g.stringValue === 'string') {
      const o = {
        ...g,
        value: g.stringValue
      };
      delete o.stringValue;
      return o;
    } else if (typeof g.boolValue === 'boolean') {
      const o = {
        ...g,
        value: g.boolValue
      };
      delete o.boolValue;
      return o;
    } else return g;
  });
};

// Take a list of sections which includes a list of input objects
// return a list of only input objects so that everything is at
// top level, and can more easily be operated on.
function formatSections(sections) {
  let formattedQuestions = [];
  sections?.forEach(section => {
    if (section.type) {
      formattedQuestions.push(section);
    } else if (section.inputs) {
      section.inputs.forEach((input, i) => {
        const obj = { ...input };
        if (obj?.group) {
          obj.group = convertStringBoolValue(obj.group);
        }
        if (i === 0) {
          obj.title = section.title || undefined;
          obj.lead = section.lead || undefined;
          obj.showChapterSeparator = section.showChapterSeparator || undefined;
          obj.isSectionFirstItem = true;
        }
        formattedQuestions.push(obj);
      });
    } else {
      formattedQuestions.push({
        title: section.title,
        lead: section.lead,
        showChapterSeparator: section.showChapterSeparator,
        isSectionFirstItem: true
      });
    }
  });
  return formattedQuestions;
}
export default function useQuestionnaireFormControl(
  sections,
  endpoint,
  submitData = {}
) {
  /**
   * This useCallback is used to get a format which is easier to handle than a list of lists
   */
  const getFormattedQuestions = useCallback(() => {
    return formatSections(sections);
  }, [sections]);

  // const initialQuestions = useRef(inputs);
  const [questions, setQuestions] = useState(getFormattedQuestions());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setQuestions(formatSections(sections));
  }, [sections]);

  const isDirtyForm = useMemo(() => {
    return questions.some(q => q.isDirty);
  });

  useBeforeUnload(isDirtyForm);

  const resetDirty = () => {
    setQuestions(questions.map(q => ({ ...q, isDirty: false })));
  };

  const resetSections = () => {
    setQuestions(formatSections(sections));
  };

  const handleOnInputChange = (value, id) => {
    setQuestions(currentQuestions =>
      currentQuestions.map(q => {
        if (q.group) {
          return {
            ...updateNestedValue(q, value, id),
            error: null
          };
        } else if (q.id !== id) return q;
        else {
          const valueObj = getValueOrValueArray(q, value);
          return { ...q, ...valueObj, error: null, isDirty: true };
        }
      })
    );
  };

  const handleOnSubmit = async (e, isSamtaleGuide) => {
    e && e.preventDefault();
    setIsLoading(true);
    resetDirty();
    /**
     * Creates a request body based on LagreSamtaleInput.cs. Currently only used in samtaleguide.
     */
    function getRequestBody() {
      function getRequestBodyItem(questionId, answers) {
        return {
          questionId: questionId,
          answers: answers
        };
      }

      return questions
        .filter(q => q.isDirty)
        .map(q => {
          if (q.isReadOnly) return null;
          const idOrKey = q.inputKey || q.id;
          if (typeof q.value === 'string') {
            return getRequestBodyItem(idOrKey, [
              { answerId: idOrKey, answerValue: q.value }
            ]);
          } else if (Array.isArray(q.valueArray)) {
            return getRequestBodyItem(
              idOrKey,
              q?.valueArray.map(val => ({
                answerId: idOrKey,
                answerValue: val
              }))
            );
          } else if (Array.isArray(q.group)) {
            const answers = q.group
              ?.map(groupItem => {
                if (typeof groupItem.value === 'boolean') {
                  return {
                    answerId: groupItem.id,
                    answerValue: groupItem.value ? groupItem.label : ''
                  };
                } else if (typeof groupItem.value === 'string') {
                  return {
                    answerId: groupItem.id,
                    answerValue: groupItem.value
                  };
                } else return null;
              })
              .filter(f => f);
            if (answers.length) {
              return getRequestBodyItem(idOrKey, answers);
            }
          } else return null;
        })
        .filter(f => f);
    }

    /**
     * Simplifies the request body to return [key | id]: value:string | value:[string]
     */
    function getSimpleRequestBody() {
      let requestBody = {};

      questions.forEach(q => {
        const idOrKey = q.inputKey || q.id;

        if (q.value) {
          requestBody[idOrKey] = q.value;
        } else if (q.valueArray) {
          requestBody[idOrKey] = q.valueArray;
        } else if (q.group) {
          if (q.type === 'radio') {
            let radioObject = q.group.find(radio => radio.value === true);
            requestBody[idOrKey] = radioObject.label;
          } else {
            let selectedCheckboxes = [],
              numberGroupIds = [];

            q?.group?.forEach(group => {
              if (typeof group.value === 'boolean' && group.value) {
                selectedCheckboxes.push(group.label);
              } else if (
                typeof group.value === 'string' &&
                group.value.length
              ) {
                numberGroupIds.push({
                  [group.id]: group.value
                });
              }
            });
            if (selectedCheckboxes.length) {
              requestBody[idOrKey] = selectedCheckboxes;
            } else if (numberGroupIds.length) {
              requestBody[idOrKey] = numberGroupIds;
            }
          }
        }
      });
      return requestBody;
    }

    const requestBody = isSamtaleGuide
      ? {
          results: getRequestBody(),
          ...submitData
        }
      : {
          ...getSimpleRequestBody(),
          ...submitData
        };
    try {
      const response = await api.execute(endpoint, requestBody);
      if (response?.errors) {
        throw response;
      }
      return response;
    } catch (err) {
      if (err.status === 400) {
        if (err.errors) {
          const questionsWithError = questions.map(i => {
            let input = i;
            Object.entries(err.errors).forEach(([errKey, errVal]) => {
              if (i.inputKey === errKey || i.id === errKey)
                input.errors = errVal;
            });
            return input;
          });
          setQuestions(questionsWithError);
        }
      }
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    questions,
    isLoading,
    handleOnInputChange,
    setQuestions,
    resetSections,
    isDirty: isDirtyForm,
    handleOnSubmit
  };
}
