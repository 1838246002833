import React from 'react';
import PropTypes from 'prop-types';
import InputByType from 'components/input-by-type';
import cn from 'classnames';
import Clicker from 'components/clicker/clicker';

export default function SamtaleQuestionItem({
  question,
  onChange,
  onBlur,
  readOnly,
  isDisabled
}) {
  const { questionText, type, id } = question;
  const LabelIfTextInput =
    type === InputByType.types.textarea ||
    type === InputByType.types.textArray ||
    type === InputByType.types.text
      ? 'label'
      : type === InputByType.types.checkbox ||
        type === InputByType.types.radio ||
        type === InputByType.types.numberGroup
      ? 'span'
      : 'h2';
  const FieldsetIfCheckboxOrRadio =
    type === InputByType.types.checkbox ||
    type === InputByType.types.radio ||
    type === InputByType.types.numberGroup
      ? 'fieldset'
      : 'div';
  const LegendIfCheckboxOrRadio =
    type === InputByType.types.checkbox ||
    type === InputByType.types.radio ||
    type === InputByType.types.numberGroup
      ? 'legend'
      : 'div';

  const errorRef = React.useRef(null);

  React.useEffect(() => {
    if (question.error) {
      errorRef.current.scrollIntoView();
    }
  }, [errorRef, question.error]);

  return (
    <FieldsetIfCheckboxOrRadio
      className={cn('samtale-question-item', {
        [`samtale-question-item--${question.type}`]: question?.type
      })}
    >
      <LegendIfCheckboxOrRadio className="samtale-question-item__question-wrapper">
        {questionText && (
          <LabelIfTextInput
            className={cn('samtale-question-item__label', {
              'samtale-question-item__label--error': question.error
            })}
            ref={errorRef}
            htmlFor={LabelIfTextInput === 'label' ? id : null}
          >
            {questionText}
          </LabelIfTextInput>
        )}
      </LegendIfCheckboxOrRadio>
      <div className="samtale-question-item__answer-wrapper">
        <InputByType
          isDisabled={isDisabled}
          onChange={onChange}
          onBlur={onBlur}
          {...question}
          placeholder={readOnly ? '' : question.placeholder}
        />
      </div>
    </FieldsetIfCheckboxOrRadio>
  );
}

SamtaleQuestionItem.propTypes = {
  question: PropTypes.shape({
    valueArray: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    maxLength: PropTypes.number, // maxLength is currently only used with type: 'textarray'
    id: PropTypes.string,
    type: PropTypes.oneOf(
      Object.values('textArray', 'textarea', 'numberGroup', 'checkbox', 'radio')
    ),
    isReadOnly: PropTypes.bool,
    removeValueLabelPrefix: PropTypes.string,
    questionText: PropTypes.string,
    hrefLink: PropTypes.exact(Clicker.link), // Used with textArray if readOnly
    placeholder: PropTypes.string,
    group: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        stringValue: PropTypes.string,
        boolValue: PropTypes.bool,
        placeholder: PropTypes.string
      })
    ),
    error: PropTypes.string // exclude from backend
  }),
  onChange: PropTypes.func.isRequired, // ignore backend
  onBlur: PropTypes.func, // ignore backend
  isDisabled: PropTypes.bool, // ignore backend
  readOnly: PropTypes.bool // ignore backend
};
SamtaleQuestionItem.defaultProps = {
  onChange: () => {}
};
