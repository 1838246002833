import SamtaleQuestionItem from 'components/samtale-question-item/samtale-question-item';
import PropTypes from 'prop-types';
import React from 'react';
import SamtaleHeading from './samtale-heading';

/**
 * SamtaleSectionWithHeading was extracted out of SamtaleChapter to be used in Redaktør screens for samtaleguide.
 */
const SamtaleSectionWithHeading = ({
  questions,
  handleOnInputChange,
  readOnly,
  isLoading
}) => {
  return (
    <div className={'samtale-chapter'}>
      {questions?.map(question => {
        const {
          title,
          lead,
          showTitleWithUnderscore,
          isSectionFirstItem,
          questionText,
          type
        } = question;
        return (
          <>
            {isSectionFirstItem && (
              <SamtaleHeading
                title={title}
                lead={lead}
                showTitleWithUnderscore={showTitleWithUnderscore}
              />
            )}
            {(questionText || type) && (
              <SamtaleQuestionItem
                onChange={handleOnInputChange}
                key={question?.id}
                question={question}
                readOnly={readOnly}
                isDisabled={readOnly || isLoading}
              />
            )}
          </>
        );
      })}
    </div>
  );
};

// This should be ignored on backend.
// The proptypes are stated in samtale-chapter
SamtaleSectionWithHeading.propTypes = {
  isLoading: PropTypes.bool, // Ignore everything here on backend
  readOnly: PropTypes.bool, // Ignore everything here on backend
  handleOnInputChange: PropTypes.func, // Ignore everything here on backend
  questions: PropTypes.arrayOf(
    // Ignore everything here on backend
    PropTypes.shape({
      title: PropTypes.string,
      lead: PropTypes.string,
      showTitleWithUnderscore: PropTypes.bool,
      isSectionFirstItem: PropTypes.bool,
      questionText: PropTypes.string,
      type: PropTypes.string
    })
  )
};

export default SamtaleSectionWithHeading;
