import React from 'react';
import Clicker from 'components/clicker/clicker';
import PropTypes from 'prop-types';

const LanguageList = ({ listOfText, removeValue, removeValueLabelPrefix }) => {
  return (
    <>
      {!!listOfText?.length && (
        <ul className="language-list">
          {listOfText.map(item => (
            <li key={item} className="language-list__list-item">
              <span className="language-list__language-text">{item}</span>
              <div className="language-list__button-wrapper">
                {removeValueLabelPrefix && (
                  <Clicker
                    type="button"
                    className="language-list__button"
                    onClick={() => removeValue(item)}
                    iconName={Clicker.iconNames.closeCircleBlack}
                    iconSize={Clicker.iconSizes.mediumPlus}
                  >
                    <span className="language-list__button-text">
                      {removeValueLabelPrefix + item}
                    </span>
                  </Clicker>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default LanguageList;

LanguageList.propTypes = {
  listOfText: PropTypes.arrayOf(PropTypes.string),
  removeValue: PropTypes.func, //exclude from backend
  removeValueLabelPrefix: PropTypes.string
};
