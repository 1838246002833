import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import api from '../../../js/api-helper';

import Modal from '../modal';
import Clicker from '../../clicker';
import LoadingSpinner from '../../loading-spinner';
import Icon from 'components/icon';
import StudentCommonFields from '../student-common-fields/student-common-fields';
import RadioGroup from '../../radio-group';
import InputByType from 'components/input-by-type';

const AddStudentModal = ({
  isOpen,
  onClose,
  onConfirm,
  isDisabled,
  closeModalHiddenText,
  addStudentText,
  removeStudentText,
  saveStudentText,
  exitText,
  cancelButtonText,
  title,
  lead,
  requiredFieldDescription,
  addSubmitEndpoint,
  lookupEndpoint,
  requestMoveEndpoint,
  firstNameTextInput,
  lastNameTextInput,
  dNumberTextInput,
  dufNumberTextInput,
  birthNumberTextInput,
  gradeInput,
  requestMoveStudentButtonText,
  orgId,
  numberOfStudentsAddedText,
  chooseIdTypeRadioGroup,
  changedIdTypeAriaLiveText
}) => {
  const [state, setState] = useState({
    isLoading: false,
    isDisabled: true,
    isDirty: false,
    pendingStudents: [],
    gradeInput,
    firstNameTextInput,
    lastNameTextInput,
    dNumberTextInput,
    dufNumberTextInput,
    birthNumberTextInput
  });
  const [currentIdType, setCurrentIdType] = useState(
    StudentCommonFields.idTypes.fnr
  );
  const [inputErrors, setInputErrors] = useState({});
  const [hasRequestMoveOption, setHasRequestMoveOption] = useState(false);
  const [confirmedMove, setConfirmedMove] = useState('');

  const studentListRef = useRef(null);

  const toggleLoading = isLoading => {
    setState(prevState => ({
      ...prevState,
      isLoading
    }));
  };

  const checkGeneralError = (value, key, stateKey) => {
    if (inputErrors[key]) setInputErrors({ ...inputErrors, [key]: [] });

    setHasRequestMoveOption(false);

    setState(prevState => ({
      ...prevState,
      isDirty: true,
      [stateKey]: {
        ...prevState[stateKey],
        value: value,
        isDirty: true
      }
    }));
  };

  const handleIsValid = isValid => {
    setState(prevState => ({
      ...prevState,
      isDisabled: !isValid
    }));
  };

  const handleOnError = err => {
    setInputErrors(err);
  };

  const resetInputs = () => {
    setState(prevState => ({
      ...prevState,
      birthNumberTextInput: { ...prevState.birthNumberTextInput, value: '' },
      dufNumberTextInput: { ...prevState.dufNumberTextInput, value: '' },
      dNumberTextInput: { ...prevState.dNumberTextInput, value: '' },
      lastNameTextInput: { ...prevState.lastNameTextInput, value: '' },
      firstNameTextInput: { ...prevState.firstNameTextInput, value: '' },
      gradeInput: { ...prevState.gradeInput, value: '', isDirty: false }
    }));

    setInputErrors({});
  };

  function checkIdAlreadyAdded() {
    function findPending(pending, key, stateTextInputKey) {
      return (
        pending[key].length && pending[key] === state[stateTextInputKey].value
      );
    }

    if (
      state.pendingStudents.find(p =>
        findPending(p, 'birthNumber', 'birthNumberTextInput')
      )
    ) {
      setInputErrors({ birthNumber: ['Fødselsnummer er allerede lagt til'] });
      return true;
    } else if (
      state.pendingStudents.find(p =>
        findPending(p, 'dNumber', 'dNumberTextInput')
      )
    ) {
      setInputErrors({ dNumber: ['D-nummer er allerede lagt til'] });
      return true;
    } else if (
      state.pendingStudents.find(p =>
        findPending(p, 'dufNumber', 'dufNumberTextInput')
      )
    ) {
      setInputErrors({ dufNumber: ['DUF nummer er allerede lagt til'] });
      return true;
    }

    return false;
  }

  const handleOnAdd = () => {
    const numberInput = {
      id: '',
      birthNumber: '',
      dNumber: '',
      dufNumber: ''
    };
    if (state.birthNumberTextInput?.value?.length === 11) {
      numberInput.birthNumber = state.birthNumberTextInput?.value;
      numberInput.id = state.birthNumberTextInput?.value;
    } else if (state.dNumberTextInput?.value?.length === 11) {
      numberInput.dNumber = state.dNumberTextInput?.value;
      numberInput.id = state.dNumberTextInput?.value;
    } else if (state.dufNumberTextInput?.value?.length === 12) {
      numberInput.dufNumber = state.dufNumberTextInput?.value;
      numberInput.id = state.dufNumberTextInput?.value;
    }

    setState(prevState => ({
      ...prevState,
      pendingStudents: [
        ...prevState.pendingStudents,
        {
          ...numberInput,
          firstName: prevState.firstNameTextInput.value,
          lastName: prevState.lastNameTextInput.value,
          grade: prevState.gradeInput.value,
          orgId
        }
      ],
      birthNumberTextInput: { ...prevState.birthNumberTextInput, value: '' },
      dufNumberTextInput: { ...prevState.dufNumberTextInput, value: '' },
      dNumberTextInput: { ...prevState.dNumberTextInput, value: '' },
      lastNameTextInput: { ...prevState.lastNameTextInput, value: '' },
      firstNameTextInput: { ...prevState.firstNameTextInput, value: '' },
      gradeInput: { ...prevState.gradeInput, value: '', isDirty: false }
    }));
  };

  const handleOnRemove = id => {
    setState(prevState => ({
      ...prevState,
      pendingStudents: prevState.pendingStudents.filter(
        student => student.id !== id
      )
    }));
  };

  const handleOnLookup = async () => {
    const alreadyAdded = checkIdAlreadyAdded();
    if (alreadyAdded) return;

    toggleLoading(true);

    try {
      const request = {
        firstName: state.firstNameTextInput.value,
        lastName: state.lastNameTextInput.value,
        grade: state.gradeInput.value,
        birthNumber: '',
        dNumber: '',
        dufNumber: ''
      };
      if (state.birthNumberTextInput?.value?.length === 11)
        request.birthNumber = state.birthNumberTextInput.value;
      else if (state.dNumberTextInput?.value?.length === 11)
        request.dNumber = state.dNumberTextInput.value;
      else if (state.dufNumberTextInput?.value?.length === 12)
        request.dufNumber = state.dufNumberTextInput.value;

      const res = await api.execute(lookupEndpoint, request);

      if (res.errors) {
        throw res;
      }

      handleOnAdd();
    } catch (err) {
      if (err.status === 400) {
        handleOnError(err.errors);

        if (err.isRegistreredInOtherSchool) setHasRequestMoveOption(true);
      }
    } finally {
      toggleLoading(false);
    }
  };

  const handleOnAddSubmit = async () => {
    toggleLoading(true);

    try {
      const res = await api.execute(addSubmitEndpoint, {
        students: state.pendingStudents.reduce((acc, student) => {
          // eslint-disable-next-line no-unused-vars
          const { id, ...rest } = student; // We omit the id in the post, since it's just an id we use locally, copied from birthNumber/dNumber

          return acc.concat(rest);
        }, [])
      });

      onConfirm(res);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      toggleLoading(false);
    }
  };

  const handleOnMoveRequest = async () => {
    toggleLoading(true);
    try {
      let { confirmText } = await api.execute(requestMoveEndpoint, {
        dNumber: state.dNumberTextInput.value,
        dufNumber: state.dufNumberTextInput.value,
        birthNumber: state.birthNumberTextInput.value
      });
      setConfirmedMove(confirmText);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      toggleLoading(false);
    }
  };

  const handleOnConfirmationClose = () => {
    setConfirmedMove('');
    resetInputs();
  };

  const closeModal = () => {
    if (confirmedMove) handleOnConfirmationClose();
    else onClose();
  };

  const adjustColumnHeights = newHeight => {
    if (newHeight && studentListRef.current) {
      if (window.innerWidth > 768) {
        const newHeightString = `calc(${newHeight}px - (0.875rem + 6px))`;
        studentListRef.current.style.maxHeight = newHeightString;
      } else {
        studentListRef.current.style.maxHeight = 'none';
      }
    }
  };

  useEffect(() => {
    return () => {
      toggleLoading(false);
    };
  }, []);

  useEffect(() => {
    let firstIdWithError;

    if (inputErrors.firstName) firstIdWithError = state?.firstNameTextInput.id;
    else if (inputErrors.lastName)
      firstIdWithError = state?.lastNameTextInput.id;
    else if (inputErrors.birthNumber)
      firstIdWithError = state?.birthNumberTextInput.id;
    else if (inputErrors.dNumber) firstIdWithError = state?.dNumberTextInput.id;
    else if (inputErrors.dufNumber)
      firstIdWithError = state?.dufNumberTextInput.id;
    else if (inputErrors.gradeInput) firstIdWithError = state?.gradeInput.id;

    if (firstIdWithError) {
      const elementToFocus = document.getElementById(firstIdWithError);
      if (elementToFocus) elementToFocus.focus();
    }
  }, [inputErrors]);

  return (
    <Modal
      themes={[
        Modal.themes.white,
        state.pendingStudents?.length
          ? Modal.themes.extraLargeWidth
          : Modal.themes.largeWidth
      ]}
      isOpen={isOpen}
      onClose={closeModal}
      isDisabled={isDisabled || state.isLoading}
      closeButtonLabel={closeModalHiddenText}
    >
      <div
        className={cn('add-student-modal', {
          'add-student-modal--has-students': state.pendingStudents?.length
        })}
      >
        <div className="add-student-modal__content">
          {title && <h2 className="add-student-modal__title">{title}</h2>}
          {requiredFieldDescription && (
            <div className="add-student-modal__required-field-desc">
              {requiredFieldDescription}
            </div>
          )}
          <div className="add-student-modal__grid-wrapper">
            {!confirmedMove && (
              <>
                <StudentCommonFields
                  className="add-student-modal__inputs"
                  lead={lead}
                  firstNameTextInput={state.firstNameTextInput}
                  lastNameTextInput={state.lastNameTextInput}
                  dNumberTextInput={state.dNumberTextInput}
                  dufNumberTextInput={state.dufNumberTextInput}
                  birthNumberTextInput={state.birthNumberTextInput}
                  gradeInput={state.gradeInput}
                  chooseIdTypeRadioGroup={chooseIdTypeRadioGroup}
                  changedIdTypeAriaLiveText={changedIdTypeAriaLiveText}
                  onChange={checkGeneralError}
                  isValidCallback={handleIsValid}
                  inputErrors={inputErrors}
                  heightChangeCallback={adjustColumnHeights}
                  chosenIdType={currentIdType}
                  setCurrentIdType={setCurrentIdType}
                />

                <div className="add-student-modal__actions">
                  <div className="add-student-modal__action-wrapper">
                    {cancelButtonText && (
                      <div className="add-student-modal__action">
                        <Clicker
                          text={cancelButtonText}
                          theme={Clicker.themes.secondary}
                          onClick={closeModal}
                        />
                      </div>
                    )}
                    {addStudentText && (
                      <div className="add-student-modal__action">
                        <Clicker
                          text={addStudentText}
                          theme={Clicker.themes.primary}
                          onClick={handleOnLookup}
                          isDisabled={state.isDisabled || hasRequestMoveOption}
                        />
                      </div>
                    )}
                    <div className="add-student-modal__action">
                      {state.isLoading && <LoadingSpinner />}
                    </div>
                  </div>
                  {hasRequestMoveOption && (
                    <div className="add-student-modal__action add-student-modal__move-student">
                      <Clicker
                        text={requestMoveStudentButtonText}
                        theme={Clicker.themes.primary}
                        onClick={handleOnMoveRequest}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            {confirmedMove && (
              <>
                <p className="add-student-modal__confirmed-text">
                  {confirmedMove}
                </p>

                <div className="add-student-modal__actions">
                  <div className="add-student-modal__action-wrapper">
                    <div className="add-student-modal__action">
                      <Clicker
                        text={exitText}
                        theme={Clicker.themes.primary}
                        onClick={handleOnConfirmationClose}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <div
              ref={studentListRef}
              className={cn('add-student-modal__pending-list', {
                'add-student-modal__pending-list--hidden': !state
                  .pendingStudents?.length
              })}
            >
              <div
                aria-live="polite"
                className="add-student-modal__students-added-status-message"
              >
                {state.pendingStudents?.length > 0 &&
                  state.pendingStudents.length +
                    ' ' +
                    numberOfStudentsAddedText}
              </div>
              <ul className="add-student-modal__student-list">
                {state.pendingStudents?.map((student, index) => {
                  const selectedGrade = state.gradeInput.options.filter(
                    option => option.value === student.grade
                  )[0];

                  return (
                    <li key={index} className="add-student-modal__student">
                      <div className="add-student-modal__student-info">
                        <div className="add-student-modal__student-name">{`${student.firstName} ${student.lastName}`}</div>
                        <div className="add-student-modal__student-grade">
                          {selectedGrade?.text}
                        </div>
                      </div>
                      <div className="add-student-modal__student-action">
                        <Clicker
                          text={removeStudentText}
                          iconName={Icon.names.close}
                          textIsHidden={true}
                          iconSize={Clicker.iconSizes.smallPlus}
                          onClick={() => handleOnRemove(student.id)}
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="add-student-modal__bottom-actions">
              <div className="add-student-modal__action">
                <Clicker
                  text={saveStudentText}
                  theme={Clicker.themes.primary}
                  onClick={handleOnAddSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const textInputProps = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isDirty: PropTypes.bool
};

AddStudentModal.propTypes = {
  isDisabled: PropTypes.bool, //exclude from backend
  onClose: PropTypes.func, //exclude from backend
  onConfirm: PropTypes.func, //exclude from backend
  isOpen: PropTypes.bool, //exclude from backend
  orgId: PropTypes.string,
  closeModalHiddenText: PropTypes.string,
  addStudentText: PropTypes.string,
  removeStudentText: PropTypes.string,
  saveStudentText: PropTypes.string,
  exitText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  title: PropTypes.string,
  requestMoveStudentButtonText: PropTypes.string,
  lead: PropTypes.string,
  requiredFieldDescription: PropTypes.string,
  lookupEndpoint: PropTypes.string,
  requestMoveEndpoint: PropTypes.string,
  addSubmitEndpoint: PropTypes.string,
  firstNameTextInput: PropTypes.exact(textInputProps),
  lastNameTextInput: PropTypes.exact(textInputProps),
  dufNumberTextInput: PropTypes.exact(textInputProps),
  dNumberTextInput: PropTypes.exact(textInputProps),
  birthNumberTextInput: PropTypes.exact(textInputProps),
  gradeInput: PropTypes.exact(InputByType.propTypes),
  numberOfStudentsAddedText: PropTypes.string,
  chooseIdTypeRadioGroup: PropTypes.exact(RadioGroup.propTypes),
  changedIdTypeAriaLiveText: PropTypes.string
};

AddStudentModal.defaultProps = {
  onConfirm: () => {},
  onClose: () => {},
  numberOfStudentsAddedText: ' elever lagt til'
};

export default AddStudentModal;
