import React, { useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const SamtaleInputSimple = ({
  inputIsActive,
  questionId,
  onChange,
  savedText,
  placeholder,
  maxLength
}) => {
  const [currentSavedText, setCurrentSavedText] = useState(savedText);

  const textareaRef = useRef(null);

  const fixHeight = () => {
    if (!textareaRef || !textareaRef.current) return;
    textareaRef.current.style.height = 0;
    textareaRef.current.style.height =
      textareaRef.current.scrollHeight + 1 + 'px';
  };

  const handleOnChange = e => {
    fixHeight();

    const newValue = e.target.value;
    setCurrentSavedText(newValue);

    const dataToSave = [{ answerId: '', answerValue: newValue }];

    onChange(dataToSave);
  };

  useLayoutEffect(() => {
    fixHeight();
  }, [inputIsActive]);

  return (
    <div className="samtale-input__simple">
      <textarea
        ref={textareaRef}
        className={cn('samtale-input__simple-input', {
          'samtale-input__simple-input--visible': inputIsActive
        })}
        rows={1}
        aria-labelledby={questionId}
        placeholder={placeholder}
        value={currentSavedText}
        onChange={handleOnChange}
        maxLength={maxLength}
      />
      <p
        className={cn('samtale-input__simple-preview', {
          'samtale-input__simple-preview--visible': !inputIsActive,
          'samtale-input__simple-preview--not-empty':
            currentSavedText && !!currentSavedText.length
        })}
      >
        {currentSavedText}
      </p>
      <p
        className={cn('samtale-input__pdf-inputs', {
          'samtale-input__pdf-inputs--visible': inputIsActive
        })}
      >
        {currentSavedText}
      </p>
    </div>
  );
};

SamtaleInputSimple.propTypes = {
  inputIsActive: PropTypes.bool, // exclude from BE
  onChange: PropTypes.func, // exclude from BE
  questionId: PropTypes.string, // exclude from BE
  savedText: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number
};

export default SamtaleInputSimple;
