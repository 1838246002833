import React from 'react';
import PropTypes from 'prop-types';

import BasicLayout from '../layouts/basic-layout';
import MissingRole from '../missing-role';

const MissingRolePage = ({ layout, missingRole }) => {
  return (
    <BasicLayout {...layout}>
      <MissingRole {...missingRole} />
    </BasicLayout>
  );
};

MissingRolePage.propTypes = {
  layout: PropTypes.exact(BasicLayout.propTypes),
  missingRole: PropTypes.exact(MissingRole.propTypes)
};

export default MissingRolePage;
