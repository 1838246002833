import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../icon';
import SlideInChildren from 'components/slide-in-children';
import useLocaleDate from '../../hooks/use-locale-date';
import colors from 'js/kartlegging-colors';

const HistoryEntry = ({ id, date, name, changeList, ferdighetsomradeList }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { formattedDate } = useLocaleDate(date);

  const elementId = `info-container-${id}`;

  return (
    <div className={cn('history-entry', { 'history-entry--open': isOpen })}>
      <button
        aria-controls={elementId}
        aria-expanded={isOpen}
        className="history-entry__button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="history-entry__button-content">
          <span className="history-entry__date">{formattedDate}</span>
          <span className="history-entry__name">{name}</span>
        </span>
        <span className="history-entry__icon">
          <Icon
            className="history-entry__icon-default"
            name={Icon.names.open}
            size={Icon.sizes.medium}
          />
          <Icon
            className="history-entry__icon-hovered"
            name={Icon.names.openWhite}
            size={Icon.sizes.medium}
          />
        </span>
      </button>
      <SlideInChildren open={isOpen}>
        <div className="history-entry__body" id={elementId}>
          {!!changeList?.length && (
            <ul className="history-entry__overall-list">
              {changeList.map((change, index) => {
                return (
                  <li
                    key={index}
                    className="history-entry__list-item history-entry__entry"
                  >
                    {change}
                  </li>
                );
              })}
            </ul>
          )}
          {!!ferdighetsomradeList?.length && (
            <ul className="history-entry__kartlegging-list">
              {ferdighetsomradeList.map(
                ({ name, color, mestringsnivaList }) => (
                  <li key={name} className="history-entry__list-item">
                    <span
                      className={cn(
                        'history-entry__ferdighetsomrade',
                        'history-entry__ferdighetsomrade--' + color
                      )}
                    >
                      {name}
                    </span>
                    <ul>
                      {mestringsnivaList?.map(({ name, utsagnList }) => (
                        <li key={name} className="history-entry__list-item">
                          {name}
                          <ul>
                            {utsagnList?.map(({ id, changeInfo }) => (
                              <li
                                key={id}
                                className="history-entry__list-item history-entry__entry"
                              >
                                <span className="history-entry__entry-id">
                                  {id}
                                </span>
                                {changeInfo}
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </li>
                )
              )}
            </ul>
          )}
        </div>
      </SlideInChildren>
    </div>
  );
};

HistoryEntry.propTypes = {
  id: PropTypes.string, //exclude from backend
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  changeList: PropTypes.arrayOf(PropTypes.string),
  ferdighetsomradeList: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      color: PropTypes.oneOf(Object.keys(colors)),
      mestringsnivaList: PropTypes.arrayOf(
        PropTypes.exact({
          name: PropTypes.string,
          utsagnList: PropTypes.arrayOf(
            PropTypes.exact({
              id: PropTypes.string,
              changeInfo: PropTypes.string
            })
          )
        })
      )
    })
  )
};

HistoryEntry.defaultProps = {
  changeList: []
};

export default HistoryEntry;
