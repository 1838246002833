import React from 'react';
import PropTypes from 'prop-types';
import Radio from 'components/radio/radio';

import cn from 'classnames';
import arrayOfObjectValues from 'js/array-of-object-values';

const themes = {};

const RadioGroup = ({
  className,
  title,
  items,
  name,
  selectedItem,
  onChange,
  themes,
  isControlledInput
}) => {
  return (
    <div className={cn('radio-group', themes, className)}>
      <fieldset className="radio-group__fieldset">
        <legend className="radio-group__title">{title}</legend>
        <div className="radio-group__wrapper">
          {items.map(radio => {
            return (
              <Radio
                key={radio.value}
                className={cn('radio-group__radio', {
                  'radio-group__radio--is-active': selectedItem === radio.value
                })}
                themes={[Radio.themes.greenInput]}
                onChange={() => onChange && onChange(radio.value)}
                name={name}
                {...radio}
                isControlledInput={isControlledInput}
                isInitiallySelected={selectedItem === radio.value}
              />
            );
          })}
        </div>
      </fieldset>
    </div>
  );
};

RadioGroup.propTypesMeta = {
  className: 'exclude',
  themes: 'exclude',
  isControlledInput: 'exclude',
  onChange: 'exclude'
};

RadioGroup.propTypes = {
  className: PropTypes.string,
  themes: arrayOfObjectValues(themes),
  isControlledInput: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
  name: PropTypes.string,
  selectedItem: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(Radio.propTypes))
};

RadioGroup.themes = themes;

export default RadioGroup;
