import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const SlideInChildren = ({ children, open }) => {
  const content = useRef(null);
  const [height, setHeight] = useState(0);

  React.useEffect(() => {
    if (open) {
      const scrollHeight = content?.current?.scrollHeight;
      const contentActiveHeight =
        scrollHeight > 1000 ? scrollHeight + 100 : scrollHeight || 0;
      setHeight(contentActiveHeight);
    } else setHeight(0);
  });

  return (
    <div className="slide-in-children">
      <div
        ref={content}
        style={{ maxHeight: height }}
        className={cn('slide-in-children__content', {
          'slide-in-children__content--open': open
        })}
      >
        {children}
      </div>
    </div>
  );
};

SlideInChildren.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node
};

export default SlideInChildren;
