import React from 'react';
import PropTypes from 'prop-types';
import Question from './question';
import cn from 'classnames';
import colors from 'js/kartlegging-colors';

const Questionnaire = ({
  title,
  titleAnchor,
  headerText,
  questions,
  isReadOnly,
  saveEndpoint,
  submitData,
  color
}) => {
  const getRadioHeaderIds = () => {
    return headerText?.labels.map(
      (_, index) => 'headerlabel' + index + submitData.mestringsnivaid
    );
  };

  return (
    <div className="questionnaire">
      {title && (
        <h2
          id={titleAnchor}
          className={cn('questionnaire__title', {
            [`questionnaire__title--${color}`]: color
          })}
        >
          {title}
        </h2>
      )}
      {headerText?.labels && (
        <div className="questionnaire__header">
          <div className="questionnaire__header-text">{headerText?.title}</div>
          <div className="questionnaire__header-labels">
            {headerText?.labels.map(({ labelShort, labelLong }, index) => (
              <div className="questionnaire__header-label" key={index}>
                <span aria-hidden={true}>{labelShort}</span>
                <span
                  id={'headerlabel' + index + submitData.mestringsnivaid}
                  className="questionnaire__header-label--long"
                >
                  {labelLong}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
      {questions && (
        <ul className="questionnaire__list">
          {questions.map((question, index) => (
            <li key={index} className="questionnaire__item">
              <Question
                {...question}
                isDisabled={isReadOnly}
                colorValue={color}
                baseData={submitData}
                saveEndpoint={saveEndpoint}
                radioHeaderLabels={headerText?.labels}
                radioHeaderIds={getRadioHeaderIds()}
                questionPreText={headerText?.title}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

Questionnaire.propTypes = {
  saveEndpoint: PropTypes.string.isRequired, // Exclude from BE
  title: PropTypes.string,
  titleAnchor: PropTypes.string,
  isReadOnly: PropTypes.bool,
  headerText: PropTypes.exact({
    title: PropTypes.string,
    labels: PropTypes.arrayOf(
      PropTypes.exact({
        labelShort: PropTypes.string,
        labelLong: PropTypes.string
      })
    )
  }),
  color: PropTypes.oneOf(Object.keys(colors)),
  submitData: PropTypes.exact({
    mestringsnivaid: PropTypes.string.isRequired,
    ferdighetsomradeid: PropTypes.string.isRequired,
    kartleggingid: PropTypes.string.isRequired
  }).isRequired,
  questions: PropTypes.arrayOf(PropTypes.exact(Question.propTypes)).isRequired
};

Questionnaire.defaultProps = {
  questions: []
};

export default Questionnaire;
