import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../layouts/navigation-layout';
import MappingOverview from '../mapping-overview';

const MappingOverviewPage = ({ layout, mappingOverview }) => {
  const [completeAndFinish, setCompleteAndFinish] = React.useState(
    layout.finishAndCompleteButton
  );
  return (
    <Layout
      {...layout}
      completeAndFinish={completeAndFinish}
      setCompleteAndFinish={setCompleteAndFinish}
    >
      <MappingOverview {...mappingOverview} />
    </Layout>
  );
};

MappingOverviewPage.propTypes = {
  layout: PropTypes.shape(Layout.propTypes),
  mappingOverview: PropTypes.exact(MappingOverview.propTypes)
};

export default MappingOverviewPage;
