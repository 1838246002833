/**
 * Add icons here if you want to use them in the app. If they have a hovered version,
 * they will change onHover
 */
export const iconNames = {
  editImage: 'edit-image',
  open: 'open',
  openWhite: 'open-white',
  personPlus: 'person-plus',
  person: 'person',
  persons: 'persons',
  playCircle: 'play-circle',
  plusCircleBlack: 'plus-circle--black',
  plusCircle: 'plus-circle',
  plus: 'plus',
  print: 'print',
  search: 'search',
  searchWhite: 'search-white',
  share: 'share',
  sort: 'sort',
  sortActive: 'sort-active',
  table: 'table',
  upload: 'upload',
  warning: 'warning',
  warningWhite: 'warning-white',
  warningRed: 'warning-red',
  warningBlue: 'warning-blue',
  warningBlack: 'warning-black',
  download: 'download',
  edit: 'edit',
  editActive: 'edit-active',
  filter: 'filter',
  historyCircle: 'history-circle',
  infoCircle: 'info-circle',
  infoCircleBlack: 'info-circle-black',
  questionCircle: 'question-circle',
  questionCircleBlue: 'question-circle-blue',
  linkOut: 'link-out',
  linkoutCircle: 'linkout-circle',
  lockNegOpen: 'lock-neg--open',
  lockNeg: 'lock-neg',
  lockOpen: 'lock-open',
  lock: 'lock',
  login_box: 'login_box',
  login_neg: 'login_neg',
  login_pos: 'login_pos',
  mail: 'mail',
  moreCircle: 'more-circle',
  more: 'more',
  moreBlue: 'more-blue',
  arrowCircle: 'arrow-circle',
  arrowCircleBlack: 'arrow-circle--black',
  arrowDownCircle: 'arrow-up-circle',
  arrowUpCircle: 'arrow-down-circle',
  arrowRight: 'arrow-right',
  arrowRightThick: 'arrow-right-thick',
  arrowLeft: 'arrow-left',
  arrowDown: 'arrow-down',
  arrowUp: 'arrow-up',
  arrowTest: 'arrow-test',
  editBold: 'quill-edit-bold',
  editItalic: 'quill-edit-italic',
  editUnderscore: 'quill-edit-underscore',
  editList: 'quill-edit-list',
  editNumberList: 'quill-edit-numberList',
  editLink: 'quill-edit-link',
  bellNotification: 'bell-notification',
  bell: 'bell',
  bellActive: 'bell-active',
  bellNotificationActive: 'bell-notification-active',
  calendar: 'calendar',
  checkCircleGreen: 'check-circle--green',
  checkCircle: 'check-circle',
  check: 'check',
  closeCircleBlack: 'close-circle--black',
  closeCircle: 'close-circle',
  closeCircleBorderless: 'close-circle-borderless',
  close: 'close',
  closeThin: 'close-thin',
  copy: 'copy',
  diagram: 'diagram',
  dialect: 'dialect',
  downloadCircle: 'download-circle',
  statisticsCircle: 'statistics-icon-black',
  statisticsCircleCurrent: 'statistics-icon-current',
  openArchive: 'open-archive',
  sendToArchive: 'send-to-archive',
  arrowUpAndDown: 'arrow-up-and-down',
  arrowUpAndDownFilledBlue: 'arrow-up-and-down-filled-blue',
  arrowUpFilledAndDownHollow: 'arrow-up-filled-and-down-hollow',
  arrowUpHollowAndDownFilled: 'arrow-up-hollow-and-down-filled',
  linesWithCircles: 'lines-with-circles',
  linesWithCirclesFilledBlue: 'lines-with-circles-filled-blue',
  squareList: 'square-list',
  fourSquares: 'four-squares',
  hamburger: 'hamburger',
  barnehage: 'barnehage',
  barnehageColored: 'barnehage-colored',
  grunnskole: 'grunnskole',
  grunnskoleColored: 'grunnskole-colored',
  videregaaende: 'videregaaende',
  videregaaendeColored: 'videregaaende-colored',
  delete: 'delete'
};

/**
 * Add icons here if you want them to have a hovered version
 */
export const iconHoverNames = {
  hoverOpen: 'hover-open',
  hoverPersonPlus: 'hover-person-plus',
  hoverPerson: 'hover-person',
  hoverPersons: 'hover-persons',
  hoverPlayCircle: 'hover-play-circle',
  hoverPlusCircleBlack: 'hover-plus-circle--black',
  hoverPlusCircle: 'hover-plus-circle',
  hoverPlus: 'hover-plus',
  hoverPrint: 'hover-print',
  hoverSearch: 'hover-search',
  hoverShare: 'hover-share',
  hoverSort: 'hover-sort',
  hoverTable: 'hover-table',
  hoverUpload: 'hover-upload',
  hoverWarning: 'hover-warning',
  hoverWarningRed: 'hover-warning-red',
  hoverWarningBlue: 'hover-warning-blue',
  hoverWarningBlack: 'hover-warning-black',
  hoverDownload: 'hover-download',
  hoverEdit: 'hover-edit',
  hoverFilter: 'hover-filter',
  hoverInfoCircle: 'hover-info-circle',
  hoverInfoCircleBlack: 'hover-info-circle-black',
  hoverQuestionCircle: 'hover-question-circle',
  hoverLinkOut: 'hover-link-out',
  hoverLinkoutCircle: 'hover-linkout-circle',
  hoverLockNegOpen: 'hover-lock-neg--open',
  hoverLockNeg: 'hover-lock-neg',
  hoverLockOpen: 'hover-lock-open',
  hoverLock: 'hover-lock',
  hoverMail: 'hover-mail',
  hoverMore: 'hover-more',
  hoverMoreCircle: 'hover-more-circle',
  hoverArrowCircle: 'hover-arrow-circle',
  hoverArrowCircleBlack: 'hover-arrow-circle--black',
  hoverArrowDownCircle: 'hover-arrow-down-circle',
  hoverArrowUpCircle: 'hover-arrow-up-circle',
  hoverArrowLeft: 'hover-arrow-left',
  hoverArrowDown: 'hover-arrow-down',
  hoverArrowUp: 'hover-arrow-up',
  hoverArrowRight: 'hover-arrow-right',
  hoverArrowRightThick: 'hover-arrow-right-thick',
  hoverBellNotification: 'hover-bell-notification',
  hoverBell: 'hover-bell',
  hoverBellActive: 'hover-bell-active',
  hvoerBellNotificationActive: 'hover-bell-notification-active',
  hoverCalendar: 'hover-calendar',
  hoverCheckCircle: 'hover-check-circle',
  hoverCloseCircle: 'hover-close-circle',
  hoverClose: 'hover-close',
  hoverCopy: 'hover-copy',
  hoverDiagram: 'hover-diagram',
  hoverDialect: 'hover-dialect',
  hoverStatisticsCircle: 'hover-statistics-icon-black',
  hoverArrowUpAndDown: 'hover-arrow-up-and-down',
  hoverArrowUpFilledAndDownHollow: 'hover-arrow-up-filled-and-down-hollow',
  hoverArrowUpHollowAndDownFilled: 'hover-arrow-up-hollow-and-down-filled',
  hoverLinesWithCircles: 'hover-lines-with-circles',
  hoverFourSquares: 'hover-four-squares',
  hoverSquareList: 'hover-square-list',
  hoverDelete: 'hover-delete'
};
