import React from 'react';
import PropTypes from 'prop-types';

import TableStateContextProvider from './table-state-context-provider';
import PhrasesContextProvider from './phrases-context-provider';
import HeaderContextProvider from './header-context-provider';
import UserContextProvider from './user-context-provider';

const ContextProviderWrapper = ({ children, phrases }) => {
  return (
    <TableStateContextProvider>
      <PhrasesContextProvider {...phrases}>
        <HeaderContextProvider>
          <UserContextProvider>{children}</UserContextProvider>
        </HeaderContextProvider>
      </PhrasesContextProvider>
    </TableStateContextProvider>
  );
};

ContextProviderWrapper.propTypes = {
  phrases: PropTypes.exact(PhrasesContextProvider.propTypes),
  children: PropTypes.node // exclude from backend
};

ContextProviderWrapper.phrasesProps = PhrasesContextProvider.propTypes;
export default ContextProviderWrapper;
