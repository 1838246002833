import { useMemo } from 'react';

export default function useLocaleDate(date) {
  return useMemo(() => {
    const d = new Date(date).toLocaleDateString('no-NO', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    const t = new Date(date).toLocaleTimeString('no-NO');
    return { formattedDate: d, formattedTime: t };
  }, [date]);
}
