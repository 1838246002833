import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import api from '../../../js/api-helper';

import Modal from '../modal';
import Clicker from '../../clicker';
import LoadingSpinner from '../../loading-spinner';
import QuickSearch from '../../quick-search';

const AssignTeacherModal = ({
  studentId,
  isOpen,
  onClose,
  onConfirm,
  isDisabled,
  closeModalHiddenText,
  doneText,
  removeText,
  readOnlyLabel,
  title,
  lead,
  submitEndpoint,
  assignedTeachers,
  removeTeacherEndpoint,
  quickSearch
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    isDisabled: false,
    assignedTeachers,
    message: ''
  });

  const handleOnSubmit = async () => {
    setIsLoading(true);

    try {
      const { resources } = await api.execute(submitEndpoint, {
        studentId,
        teachers: state.assignedTeachers
      });

      onConfirm(resources);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleOnRemove = async id => {
    setIsLoading(true);

    try {
      await api.execute(removeTeacherEndpoint, {
        studentId,
        id
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setState({
      ...state,
      assignedTeachers: state.assignedTeachers.filter(
        teacher => teacher.id !== id
      )
    });

    setIsLoading(false);
  };

  const handleOnChecked = id => {
    setState({
      ...state,
      assignedTeachers: state.assignedTeachers.map(teacher => {
        if (id !== teacher.id) return teacher;

        return { ...teacher, hasReadOnly: !teacher.hasReadOnly };
      })
    });
  };

  const handleOnItemClick = resource => {
    const { name, id, hasReadOnly } = resource;

    setState({
      ...state,
      assignedTeachers: [
        ...state.assignedTeachers,
        {
          id,
          name,
          hasReadOnly
        }
      ]
    });
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return (
    <Modal
      themes={[Modal.themes.white]}
      isOpen={isOpen}
      onClose={onClose}
      isDisabled={isDisabled || isLoading}
      closeButtonLabel={closeModalHiddenText}
    >
      <div className="assign-teacher-modal">
        <div className="assign-teacher-modal__content">
          {title && <h2 className="assign-teacher-modal__title">{title}</h2>}
          {lead && <p className="assign-teacher-modal__lead">{lead}</p>}
          <div className="assign-teacher-modal__inputs">
            <QuickSearch
              {...quickSearch}
              isDisabled={isLoading}
              studentId={studentId}
              isLoading={isLoading}
              blacklistSearchResult={state.assignedTeachers}
              onItemClick={handleOnItemClick}
            />
          </div>
          {state.message && (
            <p className="assign-teacher-modal__message">{state.message}</p>
          )}
          {state.assignedTeachers.length > 0 && (
            <div className="assign-teacher-modal__assigned-list">
              <ul className="assign-teacher-modal__list">
                {state.assignedTeachers.map((teacher, index) => {
                  const { id, name, hasReadOnly } = teacher;

                  return (
                    <li key={index} className="assign-teacher-modal__item">
                      <div className="assign-teacher-modal__teacher">
                        <div className="assign-teacher-modal__teacher-name">{`${name}`}</div>
                        <div className="assign-teacher-modal__teacher-actions">
                          <div className="assign-teacher-modal__teacher-action">
                            <label htmlFor={id}>
                              <input
                                id={id}
                                type="checkbox"
                                onChange={() => handleOnChecked(id)}
                                checked={hasReadOnly}
                              />
                              {readOnlyLabel}
                            </label>
                          </div>
                          <div className="assign-teacher-modal__teacher-action">
                            <Clicker
                              text={removeText}
                              iconName="close"
                              textIsHidden={true}
                              iconSize={Clicker.iconSizes.small}
                              onClick={() => handleOnRemove(id)}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div className="assign-teacher-modal__actions">
          <div className="assign-teacher-modal__action">
            <Clicker
              text={doneText}
              theme={Clicker.themes.primary}
              onClick={handleOnSubmit}
            />
          </div>
          <div className="assign-teacher-modal__action">
            {isLoading && <LoadingSpinner />}
          </div>
        </div>
      </div>
    </Modal>
  );
};

AssignTeacherModal.propTypes = {
  isDisabled: PropTypes.bool, //exclude from backend
  onClose: PropTypes.func, //exclude from backend
  onConfirm: PropTypes.func, //exclude from backend
  isOpen: PropTypes.bool, //exclude from backend
  studentId: PropTypes.string, //exclude from backend
  closeModalHiddenText: PropTypes.string,
  doneText: PropTypes.string,
  removeText: PropTypes.string,
  readOnlyLabel: PropTypes.string,
  title: PropTypes.string,
  removeTeacherEndpoint: PropTypes.string.isRequired,
  assignedTeachers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      hasReadOnly: PropTypes.bool
    })
  ),
  lead: PropTypes.string,
  submitEndpoint: PropTypes.string.isRequired,
  quickSearch: PropTypes.exact(QuickSearch.propTypes).isRequired
};

AssignTeacherModal.defaultProps = {
  onConfirm: () => {},
  onClose: () => {},
  assignedTeachers: []
};

export default AssignTeacherModal;
