import React from 'react';
import PropTypes from 'prop-types';
import api from '../../js/api-helper';
import MainHeading from 'components/main-heading/main-heading';
import SamtaleSummaryBox from './samtale-summary-box/samtale-summary-box';
import useQuestionnaireFormControl from '../../hooks/use-questionnaire-form-control';
import SamtaleQuestionItem from 'components/samtale-question-item/samtale-question-item';
import Clicker from 'components/clicker/clicker';
import ClickerPrevNext from 'components/clicker-prev-next';
import LoadingSpinner from 'components/loading-spinner/loading-spinner';

const SamtaleSummary = ({
  header,
  lead,
  summarySections,
  sections,
  submitEndpoint,
  readOnly,
  submitData,
  abortHref,
  linkPrev,
  toggleStatusEndpoint,
  buttonText,
  id
}) => {
  const { save, exitWithoutSave, saveAndFinish } = buttonText.global;

  const {
    questions,
    isLoading,
    handleOnInputChange,
    isDirty,
    handleOnSubmit
  } = useQuestionnaireFormControl(sections, submitEndpoint, submitData);

  const disableSave = React.useMemo(() => {
    return !isDirty || isLoading;
  }, [isDirty, isLoading]);

  const handleOnSaveAndRedirect = async e => {
    try {
      await handleOnSubmit(e, true);
      window.location = abortHref;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleOnSaveAndFinish = async e => {
    try {
      await handleOnSubmit(e, true);
      await api.execute(toggleStatusEndpoint, { id, isActive: false });
      window.location = abortHref;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  return (
    <div className="samtale-summary">
      <MainHeading {...header} />
      {lead && (
        <span
          dangerouslySetInnerHTML={{ __html: lead }}
          className="samtale-summary__lead"
        />
      )}
      <div className={'samtale-summary__summary-wrapper'}>
        {summarySections.map(section => {
          return <SamtaleSummaryBox key={section.title} {...section} />;
        })}
      </div>
      {questions?.map(question => {
        return (
          <SamtaleQuestionItem
            onChange={handleOnInputChange}
            key={question?.id}
            question={question}
            readOnly={readOnly}
            isDisabled={readOnly || isLoading}
          />
        );
      })}
      {!readOnly && (
        <div className={'samtale-summary__actions'}>
          <div />
          <div className="samtale-summary__actions-right">
            <div className="samtale-summary__loading">
              {isLoading && <LoadingSpinner />}
            </div>
            <Clicker
              href={abortHref}
              text={exitWithoutSave}
              className="samtale-summary__save-button"
              theme={Clicker.themes.secondary}
            />

            <Clicker
              onClick={e => handleOnSaveAndRedirect(e, true)}
              text={save}
              isDisabled={disableSave}
              className="samtale-summary__save-button"
              theme={Clicker.themes.secondary}
            />

            <Clicker
              onClick={e => handleOnSaveAndFinish(e)}
              text={saveAndFinish}
              className="samtale-summary__save-button"
              theme={Clicker.themes.primary}
            />
          </div>
        </div>
      )}
      <ClickerPrevNext linkPrev={linkPrev} />
    </div>
  );
};

SamtaleSummary.propTypes = {
  header: PropTypes.exact(MainHeading.propTypes),
  lead: PropTypes.string,
  summarySections: PropTypes.arrayOf(
    PropTypes.exact(SamtaleSummaryBox.propTypes)
  ),
  submitEndpoint: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  submitData: PropTypes.exact({
    samtaleId: PropTypes.string.isRequired,
    sideId: PropTypes.string.isRequired
  }).isRequired,
  abortHref: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      inputs: PropTypes.arrayOf(PropTypes.shape(SamtaleQuestionItem.question))
    })
  ),
  toggleStatusEndpoint: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  buttonText: PropTypes.exact({
    global: PropTypes.exact({
      save: PropTypes.string,
      exitWithoutSave: PropTypes.string,
      saveAndFinish: PropTypes.string
    })
  }),
  ...ClickerPrevNext.propTypes
};

export default SamtaleSummary;

SamtaleSummary.defaultProps = {
  buttonText: {
    global: {
      save: 'Save',
      saveAndFinish: 'Lukk og lagre',
      exitWithoutSave: 'Lukk uten å lagre'
    }
  }
};
