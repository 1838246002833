import isRunningOnClient from '@creuna/utils/is-running-on-client';
import cn from 'classnames';
import SanityImagePickerModal from 'components/modal/sanity-content-modal/sanity-image-picker-modal';
import {
  AtomicBlockUtils,
  ContentState,
  convertToRaw,
  EditorState
} from 'draft-js';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import PhrasesContext from '../../contexts/phrases-context';
import Clicker from '../clicker/clicker';
import './ie-polyfills';
import noNb from './no-nb';
import ToolbarInline from './ToolbarInline';
import ToolbarList from './ToolbarList';

const draftToHtml = isRunningOnClient ? require('draftjs-to-html') : undefined;
const htmlToDraft = isRunningOnClient
  ? require('html-to-draftjs').default
  : undefined;

const ReactEditor = isRunningOnClient
  ? require('react-draft-wysiwyg').Editor
  : undefined;

/**
 * This function contains a lot of logic to handle the image upload from Sanity.
 * State localImageReplacementMap is used to keep track of all images with their Sanity URL, and their locally created url.
 * We create a local url while the user is editing the code so that it won't reload from the sanity server on every newline. This is a fault with draft-js.
 * When we invoke onChange, we retrieve a value from the current editorState and replace all local URLs with the Sanity url.
 * Because this is a costly operation, onChange is only invoked onBlur.
 *
 * There's also a useEffect which updates the editorState on value change. This is so that we can reset the text from a parent component.
 *
 * - Jørgen Lybeck Hansen (not proudly)
 */
const Editor = ({
  id,
  value,
  isDisabled,
  placeholder,
  hasCustomToolbar,
  hasActionButtons,
  readOnly,
  onChange,
  onSave,
  onCancel,
  isLarge,
  sanityImageQuery
}) => {
  const { save, cancel } = useContext(PhrasesContext);

  const [localImageReplacementMap, setLocalImageReplacementMap] = useState([]);
  const [imageBankModal, setImageBankModal] = useState(false);
  const toggleImageBankModal = () => {
    setImageBankModal(!imageBankModal);
  };

  /**
   * Retrieves all <img src> attributes from a html string.
   * @returns a list of image sources.
   */
  const getListOfImagesFromHtml = htmlStr => {
    let m,
      matches = [],
      rex = /<img[^>]+src="?([^"\s]+)"?[^>]*\/>/g;

    while ((m = rex.exec(htmlStr)) !== null) {
      matches.push(m[1]);
    }

    return matches;
  };

  /**
   * It's an antipattern that this function returns a promise, but because the operation of getting local image urls from a server url is async, it needs to be like this.
   *
   * @param {*} _val a string value which should be transferred to an editorState
   * @returns a promise which resolves to editorState.
   */
  const getInitialEditorState = async _val => {
    if (!isRunningOnClient) return EditorState.createEmpty();
    if (!_val) return EditorState.createEmpty();

    // Check if there's any image urls in the htmlvalue sent from the server
    const matches = getListOfImagesFromHtml(_val);

    const promise = new Promise(resolve => {
      if (matches?.length) {
        // When there's an image url, we want to convert those to local URLs and store them in the localImageReplacementMap (for later sending back the server url)
        let newValue = _val;
        let newReplacementArray = [];
        matches.map(async (urlToReplace, i) => {
          await convertImgToBase64URL(urlToReplace, lclUrl => {
            newReplacementArray.push({
              url: urlToReplace,
              localUrl: lclUrl
            });

            newValue = newValue.replace(urlToReplace, lclUrl);
            // When we have checked all matches, and are done with the last one, resolve this promise and update localImageReplacementMap
            if (i === matches.length - 1) {
              setLocalImageReplacementMap(prevState => [
                ...prevState,
                ...newReplacementArray
              ]);
              resolve(newValue);
            }
          });
        });
      } else resolve(_val); // If there's no matches, instantly resolve with the original value
    });

    // When the promise resolves, create an EditorState from the string and return that.
    return promise.then(newVal => {
      const blocksFromHtml = htmlToDraft(newVal);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    });
  };

  const [editorState, setEditorState] = useState();

  // Before calling onChange (which triggers the useEffect further down) we want to replace image sources and italic text
  const handleTextBeforeOnChange = () => {
    const newContentState = editorState.getCurrentContent();

    let htmlValue = draftToHtml(convertToRaw(newContentState));
    if (htmlValue.trim() === `<p></p>`) htmlValue = '';
    if (/<ins>/.test(htmlValue)) htmlValue = htmlValue.replace(/ins>/g, 'u>');
    if (localImageReplacementMap?.length) {
      localImageReplacementMap.map(({ localUrl, url }) => {
        htmlValue = htmlValue.replace(localUrl, url);
      });
    }

    onChange(id, htmlValue);
  };

  // Only update parents onBlur because of the heavy image operations, onChange would slow down the editor
  const handleBlur = () => {
    handleTextBeforeOnChange();
  };

  // Every time the value changes from the parent, set the editor state to that value. This ensures that parents can control the input.
  // This could also be the default state of state:editorState, but that would not handle an async operation.
  useEffect(() => {
    async function getValAsync() {
      const val = await getInitialEditorState(value);
      setEditorState(val);
    }
    getValAsync();
  }, [value]);

  // This is the function which converts images to a local base64 url.
  async function convertImgToBase64URL(url, callback, outputFormat) {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      let canvas = document.createElement('CANVAS'),
        ctx = canvas.getContext('2d'),
        dataURL;
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };
    img.src = url;
  }

  /**
   * This function takes an image source and makes in valid for draft-js editor state
   * @param {*} url the url for the image to be added
   * @param {*} imageSize // The size
   * @param {*} alt // The image description
   */
  const addImageToRichText = async (url, imageSize, alt) => {
    convertImgToBase64URL(url, localUrl => {
      setLocalImageReplacementMap(prevState => [
        ...prevState,
        {
          url: url.url(),
          localUrl
        }
      ]);
      const entityData = {
        ...imageSize,
        src: localUrl,
        alt: alt
      };
      const contentStateWithEntity = editorState
        .getCurrentContent()
        .createEntity('IMAGE', 'IMMUTABLE', entityData);

      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity
      });
      const editorStateWithImage = AtomicBlockUtils.insertAtomicBlock(
        newEditorState,
        entityKey,
        ' '
      );
      setEditorState(editorStateWithImage);
    });
  };

  return (
    <div className={cn('editor', { 'editor--large': isLarge })}>
      {imageBankModal && (
        <SanityImagePickerModal
          selectImageCallback={addImageToRichText}
          title={'Bilder'}
          groqQuery={sanityImageQuery}
          isOpen={imageBankModal}
          onClose={() => setImageBankModal(false)}
        />
      )}
      {isRunningOnClient && (
        <ReactEditor
          handlePastedText={e => e}
          wrapperId={id}
          readOnly={readOnly}
          placeholder={placeholder}
          editorClassName="editor__editor"
          wrapperClassName="editor__wrapper"
          stripPastedStyles={false}
          toolbarHidden={!hasCustomToolbar || readOnly}
          editorState={editorState}
          localization={{
            locale: 'en',
            translations: noNb
          }}
          onBlur={handleBlur}
          onEditorStateChange={setEditorState}
          toolbarClassName="editor__default-toolbar"
          toolbar={{
            options: ['inline', 'list', 'link'],
            inline: {
              component: ToolbarInline,
              options: ['bold', 'italic', 'underline'],
              bold: {
                className: 'editor__action editor__action--bold',
                icon:
                  'data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDMwIDQwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzMwMzAzMDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmVkaXQtYm9sZDwvdGl0bGU+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMTkuODgsMjIuOEgxMi4zNXY2LjA5SDguNDlWMTFoMTN2My42NUgxMi4zNXY0LjQ3aDcuNTNaIi8+PC9zdmc+'
              },
              italic: {
                className: 'editor__action editor__action--italic',
                icon:
                  'data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDMwIDQwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzMwMzAzMDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmVkaXQtaXRhbGljPC90aXRsZT48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0xMi45MywyMC41OWwtMi42NiwyLjM1LTEsNS45NUg2Ljg3TDEwLDExaDIuMzVsLTEuNTUsOC43MUwyMC4xMSwxMWgzbC04LjQ2LDgsNS42Niw5Ljg4SDE3LjcyWiIvPjwvc3ZnPg=='
              },
              underline: {
                className: 'editor__action editor__action--underline',
                icon:
                  'data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDMwIDQwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzMwMzAzMDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmVkaXQtdW5kZXJzY29yZTwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSI4LjgyIiB5PSIyNi45NiIgd2lkdGg9IjEyLjM2IiBoZWlnaHQ9IjIiLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yMS4xOCwxMXY4LjMxYTUuMjgsNS4yOCwwLDAsMS0xLjUyLDMuODgsNi4xOSw2LjE5LDAsMCwxLTQuMDksMS43bC0uNTksMGE2LjQ3LDYuNDcsMCwwLDEtNC40Ny0xLjQ5LDUuMjQsNS4yNCwwLDAsMS0xLjY5LTQuMDlWMTFoMi4yM3Y4LjI3YTMuNzgsMy43OCwwLDAsMCwxLDIuODIsMy45MywzLjkzLDAsMCwwLDIuOTEsMSw0LDQsMCwwLDAsMi45Mi0xLDMuNzgsMy43OCwwLDAsMCwxLTIuODJWMTFaIi8+PC9zdmc+'
              }
            },
            list: {
              component: ToolbarList,
              options: ['unordered', 'ordered'],
              unordered: {
                className: 'editor__action editor__action--unordered-list',
                icon:
                  'data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDQyIDQwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzMwMzAzMDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmVkaXQtbGlzdDwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSIxNC41IiB5PSIxMS4wNCIgd2lkdGg9IjE5IiBoZWlnaHQ9IjIiLz48cmVjdCBjbGFzcz0iY2xzLTEiIHg9IjE0LjUiIHk9IjE5LjAxIiB3aWR0aD0iMTkiIGhlaWdodD0iMiIvPjxyZWN0IGNsYXNzPSJjbHMtMSIgeD0iMTQuNSIgeT0iMjYuOTgiIHdpZHRoPSIxOSIgaGVpZ2h0PSIyIi8+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSI4LjUiIHk9IjExLjAyIiB3aWR0aD0iMyIgaGVpZ2h0PSIyIi8+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSI4LjUiIHk9IjE5LjAxIiB3aWR0aD0iMyIgaGVpZ2h0PSIyIi8+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSI4LjUiIHk9IjI2Ljk2IiB3aWR0aD0iMyIgaGVpZ2h0PSIyIi8+PC9zdmc+'
              },
              ordered: {
                className: 'editor__action editor__action--ordered-list',
                icon:
                  'data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDQyIDQwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzMwMzAzMDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmVkaXQtbnVtYmVybGlzdDwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSIxNC41IiB5PSIxMS4wNCIgd2lkdGg9IjE5IiBoZWlnaHQ9IjIiLz48cmVjdCBjbGFzcz0iY2xzLTEiIHg9IjE0LjUiIHk9IjE5LjAxIiB3aWR0aD0iMTkiIGhlaWdodD0iMiIvPjxyZWN0IGNsYXNzPSJjbHMtMSIgeD0iMTQuNSIgeT0iMjYuOTgiIHdpZHRoPSIxOSIgaGVpZ2h0PSIyIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMTEsMTQuMTZIMTBWMTFsLTEsLjI5di0uNzVsMS44Ny0uNjVIMTFaIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMTEuNTQsMjIuMTZoLTN2LS42NGwxLjM3LTEuNDRhMS42NCwxLjY0LDAsMCwwLC41MS0uOTIuNjYuNjYsMCwwLDAtLjEyLS40MkEuNDUuNDUsMCwwLDAsMTAsMTguNmEuNDQuNDQsMCwwLDAtLjM3LjE5LjgzLjgzLDAsMCwwLS4xNC40OGgtMWExLjQ2LDEuNDYsMCwwLDEsLjItLjczQTEuNDIsMS40MiwwLDAsMSw5LjIxLDE4YTEuNzIsMS43MiwwLDAsMSwuNzgtLjE4LDEuNTcsMS41NywwLDAsMSwxLjA3LjMyLDEuMTEsMS4xMSwwLDAsMSwuMzcuOTEsMS4zNywxLjM3LDAsMCwxLS4wOS40OSwyLjM4LDIuMzgsMCwwLDEtLjI5LjVjLS4xNC4xOC0uMzUuNDEtLjY0LjcxbC0uNTUuNjNoMS42OFoiLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik05LjQ4LDI3LjU3SDEwQS40OS40OSwwLDAsMCwxMC41LDI3YS40NS40NSwwLDAsMC0uMTMtLjM0LjQ5LjQ5LDAsMCwwLS4zNy0uMTMuNTIuNTIsMCwwLDAtLjM1LjExLjM3LjM3LDAsMCwwLS4xNC4yOGgtMWExLDEsMCwwLDEsLjE5LS42LDEuMjcsMS4yNywwLDAsMSwuNTItLjQxLDEuNzMsMS43MywwLDAsMSwuNzMtLjE1LDEuNzQsMS43NCwwLDAsMSwxLjEyLjMzLDEuMDgsMS4wOCwwLDAsMSwuNDEuODkuOS45LDAsMCwxLS4xNy41MiwxLjE2LDEuMTYsMCwwLDEtLjQ5LjQsMS4yMSwxLjIxLDAsMCwxLC41My4zNywxLDEsMCwwLDEsLjE5LjYyLDEuMSwxLjEsMCwwLDEtLjQ0LjkyLDEuNzksMS43OSwwLDAsMS0xLjE1LjM0QTEuODUsMS44NSwwLDAsMSw5LjE4LDMwYTEuMTYsMS4xNiwwLDAsMS0uNzMtMS4wOWgxYS40MS40MSwwLDAsMCwuMTYuMzQuNTMuNTMsMCwwLDAsLjM5LjE1LjU2LjU2LDAsMCwwLC40MS0uMTUuNDkuNDksMCwwLDAsLjE2LS4zNy41OC41OCwwLDAsMC0uMTYtLjQ1QS42OS42OSwwLDAsMCwxMCwyOC4zSDkuNDhaIi8+PC9zdmc+'
              }
            },
            link: {
              options: ['link', 'unlink'],
              showOpenOptionOnHover: false,
              link: {
                className: 'editor__action editor__action--link',
                icon:
                  'data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDQyIDQwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzMwMzAzMDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmVkaXQtbGluazwvdGl0bGU+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMjUuMzksMTlhNC41Myw0LjUzLDAsMCwxLTQuMjYsM0gxMi4wNWE0LjUsNC41LDAsMSwxLDAtOWg5LjA4YTQuNTYsNC41NiwwLDAsMSwzLjc4LDJoMi4yOGE2LjU3LDYuNTcsMCwwLDAtNi4wNi00SDEyLjA1YTYuNSw2LjUsMCwxLDAsMCwxM2g5LjA4YTYuNTUsNi41NSwwLDAsMCw2LjM3LTVaIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMjkuOTQsMTZIMjAuODZhNi41NSw2LjU1LDAsMCwwLTYuMzYsNC45MmgyLjEyQTQuNTMsNC41MywwLDAsMSwyMC44NiwxOGg5LjA4YTQuNSw0LjUsMCwxLDEsMCw5SDIwLjg2QTQuNTUsNC41NSwwLDAsMSwxNywyNC45M0gxNC43N0E2LjU3LDYuNTcsMCwwLDAsMjAuODYsMjloOS4wOGE2LjUsNi41LDAsMSwwLDAtMTNaIi8+PC9zdmc+'
              },
              unlink: {
                className: 'editor__action editor__action--unlink',
                icon:
                  'data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDQyIDQwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzMwMzAzMDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmVkaXQtbGluazwvdGl0bGU+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMjUuMzksMTlhNC41Myw0LjUzLDAsMCwxLTQuMjYsM0gxMi4wNWE0LjUsNC41LDAsMSwxLDAtOWg5LjA4YTQuNTYsNC41NiwwLDAsMSwzLjc4LDJoMi4yOGE2LjU3LDYuNTcsMCwwLDAtNi4wNi00SDEyLjA1YTYuNSw2LjUsMCwxLDAsMCwxM2g5LjA4YTYuNTUsNi41NSwwLDAsMCw2LjM3LTVaIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMjkuOTQsMTZIMjAuODZhNi41NSw2LjU1LDAsMCwwLTYuMzYsNC45MmgyLjEyQTQuNTMsNC41MywwLDAsMSwyMC44NiwxOGg5LjA4YTQuNSw0LjUsMCwxLDEsMCw5SDIwLjg2QTQuNTUsNC41NSwwLDAsMSwxNywyNC45M0gxNC43N0E2LjU3LDYuNTcsMCwwLDAsMjAuODYsMjloOS4wOGE2LjUsNi41LDAsMSwwLDAtMTNaIi8+PC9zdmc+'
              }
            }
          }}
        />
      )}
      {!readOnly && (
        <div>
          <div
            className={cn('editor__toolbar', {
              'editor__toolbar--separator': hasCustomToolbar || hasActionButtons
            })}
          >
            {hasActionButtons && (
              <ul className="editor__toolbar-list">
                {sanityImageQuery && (
                  <li className="editor__toolbar-item">
                    <Clicker
                      disabled={isDisabled}
                      text={'Bilder'}
                      textIsHidden
                      iconName={Clicker.iconNames.editImage}
                      iconSize={Clicker.iconSizes.large}
                      className="editor__toolbar-button"
                      onClick={toggleImageBankModal}
                    />
                  </li>
                )}
                {onCancel && (
                  <li className="editor__toolbar-item">
                    <Clicker
                      disabled={isDisabled}
                      text={cancel}
                      className="editor__toolbar-button"
                      theme={Clicker.themes.secondary}
                      onClick={onCancel}
                    />
                  </li>
                )}
                {onSave && (
                  <li className="editor__toolbar-item">
                    <Clicker
                      disabled={isDisabled}
                      text={save}
                      theme={Clicker.themes.primary}
                      onClick={onSave}
                    />
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

Editor.propTypesMeta = 'exclude';

Editor.propTypes = {
  id: PropTypes.string,
  sanityImageQuery: PropTypes.string,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLarge: PropTypes.bool,
  hasCustomToolbar: PropTypes.bool,
  hasActionButtons: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

Editor.defaultProps = {
  value: '',
  placeholder: ''

  // imageGroqQuery:
  // '*[_type == "informasjonstekst" && general.title == "Informasjon om lesing"]'
};

export default Editor;
