/* eslint-disable react/no-multi-comp */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import RedaktorGrensesnittContext from './redaktor-grensesnitt-context';
import apiHelper from 'js/api-helper';
import useBeforeUnload from '../hooks/use-before-unload';
import InputByType from 'components/input-by-type';

import { v4 as uuid } from 'uuid';

export const feUuid = () => {
  return `Frontend_${uuid()}`;
};

const intitialApiErrMessage = {
  globalMessage: '',
  specificMessage: {
    id: '',
    message: ''
  }
};

const useUtsagnListController = ({
  setApiErrMessage,
  setRedaktorDataList,
  redaktorDataList,
  initialDataList,
  apiData,
  saveEndpoint,
  setCurrentData,
  setRecentlySaved,
  setIsLoading
}) => {
  const addUtsagnItem = () => {
    setRedaktorDataList(_redaktorDataList => [
      {
        id: feUuid(),
        languageItems: [
          {
            id: feUuid(),
            languageCode: 'nob',
            languageName: 'Bokmål',
            questionText: {
              text: ''
            },
            tooltip: {
              text: ''
            }
          },
          {
            id: feUuid(),
            languageCode: 'nno',
            languageName: 'Nynorsk',
            questionText: {
              text: ''
            },
            tooltip: {
              text: ''
            }
          }
        ]
      },
      ..._redaktorDataList
    ]);
  };

  const handleUtsagnUndo = id => {
    const updated = redaktorDataList.map(item => {
      if (id === item.id) {
        const initialData = initialDataList.find(data => data.id === id);
        return {
          ...item,
          languageItems: initialData?.languageItems || []
        };
      } else return item;
    });
    setRedaktorDataList(updated);
  };
  // key is "tooltip" or "questionText"
  const handleUtsagnChange = (id, languageItemId, value, key) => {
    const updated = redaktorDataList.map(item => {
      if (id === item.id) {
        return {
          ...item,
          languageItems: item.languageItems.map(languageItem => {
            if (languageItem.id === languageItemId) {
              return {
                ...languageItem,
                [key]: {
                  text: value
                }
              };
            } else return languageItem;
          })
        };
      } else return item;
    });
    setRedaktorDataList(updated);
  };

  const saveUtsagnChanges = async () => {
    let utsagnMissing = null;
    redaktorDataList.some(item => {
      if (
        item?.languageItems?.some(
          languageItem => languageItem.questionText?.text.length === 0
        )
      ) {
        utsagnMissing = item;
      }
    });
    if (utsagnMissing) {
      return setApiErrMessage({
        specificMessage: {
          message: `Utsagn må fylles inn`,
          id: utsagnMissing?.id
        }
      });
    } else setApiErrMessage(intitialApiErrMessage);
    if (!saveEndpoint) {
      setApiErrMessage({ globalMessage: '[dev] mangler saveEndpoint' });
      return;
    }
    setIsLoading(true);
    try {
      await apiHelper.execute(saveEndpoint, {
        ...apiData,
        data: redaktorDataList
      });
      setRecentlySaved(true);
      setCurrentData(cD => ({
        ...cD,
        initialDataList: redaktorDataList
      }));
      setIsLoading(false);
    } catch (err) {
      if (err.message) {
        setApiErrMessage({ globalMessage: err.message });
      }
    }
  };

  return {
    saveUtsagnChanges,
    handleUtsagnChange,
    handleUtsagnUndo,
    addUtsagnItem
  };
};

// DataList is different than the others here
// It consists of a list of sections, with title, id and questions
// questions is a list of objects
const useSamtaleListController = ({
  setApiErrMessage,
  setRedaktorDataList,
  redaktorDataList,
  initialDataList,
  apiData,
  saveEndpoint,
  setCurrentData,
  setIsLoading,
  otherText,
  setRecentlySaved
}) => {
  // add question to a section
  const addSamtaleItem = () => {
    setRedaktorDataList(inputs => [
      {
        id: uuid(),
        type: InputByType.types.textarea
      },
      ...inputs
    ]);
  };

  // key is "tooltip" or "questionText"
  const handleSamtaleChange = (id, value, key) => {
    const updated = redaktorDataList.map(item => {
      if (id === item.id) {
        return {
          ...item,
          [key]: {
            text: value
          }
        };
      } else return item;
    });
    setRedaktorDataList(updated);
  };

  const handleSamtaleUndo = id => {
    const updated = redaktorDataList.map(item => {
      if (id === item.id) {
        const initialData = initialDataList.find(data => data.id === id);
        return initialData;
      } else return item;
    });
    setRedaktorDataList(updated);
  };

  const saveSamtaleChanges = async () => {
    let questionTextMissing = null;
    let labelMissing = null;

    redaktorDataList.some(item => {
      if (item?.questionText?.text?.length === 0) {
        questionTextMissing = item;
      }
    });

    redaktorDataList.some(item => {
      return item?.group?.some(groupItem => {
        if (groupItem?.label?.length === 0) {
          labelMissing = item;
        }
      });
    });

    if (questionTextMissing) {
      return setApiErrMessage({
        specificMessage: {
          message: `Spørsmålstekst er påkrevd`,
          id: questionTextMissing?.id
        }
      });
    } else if (labelMissing) {
      return setApiErrMessage({
        specificMessage: {
          message: `Alle alternativene må ha en beskrivelse`,
          id: labelMissing?.id
        }
      });
    } else setApiErrMessage(intitialApiErrMessage);
    if (!saveEndpoint) {
      setApiErrMessage({ globalMessage: '[dev] mangler saveEndpoint' });
      return;
    }
    setIsLoading(true);
    try {
      await apiHelper.execute(saveEndpoint, {
        ...apiData,
        data: redaktorDataList,
        title: otherText.title,
        lead: otherText.lead,
        ShowChapterSeparator: otherText.ShowChapterSeparator
      });
      setIsLoading(false);

      setRecentlySaved(true);
      setCurrentData(cD => ({
        ...cD,
        initialDataList: redaktorDataList,
        title: otherText.title,
        lead: otherText.lead,
        ShowChapterSeparator: otherText.ShowChapterSeparator
      }));
    } catch (err) {
      setIsLoading(false);

      if (err.message) {
        setApiErrMessage({ globalMessage: err.message });
      }
    }
  };

  return {
    saveSamtaleChanges,
    handleSamtaleChange,
    handleSamtaleUndo,
    addSamtaleItem
  };
};

const useExpressionListController = ({
  setApiErrMessage,
  setRedaktorDataList,
  redaktorDataList,
  initialDataList,
  apiData,
  saveEndpoint,
  setCurrentData,
  setRecentlySaved,
  setIsLoading
}) => {
  const addExpressionItem = () => {
    setRedaktorDataList(_redaktorDataList => [
      {
        id: feUuid(),
        matchItemList: [
          {
            match: ''
          }
        ],
        description: ''
      },
      ..._redaktorDataList
    ]);
  };

  const handleExpressionUndo = id => {
    const updated = redaktorDataList.map(item => {
      if (id === item.id) {
        const initialData = initialDataList.find(data => data.id === id);
        return {
          ...item,
          matchItemList: initialData?.matchItemList || [],
          description: initialData?.description || ''
        };
      } else return item;
    });
    setRedaktorDataList(updated);
  };
  // key is "matchItemList" || "description"
  const handleExpressionChange = (id, value, key) => {
    const updated = redaktorDataList.map(item => {
      if (id === item.id) {
        return {
          ...item,
          [key]: value
        };
      } else return item;
    });
    setRedaktorDataList(updated);
  };

  const sortAlphabetically = (a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) {
      return -1;
    }
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  const saveExpressionChanges = async () => {
    let requiredMissingOrdliste = null,
      requiredMissingDescription = null;

    redaktorDataList.some(item => {
      if (
        item?.matchItemList?.length === 1 &&
        item?.matchItemList[0]?.match === ''
      ) {
        requiredMissingOrdliste = item;
      }
      if (item?.description?.length === 0) {
        requiredMissingDescription = item;
      }
    });
    if (requiredMissingOrdliste) {
      return setApiErrMessage({
        specificMessage: {
          message: `Minst ett ord må være med i ordlisten`,
          id: requiredMissingOrdliste?.id
        }
      });
    } else if (requiredMissingDescription) {
      return setApiErrMessage({
        specificMessage: {
          message: `Ordlisten mangler beskrivelse`,
          id: requiredMissingDescription?.id
        }
      });
    } else setApiErrMessage(intitialApiErrMessage);
    if (!saveEndpoint) {
      setApiErrMessage({ globalMessage: '[dev] mangler saveEndpoint' });
      return;
    }
    setIsLoading(true);
    try {
      const newData = redaktorDataList.map(expression => ({
        ...expression,
        matchItemList: expression.matchItemList
          .map(matchItem => ({
            match: matchItem.match
          }))
          .filter(matchItem => matchItem.match !== '')
      }));

      const { updatedList } = await apiHelper.execute(saveEndpoint, {
        ...apiData,
        data: newData
      });

      const updatedSortedList = updatedList
        .map(expression => ({
          ...expression,
          matchItemList: expression.matchItemList
            .filter(matchItem => (matchItem.match !== '' ? true : false))
            .sort((a, b) => sortAlphabetically(a?.match, b?.match))
        }))
        .sort((a, b) =>
          sortAlphabetically(
            a?.matchItemList[0].match,
            b?.matchItemList[0].match
          )
        );

      setRecentlySaved(true);

      setCurrentData(cD => ({
        ...cD,
        initialDataList: updatedSortedList
      }));
      setRedaktorDataList(updatedSortedList);
    } catch (err) {
      if (err.message) {
        setApiErrMessage({ globalMessage: err.message });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    addExpressionItem,
    saveExpressionChanges,
    handleExpressionChange,
    handleExpressionUndo
  };
};

const useSystemtekstListController = ({
  setApiErrMessage,
  setRedaktorDataList,
  redaktorDataList,
  initialDataList,
  apiData,
  saveEndpoint,
  setCurrentData,
  setIsLoading
}) => {
  const handleSystemtekstUndo = id => {
    const updated = redaktorDataList.map(item => {
      if (id === item.id) {
        const initialData = initialDataList.find(data => data.id === id);
        return {
          ...item,
          value: initialData?.value || ''
        };
      } else return item;
    });
    setRedaktorDataList(updated);
  };
  const handleSystemtekstChange = (id, value) => {
    const updated = redaktorDataList.map(item => {
      if (id === item.id) {
        return {
          ...item,
          value
        };
      } else return item;
    });
    setRedaktorDataList(updated);
  };

  const saveSystemtekstChanges = async () => {
    setApiErrMessage(intitialApiErrMessage);
    if (!saveEndpoint) {
      setApiErrMessage({ globalMessage: '[dev] mangler saveEndpoint' });
      return;
    }
    setIsLoading(true);
    try {
      await apiHelper.execute(saveEndpoint, {
        ...apiData,
        data: redaktorDataList
      });
      setCurrentData(cD => ({
        ...cD,
        initialDataList: redaktorDataList
      }));
      setIsLoading(false);
    } catch (err) {
      if (err.message) {
        setApiErrMessage({ globalMessage: err.message });
      }
    }
  };

  return {
    saveSystemtekstChanges,
    handleSystemtekstChange,
    handleSystemtekstUndo
  };
};

const RedaktorGrensesnittContextProvider = ({ children }) => {
  const [apiErrMessage, setApiErrMessage] = useState(intitialApiErrMessage);
  const [isLoading, setIsLoading] = useState(false);
  const [
    {
      saveEndpoint,
      apiData,
      initialDataList,
      type,
      title,
      lead,
      showChapterSeparator
    },
    setCurrentData
  ] = useState({
    saveEndpoint: '',
    apiData: {},
    initialDataList: [],
    type: '',
    title: undefined,
    lead: undefined,
    showChapterSeparator: undefined
  });
  const [recentlySaved, setRecentlySaved] = useState(false);
  const [otherText, setOtherText] = useState({
    title: undefined,
    lead: undefined,
    showChapterSeparator: undefined
  });

  const [currentDataType, setCurrentDataType] = useState('');
  const [redaktorDataList, setRedaktorDataList] = useState([]);

  const {
    addExpressionItem,
    saveExpressionChanges,
    handleExpressionChange,
    handleExpressionUndo
  } = useExpressionListController({
    setApiErrMessage,
    setRedaktorDataList,
    setCurrentData,
    setRecentlySaved,
    redaktorDataList,
    initialDataList,
    apiData,
    saveEndpoint,
    setIsLoading
  });
  const {
    saveSamtaleChanges,
    handleSamtaleChange,
    handleSamtaleUndo,
    addSamtaleItem
  } = useSamtaleListController({
    setApiErrMessage,
    setRedaktorDataList,
    setCurrentData,
    redaktorDataList,
    setRecentlySaved,
    initialDataList,
    apiData,
    saveEndpoint,
    setIsLoading,
    otherText
  });
  const {
    saveUtsagnChanges,
    handleUtsagnChange,
    handleUtsagnUndo,
    addUtsagnItem
  } = useUtsagnListController({
    setApiErrMessage,
    setRedaktorDataList,
    setCurrentData,
    setRecentlySaved,
    redaktorDataList,
    initialDataList,
    apiData,
    saveEndpoint,
    setIsLoading
  });
  const {
    handleSystemtekstChange,
    handleSystemtekstUndo,
    saveSystemtekstChanges
  } = useSystemtekstListController({
    setApiErrMessage,
    setRedaktorDataList,
    setCurrentData,
    redaktorDataList,
    initialDataList,
    apiData,
    saveEndpoint,
    setIsLoading
  });

  const deleteItem = async id => {
    const updatedRedaktorDataList = redaktorDataList.filter(
      item => item.id !== id
    );
    setRedaktorDataList(updatedRedaktorDataList);
  };

  const isDirty = useMemo(() => {
    if (
      (typeof title !== undefined && title !== otherText.title) ||
      (typeof lead !== undefined && lead !== otherText.lead) ||
      showChapterSeparator !== otherText.showChapterSeparator
    )
      return true;
    const initial = JSON.stringify(initialDataList.sort((a, b) => a.id - b.id));
    const updated = JSON.stringify(
      redaktorDataList.sort((a, b) => a.id - b.id)
    );
    return initial !== updated;
  }, [
    initialDataList,
    redaktorDataList,
    otherText,
    title,
    lead,
    showChapterSeparator
  ]);

  // useEffect(() => {
  //   if (isDirty && recentlySaved) {
  //     setRecentlySaved(false);
  //   }
  // }, [isDirty, recentlySaved]);

  useEffect(() => {
    setTimeout(() => {
      if (recentlySaved) {
        setRecentlySaved(false);
      }
    }, 1000);
  }, [recentlySaved]);

  useEffect(() => {
    setCurrentDataType(type);
    setRedaktorDataList(initialDataList);
    setApiErrMessage(intitialApiErrMessage);
  }, [initialDataList]);

  useBeforeUnload(isDirty);

  const reorder = async (index, position) => {
    function getReorderedList(list) {
      const manipulate = [...list];
      const found = manipulate.splice(index, 1)[0];
      manipulate.splice(index + position, 0, found);
      return manipulate.map((item, index) => ({ ...item, position: index }));
    }
    const updatedRedaktorDataList = getReorderedList(redaktorDataList);
    setRedaktorDataList([...updatedRedaktorDataList]);
  };

  const undoAll = () => {
    setApiErrMessage(intitialApiErrMessage);
    setOtherText({ title, lead, showChapterSeparator });
    setRedaktorDataList([...initialDataList]);
    // Weird hack because it doesnt reset everything at once
    setTimeout(() => {
      setRedaktorDataList([...initialDataList]);
    }, 100);
  };

  return (
    <RedaktorGrensesnittContext.Provider
      value={{
        state: {
          isDirty,
          apiErrMessage,
          redaktorDataList,
          currentDataType,
          isLoading,
          recentlySaved,
          otherText
        },
        functions: {
          setOtherText,
          reorder,
          setCurrentData,
          deleteItem,
          undoAll,
          addSamtaleItem,
          addUtsagnItem,
          addExpressionItem,
          saveExpressionChanges,
          saveSamtaleChanges,
          handleSamtaleChange,
          handleSamtaleUndo,
          handleExpressionChange,
          handleExpressionUndo,
          saveUtsagnChanges,
          handleUtsagnChange,
          handleUtsagnUndo,
          setApiErrMessage,
          handleSystemtekstChange,
          handleSystemtekstUndo,
          saveSystemtekstChanges
        }
      }}
    >
      {children}
    </RedaktorGrensesnittContext.Provider>
  );
};

RedaktorGrensesnittContextProvider.propTypes = {
  children: PropTypes.node // exclude from backend
};

export default RedaktorGrensesnittContextProvider;
