import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import apiHelper from 'js/api-helper';

import Icon from 'components/icon/icon';
import SamtaleInput from 'components/samtale-input/samtale-input';
import SamtaleContext from '../../contexts/samtale-context';
import Tooltip from 'components/tooltip/tooltip';

const DEBOUNCE_TIME = 300;

const SamtaleQuestion = ({
  blockId,
  questionId,
  questionText,
  preQuestionText,
  input,
  tooltip
}) => {
  const [inputIsActive, setInputIsActive] = useState(false);
  const [saveData, setSaveData] = useState(); // To be used for as fallback data

  const timeoutId = useRef(null);

  const {
    activeBlock,
    updateActiveBlock,
    samtaleId,
    saveEndpoint,
    readOnly
  } = useContext(SamtaleContext);

  const handleSave = async data => {
    try {
      const payload = {
        samtaleId: samtaleId,
        questionAnswer: {
          questionId: questionId,
          answers: data
        }
      };
      await apiHelper.execute(saveEndpoint, payload);
    } catch (error) {
      //TODO: Handle failure - fallback data
      console.log(error);
    }
  };

  const handleQuestionClick = () => {
    updateActiveBlock(blockId);
    setInputIsActive(prev => !prev);
  };

  const handleOnInputChange = data => {
    setSaveData(data);

    clearTimeout(timeoutId.current);

    timeoutId.current = setTimeout(() => {
      handleSave(data);
    }, [DEBOUNCE_TIME]);
  };

  useEffect(() => {
    if (inputIsActive && activeBlock !== blockId) setInputIsActive(false);
  }, [activeBlock]);

  return (
    <div className="samtale-question">
      <div
        className={cn('samtale-question__question-wrapper', {
          'samtale-question__question-wrapper--hoverable': !readOnly
        })}
      >
        {questionText && (
          <div className="samtale-question__button-wrapper">
            {readOnly ? (
              <>
                <div className="samtale-question__text-wrapper">
                  <div className="samtale-question__icon-dot-readonly" />
                  <span>
                    <h4 className="samtale-question__question-text">
                      {questionText}
                    </h4>
                    {tooltip && <Tooltip {...tooltip} />}
                  </span>
                </div>
              </>
            ) : (
              <span className="samtale-question__button">
                <div className="samtale-question__text-wrapper">
                  <span className="samtale-question__icon">
                    {!inputIsActive &&
                      (activeBlock === blockId ? (
                        <Icon
                          className="samtale-question__icon-default"
                          name={Icon.names.edit}
                          size={Icon.sizes.medium}
                        />
                      ) : (
                        <div
                          className={cn(
                            'samtale-question__icon-default',
                            'samtale-question__icon-dot'
                          )}
                        />
                      ))}
                    {inputIsActive && (
                      <Icon
                        className="samtale-question__icon-active"
                        name={Icon.names.editActive}
                        size={Icon.sizes.medium}
                      />
                    )}
                    <Icon
                      className="samtale-question__icon-hover"
                      name={Icon.hoverNames.hoverEdit}
                      size={Icon.sizes.medium}
                    />
                  </span>
                  <span className="samtale-question__pre-question-text">
                    {preQuestionText}
                  </span>
                  <span>
                    <h4
                      id={questionId}
                      className="samtale-question__question-text"
                      aria-expanded={inputIsActive}
                      role="button"
                      tabIndex="0"
                      onKeyDown={e => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          handleQuestionClick();
                        }
                      }}
                      onClick={handleQuestionClick}
                    >
                      {questionText}
                    </h4>
                    <span className="samtale-question__tooltip">
                      {tooltip && <Tooltip {...tooltip} />}
                    </span>
                  </span>
                </div>
              </span>
            )}
          </div>
        )}
      </div>
      {input && (
        <div className="samtale-question__input">
          <SamtaleInput
            inputIsActive={inputIsActive}
            questionId={questionId}
            questionText={questionText}
            onChange={handleOnInputChange}
            {...input}
          />
        </div>
      )}
    </div>
  );
};

SamtaleQuestion.propTypes = {
  blockId: PropTypes.string, // exclude from BE
  localReadOnly: PropTypes.string, // exclude from BE

  questionId: PropTypes.string,
  questionText: PropTypes.string,
  preQuestionText: PropTypes.string,
  input: PropTypes.exact(SamtaleInput.propTypes),
  tooltip: PropTypes.exact(Tooltip.propTypes)
};

export default SamtaleQuestion;
