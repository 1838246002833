import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import BaseLayout from './base-layout';
import NavMenu from '../nav-menu';
import Header from '../header';
import Footer from '../footer';
import Clicker from '../clicker/clicker';
import ProfileHeader from '../profile-header';
import { useChartContext } from '../../../source/contexts/chart-context';
import ChartContextProvider from '../../contexts/chart-context-provider';
import LoadingSpinner from '../loading-spinner';
import NoticeBar from 'components/notice-bar/notice-bar';

const NavigationLayout = ({
  children,
  navMenu,
  profileHeader,
  header,
  footer,
  noticeBarBlock,
  ...rest
}) => {
  const {
    completeAndFinishData,
    submitCompleteAndFinish,
    isLoading
  } = useChartContext();

  const completeButtonDisabled = useMemo(
    () => isLoading || completeAndFinishData?.disabled
  );

  return (
    <BaseLayout color={BaseLayout.colors.gray} {...rest}>
      <Header {...header} theme={Header.themes.dark} />
      <div className="navigation-layout">
        {profileHeader && (
          <ProfileHeader {...profileHeader} color={BaseLayout.colors.gray} />
        )}
        {noticeBarBlock &&
          noticeBarBlock.noticeBars &&
          noticeBarBlock.noticeBars.map((noticeBar, index) => (
            <NoticeBar
              key={index}
              onLinkClick={submitCompleteAndFinish}
              {...noticeBar}
            />
          ))}
        <div className="navigation-layout__content">
          <div className="navigation-layout__menu">
            <NavMenu {...navMenu} />
            {completeAndFinishData?.text && (
              <div className="navigation-layout__complete-button-wrapper">
                {isLoading && (
                  <div className="navigation-layout__complete-button-spinner">
                    <LoadingSpinner />
                  </div>
                )}
                <Clicker
                  className="navigation-layout__complete-button"
                  isDisabled={completeButtonDisabled}
                  theme={Clicker.themes.primary}
                  text={completeAndFinishData?.text || ''}
                  onClick={() =>
                    submitCompleteAndFinish().then(() => location.reload())
                  }
                />
              </div>
            )}
          </div>

          <main id="main-content" className="navigation-layout__main">
            {children}
          </main>
        </div>
      </div>
      <Footer {...footer} />
    </BaseLayout>
  );
};

const props = {
  children: PropTypes.node, //excluded from backend
  profileHeader: PropTypes.exact(ProfileHeader.propTypes),
  navMenu: PropTypes.exact(NavMenu.propTypes),
  finishAndCompleteButton: PropTypes.exact({
    text: PropTypes.string,
    endpoint: PropTypes.string,
    disabled: PropTypes.bool,
    isActive: PropTypes.bool,
    id: PropTypes.string
  }),
  header: PropTypes.exact(Header.propTypes),
  footer: PropTypes.exact(Footer.propTypes),
  noticeBarBlock: PropTypes.exact({
    noticeBars: PropTypes.arrayOf(PropTypes.exact(NoticeBar.propTypes))
  }), //This is not just an array because backend can't send an array to this component
  ...BaseLayout.propTypes
};

// eslint-disable-next-line react/no-multi-comp
const NavigationLayoutWithChartContext = props => {
  return (
    <ChartContextProvider {...props}>
      <NavigationLayout {...props} />
    </ChartContextProvider>
  );
};

NavigationLayout.propTypes = props;
NavigationLayoutWithChartContext.propTypes = props;

export default NavigationLayoutWithChartContext;
