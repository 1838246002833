import React from 'react';
import PropTypes from 'prop-types';

import NavigationLayout from '../layouts/navigation-layout';
import SamtaleChapter from 'components/samtale-chapter/samtale-chapter';

const SamtaleChapterPage = ({ layout, samtaleChapter }) => {
  return (
    <NavigationLayout {...layout}>
      <SamtaleChapter {...samtaleChapter} />
    </NavigationLayout>
  );
};

SamtaleChapterPage.propTypes = {
  layout: PropTypes.exact(NavigationLayout.propTypes),
  samtaleChapter: PropTypes.exact(SamtaleChapter.propTypes)
};

export default SamtaleChapterPage;
