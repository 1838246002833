import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'components/accordion/accordion';

const FrequentlyAskedQuestions = ({ title, lead, questions, htmlInfo }) => {
  return (
    <div className="frequently-asked-questions">
      {title && <h1 className="frequently-asked-questions__title">{title}</h1>}
      {lead && <div 
        className="frequently-asked-questions__lead" 
        dangerouslySetInnerHTML={{__html: lead}}
      />}
      {questions &&
        questions.map(({ title, htmlAnswer }) => (
          <Accordion title={title}>
            <div
              className="frequently-asked-questions__answer"
              dangerouslySetInnerHTML={{ __html: htmlAnswer }}
            />
          </Accordion>
        ))}
      {htmlInfo && (
        <div
          className="frequently-asked-questions__extra-info"
          dangerouslySetInnerHTML={{ __html: htmlInfo }}
        />
      )}
    </div>
  );
};

FrequentlyAskedQuestions.propTypes = {
  title: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  questions: {
    title: PropTypes.string,
    htmlAnswer: PropTypes.string
  },
  htmlInfo: PropTypes.string
};

export default FrequentlyAskedQuestions;
