import React from 'react';
import PropTypes from 'prop-types';
import Header from '../header';
import Footer from '../footer';

const StructuralLayout = ({ children, header, footer }) => {
  return (
    <div className="structural-layout">
      <Header {...header} theme={Header.themes.dark} />
      {children}
      <Footer {...footer} />
    </div>
  );
};

StructuralLayout.propTypes = {
  children: PropTypes.node, //excluded from backend
  header: PropTypes.exact(Header.propTypes),
  footer: PropTypes.exact(Footer.propTypes)
};

export default StructuralLayout;
