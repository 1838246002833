import React from 'react';
import PropTypes from 'prop-types';

import SamtaleInputSimple from './samtale-input-simple';
import SamtaleInputMulti from './samtale-input-multi';
import SamtaleInputCheckbox from './samtale-input-checkbox';
import SamtaleInputSelect from './samtale-input-select';

const inputTypes = {
  simple: 'simple',
  multi: 'multi',
  checkbox: 'checkbox',
  select: 'select'
};

const SamtaleInput = ({
  inputIsActive,
  questionId,
  questionText,
  onChange,
  type,
  inputSimple,
  inputMulti,
  inputCheckbox,
  inputSelect,
  shouldPdfHaveInputs
}) => {
  let content;
  switch (type) {
    case inputTypes.simple: {
      content = (
        <SamtaleInputSimple
          inputIsActive={shouldPdfHaveInputs || inputIsActive}
          questionId={questionId}
          onChange={onChange}
          {...inputSimple}
        />
      );
      break;
    }
    case inputTypes.multi: {
      content = (
        <SamtaleInputMulti
          inputIsActive={shouldPdfHaveInputs || inputIsActive}
          questionId={questionId}
          onChange={onChange}
          {...inputMulti}
        />
      );
      break;
    }
    case inputTypes.checkbox: {
      content = (
        <SamtaleInputCheckbox
          inputIsActive={shouldPdfHaveInputs || inputIsActive}
          questionId={questionId}
          onChange={onChange}
          {...inputCheckbox}
        />
      );
      break;
    }
    case inputTypes.select: {
      content = (
        <SamtaleInputSelect
          inputIsActive={shouldPdfHaveInputs || inputIsActive}
          questionId={questionId}
          onChange={onChange}
          {...inputSelect}
        />
      );
      break;
    }
    default: {
      content = null;
    }
  }

  const Wrapper =
    type === inputTypes.multi || type === inputTypes.checkbox
      ? 'fieldset'
      : 'div';
  return (
    <Wrapper className="samtale-input">
      {(type === inputTypes.multi || type === inputTypes.checkbox) && (
        <legend className="samtale-input__legend">{questionText}</legend>
      )}
      {content}
    </Wrapper>
  );
};

SamtaleInput.inputTypes = inputTypes;

SamtaleInput.propTypes = {
  inputIsActive: PropTypes.bool, // exclude from BE
  onChange: PropTypes.func, // exclude from BE
  questionId: PropTypes.string, // exclude from BE
  questionText: PropTypes.string, // exclude from BE
  type: PropTypes.oneOf(Object.values(inputTypes)),
  inputSimple: PropTypes.shape(SamtaleInputSimple.propTypes),
  inputMulti: PropTypes.shape(SamtaleInputMulti.propTypes),
  inputCheckbox: PropTypes.shape(SamtaleInputCheckbox.propTypes),
  inputSelect: PropTypes.shape(SamtaleInputSelect.propTypes),
  shouldPdfHaveInputs: PropTypes.bool
};

SamtaleInput.defaultProps = {
  setSaveData: () => {}
};

export default SamtaleInput;
