import PropTypes from 'prop-types';

import Clicker from 'components/clicker/clicker';
import GridCardIllustration from 'components/grid-card-illustration/grid-card-illustration';
import Tooltip from 'components/tooltip/tooltip';

const ElementPropShape = () => {};

ElementPropShape.propTypes = {
  id: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.exact({
      text: PropTypes.string,
      isList: PropTypes.bool,
      arstrinnSortOrder: PropTypes.string,
      smallCardHeader: PropTypes.string
    })
  ),
  link: PropTypes.exact(Clicker.propTypes),
  illustration: PropTypes.exact(GridCardIllustration.propTypes),
  closeDropdownText: PropTypes.string,

  studentBelongsToTeacher: PropTypes.bool,
  studentIsRequestedFromTeacher: PropTypes.bool,
  reviewed: PropTypes.bool,
  proposedDeleted: PropTypes.bool,
  requestedMoved: PropTypes.bool,

  infoTooltip: PropTypes.exact(Tooltip.propTypes),
  warningTooltip: PropTypes.exact(Tooltip.propTypes),
  isCurrentUser: PropTypes.bool,

  //FE
  menuItems: PropTypes.arrayOf(PropTypes.exact(Clicker.propTypes))
};

export default ElementPropShape;
