import React from 'react';
import PropTypes from 'prop-types';
import Clicker from 'components/clicker/clicker';
import cn from 'classnames';

const ClickerPrevNext = ({ linkPrev, linkNext }) => {
  return (
    <div
      className={cn('clicker-prev-next', {
        'clicker-prev-next--align-right': !linkPrev
      })}
    >
      {linkPrev && (
        <Clicker
          {...linkPrev}
          iconName={Clicker.iconNames.arrowLeft}
          iconIsBeforeText={true}
          iconSize={Clicker.iconSizes.medium}
          theme={Clicker.themes.navigation}
        />
      )}
      {linkNext && (
        <Clicker
          {...linkNext}
          iconName={Clicker.iconNames.arrowRight}
          iconSize={Clicker.iconSizes.medium}
          theme={Clicker.themes.navigation}
        />
      )}
    </div>
  );
};

ClickerPrevNext.propTypes = {
  linkPrev: PropTypes.exact(Clicker.link),
  linkNext: PropTypes.exact(Clicker.link)
};

export default ClickerPrevNext;
