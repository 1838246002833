import cn from 'classnames';
import Clicker from 'components/clicker/clicker';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const RedaktorNode = ({
  singleTopNode,
  type,
  versionId,
  isFirstInList,
  startOpen,
  breadcrumbText,
  node,
  currentData,
  setApiErrMessage,
  isDirty,
  setCurrentData,
  setFoundStoredData,
  foundStoredData
}) => {
  const { id, title, subtitle, nodes } = node;
  const [isOpen, setIsOpen] = useState(false);

  React.useEffect(() => {
    const urlHashLocation = sessionStorage.getItem('breadcrumb');
    if (urlHashLocation) {
      const storedBreadcrumbData = JSON.parse(urlHashLocation);
      const formattedTitle = title?.replace(/ /g, '');
      if (
        storedBreadcrumbData.type === type &&
        storedBreadcrumbData.versionId === versionId &&
        urlHashLocation?.includes(formattedTitle)
      ) {
        setIsOpen(true);

        if (storedBreadcrumbData.id === id) {
          setCurrentData({
            ...node,
            breadcrumbText
          });
          setFoundStoredData(true);
          return;
        }
      }
    }
    if (!foundStoredData && isFirstInList) {
      setCurrentData({
        ...node,
        breadcrumbText
      });
    }
    if (startOpen) {
      setIsOpen(true);
    }
  }, [isFirstInList, startOpen, title]);

  const handleClick = () => {
    if (isDirty) {
      setApiErrMessage({
        globalMessage: 'Lagre eller tilbakestill før du går videre'
      });
      return;
    }

    const persistBreadcrumb = JSON.stringify({
      id,
      type,
      versionId,
      breadcrumbText: breadcrumbText.replace(/ /g, '')
    });

    sessionStorage.setItem('breadcrumb', persistBreadcrumb);
    setCurrentData({
      ...node,
      breadcrumbText
    });
    if (!singleTopNode) {
      setIsOpen(!isOpen);
    }
  };
  return (
    <li
      className={cn(
        'redaktor-grensesnitt',
        'redaktor-grensesnitt__node-item-wrapper'
      )}
    >
      <div
        onClick={handleClick}
        className={cn('redaktor-grensesnitt__node-item', {
          'redaktor-grensesnitt__node-item--current': currentData?.id === id
        })}
      >
        <Clicker
          iconIsBeforeText
          onClick={handleClick}
          text={title}
          alt={`${title} ${subtitle ? ' - ' + subtitle : ''}`}
          iconSize={Clicker.iconSizes.small}
          iconName={
            nodes?.length && !singleTopNode
              ? isOpen
                ? Clicker.iconNames.arrowUp
                : Clicker.iconNames.arrowDown
              : ''
          }
          aria-expanded={isOpen}
          hasGhostIcon={!(nodes?.length && !singleTopNode)}
        />
      </div>
      {isOpen && nodes && !!nodes.length && (
        <ul>
          {nodes.map((_node, i) => (
            <RedaktorNode
              setFoundStoredData={setFoundStoredData}
              foundStoredData={foundStoredData}
              type={type}
              key={i + _node.id}
              versionId={versionId}
              node={_node}
              breadcrumbText={`${breadcrumbText} > ${_node.title}`}
              currentData={currentData}
              isDirty={isDirty}
              setApiErrMessage={setApiErrMessage}
              setCurrentData={setCurrentData}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default RedaktorNode;

// This reflects what frontend can handle on each node in the node list
RedaktorNode.RecursiveNodeProps = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  dataEndpoint: PropTypes.string
  // Props under here are for the intro page
};

RedaktorNode.propTypes = {
  node: PropTypes.shape(RedaktorNode.RecursiveNodeProps),
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      ...RedaktorNode.RecursiveNodeProps,
      nodes: PropTypes.any
    })
  ),
  type: PropTypes.string,
  // Props outside the recursive structure.
  versionId: PropTypes.string,
  singleTopNode: PropTypes.bool, // ignore backend
  isFirstInList: PropTypes.bool, // ignore backend
  startOpen: PropTypes.bool, // ignore backend
  isDirty: PropTypes.bool, // ignore backend
  currentData: PropTypes.shape(RedaktorNode.RecursiveNodeProps), // ignore backend
  setCurrentData: PropTypes.func, // ignore backend
  setApiErrMessage: PropTypes.func, // ignore backend
  breadcrumbText: PropTypes.string, // ignore backend
  setFoundStoredData: PropTypes.func, // ignore backend
  foundStoredData: PropTypes.bool // ignore backend
};

RedaktorNode.defaultProps = {
  setData: () => {}
};
