import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import colors from '../topic-colors';

const ProgressBar = ({ percentage, title, link, color }) => {
  return (
    <div className="progress-bar">
      {title && <h3 className="progress-bar__title">{title} </h3>}
      <div className="progress-bar__bar">
        <div className="progress-bar__background">
          <span className="progress-bar__hidden">{`${percentage}%`}</span>
          <div
            className="progress-bar__progress"
            style={{ width: `${percentage}%`, backgroundColor: colors[color] }}
          />
        </div>
        {link && link.href && (
          <a
            className={cn('progress-bar__link', {
              'progress-bar__link--disabled': !link?.isActive
            })}
            href={link?.isActive ? link.href : undefined}
          >
            {link.text}
          </a>
        )}
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  link: PropTypes.exact({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    isActive: PropTypes.bool
  }).isRequired,
  title: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(colors)).isRequired,
  percentage: PropTypes.number
};

ProgressBar.defaultProps = {
  percentage: 0
};

ProgressBar.colors = colors;

export default ProgressBar;
