import React from 'react';
import PropTypes from 'prop-types';
import MainLayout from 'components/main-layout/main-layout';
import StructuralLayout from 'components/structural-layout/structural-layout';
import Arkiv from 'components/arkiv/arkiv';
const ArkivPage = ({ structuralLayout, mainLayout, arkivContent }) => {
  return (
    <StructuralLayout {...structuralLayout}>
      <MainLayout {...mainLayout}>
        <Arkiv {...arkivContent} />
      </MainLayout>
    </StructuralLayout>
  );
};
ArkivPage.propTypes = {
  structuralLayout: PropTypes.exact(StructuralLayout.propTypes),
  mainLayout: PropTypes.exact(MainLayout.propTypes),
  arkivContent: PropTypes.exact(Arkiv.propTypes)
};
export default ArkivPage;