import React from 'react';
import PropTypes from 'prop-types';

import Clicker from '../clicker';
import Icon from 'components/icon';

const Footer = ({
  disclaimer,
  feedback,
  feedbackEmail,
  siteLinks,
  externalLink,
  htmlAbout
}) => {
  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__logo">
          <span
            aria-label="Udir logo"
            role="img"
            className="footer__udir-svg"
          />
        </div>
        <div className="footer__links">
          <p className="footer__disclaimer">{disclaimer}</p>

          <ul className="footer__list">
            {htmlAbout && (
              <li
                className="footer__html"
                dangerouslySetInnerHTML={{ __html: htmlAbout }}
              />
            )}
            {siteLinks.map((link, index) => {
              return (
                <li key={index} className="footer__item">
                  <Clicker
                    {...link}
                    theme={Clicker.themes.transparentUnderline}
                  />
                </li>
              );
            })}
          </ul>
          {feedback && feedbackEmail && (
            <p className="footer__feedback">
              {feedback}{' '}
              <Clicker
                text={feedbackEmail}
                href={`mailto:${feedbackEmail}`}
                theme={Clicker.themes.transparentUnderline}
              />
            </p>
          )}
        </div>
        <div className="footer__actions">
          <Clicker
            {...externalLink}
            theme={Clicker.themes.submit}
            iconName={Icon.names.linkOut}
            iconSize={Clicker.iconSizes.medium}
          />
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  disclaimer: PropTypes.string,
  feedback: PropTypes.string,
  feedbackEmail: PropTypes.string,
  htmlAbout: PropTypes.string,
  siteLinks: PropTypes.arrayOf(PropTypes.exact(Clicker.propTypes)),
  externalLink: PropTypes.exact(Clicker.propTypes)
};

Footer.defaultProps = {
  siteLinks: []
};

export default Footer;
