import React from 'react';
import PropTypes from 'prop-types';
import CheckboxInput from 'components/checkbox-input/checkbox-input';
import cn from 'classnames';

const CheckboxInputGroup = ({
  title,
  isDisabled,
  group,
  onChange,
  ...rest
}) => {
  return group?.length > 0 ? (
    <fieldset className="checkbox-input-group">
      <legend className="checkbox-input-group__title">{title}</legend>
      {group.map(checkboxItem => (
        <CheckboxInput
          {...rest}
          {...checkboxItem}
          onChange={onChange}
          key={checkboxItem.id}
          isDisabled={isDisabled || rest?.editorMode}
        />
      ))}
    </fieldset>
  ) : null;
};

CheckboxInputGroup.propTypes = {
  title: PropTypes.string,
  isDisabled: PropTypes.bool,
  group: PropTypes.arrayOf(PropTypes.shape(CheckboxInput.inputProps))
    .isRequired,
  onChange: PropTypes.func // ignore backend
};

CheckboxInputGroup.defaultProps = {
  onChange: () => {}
};

export default CheckboxInputGroup;
