import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import useClickOutside from '../../hooks/use-click-outside';

import BaseTable from 'components/base-table/base-table';
import BaseGrid from 'components/base-grid/base-grid';
import Clicker from 'components/clicker/clicker';
import DropdownBubble from 'components/dropdown-bubble';
import Checkbox from 'components/checkbox/checkbox';

const formats = Object.freeze({
  grid: 'grid',
  table: 'table'
});

const directions = Object.freeze({
  ascending: 'asc',
  descending: 'desc'
});

const GridTableWrapper = ({
  addActions,
  filterAction,
  sortAction,
  columnHeaders,
  elements,
  showMenus,
  elementMenuDropdown,
  missingElementsHeader,
  missingElementsText,
  filteredListEmptyText
}) => {
  const [currentFormat, setCurrentFormat] = useState(formats.grid);
  const [sorterMenuIsOpen, setSorterMenuIsOpen] = useState(false);
  const [filterMenuIsOpen, setFilterMenuIsOpen] = useState(false);

  const [currentElements, setCurrentElements] = useState(elements);
  const [originalElementsLength, setOriginalElementsLength] = useState(
    elements ? elements.length : 0
  );
  const [activeSortKey, setActiveSortKey] = useState({
    key: sortAction ? sortAction.sortAlternatives[0].sortKey : '',
    dir: directions.ascending
  });
  const [filterOptions, setFilterOptions] = useState(
    filterAction && filterAction.filterAlternatives
  );

  const filterBubbleRef = useRef(null);
  const sortBubbleRef = useRef(null);
  useClickOutside(filterBubbleRef, () => setFilterMenuIsOpen(false));
  useClickOutside(sortBubbleRef, () => setSorterMenuIsOpen(false));

  const filterAndSortList = () => {
    // Filter
    let filteredElements = elements;
    if (filterOptions) {
      filteredElements = filterOptions.reduce((accumulator, currentFilter) => {
        const isAnyFilterChosen = currentFilter.groupAlternatives.some(
          option => option.isChosen
        );

        if (!isAnyFilterChosen) return accumulator;

        const targetIndex = columnHeaders.findIndex(
          element => element.id === currentFilter.groupKey
        );

        if (targetIndex === -1) {
          return accumulator.filter(element => {
            const targetProp = currentFilter.groupKey;

            const filteredElements = currentFilter.groupAlternatives
              .filter(option => option.isChosen)
              .map(option => option.targetValue)
              .some(value => element[targetProp] === value);

            return filteredElements;
          });
        }

        return accumulator.filter(element => {
          return currentFilter.groupAlternatives
            .filter(option => option.isChosen)
            .map(option => option.text)
            .some(value => {
              const listFromCsv = element.columns[targetIndex].text.split(', ');
              return listFromCsv.includes(value);
            });
        });
      }, elements);
    }

    // Sort
    const targetIndex = columnHeaders.findIndex(
      element => element.id === activeSortKey.key
    );

    if (targetIndex < 0) {
      setCurrentElements(filteredElements);
    } else {
      const filteredAndSortedElements = [...filteredElements].sort((a, b) => {
        let valueA, valueB;

        if (
          a.columns[targetIndex].arstrinnSortOrder &&
          b.columns[targetIndex].arstrinnSortOrder
        ) {
          valueA = a.columns[targetIndex].arstrinnSortOrder;
          valueB = b.columns[targetIndex].arstrinnSortOrder;
        } else {
          valueA = a.columns[targetIndex].text
            ? a.columns[targetIndex].text
            : '';
          valueB = b.columns[targetIndex].text
            ? b.columns[targetIndex].text
            : '';
        }

        return activeSortKey.dir === directions.descending
          ? valueB.localeCompare(valueA, undefined, { sensitivity: 'base' })
          : valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
      });

      setCurrentElements(filteredAndSortedElements);
    }
  };

  const handleSortKeyChange = sortKey => {
    if (sortKey === activeSortKey.key) {
      setActiveSortKey(prev => ({
        ...prev,
        dir:
          activeSortKey.dir === directions.descending
            ? directions.ascending
            : directions.descending
      }));
    } else {
      setActiveSortKey({ key: sortKey, dir: directions.descending });
    }

    filterAndSortList();
  };

  const handleFilterCheckboxChange = id => {
    setFilterOptions(prevFilters =>
      [...prevFilters].map(group => ({
        ...group,
        groupAlternatives: group.groupAlternatives.map(alt =>
          alt.id === id ? { ...alt, isChosen: !alt.isChosen } : alt
        )
      }))
    );

    filterAndSortList();
  };

  const resetFilters = () => {
    if (filterOptions) {
      setFilterOptions(prevFilters =>
        prevFilters.map(group => ({
          ...group,
          groupAlternatives: group.groupAlternatives.map(alt => ({
            ...alt,
            isChosen: false
          }))
        }))
      );
    }
  };

  const getNumFiltersChosenText = () => {
    if (!filterOptions) return '';

    const numFiltersChosen = filterOptions.reduce((acc1, group) => {
      return acc1 + group.groupAlternatives.filter(alt => alt.isChosen).length;
    }, 0);

    if (numFiltersChosen) {
      return ` (${numFiltersChosen})`;
    }
    return '';
  };

  useEffect(() => {
    if (elements && !!elements.length) {
      setCurrentElements(elements);

      if (originalElementsLength !== elements.length) {
        resetFilters();
      } else {
        filterAndSortList();
      }

      setOriginalElementsLength(elements.length);
    }
  }, [elements]);

  useEffect(() => {
    filterAndSortList();
  }, [filterOptions, activeSortKey]);

  return (
    <div className="grid-table-wrapper">
      <div
        className={cn('grid-table-wrapper__actions-wrapper', {
          'grid-table-wrapper__actions-wrapper--multiple-elements':
            addActions && !!addActions.length
        })}
      >
        {addActions && !!addActions.length && (
          <ul className="grid-table-wrapper__add-actions">
            {addActions.map(addAction => (
              <li>
                <Clicker
                  iconName={Clicker.iconNames.plusCircle}
                  iconIsBeforeText
                  {...addAction}
                  className="grid-table-wrapper__add-button"
                />
              </li>
            ))}
          </ul>
        )}
        {elements && !!elements.length && (
          <ul className="grid-table-wrapper__action-list">
            {sortAction && (
              <li
                ref={sortBubbleRef}
                className="grid-table-wrapper__action-list-item"
                onKeyDown={e =>
                  e.key === 'Escape' && setSorterMenuIsOpen(false)
                }
              >
                <Clicker
                  iconName={Clicker.iconNames.arrowUpAndDown}
                  activeIconName={Clicker.iconNames.arrowUpAndDownFilledBlue}
                  aria-expanded={sorterMenuIsOpen}
                  iconIsBeforeText={true}
                  theme={Clicker.themes.hoverBlueNoUnderline}
                  text={sortAction.buttonText}
                  onClick={() => setSorterMenuIsOpen(prev => !prev)}
                  className="grid-table-wrapper__action-button"
                  isActive={sorterMenuIsOpen}
                />
                <DropdownBubble
                  className="grid-table-wrapper__menu-bubble"
                  isOpen={sorterMenuIsOpen}
                  rightMargin="-165px"
                >
                  <div className="grid-table-wrapper__dropdown-content">
                    <Clicker
                      text={sortAction.closeDropdownButtonLabel}
                      textIsHidden={true}
                      iconName={Clicker.iconNames.closeCircleBlack}
                      onClick={() => setSorterMenuIsOpen(false)}
                      className="grid-table-wrapper__dropdown-close-button"
                    />
                    {sortAction.sortAlternatives &&
                      !!sortAction.sortAlternatives.length && (
                        <ul className="grid-table-wrapper__dropdown-list">
                          {sortAction.sortAlternatives.map(
                            ({ sortKey, title }) => (
                              <li
                                key={sortKey}
                                className="grid-table-wrapper__dropdown-sort-list-item"
                              >
                                <Clicker
                                  onClick={() => handleSortKeyChange(sortKey)}
                                  iconName={
                                    activeSortKey.key === sortKey
                                      ? activeSortKey.dir ===
                                        directions.ascending
                                        ? Clicker.iconNames
                                            .arrowUpFilledAndDownHollow
                                        : Clicker.iconNames
                                            .arrowUpHollowAndDownFilled
                                      : Clicker.iconNames.arrowUpAndDown
                                  }
                                  iconIsBeforeText={true}
                                  text={title}
                                  theme={Clicker.themes.sortOption}
                                />
                              </li>
                            )
                          )}
                        </ul>
                      )}
                  </div>
                </DropdownBubble>
              </li>
            )}
            {filterAction && (
              <li
                ref={filterBubbleRef}
                className="grid-table-wrapper__action-list-item"
                onKeyDown={e =>
                  e.key === 'Escape' && setFilterMenuIsOpen(false)
                }
              >
                <Clicker
                  iconName={Clicker.iconNames.linesWithCircles}
                  activeIconName={Clicker.iconNames.linesWithCirclesFilledBlue}
                  aria-expanded={filterMenuIsOpen}
                  iconIsBeforeText={true}
                  theme={Clicker.themes.hoverBlueNoUnderline}
                  text={filterAction.buttonText + getNumFiltersChosenText()}
                  onClick={() => setFilterMenuIsOpen(prev => !prev)}
                  className="grid-table-wrapper__action-button"
                  isActive={filterMenuIsOpen}
                />
                <DropdownBubble
                  className="grid-table-wrapper__menu-bubble"
                  isOpen={filterMenuIsOpen}
                  rightMargin="-75px"
                >
                  <div className="grid-table-wrapper__dropdown-content">
                    <Clicker
                      text={filterAction.closeDropdownButtonLabel}
                      textIsHidden={true}
                      iconName={Clicker.iconNames.closeCircleBlack}
                      onClick={() => setFilterMenuIsOpen(false)}
                      className="grid-table-wrapper__dropdown-close-button"
                    />
                    {filterAction.resetButtonText && (
                      <Clicker
                        onClick={() => resetFilters()}
                        text={filterAction.resetButtonText}
                        theme={Clicker.themes.underlinedThin}
                        className="grid-table-wrapper__dropdown-reset-button"
                      />
                    )}
                    {filterOptions && !!filterOptions.length && (
                      <ul className="grid-table-wrapper__dropdown-list">
                        {filterOptions.map(
                          ({ groupKey, groupTitle, groupAlternatives }) => (
                            <li
                              key={groupKey}
                              className="grid-table-wrapper__dropdown-list-item"
                            >
                              <h2 className="grid-table-wrapper__dropdown-header">
                                {groupTitle}
                              </h2>
                              {groupAlternatives && !!groupAlternatives.length && (
                                <ul>
                                  {groupAlternatives.map(
                                    ({ id, text, isChosen }, index) => (
                                      <li
                                        key={index}
                                        className="grid-table-wrapper__dropdown-list-item-inner"
                                      >
                                        <Checkbox
                                          id={id}
                                          onChange={() =>
                                            handleFilterCheckboxChange(id)
                                          }
                                          checked={isChosen}
                                          label={text}
                                        />
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </div>
                </DropdownBubble>
              </li>
            )}
            <li className="grid-table-wrapper__action-list-item">
              <Clicker
                iconName={
                  currentFormat === formats.grid
                    ? Clicker.iconNames.squareList
                    : Clicker.iconNames.fourSquares
                }
                onClick={() =>
                  setCurrentFormat(prev =>
                    prev === formats.grid ? formats.table : formats.grid
                  )
                }
                theme={Clicker.themes.blueBgHover}
                className="grid-table-wrapper__action-button"
                text={currentFormat === formats.grid ? 'Liste' : 'Kort'}
                iconIsBeforeText={true}
              />
            </li>
          </ul>
        )}
      </div>
      <div className="grid-table-wrapper__content-wrapper">
        {elements && elements.length ? (
          <>
            {currentFormat === formats.table && (
              <BaseTable
                columnHeaders={columnHeaders}
                elements={currentElements}
                showMenus={showMenus}
                elementMenuDropdown={elementMenuDropdown}
                filteredListEmptyText={filteredListEmptyText}
              />
            )}
            {currentFormat === formats.grid && (
              <BaseGrid
                columnHeaders={columnHeaders}
                elements={currentElements}
                showButtons={showMenus}
                filteredListEmptyText={filteredListEmptyText}
              />
            )}
          </>
        ) : (
          <div>
            {missingElementsHeader && (
              <h2 className="grid-table-wrapper__missing-elements-header">
                {missingElementsHeader}
              </h2>
            )}
            {missingElementsText && (
              <p className="grid-table-wrapper__missing-elements-text">
                {missingElementsText}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

GridTableWrapper.propTypes = {
  addActions: PropTypes.arrayOf(PropTypes.exact(Clicker.propTypes)),
  sortAction: PropTypes.exact({
    buttonText: PropTypes.string,
    closeDropdownButtonLabel: PropTypes.string,
    sortAlternatives: PropTypes.arrayOf(
      PropTypes.exact({
        sortKey: PropTypes.string,
        title: PropTypes.string
      })
    )
  }),
  filterAction: PropTypes.exact({
    buttonText: PropTypes.string,
    closeDropdownButtonLabel: PropTypes.string,
    resetButtonText: PropTypes.string,
    filterAlternatives: PropTypes.arrayOf(
      PropTypes.exact({
        groupKey: PropTypes.string,
        groupTitle: PropTypes.string,
        groupAlternatives: PropTypes.arrayOf(
          PropTypes.exact({
            id: PropTypes.string,
            targetValue: PropTypes.oneOfType([
              PropTypes.bool,
              PropTypes.string
            ]),
            isChosen: PropTypes.bool,
            text: PropTypes.string
          })
        )
      })
    )
  }),
  columnHeaders: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      text: PropTypes.string
    })
  ),
  elements: PropTypes.arrayOf(
    PropTypes.exact(GridTableWrapper.elementPropShape)
  ),
  showMenus: PropTypes.bool,
  elementMenuDropdown: PropTypes.shape(BaseTable.propTypes.elementMenuDropdown),
  missingElementsHeader: PropTypes.string,
  missingElementsText: PropTypes.string
};

GridTableWrapper.defaultProps = {
  showMenus: true
};

export default GridTableWrapper;
