import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import SamtaleContext from '../../contexts/samtale-context';

const SamtaleInputCheckbox = ({
  inputIsActive,
  questionId,
  onChange,
  alternatives,
  noLanguagesText
}) => {
  const [currentAlternatives, setCurrentAlternatives] = useState(alternatives);
  const [knownLanguagesIsMounted, setKnownLanguagesIsMounted] = useState(false);

  const { knownLanguages } = useContext(SamtaleContext);

  const handleOnChange = (e, id) => {
    let newValue = e.target.checked;

    let newAlternatives = currentAlternatives.map(alternative => {
      if (alternative.id === id) {
        return { ...alternative, isChecked: newValue };
      }
      return alternative;
    });

    setCurrentAlternatives(newAlternatives);

    const dataToSave = newAlternatives
      .filter(alternative => alternative.isChecked)
      .map(alternative => {
        return { answerId: alternative.id, answerValue: alternative.id };
      });

    onChange(dataToSave);
  };

  useEffect(() => {
    if (!knownLanguages.length && !knownLanguagesIsMounted) {
      setKnownLanguagesIsMounted(true);
      return;
    }

    if (!currentAlternatives) return;

    let newAlternatives = knownLanguages.map(language => {
      const existingAlternative = currentAlternatives.find(
        alternative => alternative.id === language
      );
      if (existingAlternative) {
        return existingAlternative;
      }
      return {
        id: language,
        label: language,
        isChecked: false
      };
    });

    setCurrentAlternatives(newAlternatives);
  }, [knownLanguages]);

  return (
    <div className="samtale-input__checkbox">
      <div
        className={cn('samtale-input__checkbox-inputs', {
          'samtale-input__checkbox-inputs--visible': inputIsActive
        })}
      >
        {currentAlternatives &&
          (currentAlternatives.length > 0 ? (
            currentAlternatives
              .filter(alternative => knownLanguages.includes(alternative.id))
              .map(({ id, label, isChecked }) => (
                <div className="samtale-input__checkbox-wrapper" key={id}>
                  <input
                    id={'checkbox' + id}
                    type="checkbox"
                    className="samtale-input__checkbox-input"
                    aria-describedby={questionId}
                    checked={isChecked}
                    onChange={e => handleOnChange(e, id)}
                  />
                  <label
                    className="samtale-input__checkbox-label"
                    htmlFor={'checkbox' + id}
                  >
                    <span>{label}</span>
                  </label>
                </div>
              ))
          ) : (
            <p>{noLanguagesText}</p>
          ))}
      </div>
      <div
        className={cn('samtale-input__checkbox-preview', {
          'samtale-input__checkbox-preview--visible': !inputIsActive,
          'samtale-input__checkbox-preview--not-empty':
            currentAlternatives &&
            !!currentAlternatives.filter(val => val.isChecked === true).length
        })}
      >
        {currentAlternatives &&
          !!currentAlternatives.filter(
            alternative => alternative.isChecked === true
          ).length && (
            <ul>
              {currentAlternatives
                .filter(alternative => knownLanguages.includes(alternative.id))
                .filter(alternative => alternative.isChecked === true)
                .map(alternative => (
                  <li
                    key={alternative.id}
                    className="samtale-input__checkbox-preview-item"
                  >
                    {alternative.label}
                  </li>
                ))}
            </ul>
          )}
      </div>
      <p
        className={cn('samtale-input__pdf-inputs', {
          'samtale-input__pdf-inputs--visible': inputIsActive
        })}
      >
        {currentAlternatives
          .filter(alternative => alternative.isChecked === true)
          .map(alternative => alternative.label)
          .join(', ')}
      </p>
    </div>
  );
};

SamtaleInputCheckbox.propTypes = {
  inputIsActive: PropTypes.bool, // exclude from BE
  onChange: PropTypes.func, // exclude from BE
  questionId: PropTypes.string, // exclude from BE
  alternatives: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      label: PropTypes.string,
      isChecked: PropTypes.bool
    })
  ),
  noLanguagesText: PropTypes.string
};

export default SamtaleInputCheckbox;
