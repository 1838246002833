import React, { useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../../js/api-helper';

import Modal from '../modal';
import Clicker from '../../clicker';
import LoadingSpinner from '../../loading-spinner';

import School from 'components/school/school';
import CheckboxInput from 'components/checkbox-input/checkbox-input';
import SearchWithDropdown from 'components/search-with-dropdown/search-with-dropdown';

const MoveStudentModal = ({
  isOpen,
  onClose,
  onConfirm,
  isDisabled,
  search,
  closeModalHiddenText,
  title,
  lead,
  confirmButtonText,
  cancelButtonText,
  closeButtonText,
  studentName,
  studentInfoLabel,
  studentInfo,
  studentId,
  searchDescriptionText,
  selectedSchoolDescriptionText,
  acceptCheckboxText,
  moveStudentEndPoint
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [school, setSchool] = useState();

  const [confirmed, setConfirmed] = useState('');
  const [accepted, setAccepted] = useState(false);

  const handleOnConfirm = async () => {
    setIsLoading(true);
    try {
      let { confirmText } = await api.execute(moveStudentEndPoint, {
        studentId,
        newOrgId: school.orgNr
      });

      setConfirmed(confirmText);

      onConfirm(studentId);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleOnSchoolChoice = item => setSchool(item);

  return (
    <Modal
      themes={[Modal.themes.white]}
      isOpen={isOpen}
      onClose={onClose}
      isDisabled={isDisabled || isLoading}
      closeButtonLabel={closeModalHiddenText}
    >
      <div className="move-student-modal">
        <div className="move-student-modal__content">
          {title && <h2 className="move-student-modal__title">{title}</h2>}
          {confirmed ? (
            <p className="move-student-modal__lead">{confirmed}</p>
          ) : (
            lead && <p className="move-student-modal__lead">{lead}</p>
          )}
          {!confirmed && (
            <>
              <div className="move-student-modal__student">
                <div className="move-student-modal__student-wrapper">
                  <div className="move-student-modal__student-name">
                    {studentName}
                  </div>
                  <div className="move-student-modal__student-info">
                    {studentInfoLabel + ' ' + (studentInfo || '')}
                  </div>
                </div>
              </div>
              <div className="move-student-modal__row">
                <span>{searchDescriptionText}</span>
                <SearchWithDropdown
                  {...search}
                  onChange={handleOnSchoolChoice}
                  RenderedResultElement={School}
                />
              </div>

              {school && (
                <>
                  <div className="move-student-modal__selected-box">
                    <div className="move-student-modal__selected-label">
                      {selectedSchoolDescriptionText}
                    </div>
                    <School {...school} />
                  </div>
                  <div className="move-student-modal__accept-box">
                    <CheckboxInput
                      id="accept-move-student"
                      onChange={(_id, checked) => setAccepted(checked)}
                      label={acceptCheckboxText}
                    />
                  </div>
                </>
              )}
            </>
          )}

          <div className="move-student-modal__actions">
            {confirmed ? (
              <div className="move-student-modal__action">
                <Clicker
                  text={closeButtonText}
                  theme={Clicker.themes.primary}
                  onClick={onClose}
                  isDisabled={false}
                />
              </div>
            ) : (
              <>
                <div className="move-student-modal__action">
                  <Clicker
                    text={cancelButtonText}
                    theme={Clicker.themes.secondary}
                    onClick={onClose}
                  />
                </div>
                <div className="move-student-modal__action">
                  <Clicker
                    text={confirmButtonText}
                    theme={Clicker.themes.primary}
                    onClick={handleOnConfirm}
                    isDisabled={!school || isLoading || !accepted}
                  />
                </div>
              </>
            )}
            {isLoading && (
              <div className="move-student-modal__action">
                <LoadingSpinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

MoveStudentModal.propTypes = {
  isDisabled: PropTypes.bool, //exclude from backend
  onClose: PropTypes.func, //exclude from backend
  onConfirm: PropTypes.func, //exclude from backend
  isOpen: PropTypes.bool, //exclude from backend
  orgId: PropTypes.string, //exclude from backend

  closeModalHiddenText: PropTypes.string,
  title: PropTypes.string,
  lead: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  acceptCheckboxText: PropTypes.string,
  search: PropTypes.exact(SearchWithDropdown.propTypes),
  searchDescriptionText: PropTypes.string,
  selectedSchoolDescriptionText: PropTypes.string,
  studentInfoLabel: PropTypes.string,
  studentName: PropTypes.string, //exclude from backend
  studentInfo: PropTypes.string, //exclude from backend
  studentId: PropTypes.string, //exclude from backend
  moveStudentEndPoint: PropTypes.string
};

MoveStudentModal.defaultProps = {
  onConfirm: () => {},
  onClose: () => {}
};

export default MoveStudentModal;
