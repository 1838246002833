import React, { useContext, useEffect, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import SamtaleContext from '../../contexts/samtale-context';

const fixHeight = id => {
  if (!id) return;

  const targetTextArea = document.getElementById(id);
  targetTextArea.style.height = 0;
  targetTextArea.style.height = targetTextArea.scrollHeight + 1 + 'px';
};

const SamtaleInputMulti = ({
  inputIsActive,
  questionId,
  onChange,
  inputsMaxLength,
  noLanguagesText,
  alternatives,
  placeholderNewItem
}) => {
  const [currentAlternatives, setCurrentAlternatives] = useState(alternatives);
  const [knownLanguagesIsMounted, setKnownLanguagesIsMounted] = useState(false);

  const { knownLanguages } = useContext(SamtaleContext);

  const handleOnChange = (e, id) => {
    fixHeight(questionId + id);

    const newValue = e.target.value;

    const newAlternatives = currentAlternatives.map(answer => {
      if (answer.id === id) {
        return { ...answer, savedText: newValue };
      }
      return answer;
    });

    setCurrentAlternatives(newAlternatives);

    const objectToSave = newAlternatives.find(
      alternative => alternative.id === id
    );
    const dataToSave = objectToSave
      ? [
          {
            answerId: objectToSave.id,
            answerValue: objectToSave.savedText
          }
        ]
      : [];

    onChange(dataToSave);
  };

  useEffect(() => {
    if (!knownLanguages.length && !knownLanguagesIsMounted) {
      setKnownLanguagesIsMounted(true);
      return;
    }

    if (!currentAlternatives) return;

    let newAlternatives = knownLanguages.map(language => {
      const existingAlternative = currentAlternatives.find(
        alternative => alternative.id === language
      );
      if (existingAlternative) {
        return existingAlternative;
      }
      return {
        id: language,
        savedText: '',
        label: language,
        placeholder: placeholderNewItem ? placeholderNewItem : ''
      };
    });

    setCurrentAlternatives(newAlternatives);
  }, [knownLanguages]);

  useLayoutEffect(() => {
    fixHeight();
  }, [inputIsActive]);

  return (
    <div className="samtale-input__multi">
      <div
        className={cn('samtale-input__multi-inputs', {
          'samtale-input__multi-inputs--visible': inputIsActive
        })}
      >
        {currentAlternatives &&
          (currentAlternatives.length > 0 ? (
            currentAlternatives
              .filter(alternative => knownLanguages.includes(alternative.id))
              .map(({ id, savedText, label, placeholder }, index) => (
                <div className="samtale-input__multi" key={index + label}>
                  <label
                    htmlFor={questionId + id}
                    className="samtale-input__multi-label"
                  >
                    {label}
                  </label>
                  <textarea
                    id={questionId + id}
                    className="samtale-input__multi-input"
                    aria-describedby={questionId}
                    placeholder={placeholder}
                    rows={1}
                    defaultValue={savedText}
                    maxLength={inputsMaxLength}
                    onChange={e => handleOnChange(e, id)}
                  />
                  <p
                    className={cn('samtale-input__pdf-inputs', {
                      'samtale-input__pdf-inputs--visible': inputIsActive
                    })}
                  >
                    {savedText}
                  </p>
                </div>
              ))
          ) : (
            <div className="samtale-input__multi-no-language">
              <p>{noLanguagesText}</p>
            </div>
          ))}
      </div>
      <div
        className={cn('samtale-input__multi-preview', {
          'samtale-input__multi-preview--visible': !inputIsActive,
          'samtale-input__multi-preview--not-empty':
            currentAlternatives && !!currentAlternatives.length
        })}
      >
        {currentAlternatives &&
          !!currentAlternatives.length &&
          currentAlternatives
            .filter(alternative => knownLanguages.includes(alternative.id))
            .map(({ savedText, label }, index) => {
              return (
                savedText && (
                  <div key={index + label}>
                    <p className="samtale-input__multi-preview-header">
                      {label}
                    </p>
                    <p className="samtale-input__multi-preview-text">
                      {savedText}
                    </p>
                  </div>
                )
              );
            })}
      </div>
    </div>
  );
};

SamtaleInputMulti.propTypes = {
  inputIsActive: PropTypes.bool, // exclude from BE
  onChange: PropTypes.func, // exclude from BE
  questionId: PropTypes.string, // exclude from BE
  alternatives: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      savedText: PropTypes.string,
      label: PropTypes.string,
      placeholder: PropTypes.string
    })
  ),
  inputsMaxLength: PropTypes.number,
  noLanguagesText: PropTypes.string,
  placeholderNewItem: PropTypes.string
};

export default SamtaleInputMulti;
