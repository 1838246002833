import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ConfirmationModal from 'components/modal/confirmation-modal';
import ResourceTable from 'components/resource-table';
import usePhrases from '../../../hooks/use-phrases';

const SchoolAdminTable = ({
  table,
  showMenus,
  confirmationModal,
  rejectUndoConfirmationModal,
  onToggleStatus,
  onConfirmModal,
  endpoints
}) => {
  const {
    confirmDelete,
    activateMappingStatus,
    deactivateMappingStatus,
    requestDeleteText,
    rejectRemoveText,
    undoRemoveRequestText
  } = usePhrases();

  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [applicableModal, setApplicableModal] = useState({});

  const decorateWithMenu = resource => {
    let items = [];

    if (resource.requestDelete) {
      items.push({
        text:
          resource?.roleId === 'LA' ? undoRemoveRequestText : rejectRemoveText,
        onClick: () => {
          setApplicableModal({
            ...rejectUndoConfirmationModal,
            onConfirm: () => {
              setConfirmationModalIsOpen(false);
              onConfirmModal(
                resource,
                resource?.roleId === 'LA'
                  ? endpoints?.undoRemoveRequestEndpoint
                  : endpoints?.rejectRemoveEndpoint
              );
            }
          });
          setConfirmationModalIsOpen(true);
        }
      });
      if (resource?.roleId === 'SL') {
        items.push({
          text: confirmDelete,
          onClick: () => {
            setApplicableModal({
              ...confirmationModal,
              confirmText: confirmDelete,
              onConfirm: () => {
                setConfirmationModalIsOpen(false);
                onConfirmModal(resource, endpoints?.removeEndpoint);
              }
            });
            setConfirmationModalIsOpen(true);
          }
        });
      }
    } else {
      items.push({
        text: resource?.roleId === 'LA' ? requestDeleteText : confirmDelete,
        onClick: () => {
          setApplicableModal({
            ...confirmationModal,
            confirmText: confirmDelete,
            onConfirm: () => {
              setConfirmationModalIsOpen(false);
              onConfirmModal(
                resource,
                endpoints?.removeEndpoint,
                resource?.roleId === 'LA' ? { requestDelete: true } : {}
              );
            }
          });
          setConfirmationModalIsOpen(true);
        }
      });
    }

    items.push({
      text:
        resource?.status === 'green'
          ? deactivateMappingStatus
          : activateMappingStatus,
      onClick: () => onToggleStatus(resource)
    });

    return {
      ...resource,
      menuItems: items
    };
  };

  return (
    <>
      <div className="user-admin-table">
        <ResourceTable
          {...table}
          showMenus={showMenus}
          showLinks={true}
          resources={table?.resources.map(resource =>
            decorateWithMenu(resource)
          )}
        />
      </div>
      {confirmationModalIsOpen && applicableModal && (
        <ConfirmationModal
          {...applicableModal}
          isOpen={confirmationModalIsOpen}
          onClose={() => setConfirmationModalIsOpen(false)}
        />
      )}
    </>
  );
};

SchoolAdminTable.propTypes = {
  showMenus: PropTypes.bool,
  confirmationModal: PropTypes.exact(ConfirmationModal.propTypes), // exclude from backend
  rejectUndoConfirmationModal: PropTypes.exact(ConfirmationModal.propTypes), // exclude from backend
  onToggleStatus: PropTypes.func, // exclude from backend
  onConfirmModal: PropTypes.func, // exclude from backend
  table: PropTypes.exact(ResourceTable.propTypes), // exclude from backend
  endpoints: PropTypes.shape({
    removeEndpoint: PropTypes.string,
    rejectRemoveEndpoint: PropTypes.string,
    undoRemoveRequestEndpoint: PropTypes.string
  }) // exclude from backend
};

export default SchoolAdminTable;
