import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({
  id,
  onChange,
  checked,
  isDisabled,
  value,
  label,
  isUncontrolledInput,
  required
}) => {
  const handleChange = e => {
    onChange(e.target.checked, e.target.id);
  };

  return (
    <div className="checkbox">
      <input
        id={id}
        disabled={isDisabled}
        onChange={handleChange}
        type="checkbox"
        {...(isUncontrolledInput
          ? { defaultChecked: checked }
          : { checked: checked })}
        value={value}
        className="checkbox__input"
        required={required}
      />
      <label htmlFor={id} className="checkbox__label">
        <span className="checkbox__label-text">{label}</span>
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  checked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  isUncontrolledInput: PropTypes.bool,
  required: PropTypes.bool // exclude from backend
};

Checkbox.defaultProps = {
  onChange: () => {},
  checked: false
};

export default Checkbox;
