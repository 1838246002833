import InputErrorMessage from 'components/input-error-message';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import KartleggingUtsagnItem from './kartlegging-utsagn-item';
import Clicker from 'components/clicker/clicker';
import RedaktorGrensesnittContext from '../../../../contexts/redaktor-grensesnitt-context';
import Icon from 'components/icon';

export default function KartleggingUtsagnDisplayData({
  title,
  subtitle,
  questions,
  uniqueNodeId,
  apiData,
  type,
  readOnly,
  mestringsnivaNumber,
  fixedStructure,
  saveEndpoint,
  buttonText,
  placeholderText,
  labelText,
  infoBanner
}) {
  const {
    state: {
      currentDataType,
      isLoading,
      isDirty,
      apiErrMessage,
      recentlySaved,
      redaktorDataList: utsagnList
    },
    functions: {
      setCurrentData,
      reorder,
      saveUtsagnChanges,
      deleteItem,
      addUtsagnItem,
      handleUtsagnChange,
      undoAll,
      handleUtsagnUndo
    }
  } = useContext(RedaktorGrensesnittContext);
  useEffect(() => {
    setCurrentData({
      apiData: {
        versionId: apiData?.versionId,
        arstrinngruppeId: apiData?.arstrinngruppeId,
        ferdighetsomradeId: apiData?.ferdighetsomradeId,
        mestringsnivaId: apiData?.mestringsnivaId
      },
      saveEndpoint,
      initialDataList: questions,
      type: type
    });
    return () => {
      setCurrentData({
        apiData: {},
        saveEndpoint: '',
        initialDataList: [],
        type: ''
      });
    };
  }, []);

  return (
    <>
      {!readOnly && (
        <div
          className={
            'redaktor-grensesnitt__data-content-actions sticky-actions'
          }
        >
          <div>
            <Clicker
              theme={Clicker.themes.secondary}
              onClick={undoAll}
              isDisabled={!isDirty}
              text={buttonText.global.cancel}
            />
            <Clicker
              theme={Clicker.themes.secondary}
              isDisabled={fixedStructure}
              onClick={addUtsagnItem}
              text={buttonText.global.add}
            />
          </div>
          <div>
            <span className={'redaktor-grensesnitt__save-status'}>
              {isLoading ? 'Lagrer ... ' : isDirty ? 'Ulagrede endringer' : ''}
            </span>
            <Clicker
              theme={Clicker.themes.primary}
              onClick={saveUtsagnChanges}
              isDisabled={!isDirty || isLoading}
              text={buttonText.global.save}
            />
          </div>
        </div>
      )}
      {infoBanner && (
        <div className={'redaktor-grensesnitt__info-banner'}>
          <Icon name={Icon.names.infoCircle} />
          <span className={'redaktor-grensesnitt__info-banner-text'}>
            {infoBanner}
          </span>
        </div>
      )}
      {apiErrMessage?.globalMessage && (
        <InputErrorMessage errors={[apiErrMessage?.globalMessage]} />
      )}

      <ul
        key={title + ' ' + subtitle}
        className="redaktor-grensesnitt__data-content-group"
      >
        {currentDataType === type &&
          utsagnList.map((utsagn, index) => (
            <KartleggingUtsagnItem
              key={index + utsagn.id + uniqueNodeId + type}
              readOnly={readOnly}
              placeholderText={placeholderText}
              labelText={labelText}
              recentlySaved={recentlySaved}
              buttonText={buttonText.item}
              handleChange={handleUtsagnChange}
              handleUndo={handleUtsagnUndo}
              uniqueNodeId={index + uniqueNodeId}
              reorder={reorder}
              fixedStructure={fixedStructure}
              onDeleteCallback={deleteItem}
              listLength={utsagnList?.length || 0}
              utsagn={utsagn}
              index={index}
              mestringsnivaNumber={mestringsnivaNumber}
              errorMessage={
                apiErrMessage?.specificMessage?.id === utsagn.id
                  ? apiErrMessage?.specificMessage?.message
                  : ''
              }
              parentIndex={index} // TODO: replace
            />
          ))}
      </ul>
    </>
  );
}

KartleggingUtsagnDisplayData.propTypes = {
  infoBanner: PropTypes.string,
  buttonText: PropTypes.exact({
    global: PropTypes.exact({
      save: PropTypes.string.isRequired,
      cancel: PropTypes.string.isRequired,
      add: PropTypes.string.isRequired
    }).isRequired,
    item: PropTypes.exact({
      edit: PropTypes.string.isRequired,
      cancelEdit: PropTypes.string.isRequired,
      cancel: PropTypes.string.isRequired,
      moveDown: PropTypes.string.isRequired,
      moveUp: PropTypes.string.isRequired,
      delete: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  placeholderText: PropTypes.exact({
    utsagnText: PropTypes.string,
    tooltipText: PropTypes.string
  }),
  labelText: PropTypes.exact({
    utsagnText: PropTypes.string,
    tooltipText: PropTypes.string
  }),
  uniqueNodeId: PropTypes.string, // ignore backend
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  apiData: PropTypes.exact({
    versionId: PropTypes.string,
    arstrinngruppeId: PropTypes.string,
    ferdighetsomradeId: PropTypes.string,
    mestringsnivaId: PropTypes.string
  }),
  mestringsnivaNumber: PropTypes.number.isRequired,
  saveEndpoint: PropTypes.string,
  fixedStructure: PropTypes.bool,
  questions: PropTypes.arrayOf(
    PropTypes.shape(KartleggingUtsagnItem.propTypes.utsagn)
  )
};

KartleggingUtsagnDisplayData.defaultProps = {
  placeholderText: {
    utsagnText: 'Skriv et utsagn',
    tooltipText: 'Skriv forklaring til utsagnet'
  },
  labelText: {
    utsagnText: 'Utsagn',
    tooltipText: 'Forklaring'
  },
  buttonText: {
    global: {
      add: 'Legg til',
      save: 'Lagre',
      cancel: 'Tilbakestill/Avbryt'
    },
    item: {
      cancelEdit: 'Avslutt redigering',
      edit: 'Rediger',
      cancel: 'Tilbakestill',
      moveDown: 'Flytt nedover',
      moveUp: 'Flytt oppover',
      delete: 'Slett'
    }
  }
};
