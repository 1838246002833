import cn from 'classnames';
import Clicker from 'components/clicker';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import HeaderContext from '../../contexts/header-context';
import usePhrases from '../../hooks/use-phrases';
import useQuestionnaireFormControl from '../../hooks/use-questionnaire-form-control';
import EditInfoModal from '../edit-info-modal/edit-info-modal';

const colors = {
  green: 'green',
  blue: 'blue',
  black: 'black',
  gray: 'gray'
};

const ProfileHeader = ({
  name,
  infos,
  color,
  submitEndpoint,
  editProfileModal,
  showEditButton,
  headingLevel
}) => {
  const { edit } = usePhrases();
  const [isOpen, setIsOpen] = useState(false);
  const { profileHeaderData, setProfileHeaderData } = useContext(HeaderContext);

  const [activeInputs, setActiveInputs] = useState(
    profileHeaderData?.inputs || editProfileModal.inputs
  );

  const [activeInfos, setActiveInfos] = useState(
    profileHeaderData?.infos || infos
  );
  const [activeName, setActiveName] = useState(profileHeaderData?.name || name);

  const { questions, isLoading, isDirty, handleOnInputChange, handleOnSubmit } =
    useQuestionnaireFormControl(activeInputs, submitEndpoint);

  useEffect(() => {
    if (typeof profileHeaderData?.name === 'string') {
      setActiveName(profileHeaderData?.name);
    }
  }, [profileHeaderData?.name]);
  useEffect(() => {
    if (Array.isArray(profileHeaderData?.infos)) {
      setActiveInfos(profileHeaderData?.infos);
    }
  }, [profileHeaderData?.infos]);
  useEffect(() => {
    if (Array.isArray(profileHeaderData?.inputs)) {
      setActiveInputs(profileHeaderData?.inputs);
    }
  }, [profileHeaderData?.inputs]);

  const disableSave = React.useMemo(() => {
    return !isDirty || isLoading;
  }, [isDirty, isLoading]);

  const handleOnConfirm = async () => {
    try {
      const res = await handleOnSubmit();
      if (res.errors) {
        throw res;
      }
      const { infos, name, inputs } = res;
      if (infos) setActiveInfos(infos);
      if (inputs) setActiveInputs(inputs);
      if (name) setActiveName(name);
      setProfileHeaderData(null);
      setIsOpen(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const HeadingElement = headingLevel ? 'h' + headingLevel : 'h2';

  return (
    <>
      <div
        className={cn('profile-header', {
          'profile-header--columns': activeInfos?.length > 2,
          [`profile-header--${colors[color]}`]: colors[color]
        })}
      >
        <div className="profile-header__content">
          <div className="profile-header__header">
            <HeadingElement className="profile-header__title">
              {activeName}
            </HeadingElement>
            {showEditButton && (
              <div className="profile-header__actions">
                <div className="profile-header__edit">
                  {submitEndpoint && (
                    <Clicker
                      text={edit}
                      textIsHidden={true}
                      iconName={Clicker.iconNames.edit}
                      onClick={() => setIsOpen(true)}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="profile-header__info">
            {infos && (
              <ul className="profile-header__list">
                {activeInfos.map(({ label, value }, index) => (
                  <li className="profile-header__item" key={index}>
                    <span className="profile-header__label">{`${label}`}</span>
                    <span className="profile-header__value">{`${value}`}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      <EditInfoModal
        {...editProfileModal}
        inputs={questions}
        isDisabled={isLoading}
        disableSave={disableSave}
        isLoading={isLoading}
        isOpen={isOpen}
        onInputChange={handleOnInputChange}
        onConfirm={handleOnConfirm}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

ProfileHeader.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.oneOf(Object.values(colors)), //exclude from backend
  showEditButton: PropTypes.bool, //exclude from backend
  submitEndpoint: PropTypes.string,
  editProfileModal: PropTypes.exact(EditInfoModal.propTypes),
  name: PropTypes.string,
  infos: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  headingLevel: PropTypes.number
};

ProfileHeader.colors = colors;

ProfileHeader.defaultProps = {
  infos: [],
  color: colors.black,
  showEditButton: true
};

export default ProfileHeader;
