import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Clicker from 'components/clicker/clicker';
import sortKeyInObject from 'js/sortKeyInObject';
import usePhrases from '../../../hooks/use-phrases';
import SlideInChildren from 'components/slide-in-children';

export default function SamtaleSummaryBox({ title, editUrl, summary }) {
  const [isToggled, setIsToggled] = useState(false);
  const toggleSetIsToggled = () => setIsToggled(!isToggled);

  const { showMore, edit } = usePhrases();

  const renderTitleOrSpace = _title => {
    if (typeof _title === 'string') {
      if (_title.length === 0) {
        return <div className="samtale-summary-box__empty-header-separator" />;
      } else
        return (
          <h3 className="samtale-summary-box__question-title">{_title}</h3>
        );
    } else return null;
  };

  const renderItemAnswer = answer => {
    if (Array.isArray(answer)) {
      return (
        <ul>
          {answer.map((a, i) => (
            <li key={i + a} className="samtale-summary-box__answer-list-item">
              {a || '-'}
            </li>
          ))}
        </ul>
      );
    } else
      return (
        <p className="samtale-summary-box__answer-text-item">{answer || '-'}</p>
      );
  };
  return (
    <div
      className={cn('samtale-summary-box', {
        'samtale-summary-box--toggled': isToggled
      })}
    >
      <Clicker
        className="samtale-summary-box__toggle-button"
        iconName={
          isToggled
            ? Clicker.iconNames.arrowDownCircle
            : Clicker.iconNames.arrowUpCircle
        }
        onClick={toggleSetIsToggled}
        text={showMore}
        textIsHidden={true}
        iconSize={Clicker.iconSizes.medium}
      />
      <div
        className="samtale-summary-box__header-menu"
        onClick={toggleSetIsToggled}
      >
        <h2 className="samtale-summary-box__header">{title}</h2>
      </div>
      {isToggled && editUrl && (
        <Clicker
          className="samtale-summary-box__edit-button"
          href={editUrl}
          text={edit}
          textIsHidden={true}
          iconName={Clicker.iconNames.edit}
          iconSize={Clicker.iconSizes.medium}
        />
      )}

      <SlideInChildren open={isToggled}>
        {Array.isArray(summary) &&
          summary
            .sort((a, b) => sortKeyInObject(a, b, 'id'))
            .map(({ id, title, questions }) => (
              <div key={id} className="samtale-summary-box__item">
                {renderTitleOrSpace(title)}
                {questions
                  .sort((a, b) => sortKeyInObject(a, b, 'id'))
                  .map(({ question, answer, id }) => (
                    <div
                      className={'samtale-summary-box__qa-wrapper'}
                      key={`${id}-${question}`}
                    >
                      <h4 className="samtale-summary-box__header-question">
                        {question}
                      </h4>
                      {renderItemAnswer(answer)}
                    </div>
                  ))}
              </div>
            ))}
      </SlideInChildren>
    </div>
  );
}
const questionItem = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
};
const summaryItem = {
  title: PropTypes.string,
  id: PropTypes.number.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape(questionItem))
};

SamtaleSummaryBox.propTypes = {
  title: PropTypes.string.isRequired,
  editUrl: PropTypes.string,
  summary: PropTypes.arrayOf(PropTypes.shape(summaryItem))
};
