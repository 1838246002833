import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import isRunningOnClient from '@creuna/utils/is-running-on-client';

import HighchartsReact from 'highcharts-react-official';
import { useChartContext } from '../../../source/contexts/chart-context';

import colors from 'js/kartlegging-colors';

let Highcharts = undefined;

//since highcharts doesn't play well with our server-side rendering, we conditionally import them after checking for the window object.
if (isRunningOnClient) {
  Highcharts = require('highcharts');

  require('highcharts/highcharts-more')(Highcharts);
  require('highcharts/modules/exporting')(Highcharts);
}

const Chart = ({ options, comprehensionLevelLabel, labels }) => {
  const { setSVGFromChart } = useChartContext();

  const chartRef = useRef();

  function xAxisLabelFormatter() {
    if (this.value < labels.length) {
      return `
        <a 
          class="chart__xaxis-label" 
          href="${labels[this.value].href}"
          ${
            labels[this.value].ariaLabel
              ? 'aria-label="' + labels[this.value].ariaLabel + '"'
              : ''
          }>
            ${labels[this.value].title}
            <span class="chart__xaxis-label-arrow">
              <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.857 7.93136L6.857 1.07422L4.57129 1.07422L4.57129 7.93136L0.571289 7.93136L5.77129 14.9256L10.857 7.93136L6.857 7.93136Z" fill="#00468E"/>
              </svg>
            </span>
        </a>`;
    }
    return '';
  }

  function tooltipFormatter() {
    return `<span style="font-weight: bold; font-size: 1rem; display: block">${
      labels[this.point.index].title
    }</span><span style="font-size: 1rem">${comprehensionLevelLabel} ${
      this.point.level
    }</span>`;
  }

  const chartOptions = {
    ...options,
    series: options.series?.map(series => {
      return {
        ...series,
        data: series.data?.map(data => {
          return {
            ...data,
            color:
              series.color ??
              (colors[data.color] ||
                (data.color === 'white' && '#fff') ||
                'magenta')
          }; // ugly fallback color if backend passes invalid color props
        })
      };
    }),
    chart: {
      borderColor: '#ffffff',
      height: '700px',
      marginRight: 40,
      marginLeft: 40,
      ...options.chart,
      backgroundColor: 'transparent'
    },
    xAxis: {
      lineColor: '#ffffff',
      labels: {
        useHTML: true,
        style: {
          fontSize: '18px',
          fontWeight: 'normal',
          color: 'black'
        },
        formatter: xAxisLabelFormatter
      },
      ...options.xAxis
    },
    tooltip: {
      useHTML: true,
      formatter: tooltipFormatter,
      ...options.tooltip
    },
    responsive: {
      ...options.responsive,
      rules: [
        {
          condition: {
            maxWidth: 768 // scss $breakpoint--sm
          },
          chartOptions: {
            chart: {
              height: 650
            },
            xAxis: {
              labels: {
                style: {
                  fontSize: '16px'
                },
                align: 'center',
                distance: '120%'
              }
            }
          }
        },
        {
          condition: {
            maxWidth: 650
          },
          chartOptions: {
            chart: {
              height: 550
            }
          }
        },
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            chart: {
              height: 550
            },
            xAxis: {
              labels: {
                distance: '150%'
              }
            }
          }
        }
      ]
    },
    exporting: {
      enabled: false
    }
  };

  const getSVG = () => {
    let chart = chartRef?.current?.chart;
    return chart?.getSVG({ chart: { height: 600, width: 700 } });
  };

  useEffect(() => {
    setSVGFromChart(getSVG);
  }, []);

  return (
    <div className="chart">
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        ref={chartRef}
      />
    </div>
  );
};

Chart.propTypes = {
  // eslint-disable-next-line no-unused-vars
  options: PropTypes.shape({
    responsive: PropTypes.object,
    tooltip: PropTypes.object,
    xAxis: PropTypes.object,
    chart: PropTypes.object,
    series: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            color: PropTypes.oneOf(Object.keys(colors).push('white'))
          })
        )
      })
    )
  }).isRequired, // This objet is much more complex (see highcharts api), but in terms of prop validation we only care about colors for data sets
  comprehensionLevelLabel: PropTypes.string,
  labels: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      href: PropTypes.string,
      color: PropTypes.oneOf(Object.keys(colors))
    })
  )
};

Chart.defaultProps = {
  options: {},
  unit: 'poeng',
  labels: ['']
};

export default Chart;
