import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '../modal';
import LoadingSpinner from '../../loading-spinner';
import Clicker from '../../clicker';
import Checkbox from '../../checkbox/checkbox';

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  isDisabled,
  closeModalHiddenText,
  checkbox,
  confirmText,
  cancelText,
  isLoading,
  title,
  lead
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckBoxChange = () => {
    setIsChecked(prev => !prev);
  };

  return (
    <Modal
      themes={[Modal.themes.white]}
      isOpen={isOpen}
      onClose={onClose}
      isDisabled={isDisabled || isLoading}
      closeButtonLabel={closeModalHiddenText}
    >
      <div className="confirmation-modal">
        <div className="confirmation-modal__content">
          {title && <h2 className="confirmation-modal__title">{title}</h2>}
          {lead && <p className="confirmation-modal__lead">{lead}</p>}
          {checkbox && (
            <div className="confirmation-modal__checkbox">
              <Checkbox
                {...checkbox}
                onChange={handleCheckBoxChange}
                checked={isChecked}
              />
            </div>
          )}
          <div className="confirmation-modal__actions">
            <div className="confirmation-modal__actions">
              <div className="owner-assignment-modal__action">
                {isLoading && <LoadingSpinner />}
              </div>
              {onClose && cancelText && (
                <div className="confirmation-modal__action">
                  <Clicker
                    isDisabled={isDisabled || isLoading}
                    text={cancelText}
                    onClick={onClose}
                    theme={Clicker.themes.secondary}
                  />
                </div>
              )}
              {onConfirm && confirmText && (
                <div className="confirmation-modal__action">
                  <Clicker
                    isDisabled={
                      isDisabled || isLoading || (checkbox && !isChecked)
                    }
                    text={confirmText}
                    onClick={onConfirm}
                    theme={Clicker.themes.primary}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  isDisabled: PropTypes.bool, //exclude from backend
  onClose: PropTypes.func, //exclude from backend
  onConfirm: PropTypes.func, //exclude from backend
  isOpen: PropTypes.bool, //exclude from backend
  isLoading: PropTypes.bool, //exclude from backend
  closeModalHiddenText: PropTypes.string,
  title: PropTypes.string,
  lead: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  checkbox: PropTypes.exact(Checkbox.propTypes)
};

ConfirmationModal.defaultProps = {
  onConfirm: () => {},
  onClose: () => {}
};

export default ConfirmationModal;
