export default {
  // Generic
  'generic.add': 'Legg til',
  'generic.cancel': 'Avbryt',

  // BlockType
  'components.controls.blocktype.h1': 'H1',
  'components.controls.blocktype.h2': 'H2',
  'components.controls.blocktype.h3': 'H3',
  'components.controls.blocktype.h4': 'H4',
  'components.controls.blocktype.h5': 'H5',
  'components.controls.blocktype.h6': 'H6',
  'components.controls.blocktype.blockquote': 'Sitat',
  'components.controls.blocktype.code': 'Kode',
  'components.controls.blocktype.blocktype': 'Blokktype',
  'components.controls.blocktype.normal': 'Normal',

  // Color Picker
  'components.controls.colorpicker.colorpicker': 'Fargevelger',
  'components.controls.colorpicker.text': 'Tekst',
  'components.controls.colorpicker.background': 'Uthev',

  // Embedded
  'components.controls.embedded.embedded': 'Embedded',
  'components.controls.embedded.embeddedlink': 'Embedded-link',
  'components.controls.embedded.enterlink': 'Enter-link',

  // Emoji
  'components.controls.emoji.emoji': 'Emoji',

  // FontFamily
  'components.controls.fontfamily.fontfamily': 'Font',

  // FontSize
  'components.controls.fontsize.fontsize': 'Skriftstørrelse',

  // History
  'components.controls.history.history': 'History',
  'components.controls.history.undo': 'Angre',
  'components.controls.history.redo': 'Redo',

  // Image
  'components.controls.image.image': 'Bilde',
  'components.controls.image.fileUpload': 'Filopplastning',
  'components.controls.image.byURL': 'URL',
  'components.controls.image.dropFileText': 'Last opp',

  // Inline
  'components.controls.inline.bold': 'Fet',
  'components.controls.inline.italic': 'Kursiv',
  'components.controls.inline.underline': 'Understrek',
  'components.controls.inline.strikethrough': 'Strikethrough',
  'components.controls.inline.monospace': 'Monospace',
  'components.controls.inline.superscript': 'Superscript',
  'components.controls.inline.subscript': 'Subscript',

  // Link
  'components.controls.link.linkTitle': 'Tekst',
  'components.controls.link.linkTarget': 'URL',
  'components.controls.link.linkTargetOption': 'Åpne lenken i nytt vindu',
  'components.controls.link.link': 'Lenke',
  'components.controls.link.unlink': 'Fjern lenke',

  // List
  'components.controls.list.list': 'Liste',
  'components.controls.list.unordered': 'Uordnet',
  'components.controls.list.ordered': 'Ordnet',
  'components.controls.list.indent': 'Innrykk',
  'components.controls.list.outdent': 'Utrykk',

  // Remove
  'components.controls.remove.remove': 'Remove',

  // TextAlign
  'components.controls.textalign.textalign': 'Tekstjustering',
  'components.controls.textalign.left': 'Venstre',
  'components.controls.textalign.center': 'Midtstilt',
  'components.controls.textalign.right': 'Høyre',
  'components.controls.textalign.justify': 'Justér'
};
