import PropTypes from 'prop-types';
import React from 'react';
import Question from 'components/questionnaire/question';
import Tooltip from 'components/tooltip/tooltip';

export default function KartleggingPreviewDisplayData({ questions }) {
  return (
    <div style={{ marginTop: '2rem', width: '100%' }}>
      <ul className="questionnaire__list">
        {questions?.map((question, index) => (
          <li key={index} className="questionnaire__item">
            <Question
              {...question}
              hideAlternatives
              isDisabled
              onChange={() => {}}
              colorValue={''}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

KartleggingPreviewDisplayData.propTypes = {
  type: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      index: PropTypes.string.isRequired,
      questionText: PropTypes.exact({
        text: PropTypes.string.isRequired,
        definitions: PropTypes.arrayOf(
          PropTypes.exact({
            matchWord: PropTypes.string,
            title: PropTypes.string,
            lead: PropTypes.string
          })
        )
      }),
      tooltip: PropTypes.shape(Tooltip.propTypes)
    })
  )
};
