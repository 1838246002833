import React from 'react';
import PropTypes from 'prop-types';

import ActionBar from '../action-bar';

const MainHeading = ({ title, indicatorText, actionBar }) => {
  return (
    <>
      <div className="main-heading">
        {title && <h1 className="main-heading__title">{title}</h1>}
        {indicatorText && (
          <p className="main-heading__indicator-text">{indicatorText}</p>
        )}
        {actionBar && (
          <div className="main-heading__actions">
            <ActionBar {...actionBar} />
          </div>
        )}
      </div>
    </>
  );
};

MainHeading.propTypes = {
  title: PropTypes.string,
  indicatorText: PropTypes.string,
  actionBar: PropTypes.exact(ActionBar.propTypes)
};

export default MainHeading;
