import cn from 'classnames';
import api from 'js/api-helper';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, useRef } from 'react';
import RedaktorGrensesnittContext from '../../contexts/redaktor-grensesnitt-context';
import RedaktorDataDisplay from './redaktor-data-display';
import RedaktorNode from './redaktor-node';
import useClickOutside from '../../hooks/use-click-outside';
import Toggle from 'components/toggle/toggle';

const RedaktorGrensesnitt = ({
  title,
  type,
  versionId,
  hrefToggler,
  nodes,
  topNodeStartOpen
}) => {
  const {
    state: { isDirty },
    functions: { setApiErrMessage }
  } = useContext(RedaktorGrensesnittContext);
  const [foundStoredData, setFoundStoredData] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [displayData, setDisplayData] = useState({
    loading: false,
    error: false,
    data: null,
    tabList: [],
    breadcrumbText: ''
  });
  const listRef = useRef();
  const clickedOutside = useClickOutside(listRef);

  const newNodes = React.useMemo(() => {
    function searchTree(_nodes) {
      return _nodes?.map(node => {
        return {
          ...node,
          nodes: node.nodes ? searchTree(node.nodes) : undefined
        };
      });
    }

    return searchTree(nodes);
  }, [nodes]);

  useEffect(() => {
    // let timeout;
    const fetchAdminData = async endpoint => {
      setDisplayData(_displayData => ({
        ..._displayData,
        loading: true,
        error: false
      }));
      try {
        const data = await api.execute(endpoint);
        setDisplayData({
          data,
          loading: false,
          error: false,
          breadcrumbText: currentData?.breadcrumbText
        });
      } catch (err) {
        setDisplayData(_displayData => ({
          data: null,
          loading: false,
          error: true,
          breadcrumbText: ''
        }));
      }
    };

    if (currentData?.dataEndpoint) {
      fetchAdminData(currentData.dataEndpoint);
    } else {
      setDisplayData(aD => ({
        ...aD,
        data: null
      }));
    }
  }, [currentData]);

  const togglePreviewEdit = () => {
    if (window) {
      window.location = hrefToggler.href;
    }
  };

  return (
    <div className="redaktor-grensesnitt">
      {title && (
        <div className="redaktor-grensesnitt__content">
          <h1 className="redaktor-grensesnitt__title">{title}</h1>
        </div>
      )}

      <div
        className={cn('redaktor-grensesnitt__wrapper', {
          'redaktor-grensesnitt__wrapper--loading': displayData?.loading
        })}
      >
        {newNodes && !!newNodes.length && (
          <ul
            ref={listRef}
            className={cn('redaktor-grensesnitt__list-wrapper', {
              'redaktor-grensesnitt__list-wrapper--active-mobile': !clickedOutside
            })}
          >
            {newNodes.map((node, i) => (
              <RedaktorNode
                type={type}
                versionId={versionId}
                key={i + node.title}
                isDirty={isDirty}
                breadcrumbText={node.title}
                isFirstInList={i === 0}
                startOpen={topNodeStartOpen}
                singleTopNode={newNodes?.length === 1}
                currentData={currentData}
                setApiErrMessage={setApiErrMessage}
                setCurrentData={setCurrentData}
                foundStoredData={foundStoredData}
                setFoundStoredData={setFoundStoredData}
                node={node}
              />
            ))}
          </ul>
        )}

        <div className="redaktor-grensesnitt__data-wrapper">
          {hrefToggler && hrefToggler?.show && (
            <div className="redaktor-grensesnitt__toggler_wrapper">
              <Toggle onToggle={togglePreviewEdit} {...hrefToggler} />
            </div>
          )}
          <RedaktorDataDisplay
            key={currentData?.breadcrumbText}
            displayData={displayData}
          />
        </div>
      </div>
    </div>
  );
};

RedaktorGrensesnitt.propTypes = {
  type: PropTypes.string,
  versionId: PropTypes.string,
  title: PropTypes.string,
  topNodeStartOpen: PropTypes.bool,
  nodes: PropTypes.arrayOf(PropTypes.shape(RedaktorNode.propTypes)),
  hrefToggler: PropTypes.shape(Toggle.propTypes)
};

RedaktorGrensesnitt.defaultProps = {
  showRemoveAction: true
};

export default RedaktorGrensesnitt;
