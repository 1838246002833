import Clicker from 'components/clicker/clicker';
import InputErrorMessage from 'components/input-error-message';
import LoadingSpinner from 'components/loading-spinner/loading-spinner';
import SelectInput from 'components/select-input/select-input';
import SlideInChildren from 'components/slide-in-children';
import apiHelper from 'js/api-helper';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AlertModal from './alert-modal';

const RedaktorIntroEdit = ({
  getActionsEndpoint,
  actionButtonText,
  dropdownPlaceholder,
  title,
  lead,
  versions,
  isOpen,
  radioButtons,
  setIsOpen
}) => {
  const [proceedButton, setProceedButton] = useState({
    endpoint: '',
    text: ''
  });

  const [currentSelectedVersion, setCurrentSelectedVersion] = useState(null);
  const [showRadioButtons, setShowRadioButtons] = useState(false);
  const [currentRadioValue, setCurrentRadioValue] = useState('');
  const [alertModal, setAlertModal] = useState(null);
  const [{ isLoading, error }, setFetchStatus] = useState({
    isLoading: false,
    error: ''
  });

  const closeAlertModal = () => {
    setAlertModal(aM => ({ ...aM, isOpen: false }));
  };
  const openAlertModal = () => {
    setAlertModal(aM => ({ ...aM, isOpen: true }));
  };

  const handleSelectVersion = async value => {
    if (!value) {
      setShowRadioButtons(false);
      setProceedButton({
        endpoint: '',
        text: ''
      });
      return;
    }
    const selectedVersion = versions.find(v => v.value === value);
    setCurrentSelectedVersion(selectedVersion);
    if (selectedVersion.isPublished) {
      setShowRadioButtons(true);
      setProceedButton({
        endpoint: '',
        text: ''
      });
    } else {
      setFetchStatus({ isLoading: true, error: '' });

      try {
        setShowRadioButtons(false);
        const res = await apiHelper.execute(getActionsEndpoint, {
          versionId: selectedVersion.value
        });
        setProceedButton({
          endpoint: res.endpoint,
          text: res.text
        });
        if (res.alertModal) setAlertModal({ ...res.alertModal, isOpen: false });
        setFetchStatus({ isLoading: false, error: '' });
      } catch (err) {
        setFetchStatus({
          isLoading: true,
          error: err?.message || 'Noe gikk galt'
        });
      }
    }
  };

  const handleRadioClick = async e => {
    setFetchStatus({ isLoading: true, error: '' });

    const radioEndpoint = e.target.value;
    const radioButtonId = e.target.id;
    const selectedRadio = radioButtons.find(r => r.id === radioButtonId);

    setCurrentRadioValue(selectedRadio);
    try {
      const res = await apiHelper.execute(radioEndpoint, {
        radioButtonId,
        versionId: currentSelectedVersion.value
      });
      setProceedButton({
        endpoint: res.endpoint,
        text: res.text
      });
      if (res.alertModal) setAlertModal({ ...res.alertModal, isOpen: false });
      setFetchStatus({ isLoading: false, error: '' });
    } catch (err) {
      setFetchStatus({
        isLoading: true,
        error: err?.message || 'Noe gikk galt'
      });
    }
  };

  const handleProceed = async () => {
    if (!proceedButton?.endpoint) return;
    if (alertModal && !alertModal.isOpen) return openAlertModal();
    setFetchStatus({
      isLoading: true,
      error: ''
    });
    const endpointData = {
      versionId: currentSelectedVersion?.value
    };
    if (currentRadioValue?.id) {
      endpointData.radioButtonId = currentRadioValue.id;
    }
    try {
      await apiHelper.execute(proceedButton.endpoint, endpointData);
      setFetchStatus({
        isLoading: false,
        error: ''
      });
    } catch (err) {
      setFetchStatus({
        isLoading: false,
        error: err?.message || 'Noe gikk galt'
      });
    }
  };

  return (
    <div
      className={'redaktor-intro__box'}
      onClick={() => !isOpen && setIsOpen()}
      style={!isOpen ? { cursor: 'pointer' } : {}}
    >
      {alertModal && (
        <AlertModal
          {...alertModal}
          onClose={closeAlertModal}
          handleProceed={handleProceed}
        />
      )}
      <div className={'redaktor-intro__box-text-wrapper'}>
        <h2
          id="redaktor-intro-edit-heading"
          className={'redaktor-intro__box-title'}
        >
          {title}
        </h2>
        <p className={'redaktor-intro__box-lead'}>{lead}</p>
      </div>
      <SlideInChildren open={isOpen}>
        <div
          style={{ minHeight: 100 }}
          className={'redaktor-intro__box-slide-in-wrapper'}
        >
          <SelectInput
            ariaLabelledby="redaktor-intro-edit-heading"
            id={'edit-version'}
            onChange={handleSelectVersion}
            onFocus={() => !isOpen && setIsOpen()}
            placeholder={dropdownPlaceholder}
            options={versions.map(({ text, value }) => ({ text, value }))}
          />
          <br />
          {showRadioButtons &&
            Boolean(radioButtons?.length) &&
            radioButtons.map(radio => {
              return (
                <div key={radio?.id} className="radio-button-input">
                  <div className="radio-button-input__radio-border">
                    <input
                      id={radio.id}
                      name={'select-next-step'} // set common id from question to create radio-group
                      type="radio"
                      onChange={handleRadioClick}
                      checked={radio?.id === currentRadioValue?.id}
                      value={radio?.endpoint} // id used here to set the current value of question
                      className="radio-button-input__input"
                    />
                    <label
                      htmlFor={radio.id}
                      className="radio-button-input__label"
                    >
                      {radio.label}
                    </label>
                    <span className="radio-button-input__dot" />
                  </div>
                </div>
              );
            })}
          {error && <InputErrorMessage errors={[error]} />}
          <div className={'redaktor-intro__button-wrapper'}>
            {isLoading && <LoadingSpinner />}
            <Clicker
              isDisabled={!proceedButton?.text || isLoading}
              onClick={handleProceed}
              text={proceedButton?.text ? proceedButton.text : actionButtonText}
              theme={Clicker.themes.primary}
            />
          </div>
        </div>
      </SlideInChildren>
    </div>
  );
};

RedaktorIntroEdit.propTypes = {
  getActionsEndpoint: PropTypes.string.isRequired,
  // takes id of selected version and returns list of radio buttons with corresponding object with proceed button text and href
  title: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  versions: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      isPublished: PropTypes.bool.isRequired
    })
  ),
  actionButtonText: PropTypes.string,
  dropdownPlaceholder: PropTypes.string,
  radioButtons: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      id: PropTypes.string,
      endpoint: PropTypes.string
    })
  ),
  isOpen: PropTypes.bool, // ignore backend
  setIsOpen: PropTypes.func // ignore backend
};

RedaktorIntroEdit.defaultProps = {
  actionButtonText: 'Rediger kartleggingsspesifikasjon',
  dropdownPlaceholder: 'Velg versjon'
};

export default RedaktorIntroEdit;
