import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../icon';
import SlideInChildren from 'components/slide-in-children';
import ListOfParagraphs from 'components/list-of-paragraphs';

const CatalogueEntry = ({ id, title, body }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const elementId = `info-container-${id}`;

  return (
    <div className={cn('catalogue-entry', { 'catalogue-entry--open': isOpen })}>
      <button
        aria-controls={elementId}
        aria-expanded={isOpen}
        className="catalogue-entry__button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="catalogue-entry__button-content">
          <span className="catalogue-entry__name">{title}</span>
        </span>
        <span className="catalogue-entry__icon">
          <Icon name={Icon.names.open} size={Icon.sizes.medium} />
        </span>
      </button>
      <SlideInChildren open={isOpen}>
        <div className="catalogue-entry__body" id={elementId}>
          {body.map(bodyItem => {
            return (
              <div key={bodyItem.id + bodyItem.title}>
                <h3 className={'catalogue-entry__body-subtitle'}>
                  {bodyItem.title}
                </h3>
                {bodyItem?.paragraphs?.map(({ avsnitt, list }, index) => {
                  return (
                    <ListOfParagraphs
                      key={index}
                      avsnitt={avsnitt}
                      list={list}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </SlideInChildren>
    </div>
  );
};

CatalogueEntry.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      paragraphs: PropTypes.arrayOf(
        PropTypes.exact(ListOfParagraphs.propTypes)
      ),
      terminatingParagraphs: ListOfParagraphs.listProp
    })
  )
};

export default CatalogueEntry;
