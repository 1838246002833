import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import BaseLayout from './base-layout';
import Footer from '../footer';
import Header from '../header';

const themes = {
  document: 'document'
};

const AnonymousLayout = ({ children, header, footer, theme, ...rest }) => {
  return (
    <BaseLayout color={BaseLayout.colors.gray} {...rest}>
      <Header {...header} showBreadcrumbs={false} showUserSwitcher={false} />
      <main
        id="main-content"
        className={cn('anonymous-layout', {
          [`anonymous-layout--${themes[theme]}`]: themes[theme]
        })}
      >
        {children}
      </main>
      <Footer {...footer} />
    </BaseLayout>
  );
};

AnonymousLayout.propTypes = {
  children: PropTypes.node, //excluded from backend
  footer: PropTypes.exact(Footer.propTypes),
  header: PropTypes.exact(Header.propTypes),
  theme: PropTypes.oneOf(Object.values(themes)),
  ...BaseLayout.propTypes
};

AnonymousLayout.themes = themes;

export default AnonymousLayout;
