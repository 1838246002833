import PropTypes from 'prop-types';
import React from 'react';

import SamtaleContextProvider from '../../../../contexts/samtale-context-provider';

import SamtaleBlock from 'components/samtale-block/samtale-block';

export default function SamtalePreviewDisplayData({ lead, blocks }) {
  return (
    <SamtaleContextProvider readOnly={true}>
      <div className="samtaleguide-preview">
        <div className="samtaleguide-preview__wrapper">
          {lead && (
            <span
              dangerouslySetInnerHTML={{ __html: lead }}
              className="samtaleguide-preview__lead"
            />
          )}
          {blocks && !!blocks.length && (
            <ul>
              {blocks.map((block, index) => (
                <li key={index}>
                  <SamtaleBlock {...block} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </SamtaleContextProvider>
  );
}

SamtalePreviewDisplayData.propTypes = {
  lead: PropTypes.string,
  blocks: PropTypes.arrayOf(PropTypes.exact(SamtaleBlock.propTypes))
};
