import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import colors from 'js/kartlegging-colors';
import Icon from 'components/icon';
import HighlightedAnchorLinkList from 'components/navigation-link-list/highlighted-anchor-link-list';

const NavigationLinkList = ({ list, level = 1 }) => {
  return (
    <>
      {list && !!list.length && (
        <ul
          className={cn('navigation-link-list', [
            `navigation-link-list--level-${level}`
          ])}
        >
          {list.map(({ link, linkList, anchorLinkList, color, isCurrent }) => (
            <li
              key={link.href}
              className={cn(
                'navigation-link-list__item',
                {
                  [`navigation-link-list__item--${color}`]: color
                },
                {
                  'navigation-link-list__item--current': isCurrent
                }
              )}
            >
              <a
                className="navigation-link-list__link"
                href={link.href}
                aria-current={isCurrent ? 'page' : false}
              >
                {link.text}
                <Icon
                  className="navigation-link-list__arrow-icon navigation-link-list__link-arrow"
                  name={Icon.hoverNames.hoverArrowRightThick}
                  size={Icon.sizes.medium}
                />
              </a>
              {linkList && (
                <NavigationLinkList {...linkList} level={level + 1} />
              )}
              {anchorLinkList && (
                <HighlightedAnchorLinkList list={anchorLinkList} />
              )}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

NavigationLinkList.propTypes = {
  level: PropTypes.number, // exclude from backend
  list: PropTypes.arrayOf(
    PropTypes.exact({
      link: PropTypes.exact({
        href: PropTypes.string,
        text: PropTypes.string
      }),
      linkList: PropTypes.exact(NavigationLinkList.propTypes),
      anchorLinkList: PropTypes.arrayOf(
        PropTypes.exact({
          href: PropTypes.string,
          text: PropTypes.string
        })
      ),
      isCurrent: PropTypes.bool,
      color: PropTypes.oneOf(Object.keys(colors))
    })
  )
};

export default NavigationLinkList;
