import React, { useState } from 'react';
import PropTypes from 'prop-types';

import api from 'js/api-helper';

import GridTableWrapper from 'components/grid-table-wrapper/grid-table-wrapper';
import ConfirmationModal from 'components/modal/confirmation-modal';
import LoadingSpinner from 'components/loading-spinner';

const Arkiv = ({
  content,
  removeText,
  removeEndpoint,
  removeConfirmationModal,

  proposeDeleteText,
  proposeDeleteEndpoint,
  proposeDeleteConfirmationModal,

  regretDeleteText,
  regretDeleteEndpoint,

  rejectDeleteText,
  rejectDeleteEndpoint
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentElements, setCurrentElements] = useState(content.elements);
  const [
    removeConfirmationModalIsOpen,
    setRemoveConfirmationModalIsOpen
  ] = useState(false);
  const [removeConfirmAction, setRemoveConfirmAction] = useState(null);
  const [
    proposeDeleteConfirmationModalIsOpen,
    setProposeDeleteConfirmationModalIsOpen
  ] = useState(false);
  const [proposeDeleteConfirmAction, setProposeDeleteConfirmAction] = useState(
    null
  );

  const removeElement = async targetId => {
    setIsLoading(true);

    try {
      await api.execute(removeEndpoint, { id: targetId });
      setCurrentElements(elements =>
        elements.filter(element => element.id !== targetId)
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
  };

  const handleRemoveClick = id => {
    setRemoveConfirmAction(() => () => {
      removeElement(id);
      setRemoveConfirmationModalIsOpen(false);
    });
    setRemoveConfirmationModalIsOpen(true);
  };

  const proposeDelete = async targetId => {
    setIsLoading(true);

    try {
      const payload = await api.execute(proposeDeleteEndpoint, {
        id: targetId,
        requestDelete: true
      });
      setCurrentElements(elements =>
        elements?.map(element => {
          if (element.id === targetId) {
            return {
              ...element,
              proposedDeleted: payload.proposedDeleted,
              warningTooltip: payload.warningTooltip
            };
          }
          return element;
        })
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProposeDeleteClick = async targetId => {
    setProposeDeleteConfirmAction(() => () => {
      proposeDelete(targetId);
      setProposeDeleteConfirmationModalIsOpen(false);
    });
    setProposeDeleteConfirmationModalIsOpen(true);
  };

  const handleRegretDeleteClick = async targetId => {
    setIsLoading(true);

    try {
      await api.execute(regretDeleteEndpoint, { id: targetId });
      setCurrentElements(elements =>
        elements.map(element => {
          if (element.id === targetId) {
            element.proposedDeleted = false;
            element.warningTooltip = null;
          }
          return element;
        })
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
  };

  const handleRejectDeleteClick = async targetId => {
    setIsLoading(true);

    try {
      await api.execute(rejectDeleteEndpoint, { id: targetId });
      setCurrentElements(elements =>
        elements.map(element => {
          if (element.id === targetId) {
            element.proposedDeleted = false;
            element.warningTooltip = null;
          }
          return element;
        })
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
  };

  const addMenuToElements = () => {
    if (!(content.elements && !!content.elements.length)) return [];

    return [...currentElements].map(element => {
      const menuItems = [];

      // LA
      proposeDeleteText &&
        !element.proposedDeleted &&
        menuItems.push({
          text: proposeDeleteText,
          onClick: handleProposeDeleteClick
        });

      regretDeleteText &&
        element.proposedDeleted &&
        menuItems.push({
          text: regretDeleteText,
          onClick: handleRegretDeleteClick
        });

      // SL
      removeText &&
        menuItems.push({
          text: removeText,
          onClick: handleRemoveClick
        });

      rejectDeleteText &&
        element.proposedDeleted &&
        menuItems.push({
          text: rejectDeleteText,
          onClick: handleRejectDeleteClick
        });
      return {
        ...element,
        menuItems: menuItems
      };
    });
  };

  return (
    <div className="arkiv">
      <GridTableWrapper {...content} elements={addMenuToElements()} />
      {isLoading && <LoadingSpinner shouldTakeOverScreen={true} />}
      <ConfirmationModal
        {...removeConfirmationModal}
        isOpen={removeConfirmationModalIsOpen}
        onConfirm={removeConfirmAction}
        onClose={() => setRemoveConfirmationModalIsOpen(false)}
      />
      <ConfirmationModal
        {...proposeDeleteConfirmationModal}
        isOpen={proposeDeleteConfirmationModalIsOpen}
        onConfirm={proposeDeleteConfirmAction}
        onClose={() => setProposeDeleteConfirmationModalIsOpen(false)}
      />
    </div>
  );
};

Arkiv.propTypes = {
  content: PropTypes.exact(GridTableWrapper.propTypes),
  removeText: PropTypes.string,
  removeEndpoint: PropTypes.string,
  removeConfirmationModal: PropTypes.exact(ConfirmationModal.propTypes),

  proposeDeleteText: PropTypes.string,
  proposeDeleteEndpoint: PropTypes.string,
  proposeDeleteConfirmationModal: PropTypes.exact(ConfirmationModal.propTypes),

  regretDeleteText: PropTypes.string,
  regretDeleteEndpoint: PropTypes.string,

  rejectDeleteText: PropTypes.string,
  rejectDeleteEndpoint: PropTypes.string
};

Arkiv.defaultProps = {};

export default Arkiv;
