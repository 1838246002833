import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon/icon';

import cn from 'classnames';

const Bruker = ({
  className,
  name,
  email,
  phoneNumber,
  emailLabel,
  phoneNumberLabel,
  isDisabled,
  onRemoveSelection
}) => {
  return (
    <div className={cn('bruker', className)}>
      {name && <span className="bruker__name">{name}</span>}
      {email && (
        <span className="bruker__email">
          {emailLabel}: {email}
        </span>
      )}
      {phoneNumber && (
        <span className="bruker__phone-number">
          {phoneNumberLabel}: {phoneNumber}
        </span>
      )}
      {onRemoveSelection && (
        <button
          className="bruker__remove-selection"
          onClick={onRemoveSelection}
        >
          <Icon name={Icon.names.close} />
        </button>
      )}
    </div>
  );
};

Bruker.propTypes = {
  className: PropTypes.string, //exclude
  name: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  emailLabel: PropTypes.string,
  phoneNumberLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  onRemoveSelection: PropTypes.func
};

export default Bruker;
