import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from 'components/icon';
import topicColors from '../topic-colors';

const colors = {
  ...topicColors,
  white: 'white', // in addition to the topic colors, we accept white and black as valid colors for some implementations of the menu
  black: 'black'
};

const NavLink = ({ href, isActive, text, color, hasCheckmark }) => {
  const isSamtaleguide = React.useMemo(() => color === colors.white);
  const boxShadow = React.useMemo(() => {
    return isSamtaleguide
      ? hasCheckmark
        ? isActive
          ? `0 0 0 3px ${topicColors['green']}`
          : `0 0 0 1px ${topicColors['green']}`
        : isActive
        ? `0 0 0 2px black`
        : `0 0 0 1px black`
      : isActive && `0 0 0 3px ${topicColors[color]}`;
  }, [hasCheckmark, isActive, color]);
  return (
    <a
      href={href}
      className={cn('nav-link', {
        'nav-link--active': isActive
      })}
    >
      <span className={'nav-link__text'}>{text}</span>
      <span
        className={cn('nav-link__icon', {
          'nav-link__icon--active': isActive,
          'nav-link__icon--white': isSamtaleguide,
          'nav-link__icon--black': color === colors.black,
          'nav-link__icon--checked': hasCheckmark
        })}
        style={{
          backgroundColor: topicColors[color],
          boxShadow: boxShadow
        }}
      >
        {hasCheckmark && (
          <Icon size={Icon.sizes.small} name={Icon.names.check} />
        )}
      </span>
    </a>
  );
};

NavLink.propTypes = {
  isActive: PropTypes.bool,
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  color: PropTypes.oneOf(Object.keys(colors)).isRequired,
  hasCheckmark: PropTypes.bool
};

export default NavLink;
