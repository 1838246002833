import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import api from '../../../js/api-helper';

import Modal from '../modal';
import Clicker from '../../clicker';
import LoadingSpinner from '../../loading-spinner';
import QuickSearch from '../../quick-search';
import Results from '../../results';
import Person from '../../person';
import SelectInput from '../../select-input';

import InputErrorMessage from 'components/input-error-message';

//This should be replaced by AddBrukerrolleModal in v2.1
const AddResourceModal = ({
  isOpen,
  orgId,
  onClose,
  onConfirm,
  isDisabled,
  errorMessage,
  quickSearch,
  closeModalHiddenText,
  addResourceText,
  title,
  lead,
  selectedResources,
  directConfirm,
  roleInput: propRoleInput,
  getUserRolesEndpoint,
  roleAssignmentCode
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showRoleSelection, setShowRoleSelection] = useState(false);

  const [resources, setResources] = useState(selectedResources);
  const [roleInput, setRoleInput] = useState(propRoleInput);

  const handleOnRoleChange = value => {
    setRoleInput({
      ...roleInput,
      value
    });
  };

  const handleOnConfirm = async () => {
    const confirmResource =
      getUserRolesEndpoint && roleInput?.value
        ? [{ ...resources[0], role: roleInput.value }]
        : resources;

    onConfirm(confirmResource, roleInput?.value);
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  const handleOnNext = async () => {
    setIsLoading(true);

    try {
      const { options, value } = await api.execute(getUserRolesEndpoint, {
        text: orgId
      });

      setShowRoleSelection(true);
      setIsLoading(false);
      setRoleInput({
        ...roleInput,
        options,
        value
      });
    } catch (e) {
      setIsLoading(false);
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleOnItemClick = item => {
    if (directConfirm) {
      onConfirm([item]);
    } else {
      if (getUserRolesEndpoint) {
        handleOnNext();
        setResources([item]);
      } else setResources([item]);
    }
  };

  return (
    <Modal
      themes={[Modal.themes.white]}
      isOpen={isOpen}
      onClose={onClose}
      isDisabled={isDisabled || isLoading}
      closeButtonLabel={closeModalHiddenText}
    >
      <div className="add-resource-modal">
        <div className="add-resource-modal__content">
          {title && <h2 className="add-resource-modal__title">{title}</h2>}
          {lead && <p className="add-resource-modal__lead">{lead}</p>}
          <div className="add-resource-modal__row">
            <QuickSearch
              {...quickSearch}
              roleAssignmentCode={roleAssignmentCode}
              orgId={orgId}
              isDisabled={isLoading}
              isLoading={isLoading}
              onItemClick={handleOnItemClick}
            />
          </div>

          {resources?.length > 0 && (
            <>
              <div className="add-resource-modal__row">
                <Results label="Du har valgt" items={resources} />
              </div>

              <InputErrorMessage errors={errorMessage ? [errorMessage] : []} />

              <div className="add-resource-modal__row">
                {showRoleSelection && (
                  <div className="add-resource-modal__action">
                    <SelectInput
                      {...roleInput}
                      onChange={handleOnRoleChange}
                      isDisabled={isLoading}
                    />
                  </div>
                )}
              </div>

              <div className="add-resource-modal__actions">
                <div className="add-resource-modal__action">
                  <Clicker
                    text={addResourceText}
                    theme={Clicker.themes.primary}
                    onClick={handleOnConfirm}
                    isDisabled={
                      getUserRolesEndpoint ? !roleInput?.value : false
                    }
                  />
                </div>
                <div className="add-resource-modal__action">
                  {isLoading && <LoadingSpinner />}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="add-resource-modal__row"></div>
      </div>
    </Modal>
  );
};

AddResourceModal.propTypes = {
  directConfirm: PropTypes.bool, //exclude from backend
  isDisabled: PropTypes.bool, //exclude from backend
  onClose: PropTypes.func, //exclude from backend
  onConfirm: PropTypes.func, //exclude from backend
  isOpen: PropTypes.bool, //exclude from backend
  showTextInput: PropTypes.bool, //exclude from backend
  errorMessage: PropTypes.string, //exclude from backend
  orgId: PropTypes.string, //exclude from backend
  roleAssignmentCode: PropTypes.string, //exclude from backend

  closeModalHiddenText: PropTypes.string,
  addResourceText: PropTypes.string,
  title: PropTypes.string,
  lead: PropTypes.string,
  selectedResources: PropTypes.arrayOf(PropTypes.exact(Person.propTypes)),
  quickSearch: PropTypes.exact(QuickSearch.propTypes),
  getUserRolesEndpoint: PropTypes.string,
  roleInput: PropTypes.exact(SelectInput.propTypes)
};

AddResourceModal.defaultProps = {
  onConfirm: () => {},
  onClose: () => {},
  selectedResources: []
};

export default AddResourceModal;
