import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import HeaderContext from '../../contexts/header-context';
import DateInput from 'components/date-input/date-input';
import CheckboxItem from 'components/checkbox-input/checkbox-input';
import SelectInput from 'components/select-input/select-input';
import InputErrorMessage from 'components/input-error-message';

const colors = {
  green: 'green',
  blue: 'blue',
  black: 'black'
};

const inputComponents = {
  date: DateInput,
  dropdown: SelectInput,
  checkbox: CheckboxItem
};

const validateInput = (value, inputObj, inputState) => {
  if (inputObj?.type === 'date' && inputObj?.max && inputObj?.min) {
    let theOtherDateInputField = inputState.find(
      el => el.type === 'date' && el.id !== inputObj.id
    );
    let valueTime = new Date(value).getTime();
    if (valueTime < inputObj.min.getTime()) {
      return { error: 'Dato før tillatt minimumsdato', id: inputObj.id };
    } else if (valueTime > inputObj.max.getTime()) {
      return { error: 'Dato etter tillatt maksimumsdato', id: inputObj.id };
    } else if (
      inputObj.label === 'Fra' &&
      new Date(theOtherDateInputField.value).getTime() < valueTime
    ) {
      return { error: 'Fra-dato er etter til-dato', id: inputObj.id };
    } else if (
      inputObj.label === 'Til' &&
      new Date(theOtherDateInputField.value).getTime() > valueTime
    ) {
      return { error: 'Til-dato er før fra-dato', id: inputObj.id };
    }
  }
  return;
};

const InspectorProgressionHeader = ({ name, color, inputs }) => {
  const { adminHeaderData, setAdminHeaderData } = useContext(HeaderContext);

  const currentName = useMemo(() => {
    return adminHeaderData?.name || name;
  }, [adminHeaderData?.name, name]);
  const currentInputs = useMemo(() => {
    return adminHeaderData?.inputs || inputs;
  }, [adminHeaderData?.inputs, inputs]);

  useEffect(() => {
    const inputData = currentInputs.map(input => {
      let newInput = input;
      if (input?.max) {
        newInput.max = new Date(input.max);
      }
      if (input?.min) {
        newInput.min = new Date(input.min);
      }
      return newInput;
    });
    setAdminHeaderData(prev => ({ ...prev, inputs: inputData, errors: true }));
  }, []);

  const handleChangeInput = (value, id) => {
    let tempInputState = [...currentInputs];
    let error = validateInput(
      value,
      tempInputState.find(el => el.id === id),
      tempInputState
    );
    if (!error?.error) {
      tempInputState.find(el => el.id === id).value = value;
      tempInputState.find(el => el.id === id).error = false;
      setAdminHeaderData(prev => ({ ...prev, inputs: tempInputState }));
    } else {
      tempInputState.find(el => el.id === id).error = error.error;
      setAdminHeaderData(prev => ({ ...prev, inputs: tempInputState }));
    }
    setAdminHeaderData(prev => ({
      ...prev,
      errors:
        tempInputState.some(input => input.error) ||
        tempInputState.some(input =>
          input.type === 'checkbox' ? false : !input.value
        )
    }));
  };

  return (
    <>
      <div
        className={cn('inspector-progression-header', {
          [`inspector-progression-header--${colors[color]}`]: colors[color]
        })}
      >
        <div className="inspector-progression-header__content">
          <div className="inspector-progression-header__heading-wrapper">
            <h1 className="inspector-progression-header__title">
              {currentName}
            </h1>
          </div>
          <div className="inspector-progression-header__inputs">
            {currentInputs?.map((input, i) => {
              let RenderComponent = inputComponents[input.type];
              let extraProps =
                input.type === 'checkbox' ? { value: false } : null;
              return (
                <InputErrorMessage
                  key={'inspector-progression-header-input-' + i}
                  errors={input?.error ? [input?.error] : []}
                >
                  <RenderComponent
                    {...input}
                    onChange={(val, id) => handleChangeInput(val, id)}
                    {...extraProps}
                    value={input?.value}
                  />
                </InputErrorMessage>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

InspectorProgressionHeader.propTypes = {
  color: PropTypes.oneOf(Object.values(colors)), //exclude from backend
  name: PropTypes.string,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      label: PropTypes.string
    })
  )
};

InspectorProgressionHeader.colors = colors;

InspectorProgressionHeader.defaultProps = {
  infos: [],
  color: colors.green
};

export default InspectorProgressionHeader;
