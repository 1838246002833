import React, { Fragment } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../../../icon';
import SlideInChildren from 'components/slide-in-children';
import { PortableText } from '@portabletext/react';
import { serializers } from '../serializers';

const SanityModalEntry = ({ id, title, brodtekst, underChapters }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const elementId = `info-container-${id}`;

  return (
    <div
      className={cn('sanity-modal-entry', {
        'sanity-modal-entry--open': isOpen
      })}
    >
      <button
        aria-controls={elementId}
        aria-expanded={isOpen}
        className="sanity-modal-entry__button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="sanity-modal-entry__button-content">
          {title && <span className="sanity-modal-entry__name">{title}</span>}
        </span>

        <span className="sanity-modal-entry__icon">
          <Icon
            className="sanity-modal-entry__icon-default"
            name={Icon.names.open}
            size={Icon.sizes.medium}
          />
          <Icon
            className="sanity-modal-entry__icon-hovered"
            name={Icon.names.openWhite}
            size={Icon.sizes.medium}
          />
        </span>
      </button>
      <SlideInChildren open={isOpen}>
        <div className="sanity-modal-entry__body">
          <PortableText components={serializers} value={brodtekst} />

          {underChapters?.length &&
            underChapters.map(
              ({ title: ucTitle, brodtekst: ucBrodtekst }, i) => (
                <Fragment key={i}>
                  <h3>{ucTitle}</h3>
                  {ucBrodtekst && (
                    <PortableText
                      components={serializers}
                      value={ucBrodtekst}
                    />
                  )}
                </Fragment>
              )
            )}
        </div>
      </SlideInChildren>
    </div>
  );
};

SanityModalEntry.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  brodtekst: PropTypes.any,
  underChapters: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      brodtekst: PropTypes.any
    })
  )
};

export default SanityModalEntry;
