import React from 'react';
import PropTypes from 'prop-types';

import useClickOutside from '../../hooks/use-click-outside';
import cn from 'classnames';

const DropdownBubble = ({
  children,
  isOpen,
  className,
  shouldArrowHoverChange,
  shouldUseMobileModal,
  handleClose,
  rightMargin
}) => {
  const dropdownBubbleRef = React.useRef();
  const mobileModalRef = React.useRef();
  useClickOutside(dropdownBubbleRef, handleClose, [mobileModalRef]);

  return (
    <div
      className={cn(
        'dropdown-bubble',
        className,
        { 'dropdown-bubble--is-open': isOpen },
        { 'dropdown-bubble--mobile-modal': shouldUseMobileModal }
      )}
      tabIndex="-1"
    >
      <div
        className="dropdown-bubble__list"
        style={rightMargin ? { marginRight: rightMargin } : undefined}
        ref={dropdownBubbleRef}
      >
        {children}
      </div>

      <div
        className={cn('dropdown-bubble__mobile-arrow', {
          'dropdown-bubble__mobile-arrow--hover': shouldArrowHoverChange
        })}
      />
    </div>
  );
};

DropdownBubble.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  shouldArrowHoverChange: PropTypes.bool,
  shouldUseMobileModal: PropTypes.bool,
  rightMargin: PropTypes.string,
  handleClose: PropTypes.func
};

DropdownBubble.propTypesMeta = 'exclude';

export default DropdownBubble;
