import React, { useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../../js/api-helper';

import Modal from '../modal';
import Clicker from '../../clicker';
import LoadingSpinner from '../../loading-spinner';

import QuickSearch from 'components/quick-search/quick-search';
import School from 'components/school/school';
import Accordion from 'components/accordion/accordion';

const MoveAllStudentsModal = ({
  isOpen,
  onClose,
  closeModalHiddenText,
  numberOfStudentsText,
  confirmButtonText,
  closeButtonText,
  lead,
  moveStudentsEndpoint,
  searchTitle,
  quickSearch,
  title,
  students
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [school, setSchool] = useState();
  const [confirmed, setConfirmed] = useState('');

  const handleOnConfirm = async () => {
    setIsLoading(true);
    try {
      let { confirmText } = await api.execute(moveStudentsEndpoint, {
        students,
        newOrgId: school?.orgNr
      });

      setConfirmed(confirmText);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleOnSchoolChoice = item => setSchool(item);

  return (
    <Modal
      themes={[Modal.themes.white]}
      isOpen={isOpen}
      onClose={onClose}
      isDisabled={isLoading}
      closeButtonLabel={closeModalHiddenText}
    >
      <div className="move-all-students-modal">
        <div className="move-all-students-modal__content">
          {title && <h2 className="move-all-students-modal__title">{title}</h2>}
          <p className="move-all-students-modal__lead">
            {!confirmed ? lead : confirmed}
          </p>
          {!confirmed && (
            <>
              <div className="move-all-students-modal__students">
                <Accordion
                  title={numberOfStudentsText}
                  theme="green"
                  headingLevel={3}
                >
                  <ul className="move-all-students-modal__students-list">
                    {students?.map(({ displayName, birthDate }, i) => (
                      <li
                        className="move-all-students-modal__student"
                        key={`move-student-${i}`}
                      >
                        <div className="move-all-students-modal__student-name">
                          {displayName && displayName}
                        </div>
                        {birthDate && (
                          <div className="move-all-students-modal__student-info">
                            {`Født: ${birthDate}`}
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </Accordion>
              </div>
              {searchTitle && (
                <h3 className="move-all-students-modal__search-title">
                  {searchTitle}
                </h3>
              )}
              <div className="move-all-students-modal__search">
                {!school ? (
                  <QuickSearch
                    {...quickSearch}
                    onItemClick={handleOnSchoolChoice}
                  />
                ) : (
                  <School
                    {...school}
                    themes={['grey', 'remove-selection']}
                    onRemoveSelection={() => setSchool(null)}
                  />
                )}
              </div>
            </>
          )}
          <div className="move-all-students-modal__actions">
            <div className="move-all-students-modal__action">
              <Clicker
                text={confirmed ? closeButtonText : confirmButtonText}
                theme={Clicker.themes.primary}
                onClick={confirmed ? onClose : handleOnConfirm}
                isDisabled={confirmed ? false : isLoading || !school}
              />
            </div>
            <div className="move-all-students-modal__action">
              {isLoading && <LoadingSpinner />}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

MoveAllStudentsModal.propTypes = {
  onClose: PropTypes.func, //exclude from backend
  isOpen: PropTypes.bool, //exclude from backend

  closeModalHiddenText: PropTypes.string,
  numberOfStudentsText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  lead: PropTypes.string,
  moveStudentsEndpoint: PropTypes.string,
  searchTitle: PropTypes.string,
  quickSearch: PropTypes.shape({
    endpoint: PropTypes.string,
    textInput: PropTypes.shape({
      defaultValue: PropTypes.any,
      id: PropTypes.string,
      inputKey: PropTypes.string,
      isDisabled: PropTypes.bool,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string
    })
  }),
  studentFodtLabel: PropTypes.string,
  title: PropTypes.string,
  students: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      orgId: PropTypes.string,
      displayName: PropTypes.string,
      birthDate: PropTypes.string
    })
  ) //exclude from backend
};

MoveAllStudentsModal.defaultProps = {
  onClose: () => {}
};

export default MoveAllStudentsModal;
