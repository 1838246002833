import React from 'react';
import PropTypes from 'prop-types';

import MainLayout from 'components/main-layout/main-layout';
import StructuralLayout from 'components/structural-layout/structural-layout';
import Elever from 'components/elever/elever';
import EleverContentContextProvider from '../../contexts/elever-content-context-provider';

const EleverPage = ({ structuralLayout, mainLayout, eleverContent }) => {
  return (
    <EleverContentContextProvider>
      <StructuralLayout {...structuralLayout}>
        <MainLayout {...mainLayout}>
          <Elever {...eleverContent} />
        </MainLayout>
      </StructuralLayout>
    </EleverContentContextProvider>
  );
};

EleverPage.propTypes = {
  structuralLayout: PropTypes.exact(StructuralLayout.propTypes),
  mainLayout: PropTypes.exact(MainLayout.propTypes),
  eleverContent: PropTypes.exact(Elever.propTypes)
};

export default EleverPage;
