import React from 'react';
import PropTypes from 'prop-types';

export default function QuestionExplanationBox({ title, lead, list, tooltip }) {
  return (
    <div className="explanation-box">
      {tooltip && <p>{tooltip}</p>}
      <h2 className="explanation-box__header">{title}</h2>
      <p className="explanation-box__lead">{lead}</p>
      {list && (
        <ul className="explanation-box__list">
          {list.map(item => (
            <li className="explanation-box__list-item" key={item}>
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

QuestionExplanationBox.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string),
  tooltip: PropTypes.exact({
    title: PropTypes.string.isRequired,
    lead: PropTypes.string.isRequired
  })
};
