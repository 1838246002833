import React from 'react';
import PropTypes from 'prop-types';

import ProgressBar from './progress-bar';

const ProgressBars = ({ bars }) => {
  return (
    <div className="progress-bars">
      <ul className="progress-bars__list">
        {bars.map((bar, index) => {
          return (
            <li key={index} className="progress-bars__item">
              <ProgressBar {...bar} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

ProgressBars.propTypes = {
  bars: PropTypes.arrayOf(PropTypes.exact(ProgressBar.propTypes))
};

ProgressBars.defaultProps = {
  bars: []
};

export default ProgressBars;
