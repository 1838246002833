import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '../modal';
import HistoryEntry from '../../history-entry';
import handleTabNavigationEvents from 'js/tab-menu-helper';
import cn from 'classnames';
import apiHelper from 'js/api-helper';
import LoadingSpinner from 'components/loading-spinner';

const tabs = {
  overall: 'overall-tab',
  kartlegging: 'kartlegging-tab'
};

const HistoryModal = ({
  isOpen,
  onClose,
  isDisabled,
  closeModalHiddenText,
  historyContentEndpoint
}) => {
  const [historyModalContent, setHistoryModalContent] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(tabs.overall);
  const handleTabNavigation = event =>
    handleTabNavigationEvents(event, Object.values(tabs));

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      apiHelper
        .get(historyContentEndpoint)
        .then(result => {
          setHistoryModalContent(result);
          setIsLoading(false);
        })
        .catch(_error => setIsLoading(false));
    }
  }, [isOpen]);

  let {
    title,
    htmlLead,
    overallTabText,
    overallEntries,
    kartleggingTabText,
    kartleggingEntries
  } = historyModalContent || {};

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isDisabled={isDisabled}
      closeButtonLabel={closeModalHiddenText}
      className="history-modal"
    >
      {isLoading ? (
        <LoadingSpinner className="history-modal__loading" large />
      ) : (
        <div className="history-modal__content">
          {title && (
            <h2 id="history-title" className="history-modal__title">
              {title}
            </h2>
          )}
          {htmlLead && (
            <div
              className="history-modal__lead"
              dangerouslySetInnerHTML={{ __html: htmlLead }}
            />
          )}
          <div
            className="history-modal__tab-list"
            role="tablist"
            aria-labelledby="history-title"
            onKeyDown={handleTabNavigation}
          >
            {overallTabText && (
              <button
                id={tabs.overall}
                className={cn('history-modal__tab', {
                  'history-modal__tab--active': currentTab === tabs.overall
                })}
                role="tab"
                aria-selected={currentTab === tabs.overall}
                aria-controls="overall-panel"
                onClick={() => setCurrentTab(tabs.overall)}
                tabIndex={currentTab === tabs.overall ? 0 : -1}
              >
                {overallTabText}
              </button>
            )}
            {kartleggingTabText && (
              <button
                id={tabs.kartlegging}
                className={cn('history-modal__tab', {
                  'history-modal__tab--active': currentTab === tabs.kartlegging
                })}
                role="tab"
                aria-selected={currentTab === tabs.kartlegging}
                aria-controls="kartlegging-panel"
                onClick={() => setCurrentTab(tabs.kartlegging)}
                tabIndex={currentTab === tabs.kartlegging ? 0 : -1}
              >
                {kartleggingTabText}
              </button>
            )}
          </div>
          {currentTab === tabs.overall && (
            <div
              id="overall-panel"
              role="tabpanel"
              aria-labelledby={tabs.overall}
            >
              <menu className="history-modal__list ">
                {overallEntries?.map((entry, index) => {
                  return (
                    <li key={index} className="history-modal__item">
                      <HistoryEntry id={`${index}`} {...entry} />
                    </li>
                  );
                })}
              </menu>
            </div>
          )}
          {currentTab === tabs.kartlegging && (
            <div
              id="kartlegging-panel"
              role="tabpanel"
              aria-labelledby={tabs.kartlegging}
            >
              <menu className="history-modal__list ">
                {kartleggingEntries?.map((entry, index) => {
                  return (
                    <li key={index} className="history-modal__item">
                      <HistoryEntry id={`${index}`} {...entry} />
                    </li>
                  );
                })}
              </menu>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

HistoryModal.propTypes = {
  isDisabled: PropTypes.bool, //exclude from backend
  onClose: PropTypes.func, //exclude from backend
  isOpen: PropTypes.bool, //exclude from backend
  closeModalHiddenText: PropTypes.string,
  historyContentEndpoint: PropTypes.string.isRequired
  //title: PropTypes.string,
  //htmlLead: PropTypes.string,
  //overallTabText: PropTypes.string,
  //overallEntries: PropTypes.arrayOf(PropTypes.exact(HistoryEntry.propTypes)),
  //kartleggingTabText: PropTypes.string,
  //kartleggingEntries: PropTypes.arrayOf(PropTypes.exact(HistoryEntry.propTypes))
};

HistoryModal.defaultProps = {
  onConfirm: () => {},
  onClose: () => {},
  entries: []
};

export default HistoryModal;
