/* eslint-disable no-use-before-define */

let sanityClient = () => {};
let projectId = '';
let dataset = '';
let apiVersion = '2021-03-25';
let token = '';

if (typeof window !== 'undefined') {
  sanityClient = require('@sanity/client');

  projectId =
    sessionStorage.getItem('projectId') ||
    process.env.SANITY_PROJECT_ID ||
    projectId;
  dataset =
    sessionStorage.getItem('dataset') || process.env.SANITY_DATASET || dataset;
  token = sessionStorage.getItem('token') || process.env.SANITY_TOKEN || token;
  apiVersion =
    sessionStorage.getItem('apiVersion') ||
    process.env.SANITY_API_VERSION ||
    apiVersion;
}

export default sanityClient({
  projectId,
  dataset,
  token,
  apiVersion,
  useCdn: true
});
