import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import useClickOutside from '../../hooks/use-click-outside';

import Clicker from '../clicker';
import Icon from '../icon';

const TableMenuButton = ({
  items,
  isDisabled,
  menuButtonText,
  closeDropdownButtonLabel
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const menuRef = useRef();
  useClickOutside(menuRef, () => setIsOpen(false));

  return (
    <span
      ref={menuRef}
      className={cn('table-menu-button', {
        'table-menu-button--active': isOpen,
        'table-menu-button--disabled': isDisabled
      })}
    >
      <Clicker
        onClick={() => setIsOpen(prev => !prev)}
        text={menuButtonText}
        disabled={isDisabled}
        aria-expanded={isOpen}
        isActive={isOpen}
        textIsHidden={true}
        iconName={Icon.names.more}
        iconSize={Clicker.iconSizes.mediumPlus}
        activeIconName={Icon.names.moreBlue}
        iconIsPadded={true}
      />

      {isOpen && (
        <span className="table-menu-button__menu">
          <Clicker
            text={closeDropdownButtonLabel}
            textIsHidden={true}
            iconName={Clicker.iconNames.closeCircleBlack}
            onClick={() => setIsOpen(false)}
            className="table-menu-button__close-button"
          />
          <menu className="table-menu-button__list">
            {items.map((item, index) => {
              const isLink = !!item?.href;

              return item.text ? (
                <li key={index} className="table-menu-button__item">
                  <Clicker
                    {...item}
                    theme={Clicker.themes.hoverBlueNoUnderline}
                    onClick={
                      isLink
                        ? undefined
                        : () => {
                            setIsOpen(false);
                            item.onClick();
                          }
                    }
                  />
                </li>
              ) : undefined;
            })}
          </menu>
        </span>
      )}
    </span>
  );
};

TableMenuButton.propTypes = {
  isDisabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.exact(Clicker.propTypes)).isRequired,
  menuButtonText: PropTypes.string,
  closeDropdownButtonLabel: PropTypes.string
};

TableMenuButton.defaultProps = {
  items: []
};

export default TableMenuButton;
