import { useEffect } from 'react';

export default function useBeforeUnload(preventDefault) {
  useEffect(() => {
    if (!preventDefault) {
      window.onhashchange = null;
      window.onbeforeunload = null;
      return;
    }
    window.addEventListener('hashchange', onhashChange);
    window.onbeforeunload = confirmExit;
    function confirmExit() {
      return 'show warning';
    }
    function onhashChange(e) {
      e.preventDefault();
    }

    return () => {
      window.onhashchange = null;
      window.onbeforeunload = null;
    };
  }, [preventDefault]);
}
