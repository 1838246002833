import React from 'react';
import PropTypes from 'prop-types';

import Clicker from 'components/clicker';

const SkipLink = ({ linkText }) => (
  <Clicker className="skip-link" href={'#main-content'} text={linkText} />
);

SkipLink.propTypes = {
  linkText: PropTypes.string.isRequired
};

export default SkipLink;
