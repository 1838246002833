import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import api from 'js/api-helper';

import ResourceTable from 'components/resource-table';
import ActionBar from 'components/action-bar';
import LoadingSpinner from 'components/loading-spinner';
import SchoolAdminTable from './school-admin-table';
import ConfirmationModal from 'components/modal/confirmation-modal';
import Clicker from 'components/clicker/clicker';
import HeaderContext from '../../../contexts/header-context';
import Tabs from 'components/tabs/tabs';

const SchoolAdmin = ({
  updateNestedValue,
  tabs,
  title,
  showMenus,
  addButtonText,
  table,
  actionBar,
  addEndpoint,
  removeEndpoint,
  rejectRemoveEndpoint,
  undoRemoveRequestEndpoint,
  confirmationModal,
  rejectUndoConfirmationModal,
  getUpdatedHeaderEndpoint,
  toggleStatusEndpoint
}) => {
  const [state, setState] = useState({ table });
  const [isLoading, setIsLoading] = useState(false);
  const { setProfileHeaderData } = useContext(HeaderContext);
  const handleOnAdd = async () => {
    setIsLoading(true);

    await api.execute(addEndpoint, {});
  };

  const handleOnToggleStatus = async resource => {
    setIsLoading(true);

    const { id, status } = resource;

    try {
      const newResource = await api.execute(toggleStatusEndpoint, {
        id,
        isActive: status !== 'green'
      });

      const updatedTable = {
        ...state.table,
        resources: state.table.resources.map(oldResource => {
          if (newResource.id === oldResource.id) return newResource;

          return oldResource;
        })
      };
      updateNestedValue({ table: updatedTable });

      setState({
        ...state,
        table: updatedTable
      });
      if (status === 'green') {
        const newHeaderInfos = await api.get(
          getUpdatedHeaderEndpoint + '?id=' + id
        );
        setProfileHeaderData(_profileHeaderData => ({
          name: _profileHeaderData?.name,
          infos: Array.isArray(newHeaderInfos?.infos)
            ? newHeaderInfos?.infos
            : undefined,
          inputs: Array.isArray(newHeaderInfos?.inputs)
            ? newHeaderInfos?.inputs
            : undefined
        }));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
  };

  const handleMapping = async (resource, endpoint, extras = {}) => {
    setIsLoading(true);
    const { id } = resource;
    try {
      const newResource = await api.execute(endpoint, {
        id,
        ...extras
      });

      if (newResource.id !== undefined) {
        const updatedTable = {
          ...state.table,
          resources: state.table.resources.map(oldResource => {
            if (newResource.id === oldResource.id) return newResource;

            return oldResource;
          })
        };
        updateNestedValue({ table: updatedTable });

        setState({
          ...state,
          table: updatedTable
        });
      } else {
        const updatedTable = {
          ...state.table,
          resources: state.table.resources.filter(
            resource => resource.id !== id
          )
        };
        updateNestedValue({ table: updatedTable });
        setState({
          ...state,
          table: updatedTable
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
    // window.location.reload();
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return (
    <>
      <div className="school-admin">
        <div className="school-admin__content">
          {tabs && (
            <div className={'school-admin__tabs'}>
              <Tabs {...tabs} />
            </div>
          )}

          {title && <h1 className="school-admin__title">{title}</h1>}
          <div className={'school-admin__actions-wrapper'}>
            {addButtonText && addEndpoint && (
              <Clicker
                theme={Clicker.themes.primary}
                text={addButtonText}
                onClick={handleOnAdd}
              />
            )}
            {actionBar && (
              <div className="school-admin__actions">
                <div className="school-admin__action">
                  <ActionBar {...actionBar} />
                </div>
              </div>
            )}
          </div>

          <div className="school-admin__row">
            <SchoolAdminTable
              {...state}
              showMenus={showMenus}
              confirmationModal={confirmationModal}
              rejectUndoConfirmationModal={rejectUndoConfirmationModal}
              onToggleStatus={handleOnToggleStatus}
              onConfirmModal={handleMapping}
              endpoints={{
                removeEndpoint,
                rejectRemoveEndpoint,
                undoRemoveRequestEndpoint
              }}
            />
          </div>
        </div>
      </div>
      {isLoading && <LoadingSpinner shouldTakeOverScreen={true} />}
    </>
  );
};

SchoolAdmin.propTypes = {
  title: PropTypes.string,
  tabs: PropTypes.exact(Tabs.propTypes),

  showMenus: PropTypes.bool,
  removeEndpoint: PropTypes.string,
  actionBar: PropTypes.exact(ActionBar.propTypes),
  addButtonText: PropTypes.string,
  addEndpoint: PropTypes.string.isRequired,
  toggleStatusEndpoint: PropTypes.string.isRequired,
  getUpdatedHeaderEndpoint: PropTypes.string.isRequired,
  rejectRemoveEndpoint: PropTypes.string,
  undoRemoveRequestEndpoint: PropTypes.string,
  confirmationModal: PropTypes.exact(ConfirmationModal.propTypes),
  rejectUndoConfirmationModal: PropTypes.exact(ConfirmationModal.propTypes),
  table: PropTypes.exact(ResourceTable.propTypes),
  updateNestedValue: PropTypes.func // ignore backend
};

SchoolAdmin.defaultProps = {
  updateNestedValue: () => {}
};

export default SchoolAdmin;
