import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon';

const MissingRole = ({ title, htmlLead, leadBoxTitle, htmlLeadBox }) => {
  return (
    <div className="missing-role">
      {title && <h1 className="missing-role__title">{title}</h1>}
      {htmlLead && (
        <div
          dangerouslySetInnerHTML={{ __html: htmlLead }}
          className="missing-role__lead"
        />
      )}
      <div className="missing-role-box">
        <div className="missing-role-box__box-content">
          <span className="missing-role-box__icon-margin">
            <Icon name={Icon.names.login_box} size={Icon.sizes.xxl} />
          </span>
          <div>
            <h3 className="missing-role-box__title-box">{leadBoxTitle}</h3>
            {htmlLeadBox && (
              <div
                dangerouslySetInnerHTML={{ __html: htmlLeadBox }}
                className="missing-role__lead"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

MissingRole.propTypes = {
  title: PropTypes.string,
  htmlLead: PropTypes.string,
  leadBoxTitle: PropTypes.string,
  htmlLeadBox: PropTypes.string
};

MissingRole.defaultProps = {
  selections: []
};

export default MissingRole;
