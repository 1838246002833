import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';

const SiteInfoBanner = ({ infoTextAsHtml }) => {
  return (
    <div className="site-info-banner">
      <div className="site-info-banner__banner">
        <div className="site-info-banner__content">
          <Icon
            className="site-info-banner__icon"
            name={Icon.names.warningWhite}
            size={Icon.sizes.medium}
          />
          <div dangerouslySetInnerHTML={{ __html: infoTextAsHtml }} />
        </div>
      </div>
    </div>
  );
};

SiteInfoBanner.propTypes = {
  infoTextAsHtml: PropTypes.string
};

export default SiteInfoBanner;
