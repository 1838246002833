import React from 'react';
import PropTypes from 'prop-types';

import AnonymousLayout from '../layouts/anonymous-layout';

const NedetidPage = ({ layout, title, htmlLead }) => {
  return (
    <AnonymousLayout {...layout}>
      <div className="nedetid-page">
        <div>
          <div className="nedetid-page">
            <h1 className="nedetid-page__title">{title}</h1>

            {htmlLead && (
              <div
                className="nedetid-page__html"
                dangerouslySetInnerHTML={{ __html: htmlLead }}
              />
            )}
          </div>
        </div>
      </div>
    </AnonymousLayout>
  );
};

NedetidPage.propTypes = {
  layout: PropTypes.exact(AnonymousLayout.propTypes),
  title: PropTypes.string.isRequired,
  htmlLead: PropTypes.string
};

export default NedetidPage;
