import React, { useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../js/api-helper';

import TextInput from '../text-input';
import LoadingSpinner from '../loading-spinner';

import Person from '../person';
import QuickSearchSchool from '../person/quick-search-school';
import useDebounce from '../../hooks/use-debounce';

const MIN_CHARACTERS = 2;
const DEBOUNCE_TIME = 500;

const QuickSearch = ({
  textInput,
  isDisabled,
  endpoint,
  studentId,
  blacklistSearchResult,
  onItemClick,
  roleAssignmentCode,
  orgId
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [activeTextInput, setActiveTextInput] = useState({
    ...textInput,
    value: ''
  });

  const debouncedSearchText = useDebounce(activeTextInput.value, DEBOUNCE_TIME);

  const resetList = () => {
    setItems([]);
    setMessage('');
    setIsOpen(false);
  };

  const handleOnChange = value => {
    setActiveTextInput({ ...activeTextInput, value: value });
  };

  const executeSearch = async () => {
    setIsLoading(true);

    const data = studentId
      ? {
          studentId,
          value: activeTextInput.value
        }
      : {
          value: activeTextInput.value,
          orgId: orgId,
          roleAssignmentCode: roleAssignmentCode
        };
    try {
      const { items, message } = await api.execute(endpoint, data);

      if (items?.length) {
        const filteredItems = blacklistSearchResult
          ? items.filter(({ id }) => {
              return !blacklistSearchResult.some(bItem => bItem.id === id);
            })
          : items;

        if (filteredItems?.length > 0) setIsOpen(true);

        message ? setMessage(message) : setMessage('');

        setItems(filteredItems);
      } else {
        setItems([]);
        message ? setMessage(message) : setMessage('');
      }
      setIsLoading(false);
    } catch (err) {
      setItems([]);
      err.message ? setMessage(err.message) : setMessage('');
      setIsLoading(false);
    }
  };

  const handleOnResultClick = item => {
    resetList();

    onItemClick(item);

    setActiveTextInput({ ...activeTextInput, value: '' });
  };

  React.useEffect(() => {
    if (debouncedSearchText.length >= MIN_CHARACTERS) {
      executeSearch();
    }
  }, [debouncedSearchText]);

  return (
    <div className="quick-search">
      <div className="quick-search__input">
        <TextInput
          {...activeTextInput}
          autoFocus
          theme={TextInput.themes.underlined}
          onChange={handleOnChange}
          isDisabled={isDisabled}
          hasAutoComplete={false}
        />
        {isLoading && (
          <div className="quick-search__loading">
            <LoadingSpinner />
          </div>
        )}
      </div>
      <div className="quick-search__results">
        {isOpen && (
          <div className="quick-search__menu">
            <ul className="quick-search__list">
              {items?.map((item, index) => {
                if (item.orgNr) {
                  return (
                    <li key={index} className="quick-search__item">
                      <QuickSearchSchool
                        {...item}
                        isDisabled={isLoading}
                        onClick={() => handleOnResultClick(item)}
                      />
                    </li>
                  );
                } else
                  return (
                    <li key={index} className="quick-search__item">
                      <Person
                        {...item}
                        isDisabled={isLoading || item.isDisabled}
                        onClick={() => handleOnResultClick(item)}
                      />
                    </li>
                  );
              })}
            </ul>
          </div>
        )}
      </div>
      {message && <p className="quick-search__message">{message}</p>}
    </div>
  );
};

QuickSearch.propTypes = {
  isDisabled: PropTypes.bool, //exclude from backend
  onItemClick: PropTypes.func, //exclude from backend
  roleAssignmentCode: PropTypes.string, //exclude from backend
  orgId: PropTypes.string, //exclude from backend
  textInput: PropTypes.exact(TextInput.propTypes),
  endpoint: PropTypes.string.isRequired,
  studentId: PropTypes.string,
  blacklistSearchResult: PropTypes.array
};

export default QuickSearch;
