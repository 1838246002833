import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import useClickOutside from '../../hooks/use-click-outside';
import Icon from '../icon';

import UserContext from '../../contexts/user-context';

const themes = { dark: 'dark', default: 'default' };

const UserSwitcher = ({
  name,
  role,
  logoutLink,
  links,
  theme,
  userSwitcherOpenUUText,
  userSwitcherCloseUUText
}) => {
  const [isOpen, setIsOpen] = useState(false);

  //TO DO: Find better way to do this for pages that don't use context. If all pages should use context links should be initialised in the context nnot in UserSwitcher first.
  const { roles, updateRoles } = useContext(UserContext) || { roles: links };

  const componentRef = useRef();

  useClickOutside(componentRef, () => setIsOpen(false));

  React.useEffect(() => {
    updateRoles && updateRoles(links);
  }, []);

  return (
    <div
      className={cn('user-switcher', {
        'user-switcher--open': isOpen,
        [`user-switcher--${themes[theme]}`]: themes[theme]
      })}
      ref={componentRef}
    >
      <button
        className="user-switcher__button"
        onClick={() => setIsOpen(!isOpen)}
        aria-label={
          !isOpen
            ? userSwitcherOpenUUText || 'Åpne rollevelger'
            : userSwitcherCloseUUText || 'Lukk rollevelger'
        }
        aria-expanded={isOpen}
      >
        <span className="user-switcher__text">
          {name && <span className="user-switcher__name">{name}</span>}
          {role && <span className="user-switcher__role">{role}</span>}
        </span>

        <span className="user-switcher__profile-icon">
          <Icon
            name={
              theme === themes.dark
                ? Icon.names.login_neg
                : Icon.names.login_pos
            }
            size={Icon.sizes.largePlus}
          />
        </span>
      </button>
      {isOpen && (
        <div className="user-switcher__menu">
          <ul className="user-switcher__menu-list">
            {roles?.map((link, index) => {
              return (
                <li
                  className="user-switcher__menu-item"
                  key={'user-switcher-role-' + index}
                >
                  <a href={link?.href} className="user-switcher__menu-link">
                    <span className="user-switcher__menu-role-text">
                      {link.text}
                    </span>
                    <span className="user-switcher__menu-role-description">
                      {link.description}
                    </span>
                  </a>
                </li>
              );
            })}
            {logoutLink && (
              <li className="user-switcher__menu-link-item">
                <a
                  className="user-switcher__logout-link"
                  href={logoutLink?.href}
                >
                  {logoutLink?.text}
                </a>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

UserSwitcher.propTypes = {
  name: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(Object.values(themes)), // ignore from backend
  role: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      description: PropTypes.string
    })
  ),
  logoutLink: PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
  }),
  userSwitcherOpenUUText: PropTypes.string,
  userSwitcherCloseUUText: PropTypes.string
};

UserSwitcher.defaultProps = {
  links: [],
  theme: themes.default
};

UserSwitcher.themes = themes;

export default UserSwitcher;
