import React, { useEffect, useState } from 'react';
import Icon from 'components/icon';
import cn from 'classnames';
import PropTypes from 'prop-types';

const HighlightedAnchorLinkList = ({ list }) => {
  const [currentAnchorLink, setCurrentAnchorLink] = useState(list[0].href);

  const handleScroll = () => {
    list.forEach(link => {
      const anchorElement = document.querySelector(link.href);
      const top = anchorElement?.getBoundingClientRect().top;

      if (window.innerHeight / 3 > top) {
        setCurrentAnchorLink(link.href);
      }
    });
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <ul className="navigation-link-list__anchor-list">
      {list.map(anchorLink => (
        <li
          key={anchorLink.href}
          className={cn('navigation-link-list__item', {
            'navigation-link-list__item--current':
              anchorLink.href === currentAnchorLink
          })}
        >
          <a className="navigation-link-list__link" href={anchorLink.href}>
            {anchorLink.text}
            <Icon
              className="navigation-link-list__arrow-icon navigation-link-list__anchor-arrow"
              name={Icon.names.arrowRightThick}
              size={Icon.sizes.medium}
            />
            <Icon
              className="navigation-link-list__arrow-icon navigation-link-list__anchor-arrow navigation-link-list__anchor-arrow--hover"
              name={Icon.hoverNames.hoverArrowRightThick}
              size={Icon.sizes.medium}
            />
          </a>
        </li>
      ))}
    </ul>
  );
};

HighlightedAnchorLinkList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.exact({ href: PropTypes.string, text: PropTypes.string })
  )
};

export default HighlightedAnchorLinkList;
