import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Clicker from '../clicker/clicker';

const ArrowTooltip = forwardRef(
  (
    {
      isOpen,
      onClose,
      title,
      lead,
      list,
      top,
      left,
      children,
      closeButtonLabel
    },
    ref
  ) => {
    if (!isOpen) return null;

    return (
      <span ref={ref} className="arrow-tooltip" style={{ top: top }}>
        <div className="arrow-tooltip__arrow" style={{ left: left }} />
        <span className="arrow-tooltip__content">
          {title && <b className="arrow-tooltip__title">{title}: </b>}
          {lead && <div dangerouslySetInnerHTML={{ __html: lead }} />}
          {list && !!list.length && (
            <ul className="arrow-tooltip__text-list">
              {list.map((listItem, index) => (
                <li
                  key={listItem + index}
                  className="arrow-tooltip__text-list-item"
                >
                  {listItem}
                </li>
              ))}
            </ul>
          )}
          {children}
        </span>
        <Clicker
          text={closeButtonLabel}
          textIsHidden={true}
          iconName={Clicker.iconNames.closeCircleBlack}
          onClick={() => onClose()}
          theme={Clicker.themes.alignedRight}
          className="arrow-tooltip__close-button"
        />
      </span>
    );
  }
);

ArrowTooltip.textProps = {
  title: PropTypes.string,
  lead: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string)
};

ArrowTooltip.propTypes = {
  onClose: PropTypes.func, //exclude from backend
  isOpen: PropTypes.bool, //exclude from backend
  closeButtonLabel: PropTypes.string,
  ...ArrowTooltip.textProps
};

ArrowTooltip.defaultProps = {
  onConfirm: () => {},
  onClose: () => {}
};

export default ArrowTooltip;
