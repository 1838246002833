import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import api from '../../js/api-helper';

import TextInput from '../text-input';
import LoadingSpinner from '../loading-spinner';

import useDebounce from '../../hooks/use-debounce';

const TextInputWithValidation = ({
  endpoint,
  theme,
  textInput,
  onBlur,
  isDisabled,
  onValidated,
  isValid,
  maxLength,
  onChange,
  debounceTimeInMs,
  minCharacters,
  message
}) => {
  const [state, setState] = useState({
    textInput,
    isValid,
    isDirty: false,
    isLoading: false,
    message,
    errors: []
  });

  const debouncedValue = useDebounce(state.textInput.value, debounceTimeInMs);

  const setValue = value => {
    if (state.isValid) onValidated(false, '');

    setState({
      ...state,
      isValid: false,
      errors: [],
      message: '',
      textInput: {
        ...state.textInput,
        value
      }
    });
  };

  const setMessage = message => {
    setState({
      ...state,
      message
    });
  };

  const setIsLoading = isLoading => {
    setState({
      ...state,
      isValid: false,
      isLoading
    });
  };

  const resetErrors = () => {
    setState({
      ...state,
      errors: []
    });
  };

  const executeValidation = async () => {
    resetErrors();
    setMessage('');
    setIsLoading(true);

    const value = state?.textInput?.value;

    try {
      const { errors, message } = await api.execute(endpoint, {
        value
      });

      if (errors?.length > 0) {
        setState({
          ...state,
          isValid: false,
          errors
        });

        onValidated(false, '');

        return;
      }

      if (message)
        setState({
          ...state,
          isValid: true,
          message
        });

      onValidated(true, value);

      return;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (debouncedValue?.length >= minCharacters) {
      executeValidation();
    }
  }, [debouncedValue]);

  return (
    <div
      className={cn('text-input-with-validation', {
        'text-input-with-validation--errors': !state.isValid
      })}
    >
      <div className="text-input-with-validation__input">
        <div className="text-input-with-validation__input-content">
          <TextInput
            {...state.textInput}
            maxLength={maxLength}
            isDisabled={isDisabled}
            theme={theme}
            onChange={value => {
              setValue(value);

              onChange(state.isValid);
            }}
            onBlur={() => {
              onBlur(state.isValid);
            }}
          />
          {state.isLoading && (
            <div className="text-input-with-validation__loading">
              <LoadingSpinner />
            </div>
          )}
        </div>
        <div className="text-input-with-validation__input-message">
          {state.message}
        </div>
      </div>
      {state?.errors && (
        <div className="text-input-with-validation__errors">
          <ul>
            {state.errors.map(({ text }, index) => {
              return <li key={index}>{text}</li>;
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

TextInputWithValidation.propTypes = {
  isValid: PropTypes.bool, // exclude from backend
  onBlur: PropTypes.func, // exclude from backend
  onChange: PropTypes.func, // exclude from backend
  onValidated: PropTypes.func, // exclude from backend
  isDisabled: PropTypes.bool, // exclude from backend
  maxLength: PropTypes.number, // exclude from backend
  theme: PropTypes.number, // exclude from backend
  textInput: PropTypes.exact(TextInput.propTypes),
  endpoint: PropTypes.string.isRequired,
  message: PropTypes.string,
  debounceTimeInMs: PropTypes.number,
  minCharacters: PropTypes.number
};

TextInputWithValidation.defaultProps = {
  onBlur: () => {},
  theme: TextInput.themes.default,
  onChange: () => {},
  onValidated: () => {},
  debounceTimeInMs: 500,
  minCharacters: 2,
  message: ''
};

TextInputWithValidation.themes = TextInput.themes;

export default TextInputWithValidation;
