import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import api from 'js/api-helper';
import filterArraysWithoutDuplicatesByKey from 'js/filter-arrays-without-duplicates-by-key';

import usePhrases from '../../../hooks/use-phrases';

import StudentAdminTable from './student-admin-table';
import LoadingSpinner from 'components/loading-spinner';
import ResourceTable from 'components/resource-table';
import AssignTeacherModal from 'components/modal/assign-teacher-modal/assign-teacher-modal';
import AddStudentModal from 'components/modal/add-student-modal/add-student-modal';
import MoveStudentModal from 'components/modal/move-student-modal/move-student-modal';
import RejectMoveStudentModal from 'components/modal/reject-move-student-modal/reject-move-student-modal';
import MoveAllStudentsModal from 'components/modal/move-all-students-modal/move-all-students-modal';
import Clicker from 'components/clicker';
import Tabs from 'components/tabs/tabs';
import AddStudentsFromFile from 'components/add-students-from-file/add-students-from-file';
import ConfirmationModal from 'components/modal/confirmation-modal';

const StudentAdmin = ({
  updateNestedValue,
  title,
  table,
  tabs,
  showMenus,
  removeEndpoint,
  confirmationModal,
  editEndpoint,
  assignTeacherEndpoint,
  addStudentsFromFile,
  addStudentModal,
  assignTeacherModal,
  moveStudentModal,
  rejectMoveStudentModal,
  getStudentEndpoint,
  getAllFlyttingerForElevEndpoint,
  moveAllStudentsButtonText,
  moveAllStudentsModal,
  handleRefreshData
}) => {
  const { addStudent, addStudentFromFile, editStudent } = usePhrases();

  const [state, setState] = useState({ table });
  const [isLoading, setIsLoading] = useState(false);

  const [addStudentsFromFileIsOpen, setAddStudentsFromFileIsOpen] = useState(
    false
  );
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [assignTeacherModalIsOpen, setAssignTeacherModalIsOpen] = useState(
    false
  );
  const [moveStudenModalIsOpen, setMoveStudenModalIsOpen] = useState(false);
  const [
    rejectMoveStudenModalIsOpen,
    setRejectMoveStudenModalIsOpen
  ] = useState(false);
  const [editModal, setEditModal] = useState({
    ...addStudentModal,
    title: editStudent
  });
  const [teacherModal, setTeacherModal] = useState(assignTeacherModal);
  const [moveModal, setMoveModal] = useState(moveStudentModal);
  const [rejectMoveModal, setRejectMoveModal] = useState(
    rejectMoveStudentModal
  );
  const [moveAllStudentsModalIsOpen, setMoveAllStudentsModalIsOpen] = useState(
    false
  );

  const handleOnEditClick = async resources => {
    setIsLoading(true);

    const { id } = resources[0];

    const {
      firstName,
      lastName,
      birthNumber,
      dufNumber,
      dNumber,
      grade
    } = await api.execute(editEndpoint, { id });

    setIsLoading(false);

    setEditModal({
      ...editModal,
      id,
      isEditing: true,
      firstNameTextInput: {
        ...editModal?.firstNameTextInput,
        value: firstName
      },
      lastNameTextInput: {
        ...editModal?.lastNameTextInput,
        value: lastName
      },
      dNumberTextInput: {
        ...editModal?.dNumberTextInput,
        value: dNumber
      },
      birthNumberTextInput: {
        ...editModal?.birthNumberTextInput,
        value: birthNumber
      },
      dufNumberTextInput: {
        ...editModal?.dufNumberTextInput,
        value: dufNumber
      },
      gradeInput: {
        ...editModal?.gradeInput,
        value: grade
      }
    });

    setEditModalIsOpen(true);
  };

  const handleOnRemoveClick = async ids => {
    setIsLoading(true);

    try {
      await api.execute(removeEndpoint, { ids });

      const updatedTable = {
        ...state.table,
        resources: state.table.resources.filter(resource => {
          return ids.indexOf(resource.id) === -1;
        })
      };
      setState({
        ...state,
        table: updatedTable
      });
      updateNestedValue({ table: updatedTable });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
  };

  const handleOnAddConfirm = resources => {
    setState({
      ...state,
      table: {
        ...state.table,
        resources: filterArraysWithoutDuplicatesByKey(
          state.table.resources,
          resources,
          'id'
        )
      }
    });

    setAddModalIsOpen(false);
  };

  const handleOnEditConfirm = resource => {
    const { id } = resource;

    const updatedTable = {
      ...state.table,
      resources: state.table.resources.map(oldResource => {
        if (oldResource.id === id) return resource;

        return oldResource;
      })
    };
    updateNestedValue({ table: updatedTable });
    setState({
      ...state,
      table: updatedTable
    });

    setEditModalIsOpen(false);
  };

  const handleOnAssignTeacherClick = async resources => {
    setIsLoading(true);

    const id = resources[0].id;

    try {
      const { assignedTeachers } = await api.execute(assignTeacherEndpoint, {
        id
      });

      setTeacherModal({
        ...assignTeacherModal,
        assignedTeachers,
        studentId: id
      });

      setAssignTeacherModalIsOpen(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
  };

  const handleOnMoveStudentClick = async resources => {
    setIsLoading(true);

    const id = resources[0].id;

    try {
      const payload = await api.execute(getStudentEndpoint, {
        id
      });

      setMoveModal({
        ...moveModal,
        ...payload,
        studentId: id
      });

      setMoveStudenModalIsOpen(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
  };

  const handleOnMoveStudentConfirm = id => {
    const updatedTable = {
      ...state.table,
      resources: state.table.resources.filter(
        oldResource => oldResource.id !== id
      )
    };
    updateNestedValue({ table: updatedTable });
    setState({
      ...state,
      table: updatedTable
    });
  };

  const handleOnAssignTeacherConfirm = resources => {
    const updatedTable = {
      ...state.table,
      resources: filterArraysWithoutDuplicatesByKey(
        state.table.resources,
        resources,
        'id'
      )
    };
    updateNestedValue({ table: updatedTable });
    setState({
      ...state,
      table: updatedTable
    });

    setAssignTeacherModalIsOpen(false);
  };

  const handleOnRejectMoveStudentClick = async resources => {
    setIsLoading(true);

    const id = resources[0].id;
    const studentName = resources[0].displayName;

    try {
      const payload = await api.execute(getAllFlyttingerForElevEndpoint, {
        id
      });

      if (payload.length === 1) {
        const orgid = payload[0].onskesFlyttetTil;
        const orgidAsArray = [orgid];

        await api.execute(rejectMoveModal.rejectMoveStudentEndpoint, {
          studentId: id,
          orgIds: orgidAsArray
        });
        window.location.reload();
      } else {
        setRejectMoveModal({
          ...rejectMoveModal,
          payload,
          studentId: id,
          studentName: studentName
        });

        setRejectMoveStudenModalIsOpen(true);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    setIsLoading(false);
  };

  const handleOnMoveStudentReject = async schoolNames => {
    const updatedTable = {
      ...state.table,
      resources: state.table.resources.map(resource => ({
        ...resource,
        requestMove: schoolNames !== null
      }))
    };
    setState({
      ...state,
      table: updatedTable
    });
    updateNestedValue({ table: updatedTable });
    setRejectMoveStudenModalIsOpen(false);
    window.location.reload();
  };

  useEffect(() => {
    setState({ table });
  }, [table]);

  return (
    <>
      <div className="student-admin">
        <div className="student-admin__content">
          {addStudentsFromFileIsOpen ? (
            <AddStudentsFromFile
              {...addStudentsFromFile}
              onClickGoBack={() => {
                setAddStudentsFromFileIsOpen(false);
                handleRefreshData(true);
              }}
            />
          ) : (
            <>
              {tabs && (
                <div className={'student-admin__tabs'}>
                  <Tabs {...tabs} />
                </div>
              )}
              {title && <h1 className="student-admin__title">{title}</h1>}
              {(addStudentModal || moveAllStudentsModal) && (
                <div className="student-admin__actions">
                  {addStudentModal && (
                    <div className="student-admin__action">
                      <Clicker
                        text={addStudent}
                        theme={Clicker.themes.primary}
                        onClick={() => setAddModalIsOpen(true)}
                      />
                    </div>
                  )}
                  {moveAllStudentsModal && (
                    <div className="student-admin__action">
                      <Clicker
                        text={moveAllStudentsButtonText}
                        theme={Clicker.themes.primary}
                        onClick={() => setMoveAllStudentsModalIsOpen(true)}
                      />
                    </div>
                  )}
                  {addStudentsFromFile && (
                    <div className="student-admin__action">
                      <Clicker
                        text={addStudentFromFile}
                        theme={Clicker.themes.secondary}
                        onClick={() => setAddStudentsFromFileIsOpen(true)}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="student-admin__row">
                <StudentAdminTable
                  {...state}
                  confirmationModal={confirmationModal}
                  assignTeacherClick={handleOnAssignTeacherClick}
                  editClick={handleOnEditClick}
                  moveStudentClick={handleOnMoveStudentClick}
                  declineMoveStudentClick={handleOnRejectMoveStudentClick}
                  onRemove={handleOnRemoveClick}
                  showMenus={showMenus}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {isLoading && <LoadingSpinner shouldTakeOverScreen={true} />}
      {addModalIsOpen && addStudentModal && (
        <AddStudentModal
          {...addStudentModal}
          isOpen={addModalIsOpen}
          onConfirm={handleOnAddConfirm}
          onClose={() => setAddModalIsOpen(false)}
        />
      )}
      {editModalIsOpen && (
        <AddStudentModal
          {...editModal}
          isOpen={editModalIsOpen}
          onConfirm={handleOnEditConfirm}
          onClose={() => setEditModalIsOpen(false)}
        />
      )}
      {assignTeacherModalIsOpen && (
        <AssignTeacherModal
          {...teacherModal}
          isOpen={assignTeacherModalIsOpen}
          onConfirm={handleOnAssignTeacherConfirm}
          onClose={() => setAssignTeacherModalIsOpen(false)}
        />
      )}
      {moveStudenModalIsOpen && (
        <MoveStudentModal
          {...moveModal}
          isOpen={moveStudenModalIsOpen}
          onConfirm={handleOnMoveStudentConfirm}
          onClose={() => setMoveStudenModalIsOpen(false)}
        />
      )}
      {rejectMoveStudenModalIsOpen && (
        <RejectMoveStudentModal
          {...rejectMoveModal}
          isOpen={rejectMoveStudenModalIsOpen}
          onConfirm={handleOnMoveStudentReject}
          onClose={() => setRejectMoveStudenModalIsOpen(false)}
        />
      )}
      {moveAllStudentsModalIsOpen && (
        <MoveAllStudentsModal
          {...moveAllStudentsModal}
          students={table?.resources}
          isOpen={moveAllStudentsModalIsOpen}
          onClose={() => {
            setMoveAllStudentsModalIsOpen(false);
            setIsLoading(true);
            window?.location?.reload();
          }}
        />
      )}
    </>
  );
};

StudentAdmin.propTypes = {
  title: PropTypes.string,
  tabs: PropTypes.exact(Tabs.propTypes),
  showMenus: PropTypes.bool,
  assignTeacherModal: PropTypes.exact(AssignTeacherModal.propTypes),
  removeEndpoint: PropTypes.string.isRequired,
  confirmationModal: PropTypes.exact(ConfirmationModal.propTypes),
  editEndpoint: PropTypes.string.isRequired,
  assignTeacherEndpoint: PropTypes.string.isRequired,
  addStudentsFromFile: PropTypes.shape(AddStudentsFromFile.propTypes),
  table: PropTypes.exact(ResourceTable.propTypes),
  addStudentModal: PropTypes.exact(AddStudentModal.propTypes),
  moveStudentModal: PropTypes.exact(MoveStudentModal.propTypes),
  rejectMoveStudentModal: PropTypes.exact(RejectMoveStudentModal.propTypes),
  moveAllStudentsButtonText: PropTypes.string,
  moveAllStudentsModal: PropTypes.exact(MoveAllStudentsModal.propTypes),
  getStudentEndpoint: PropTypes.string,
  getAllFlyttingerForElevEndpoint: PropTypes.string,
  updateNestedValue: PropTypes.func, // ignore backend
  handleRefreshData: PropTypes.func // ignore backend
};

StudentAdmin.defaultProps = {
  updateNestedValue: () => {}
};

export default StudentAdmin;
