import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon';
import Clicker from '../clicker';

const Login = ({
  link,
  title,
  leadBoxTitle,
  htmlLead,
  htmlLeadBoxFirst,
  htmlLeadBoxSecond
}) => {
  return (
    <div>
      <div className="login">
        <h1 className="login__title">{title}</h1>

        {htmlLead && (
          <div
            className={'login__html'}
            dangerouslySetInnerHTML={{ __html: htmlLead }}
          />
        )}

        <div className="login__box">
          <div className="login__box-content">
            <span className="login__icon-margin">
              <Icon name={Icon.names.login_box} size={Icon.sizes.xxl} />
            </span>
            <div>
              {leadBoxTitle && (
                <h2 className="login__title-box">{leadBoxTitle}</h2>
              )}

              {htmlLeadBoxFirst && (
                <div
                  className={'login__html'}
                  dangerouslySetInnerHTML={{ __html: htmlLeadBoxFirst }}
                />
              )}
              {htmlLeadBoxSecond && (
                <div
                  className={'login__html'}
                  dangerouslySetInnerHTML={{ __html: htmlLeadBoxSecond }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="login__actions">
        <Clicker {...link} theme={Clicker.themes.primary} />
      </div>
    </div>
  );
};

Login.textWithLink = {
  text: PropTypes.string,
  link: PropTypes.exact({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
  }).isRequired
};

Login.propTypes = {
  title: PropTypes.string.isRequired,
  leadBoxTitle: PropTypes.string,
  htmlLead: PropTypes.string,
  htmlLeadBoxFirst: PropTypes.string,
  htmlLeadBoxSecond: PropTypes.string,
  link: PropTypes.exact({
    text: PropTypes.string,
    href: PropTypes.string
  })
};

export default Login;
