import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from 'components/icon/icon';

const themes = ['grey', 'remove-selection'];

const School = ({
  name,
  address,
  orgNr,
  themes,
  onRemoveSelection,
  orgNrLabel
}) => {
  return (
    <div
      className={cn(
        'school',
        themes?.map(theme => `school--${theme}`)
      )}
    >
      {name && <span className="school__name">{name}</span>}
      <span className="school__info">{address && address}</span>
      {orgNr && (
        <span className="school__number">{orgNrLabel + ': ' + orgNr}</span>
      )}
      {onRemoveSelection && (
        <button
          className="school__remove-selection"
          onClick={onRemoveSelection}
        >
          <Icon name={Icon.names.close} />
        </button>
      )}
    </div>
  );
};

School.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  orgNr: PropTypes.string,
  orgNrLabel: PropTypes.string,
  onRemoveSelection: PropTypes.func,
  themes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(themes)))
};

export default School;
