import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../layouts/redaktor-layout';
import RedaktorGrensesnitt from '../redaktor-grensesnitt/redaktor-grensesnitt';
import RedaktorGrensesnittContextProvider from '../../contexts/redaktor-grensesnitt-context-provider';

const RedaktorGrensesnittPage = ({ layout, redaktorGrensesnitt }) => {
  return (
    <RedaktorGrensesnittContextProvider>
      <Layout
        {...layout}
        adminHeader={{ ...layout.adminHeader, color: 'blue' }}
      >
        <RedaktorGrensesnitt {...redaktorGrensesnitt} />
      </Layout>
    </RedaktorGrensesnittContextProvider>
  );
};

RedaktorGrensesnittPage.propTypes = {
  layout: PropTypes.exact(Layout.propTypes),
  redaktorGrensesnitt: PropTypes.exact(RedaktorGrensesnitt.propTypes)
};

export default RedaktorGrensesnittPage;
