import React from 'react';

import PropTypes from 'prop-types';
import cn from 'classnames';
import LanguagePicker from './language-picker';
import UserSwitcher from './user-switcher';
import Breadcrumbs from './breadcrumbs';
import NotificationsMenu from 'components/notifications-menu/notifications-menu';
import SkipLink from 'components/skip-link/skip-link';
import SiteInfoBanner from 'components/site-info-banner/site-info-banner';
import Toggle from 'components/toggle/toggle';

const themes = {
  default: 'default',
  dark: 'dark'
};
const sessionStorageVar = 'prevStatisticsHref';

const Header = ({
  skipLink,
  title,
  theme,
  infoBanner,
  breadcrumbs,
  userSwitcher,
  notificationsMenu,
  languagePicker,
  showLanguagePicker,
  showBreadcrumbs,
  showUserSwitcher,
  hrefToggler
}) => {
  const toStatisticsPageOrBack = () => {
    if (window?.location?.pathname.includes('statist')) {
      const prevUrl = sessionStorage.getItem(sessionStorageVar);
      if (prevUrl) {
        window.location = prevUrl;
      } else {
        window.history.back();
      }
    } else {
      sessionStorage.setItem(sessionStorageVar, window.location.pathname);
      window.location = hrefToggler.href;
    }
  };

  return (
    <div
      className={cn('header', {
        [`header--${themes[theme]}`]: themes[theme]
      })}
    >
      <SkipLink {...skipLink} />
      <div className="header__user">
        <div className="header__user-content">
          {title && <div className="header__logo-title">{title}</div>}
          <div className="header__user-content-flex-wrap">
            {showLanguagePicker && languagePicker && (
              <LanguagePicker
                {...languagePicker}
                alignCenter={
                  !userSwitcher || !(userSwitcher.name && userSwitcher.role)
                }
              />
            )}
            {showUserSwitcher && userSwitcher && (
              <nav className="header__user-switcher">
                <UserSwitcher {...userSwitcher} theme={themes.default} />
              </nav>
            )}
            {notificationsMenu && (
              <div className="header__notification-menu">
                <NotificationsMenu {...notificationsMenu} />
              </div>
            )}
          </div>
        </div>
      </div>
      {infoBanner && <SiteInfoBanner {...infoBanner} />}
      <div className="header__navigation">
        <div className="header__navigation-wrapper">
          <div className="header__navigation-content">
            {breadcrumbs && showBreadcrumbs && (
              <div className="header__breadcrumbs">
                <Breadcrumbs {...breadcrumbs} />
              </div>
            )}
          </div>
          <div className="header__statistics-content">
            {hrefToggler?.show && (
              <Toggle
                {...hrefToggler}
                onToggle={toStatisticsPageOrBack}
                theme={Toggle.themes.darkBackground}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Header.defaultProps = {
  breadcrumbs: {}
};

Header.propTypes = {
  skipLink: PropTypes.exact(SkipLink.propTypes),
  title: PropTypes.string.isRequired,
  infoBanner: PropTypes.exact(SiteInfoBanner.propTypes),
  showBreadcrumbs: PropTypes.bool,
  hrefToggler: PropTypes.shape(Toggle.propTypes),
  theme: PropTypes.oneOf(Object.values(themes)),
  showUserSwitcher: PropTypes.bool,
  userSwitcher: PropTypes.exact(UserSwitcher.propTypes),
  notificationsMenu: PropTypes.exact(NotificationsMenu.propTypes),
  showLanguagePicker: PropTypes.bool,
  languagePicker: PropTypes.exact(LanguagePicker.propTypes),
  breadcrumbs: PropTypes.exact(Breadcrumbs.propTypes)
};

Header.themes = themes;

Header.defaultProps = {
  breadcrumbs: {},
  showBreadcrumbs: true,
  showUserSwitcher: true,
  showLanguagePicker: true,
  theme: themes.default
};

export default Header;
