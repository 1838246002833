const defaultFetchOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  credentials: 'include'
};

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function parseResponse(response) {
  return response
    .json()
    .then(json => ({ json, response }))
    .catch(() => ({ json: undefined, response }));
}

function handleNotOk({ json, response }) {
  // NOTE: `response.ok` is true when the returned status is in the inclusive range 200-299.
  if (!response.ok) {
    if (!json) {
      const error = new Error(response.statusText);

      error.name = `${response.status} on ${response.url}`;

      throw error;
    } else {
      throw json;
    }
  }

  return { json, response };
}

function handleResponse({ json }) {
  return json?.payload || json;
}

function handleFetchError(error) {
  return Promise.reject(error);
}

function handleRedirect({ json }) {
  if (json?.redirectUrl) {
    window.location = json.redirectUrl;
  }

  return { json };
}

function request(url, options) {
  return fetch(url, options)
    .then(parseResponse)
    .then(handleNotOk)
    .then(handleRedirect)
    .then(handleResponse)
    .catch(handleFetchError);
}

function post(endpoint, data) {
  return request(
    endpoint,
    Object.assign({}, defaultFetchOptions, {
      body: data,
      method: 'post'
    })
  );
}

function postWithCustomOptions(endpoint, data, customOptions) {
  return request(
    endpoint,
    Object.assign({}, customOptions, {
      body: data,
      method: 'post'
    })
  );
}

function isStaticSiteRequest(url) {
  return (
    url.startsWith('/static-site/api') ||
    url.startsWith('http://localhost:8080/static-site/api')
  );
}

function staticSiteRequest(endpoint, data) {
  // eslint-disable-next-line no-console
  console.log(`Fetching ${endpoint}`, data);
  return new Promise(resolve => {
    setTimeout(() => {
      const response = request(endpoint, defaultFetchOptions).then(response => {
        // eslint-disable-next-line no-console
        console.log(`Response from ${endpoint}`, response);
        return response;
      });
      resolve(response);
    }, randomIntFromInterval(500, 1500));
  });
}

function get(endpoint) {
  return isStaticSiteRequest(endpoint)
    ? staticSiteRequest(endpoint)
    : request(endpoint, defaultFetchOptions);
}

function execute(endpoint, data) {
  return isStaticSiteRequest(endpoint)
    ? staticSiteRequest(endpoint, data)
    : post(endpoint, JSON.stringify(data));
}

function executeWithCustomOptions(endpoint, data, customOptions = {}) {
  return isStaticSiteRequest(endpoint)
    ? staticSiteRequest(endpoint, data)
    : postWithCustomOptions(endpoint, data, customOptions);
}

export default {
  execute,
  get,
  executeWithCustomOptions
};
