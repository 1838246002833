import Clicker from 'components/clicker/clicker';
import Modal from 'components/modal/modal';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from 'components/accordion/accordion';
import CheckboxItem from 'components/checkbox-input/checkbox-input';

const GranulatedRequestModal = ({
  onClose,
  isOpen,
  closeModalHiddenText,
  title,
  subTitle,
  cancelButtonText,
  exportImportButtonText,
  handleProceed,
  blockItemList,
  exportImportEndpoint
}) => {
  const [listOfSystemText, setListOfSystemText] = useState(blockItemList);

  const handleChange = (checked, id) => {
    let text = listOfSystemText;

    text.map(blockItem => {
      blockItem.textItemList.map(textItem => {
        if (textItem.textName === id) {
          textItem.isSelected = checked;
        }
      });
    });
    setListOfSystemText([...text]);
  };

  const handleBlockChange = (checked, id) => {
    let text = listOfSystemText;

    text.map(blockItem => {
      if (blockItem.blockName === id) {
        blockItem.textItemList.map(textItem => {
          textItem.isSelected = checked;
        });
      }
    });
    setListOfSystemText([...text]);
  };

  const handleChangeAll = checked => {
    let text = listOfSystemText;

    text.map(blockItem => {
      blockItem.textItemList.map(textItem => {
        textItem.isSelected = checked;
      });
    });

    setListOfSystemText([...text]);
  };

  const handleImportExport = () => {
    handleProceed(listOfSystemText, exportImportEndpoint);
  };

  const recursiveChecklistMaker = baseList => {
    let list = baseList;

    return list.map((blockItem, index) => {
      const isBlockSelected = blockItem.textItemList.every(
        item => item.isSelected
      );
      return (
        <React.Fragment key={'blockItem-' + index}>
          <li>
            <ul>
              <Accordion
                title={blockItem.blockDisplayName}
                theme={Accordion.themes.systemtext}
                checkbox={
                  <CheckboxItem
                    key={`blockItemCheckBox-${index}-${isBlockSelected}`}
                    id={blockItem.blockName}
                    inputKey={`${index}`}
                    label={' '}
                    value={isBlockSelected}
                    isPartiallyChecked={
                      !isBlockSelected &&
                      blockItem.textItemList.some(item => item.isSelected)
                    }
                    onChange={handleBlockChange}
                  />
                }
              >
                {blockItem.textItemList.map(
                  ({ textName, textDisplayName, isSelected }, i) => {
                    return (
                      <li key={`textItem-${i}-${isSelected}`}>
                        <div className="granulated-request-modal__text-item">
                          <CheckboxItem
                            id={textName}
                            inputKey={`${i}`}
                            label={textDisplayName}
                            value={isSelected}
                            onChange={handleChange}
                          ></CheckboxItem>
                        </div>
                      </li>
                    );
                  }
                )}
              </Accordion>
            </ul>
          </li>
        </React.Fragment>
      );
    });
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      themes={[Modal.themes.gray]}
      closeButtonLabel={closeModalHiddenText}
    >
      <h1 className="granulated-request-modal__title">{title}</h1>
      <h2 className="granulated-request-modal__sub-title">{subTitle}</h2>
      <div className="granulated-request-modal__check-all">
        <CheckboxItem
          key={`checkAll-${title}`}
          id={'checkAllSystemTexts'}
          label={'Marker alle'}
          value={listOfSystemText?.every(bI =>
            bI.textItemList?.every(tI => tI.isSelected)
          )}
          onChange={handleChangeAll}
        />
      </div>
      <div className="granulated-request-modal__checklist-container">
        <ul>{recursiveChecklistMaker(listOfSystemText)}</ul>
      </div>

      <div className="granulated-request-modal__button-wrapper">
        <Clicker
          onClick={onClose}
          text={cancelButtonText || 'Avbryt'}
          theme={Clicker.themes.secondary}
        />
        <Clicker
          isDisabled={listOfSystemText?.every(bI =>
            bI.textItemList.every(tI => !tI.isSelected)
          )}
          onClick={handleImportExport}
          text={exportImportButtonText || 'Gå videre'}
          theme={Clicker.themes.primary}
        />
      </div>
    </Modal>
  );
};

GranulatedRequestModal.propTypes = {
  closeModalHiddenText: PropTypes.string,
  title: PropTypes.string.isRequired, // not a model, returned from API request with proceedButton
  subTitle: PropTypes.string, // not a model, returned from API request with proceedButton
  cancelButtonText: PropTypes.string.isRequired, // not a model, returned from API request with proceedButton
  exportImportButtonText: PropTypes.string.isRequired, // not a model, returned from API request with proceedButton
  onClose: PropTypes.func.isRequired, // ignore backend
  isOpen: PropTypes.bool.isRequired, // ignore backend
  handleProceed: PropTypes.func.isRequired, // ignore backend,
  exportImportEndpoint: PropTypes.string,
  blockItemList: PropTypes.arrayOf(
    PropTypes.shape({
      blockName: PropTypes.string,
      blockDisplayName: PropTypes.string,
      blockDescription: PropTypes.string,
      textItemList: PropTypes.arrayOf(
        PropTypes.shape({
          isSelected: PropTypes.bool,
          textName: PropTypes.string,
          textDisplayName: PropTypes.string,
          textDescription: PropTypes.string,
          languageTextValueItemList: PropTypes.arrayOf(
            PropTypes.shape({
              LanguageCode: PropTypes.string,
              textValue: PropTypes.string
            })
          )
        })
      )
    })
  )
};

export default GranulatedRequestModal;
