import React from 'react';
import PropTypes from 'prop-types';

import BasicLayout from '../layouts/basic-layout';
import RoleSelection from '../role-selection';

const RoleSelectionPage = ({ layout, roleSelection }) => {
  return (
    <BasicLayout {...layout}>
      <RoleSelection {...roleSelection} />
    </BasicLayout>
  );
};

RoleSelectionPage.propTypes = {
  layout: PropTypes.exact(BasicLayout.propTypes),
  roleSelection: PropTypes.exact(RoleSelection.propTypes)
};

export default RoleSelectionPage;
