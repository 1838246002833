import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import usePhrases from '../../hooks/use-phrases';

const Person = ({ name, email, phoneNumber, onClick, isDisabled }) => {
  const { email: emailLabel, phoneNumber: phoneNumberLabel } = usePhrases();

  const Element = onClick ? 'button' : 'div';

  return (
    <Element
      className={cn('person', { 'person--button': !!onClick })}
      onClick={onClick}
      disabled={isDisabled}
    >
      <div className="person__content">
        {name && <span className="person__name">{name}</span>}
        {email && (
          <span className="person__email">
            {emailLabel}: {email}
          </span>
        )}
        {phoneNumber && (
          <span className="person__phone-number">
            {phoneNumberLabel}: {phoneNumber}
          </span>
        )}
      </div>
    </Element>
  );
};

Person.propTypes = {
  onClick: PropTypes.func, // ignore from backend
  isDisabled: PropTypes.bool, // ignore from backend
  name: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string
};

export default Person;
