import Clicker from 'components/clicker/clicker';
import Modal from 'components/modal/modal';
import React from 'react';
import PropTypes from 'prop-types';

const AlertModal = ({
  onClose,
  isOpen,
  title,
  lead,
  cancelText,
  okText,
  handleProceed
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <h1 className={'redaktor-intro__modal-title'}>{title}</h1>
      <p className={'redaktor-intro__modal-lead'}>{lead}</p>
      <div className={'redaktor-intro__button-wrapper'}>
        <Clicker
          onClick={onClose}
          text={cancelText || 'Avbryt'}
          theme={Clicker.themes.secondary}
        />
        <Clicker
          onClick={handleProceed}
          text={okText || 'Gå videre'}
          theme={Clicker.themes.primary}
        />
      </div>
    </Modal>
  );
};

AlertModal.propTypes = {
  title: PropTypes.string.isRequired, // not a model, returned from API request with proceedButton
  lead: PropTypes.string.isRequired, // not a model, returned from API request with proceedButton
  cancelText: PropTypes.string.isRequired, // not a model, returned from API request with proceedButton
  okText: PropTypes.string.isRequired, // not a model, returned from API request with proceedButton
  onClose: PropTypes.func.isRequired, // ignore backend
  isOpen: PropTypes.bool.isRequired, // ignore backend
  handleProceed: PropTypes.func.isRequired // ignore backend
};

export default AlertModal;
