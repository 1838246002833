import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowTooltip from 'components/arrow-tooltip/arrow-tooltip';
import useClickOutside from '../../hooks/use-click-outside';
import Clicker from '../clicker';

const DataOverflowHandler = ({ dataString, closeButtonLabel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const clickerRef = useRef(null);

  useClickOutside(clickerRef, () => setIsOpen(false));

  return (
    <div className="data-overflow-handler">
      <Clicker
        className="data-overflow-handler__wrapper"
        theme={Clicker.themes.alignedLeft}
        ref={clickerRef}
        onClick={() => setIsOpen(isOpen => !isOpen)}
      >
        <div className="data-overflow-handler__data">
          <span className="data-overflow-handler__data-string">
            {dataString.split(',')[0]}
          </span>
          <span className="data-overflow-handler__data-overflow-number">
            {' +' + (dataString.split(',').length - 1)}
          </span>
        </div>
      </Clicker>
      <ArrowTooltip
        isOpen={isOpen}
        top={40}
        onClose={() => setIsOpen(false)}
        lead={dataString}
        closeButtonLabel={closeButtonLabel}
      />
    </div>
  );
};

DataOverflowHandler.propTypes = {
  dataString: PropTypes.string,
  closeButtonLabel: PropTypes.string
};

export default DataOverflowHandler;
