import React from 'react';
import PropTypes from 'prop-types';
import ActionBar from 'components/action-bar';

const ContentHeader = ({ heading, subheading, actionBar }) => {
  return (
    <div className="content-header">
      <hgroup className="content-header__heading-group">
        <h1 className="content-header__heading">{heading}</h1>
        <p className="content-header__subheading">{subheading}</p>
      </hgroup>
      {actionBar ? (
        <ActionBar {...actionBar} />
      ) : (
        <div className="content-header__spacer"></div>
      )}
    </div>
  );
};

ContentHeader.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  actionBar: PropTypes.exact(ActionBar.propTypes)
};

export default ContentHeader;
