import React from 'react';
import PropTypes from 'prop-types';

import Kartlegging from 'components/kartlegging/kartlegging';
import MainLayout from 'components/main-layout';
import StructuralLayout from 'components/structural-layout/structural-layout';

const KartleggingPage = ({ structuralLayout, mainLayout, kartlegging }) => {
  return (
    <StructuralLayout {...structuralLayout}>
      <MainLayout {...mainLayout}>
        <Kartlegging {...kartlegging} />
      </MainLayout>
    </StructuralLayout>
  );
};

KartleggingPage.propTypes = {
  structuralLayout: PropTypes.shape(StructuralLayout.propTypes),
  mainLayout: PropTypes.shape(MainLayout.propTypes),
  kartlegging: PropTypes.exact(Kartlegging.propTypes)
};

export default KartleggingPage;
