import api from 'js/api-helper';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext, useMemo, useRef } from 'react';
import AdminNode from './admin-node';
import cn from 'classnames';
import HeaderContext from '../../contexts/header-context';
import AdminDataDisplay from './admin-data-display';
import useClickOutside from '../../hooks/use-click-outside';
import QuickSearchDropdown from 'components/quick-search-dropdown/quick-search-dropdown';

const Admin = ({ title, showSearch, search, searchEndpoint, nodes }) => {
  const { setAdminHeaderData } = useContext(HeaderContext);
  const listRef = useRef();
  const clickedOutside = useClickOutside(listRef);
  const [currentTab, setCurrentTab] = useState(null);
  const [currentNodes, setCurrentNodes] = useState(nodes);
  const [currentData, setCurrentData] = useState(null);
  const [displayData, setDisplayData] = useState({
    loading: false,
    error: false,
    data: null
  });

  const newNodes = useMemo(() => {
    function isStatusOk(node) {
      const noChildNodes = !node?.nodes?.length;

      return noChildNodes
        ? node.statusOk
        : !node.statusOk
        ? node.statusOk
        : node.nodes.every(childNode => isStatusOk(childNode));
    }

    function searchTree(_nodes) {
      return _nodes?.map(node => {
        return {
          ...node,
          statusOk: node.isExcluded ? true : isStatusOk(node),
          nodes: node.nodes ? searchTree(node.nodes) : undefined
        };
      });
    }

    return searchTree(currentNodes);
  }, [currentNodes]);

  const updateNestedValue = ({
    nodeId,
    tabData,
    isExcluded,
    forceUpdateStatus = false
  }) => {
    function updateTree(_nodes) {
      return _nodes?.map(node => {
        if (nodeId === node.id) {
          if (typeof isExcluded === 'boolean') {
            return {
              ...node,
              isExcluded: isExcluded,
              startNode: false,
              endpointToggler: {
                ...node.endpointToggler,
                isLeft: !isExcluded
              }
            };
          }

          const statusOk = Boolean(
            tabData?.type === 'owner' && tabData?.table?.resources?.length
          );
          return {
            ...node,
            statusOk: forceUpdateStatus ? statusOk : node.statusOk,
            startNode: false
          };
        } else
          return {
            ...node,
            startNode: false,
            nodes: node.nodes ? updateTree(node.nodes) : undefined
          };
      });
    }

    if (
      typeof nodeId === 'string' &&
      (typeof tabData === 'object' || typeof isExcluded === 'boolean')
    ) {
      const result = updateTree(currentNodes);
      setCurrentNodes(result);
    }
  };

  const handleSearch = (
    searchQuery,
    includeBrukere,
    includeSkoler,
    includeKommuner,
    includeAll
  ) => {
    setDisplayData(_displayData => ({
      ..._displayData,
      loading: true,
      error: false
    }));
    api
      .execute(searchEndpoint, {
        value: searchQuery,
        includeBrukere,
        includeSkoler,
        includeKommuner,
        includeAll
      })
      .then(response => {
        setCurrentData(_currentData => ({
          ..._currentData,
          tabList: response.tabList,
          id: 'undefined',
          error: false,
          endpointToggler: undefined,
          breadcrumbText: undefined
        }));
      })
      .catch(() =>
        setDisplayData(_displayData => ({
          ..._displayData,
          loading: false,
          error: true
        }))
      );
  };

  const handleSearchItemClick = ({ nodeId }) => {
    window.history.replaceState(null, '', '?' + nodeId);
    let foundNode;
    const findNode = nodes => {
      nodes?.forEach(node => {
        if (nodeId === node.id) {
          foundNode = node;
        } else if (node.nodes) findNode(node.nodes);
      });
    };
    findNode(nodes);
    setCurrentData(foundNode);
  };

  useEffect(() => {
    if (currentData?.adminHeader) {
      setAdminHeaderData(currentData.adminHeader);
    } else {
      setAdminHeaderData({
        name: '',
        infos: null
      });
    }
  }, [currentData]);

  const getData = shouldForceUpdate => {
    let tabDataEndpoint = '',
      tabText = '',
      storedTabListData = null;
    const currentTabIndex = currentData?.tabList?.findIndex(
      tab => tab?.text === currentTab
    );
    if (currentData && typeof currentTabIndex === 'number') {
      if (currentTabIndex === -1) {
        tabText = currentData.tabList[0]?.text;
        tabDataEndpoint = currentData.tabList[0]?.endpoint;
        setCurrentTab(tabText);
        // const currentTabIndex = currentData?.tabList?.indexOf(currentTab);
      } else {
        const { text, endpoint } = currentData?.tabList[currentTabIndex];
        tabText = text;
        tabDataEndpoint = endpoint;
      }
      storedTabListData = currentData?.tabList[currentTabIndex]?.data;
    }

    // let timeout;
    const fetchAdminData = async endpoint => {
      setDisplayData(_displayData => ({
        ..._displayData,
        loading: true,
        error: false
      }));
      try {
        const data = await api.execute(endpoint);
        setDisplayData({
          data,
          loading: false,
          error: false,
          endpointToggler: currentData?.endpointToggler,
          id: currentData?.id,
          tabList: currentData?.tabList,
          breadcrumbText: currentData?.breadcrumbText
        });
        updateNestedValue({ nodeId: currentData.id, tabData: data });
      } catch (err) {
        setDisplayData(_displayData => ({
          data: null,
          loading: false,
          endpointToggler: null,
          breadcrumbText: '',
          id: '',
          error: true,
          tabList: null
        }));
      }
    };

    if ((shouldForceUpdate || !storedTabListData) && tabDataEndpoint) {
      fetchAdminData(tabDataEndpoint);
    } else if (storedTabListData) {
      setDisplayData({
        loading: false,
        error: false,
        data: storedTabListData,
        id: currentData?.id,
        breadcrumbText: currentData?.breadcrumbText,
        tabList: currentData?.tabList
      });
    } else {
      // There is no tabs or endpoints
      setDisplayData(_displayData => ({
        ..._displayData,
        data: null,
        tabList: null,
        endpointToggler: null,
        breadcrumbText: '',
        id: ''
      }));
    }
  };

  useEffect(() => {
    getData(false);
  }, [currentData, currentTab]);

  return (
    <>
      <div className="admin">
        <div className="admin__content">
          {title && <h1 className="admin__title">{title}</h1>}
        </div>

        <div
          className={cn('admin__wrapper', {
            'admin__wrapper--loading': displayData?.loading
          })}
        >
          <div className="admin__left-side-wrapper">
            <div className={'admin__search-wrapper'}>
              {showSearch && (
                <>
                  <QuickSearchDropdown
                    onSearchClick={handleSearch}
                    onItemClick={handleSearchItemClick}
                    {...search}
                  />
                </>
              )}
            </div>
            <div
              ref={listRef}
              className={cn('admin__list-wrapper', {
                'admin__list-wrapper--active-mobile': !clickedOutside
              })}
            >
              <div className="admin__list-scroll-left">
                {newNodes?.map((node, i) => {
                  return (
                    <AdminNode
                      key={i + node.title}
                      outer
                      breadcrumbText={node.title}
                      isFirstInList={i === 0}
                      singleTopNode={newNodes?.length === 1}
                      currentData={currentData}
                      setCurrentData={setCurrentData}
                      node={node}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <AdminDataDisplay
            updateNestedValue={updateNestedValue}
            handleSearchItemClick={handleSearchItemClick}
            key={`${displayData?.breadcrumbText}-${currentTab}`}
            displayData={displayData}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            handleRefreshData={getData}
          />
        </div>
      </div>
    </>
  );
};

Admin.propTypes = {
  title: PropTypes.string,
  showSearch: PropTypes.bool,
  search: PropTypes.exact(QuickSearchDropdown.propTypes),
  searchEndpoint: PropTypes.string,
  nodes: PropTypes.arrayOf(PropTypes.shape(AdminNode.propTypes))
};

Admin.defaultProps = {
  showRemoveAction: true
};

export default Admin;
