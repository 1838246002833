import React from 'react';
import PropTypes from 'prop-types';

import Login from '../login';
import AnonymousLayout from '../layouts/anonymous-layout';

const LoginPage = ({ layout, login }) => {
  return (
    <AnonymousLayout {...layout}>
      <div className="login-page">
        <Login {...login} />
      </div>
    </AnonymousLayout>
  );
};

LoginPage.propTypes = {
  layout: PropTypes.exact(AnonymousLayout.propTypes),
  login: PropTypes.exact(Login.propTypes)
};

export default LoginPage;
