import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Clicker from 'components/clicker/clicker';
import useClickOutside from '../../hooks/use-click-outside';
import Icon from 'components/icon';
import cn from 'classnames';

const themes = {
  warning: 'warning',
  info: 'info'
};

const InfoBubbleButton = ({
  buttonText,
  infoText,
  theme = 'warning',
  error = false,
  zIndex
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const bubbleRef = useRef();
  useClickOutside(bubbleRef, () => setIsOpen(false));

  return (
    <span
      ref={bubbleRef}
      className={cn('info-bubble-button', {
        'info-bubble-button--active': isOpen
      })}
      style={zIndex !== null ? { zIndex: zIndex } : {}}
    >
      <Clicker
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
        textIsHidden={true}
        iconName={
          theme === themes.warning
            ? Icon.names.warningRed
            : theme === 'info'
            ? error
              ? Icon.names.warningRed
              : Icon.names.infoCircle
            : null
        }
        activeIconName={
          theme === themes.warning
            ? Icon.names.warningBlue
            : theme === 'info'
            ? error
              ? Icon.names.warningBlack
              : Icon.names.infoCircleBlack
            : null
        }
        iconSize={Clicker.iconSizes.medium}
      >
        <span className="info-bubble-button__button-text">{buttonText}</span>
      </Clicker>
      {isOpen && (
        <div
          className="info-bubble-button__bubble"
          dangerouslySetInnerHTML={{ __html: infoText }}
        />
      )}
    </span>
  );
};

InfoBubbleButton.propTypes = {
  buttonText: PropTypes.string,
  infoText: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  error: PropTypes.bool
};

export default InfoBubbleButton;
