import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import InputByType from 'components/input-by-type';
import InputErrorMessage from 'components/input-error-message';
import MenuButton from 'components/resource-table/menu-button';
import TextInput from 'components/text-input';
import UdirRichTextEditor from 'components/rich-text-editor/rich-text-editor';

export default function SamtaleEditItem({
  samtaleQuestion,
  uniqueNodeId,
  listLength,
  onDeleteCallback,
  fixedStructure,
  reorder,
  index,
  handleChange,
  handleUndo,
  errorMessage,
  recentlySaved,
  placeholderText,
  labelText,
  buttonText,
  newQuestionTypeText,
  readOnly
}) {
  const typeSelected = useMemo(() => {
    return samtaleQuestion.type !== InputByType.types.hidden;
  }, [samtaleQuestion.type]);

  const requiredInputRef = useRef(null);
  const [inEditMode, setInEditMode] = useState(!typeSelected);
  const initialSamtaleQuestion = useRef(JSON.stringify(samtaleQuestion));

  useEffect(() => {
    if (recentlySaved) {
      setInEditMode(false);
      initialSamtaleQuestion.current = JSON.stringify(samtaleQuestion);
    }
  });

  const undoDisabled = useMemo(() => {
    return initialSamtaleQuestion.current === JSON.stringify(samtaleQuestion);
  }, [initialSamtaleQuestion, samtaleQuestion, inEditMode]);

  const hideTools = !inEditMode || readOnly;

  useEffect(() => {
    if (requiredInputRef?.current && errorMessage) {
      requiredInputRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [requiredInputRef, errorMessage]);

  return (
    <li
      className="redaktor-grensesnitt__data-content-group-item-wrapper"
      ref={requiredInputRef}
      key={uniqueNodeId + 'list'}
    >
      <div className={'redaktor-grensesnitt__data-content-group-item-left'}>
        <div
          className={'redaktor-grensesnitt__data-content-group-number-index'}
        >{`${index + 1}`}</div>
      </div>
      <div className="redaktor-grensesnitt__data-content-group-item">
        {errorMessage && (
          <div style={{ marginBottom: '1rem' }}>
            <InputErrorMessage errors={[errorMessage]} />
          </div>
        )}
        {!readOnly && (
          <div style={{ position: 'absolute', right: '0', top: '0' }}>
            <MenuButton
              items={[
                {
                  text: inEditMode ? buttonText.cancelEdit : buttonText.edit,
                  onClick: () => setInEditMode(!inEditMode)
                },
                {
                  isDisabled: fixedStructure || index === 0,
                  text: buttonText.moveUp,
                  onClick: () => reorder(index, -1)
                },
                {
                  isDisabled: fixedStructure || index === listLength - 1,
                  onClick: () => reorder(index, 1),
                  text: buttonText.moveDown
                },
                {
                  isDisabled: fixedStructure,
                  onClick: () => onDeleteCallback(samtaleQuestion.id),
                  text: buttonText.delete
                },
                {
                  isDisabled: undoDisabled,
                  onClick: () => handleUndo(samtaleQuestion.id),
                  text: buttonText.cancel
                }
              ]}
            />
          </div>
        )}
        {(samtaleQuestion.questionTypeText || newQuestionTypeText) && (
          <div style={{ marginBottom: '1rem' }}>
            {labelText.questionType +
              ': ' +
              (samtaleQuestion.questionTypeText || newQuestionTypeText)}
          </div>
        )}
        {typeSelected && (
          <div style={{ marginBottom: '1rem' }}>
            <TextInput
              isDisabled={hideTools}
              id={samtaleQuestion.id}
              key={uniqueNodeId + 'text'}
              label={labelText.questionText}
              placeholder={placeholderText.question}
              theme={TextInput.themes.defaultDisabled}
              value={samtaleQuestion?.questionText?.text || ''}
              onChange={(value, id) => handleChange(id, value, 'questionText')}
            />
          </div>
        )}
        <UdirRichTextEditor
          id={samtaleQuestion.id}
          label={labelText.tooltipText}
          placeholder={placeholderText.tooltipText}
          value={samtaleQuestion?.tooltip?.text || ''}
          onChange={(id, value) => handleChange(id, value, 'tooltip')}
          hasActionButtons={true}
          readOnly={hideTools}
        />
      </div>
    </li>
  );
}

SamtaleEditItem.questionProps = {
  id: PropTypes.string,
  questionText: PropTypes.exact({
    text: PropTypes.string
  }),
  questionTypeText: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  group: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      stringValue: PropTypes.string,
      boolValue: PropTypes.bool,
      placeholder: PropTypes.string
    })
  )
};

SamtaleEditItem.propTypes = {
  samtaleQuestion: PropTypes.shape(SamtaleEditItem.questionProps),
  buttonText: PropTypes.exact({
    edit: PropTypes.string.isRequired, // ignore backend
    cancelEdit: PropTypes.string.isRequired, // ignore backend
    cancel: PropTypes.string.isRequired, // ignore backend
    moveDown: PropTypes.string.isRequired, // ignore backend
    moveUp: PropTypes.string.isRequired, // ignore backend
    delete: PropTypes.string.isRequired // ignore backend
  }).isRequired,
  placeholderText: PropTypes.shape({
    lead: PropTypes.string, // ignore backend
    question: PropTypes.string, // ignore backend
    tooltipText: PropTypes.string // ignore backend
  }),
  labelText: PropTypes.exact({
    // ignore backend
    title: PropTypes.string, // ignore backend
    questionText: PropTypes.string, // ignore backend
    questionType: PropTypes.string, // ignore backend
    showChapterSeparator: PropTypes.string, // ignore backend
    lead: PropTypes.string, // ignore backend
    tooltipText: PropTypes.string // ignore backend
  }),
  newQuestionTypeText: PropTypes.string, // ignore BE
  recentlySaved: PropTypes.bool, // ignore backend
  readOnly: PropTypes.bool, // ignore backend
  fixedStructure: PropTypes.bool, // ignore backend
  listLength: PropTypes.number.isRequired, //Ignore backend
  handleChange: PropTypes.func.isRequired, //Ignore backend
  handleUndo: PropTypes.func.isRequired, //Ignore backend
  onDeleteCallback: PropTypes.func.isRequired, //Ignore backend
  reorder: PropTypes.func.isRequired, //Ignore backend
  index: PropTypes.number.isRequired, //Ignore backend
  uniqueNodeId: PropTypes.string.isRequired, // ignore backend
  errorMessage: PropTypes.string // ignore backend
};
