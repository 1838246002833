import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Clicker from 'components/clicker/clicker';
import SchoolAdmin from './school-admin/school-admin';
import StudentAdmin from './student-admin/student-admin';
import OwnerAdmin from './owner-admin/owner-admin';

const AdminNode = ({
  singleTopNode,
  isFirstInList,
  breadcrumbText,
  node,
  currentData,
  setCurrentData,
  indent
}) => {
  const { id, statusOk, title, nodes, isExcluded } = node;
  const [isOpen, setIsOpen] = useState(false);

  const nodeRef = useRef();

  const isChildCurrent = () => {
    let query = window.location.href.split('?')[1];
    const checkIfNodeContainCurrent = node => {
      if (currentData?.id === node.id || query === node.id) return true;

      if (node.nodes) {
        let doesExist = false;
        for (let i = 0; i < node.nodes.length; i++) {
          doesExist = doesExist || checkIfNodeContainCurrent(node.nodes[i]);
        }
        return doesExist;
      }
      return false;
    };

    let doesExist = false;
    nodes?.forEach(
      node => (doesExist = doesExist || checkIfNodeContainCurrent(node))
    );

    return doesExist;
  };

  useEffect(() => {
    if (isChildCurrent()) setIsOpen(true);
  }, [currentData]);

  useEffect(() => {
    let query = window.location.href.split('?')[1];
    if (isFirstInList) setIsOpen(true);

    if (isFirstInList && query !== id) {
      setCurrentData({
        ...node,
        breadcrumbText
      });
    } else if (query === id) {
      setCurrentData({
        ...node,
        breadcrumbText
      });
      setIsOpen(true);
      nodeRef.current.scrollIntoView();
    }
  }, [isFirstInList]);

  const handleClick = () => {
    // If tablist does not exist, the id is not permanent beteewn reloads and is therefore not added to the URL parameters
    if (node?.tabList && id) window.history.replaceState(null, '', '?' + id);
    else window.history.replaceState(null, '', location.pathname);
    setCurrentData({
      ...node,
      breadcrumbText
    });
    if (!singleTopNode) {
      setIsOpen(!isOpen);
    }
  };
  return (
    <div className={cn('admin')}>
      <div
        style={{ paddingLeft: `${indent}em` }}
        onClick={handleClick}
        ref={nodeRef}
        className={cn('admin__node-item', {
          'admin__node-item--current': currentData?.id === id
        })}
      >
        <span
          className={cn('admin__node-item-dot', {
            'admin__node-item-dot--complete': statusOk,
            'admin__node-item-dot--hidden': isExcluded
          })}
        />
        <Clicker
          iconIsBeforeText
          onClick={handleClick}
          text={title}
          alt={title}
          iconSize={Clicker.iconSizes.small}
          iconName={
            nodes?.length && !singleTopNode
              ? isOpen
                ? Clicker.iconNames.arrowUp
                : Clicker.iconNames.arrowDown
              : ''
          }
        />
      </div>
      {isOpen &&
        nodes?.map((_node, i) => {
          return (
            <AdminNode
              key={i + _node.id}
              node={_node}
              breadcrumbText={`${breadcrumbText} > ${_node.title}`}
              indent={indent + 1}
              currentData={currentData}
              setCurrentData={setCurrentData}
            />
          );
        })}
    </div>
  );
};

export default AdminNode;

const endpointDataResponse = PropTypes.oneOfType([
  PropTypes.shape(SchoolAdmin.propTypes),
  PropTypes.shape(StudentAdmin.propTypes),
  PropTypes.shape(OwnerAdmin.propTypes)
]);
// This reflects what frontend can handle on each node in the node list
AdminNode.RecursiveNodeProps = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  endpointToggler: PropTypes.exact({
    endpoint: PropTypes.string,
    leftText: PropTypes.string,
    isLeft: PropTypes.bool,
    show: PropTypes.bool,
    rightText: PropTypes.string
  }),
  statusOk: PropTypes.bool,
  isExcluded: PropTypes.bool,
  adminHeader: PropTypes.exact({
    name: PropTypes.string,
    infos: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      })
    )
  }),
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      endpoint: PropTypes.string,
      isActive: PropTypes.bool, // ignore backend
      // Ignore data from backend. This is set from frontend based on response from endpoint.
      // It can be one of the OLD admin types.
      // When endpoint returns one of these, also include a type object
      data: endpointDataResponse // ignore backend, this is returned from endpoint
    })
  )
};

AdminNode.propTypes = {
  node: PropTypes.shape(AdminNode.RecursiveNodeProps),
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      ...AdminNode.RecursiveNodeProps,
      nodes: PropTypes.any
    })
  ),
  // Props outside the recursive structure. Ignore these on backend:
  singleTopNode: PropTypes.bool,
  isFirstInList: PropTypes.bool,
  isExcluded: PropTypes.bool,
  indent: PropTypes.number,
  currentData: PropTypes.shape(AdminNode.RecursiveNodeProps),
  setCurrentData: PropTypes.func,
  breadcrumbText: PropTypes.string
};

AdminNode.defaultProps = {
  setData: () => {},
  outer: false,
  indent: 1
};
