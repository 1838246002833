import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Clicker from 'components/clicker/clicker';

const StatistikkNode = ({
  id,
  title,
  subTitle,
  singleTopNode,
  isFirstInList,
  statusOk,
  data,
  nodes,
  breadcrumbText,
  currentData,
  setCurrentData,
  statusIndicator,
  isExcluded,
  indent
}) => {
  const [isOpen, setIsOpen] = useState(false);
  React.useEffect(() => {
    if (isFirstInList) {
      setCurrentData({
        id: id,
        data: data,
        statusOk,
        title: title,
        subTitle: subTitle,
        breadcrumbText
      });
      setIsOpen(true);
    }
  }, [isFirstInList]);

  const handleClick = () => {
    setCurrentData({
      id: id,
      data: data,
      statusOk,
      title: title,
      subTitle: subTitle,
      breadcrumbText
    });
    if (!singleTopNode) {
      setIsOpen(!isOpen);
    }
  };
  return (
    <div className={cn('statistikk-admin')}>
      <div
        style={{ paddingLeft: `${indent}em` }}
        onClick={handleClick}
        className={cn('statistikk-admin__node-item', {
          'statistikk-admin__node-item--current': currentData?.id === id
        })}
      >
        {statusIndicator && (
          <span
            className={cn('statistikk-admin__node-item-dot', {
              'statistikk-admin__node-item-dot--complete': statusOk,
              'statistikk-admin__node-item-dot--ignore': isExcluded
            })}
          />
        )}
        <Clicker
          iconIsBeforeText
          onClick={handleClick}
          text={title}
          alt={title}
          iconSize={Clicker.iconSizes.small}
          iconName={
            nodes?.length && !singleTopNode
              ? isOpen
                ? Clicker.iconNames.arrowUp
                : Clicker.iconNames.arrowDown
              : ''
          }
        />
      </div>
      {isOpen &&
        nodes?.map((node, i) => {
          return (
            <StatistikkNode
              key={i + node.id}
              {...node}
              breadcrumbText={`${breadcrumbText} > ${node.title}`}
              statusIndicator={statusIndicator}
              indent={indent + (singleTopNode ? 0 : 1)}
              currentData={currentData}
              setCurrentData={setCurrentData}
            />
          );
        })}
    </div>
  );
};

export default StatistikkNode;

StatistikkNode.GraphProps = {
  x: PropTypes.string,
  y: PropTypes.string,
  color: PropTypes.string
};

StatistikkNode.LabelTextProps = {
  label: PropTypes.string,
  text: PropTypes.string,
  fullWidth: PropTypes.bool,
  statusOk: PropTypes.bool,
  statusIndicator: PropTypes.bool
};

StatistikkNode.RecursiveNodeProps = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  statusOk: PropTypes.bool,
  overordnetStatusOk: PropTypes.bool,
  isExcluded: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.shape(StatistikkNode.LabelTextProps))
  )
};

StatistikkNode.propTypes = {
  ...StatistikkNode.RecursiveNodeProps,
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      ...StatistikkNode.RecursiveNodeProps,
      nodes: PropTypes.any
    })
  ),
  // Props outside the recursive structure. Ignore these on backend:
  singleTopNode: PropTypes.bool,
  isFirstInList: PropTypes.bool,
  statusIndicator: PropTypes.bool,
  currentdata: PropTypes.shape(StatistikkNode.RecursiveNodeProps),
  setCurrentData: PropTypes.func,
  breadcrumbText: PropTypes.string
};

StatistikkNode.defaultProps = {
  setData: () => {},
  outer: false,
  indent: 1
};
