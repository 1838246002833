import React from 'react';
import PropTypes from 'prop-types';

const LanguageSearchResult = ({
  suggestions,
  isDisabled,
  handleAddByButton
}) => {
  if (!suggestions.length) return null;
  return (
    <ul className="language-select__input-suggestion-list">
      {suggestions
        .filter((l, i) => i < 5)
        .map(({ norwegianName, nativeName, iso6391Code }) => (
          <li
            key={norwegianName}
            className="language-select__input-suggestion-list-item"
          >
            <button
              type="button"
              disabled={isDisabled}
              className="language-select__suggestion-item-button"
              onClick={e => handleAddByButton(e, norwegianName)}
            >
              <span className="language-select__suggestion-item-button-text">
                {norwegianName}&nbsp;
              </span>
              <span
                className="language-select__suggestion-item-button-text"
                lang={iso6391Code}
              >
                ({nativeName})
              </span>
            </button>
          </li>
        ))}
    </ul>
  );
};

LanguageSearchResult.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.object), //exclude from backend
  isDisabled: PropTypes.bool, //exclude from backend
  handleAddByButton: PropTypes.func //exclude from backend
};

LanguageSearchResult.defaultValue = {
  suggestions: []
};

export default LanguageSearchResult;
