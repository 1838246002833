import RedaktorGrensesnittToolbar from 'components/redaktor-grensesnitt/redaktor-grensesnitt-toolbar';
import PropTypes from 'prop-types';
import React from 'react';

export default function GenerellDashboardDisplayData({
  redaktorActions,
  infoBoxes
}) {
  return (
    <div style={{ width: '100%' }}>
      <div className={'redaktor-grensesnitt__info-container'}>
        {infoBoxes.map(({ label, date }) => (
          <div key={label} className={'redaktor-grensesnitt__info-box'}>
            <p style={{ marginBottom: '1rem' }}>{label}</p>
            <b style={{ fontSize: 40 }}>{date}</b>
          </div>
        ))}
      </div>
      <RedaktorGrensesnittToolbar {...redaktorActions} />
    </div>
  );
}

GenerellDashboardDisplayData.propTypes = {
  type: PropTypes.string,
  redaktorActions: PropTypes.exact(RedaktorGrensesnittToolbar.propTypes),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  infoBoxes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      date: PropTypes.string
    })
  )
};
