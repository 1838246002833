import React from 'react';
import PropTypes from 'prop-types';

import Clicker from '../clicker';
import NavMenuMobile from './nav-menu-mobile';
import NavLink from './nav-link';

const NavMenu = ({ backLink, navLinks }) => {
  return (
    <>
      <nav className="nav-menu">
        {backLink && (
          <div className="nav-menu__back">
            <Clicker
              {...backLink}
              theme={Clicker.themes.navigation}
              iconName={Clicker.iconNames.arrowLeft}
              iconSize={Clicker.iconSizes.medium}
              iconIsBeforeText={true}
            />
          </div>
        )}
        <menu className="nav-menu__menu">
          {navLinks.map((link, index) => {
            return (
              <li className="nav-menu__item" key={index}>
                <NavLink {...link} />
              </li>
            );
          })}
        </menu>
        <div className="nav-menu__mobile-menu">
          <NavMenuMobile links={navLinks} />
        </div>
      </nav>
    </>
  );
};

NavMenu.propTypes = {
  navLinks: PropTypes.arrayOf(PropTypes.exact(NavLink.propTypes)),
  backLink: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string
  })
};

NavMenu.defaultProps = {
  navLinks: []
};

export default NavMenu;
