import React from 'react';
import PropTypes from 'prop-types';
import colors from 'js/kartlegging-colors';

const OversiktChartTable = ({
  header,
  mestringsgradHead,
  kartleggingsgradHead,
  rows
}) => {
  return (
    <div className="oversikt-chart-table">
      <table className="oversikt-chart-table__table">
        <thead>
          <tr>
            <td rowSpan={2}></td>
            {header?.map(data => (
              <th
                key={data}
                className="oversikt-chart-table__mestringsnivå-header"
                colSpan={2}
                scope="colgroup"
              >
                {data}
              </th>
            ))}
          </tr>
          <tr>
            <th className="oversikt-chart-table__sub-header" scope="col">
              {mestringsgradHead}
            </th>
            <th className="oversikt-chart-table__sub-header" scope="col">
              {kartleggingsgradHead}
            </th>
            <th className="oversikt-chart-table__sub-header" scope="col">
              {mestringsgradHead}
            </th>
            <th className="oversikt-chart-table__sub-header" scope="col">
              {kartleggingsgradHead}
            </th>
            <th className="oversikt-chart-table__sub-header" scope="col">
              {mestringsgradHead}
            </th>
            <th className="oversikt-chart-table__sub-header" scope="col">
              {kartleggingsgradHead}
            </th>
          </tr>
        </thead>
        <tbody>
          {rows?.map(({ name, color, row }) => (
            <tr key={name}>
              <th
                className={`oversikt-chart-table__kompetanseområde-header oversikt-chart-table__kompetanseområde-header--${color}`}
                scope='row'
              >
                {name}
              </th>
              {row?.map((data, i) => (
                <td key={i} className="oversikt-chart-table__data-cell">
                  {data}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

OversiktChartTable.propTypes = {
  header: PropTypes.arrayOf(PropTypes.string),
  mestringsgradHead: PropTypes.string,
  kartleggingsgradHead: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      color: PropTypes.oneOf(Object.keys(colors)),
      row: PropTypes.arrayOf(PropTypes.string)
    })
  )
};

export default OversiktChartTable;
