import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../js/api-helper';

import TextInput from '../text-input';
import LoadingSpinner from '../loading-spinner';

import useDebounce from '../../hooks/use-debounce';
import useClickOutside from '../../hooks/use-click-outside';
import Clicker from 'components/clicker/clicker';
import SearchItem from 'components/search-item/search-item';

const MIN_CHARACTERS = 2;
const DEBOUNCE_TIME = 500;
const MAX_NUMBER_OF_RESULTS = 6;

const QuickSearchDropdown = ({
  textInput,
  searchButtonText,
  brukereLabel,
  skolerLabel,
  kommunerLabel,
  isDisabled,
  endpoint,
  onItemClick,
  onSearchClick
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const listRef = useRef();
  useClickOutside(listRef, () => setIsOpen(false));

  const [items, setItems] = useState([]);
  const [message, setMessage] = useState('');
  const [showAllText, setShowAllText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [activeTextInput, setActiveTextInput] = useState({
    ...textInput,
    value: ''
  });
  const [activeFilters, setActiveFilters] = useState({
    users: false,
    schools: false,
    municipalities: false
  });

  const debouncedSearchText = useDebounce(activeTextInput.value, DEBOUNCE_TIME);

  const resetList = () => {
    setItems([]);
    setMessage('');
    setIsOpen(false);
  };

  const handleOnChange = value => {
    setActiveTextInput({ ...activeTextInput, value: value });
    if (value.length < 2) {
      setMessage('');
      setItems([]);
      setShowAllText('');
      setIsOpen(false);
    }
  };

  const executeSearch = async () => {
    setIsLoading(true);

    try {
      const { items, message, showAllText } = await api.execute(endpoint, {
        value: activeTextInput.value,
        includeBrukere: activeFilters.users,
        includeSkoler: activeFilters.schools,
        includeKommuner: activeFilters.municipalities,
        includeAll:
          Object.values(activeFilters).every(val => val) ||
          Object.values(activeFilters).every(val => !val)
      });

      if (items?.length > 0 || message) setIsOpen(true);

      if (message) setMessage(message);
      else setMessage('');

      if (items?.length) setItems(items);
      else setItems([]);

      if (showAllText) setShowAllText(showAllText);
      else setShowAllText('');

      setIsLoading(false);
    } catch (err) {
      setItems([]);
      err.message ? setMessage(err.message) : setMessage('');
      setIsLoading(false);
    }
  };

  const handleOnSearchClick = () => {
    if (activeTextInput.value.length >= 2) {
      onSearchClick(
        activeTextInput.value,
        activeFilters.users,
        activeFilters.schools,
        activeFilters.municipalities,
        Object.values(activeFilters).every(val => val) ||
          Object.values(activeFilters).every(val => !val)
      );
      setIsOpen(false);
    }
  };

  const handleOnResultClick = item => {
    resetList();

    onItemClick(item);

    setActiveTextInput({ ...activeTextInput, value: '' });
  };

  React.useEffect(() => {
    if (debouncedSearchText.length >= MIN_CHARACTERS) {
      executeSearch();
    }
  }, [debouncedSearchText]);

  return (
    <div className="quick-search-dropdown" ref={listRef}>
      <div className="quick-search-dropdown__input">
        <TextInput
          {...activeTextInput}
          autoFocus={false}
          theme={TextInput.themes.minimal}
          onChange={handleOnChange}
          onFocus={() => setIsOpen(message || items.length > 0)}
          onEnter={handleOnSearchClick}
          isDisabled={isDisabled}
          hasAutoComplete={false}
        />

        {isLoading && (
          <div className="quick-search-dropdown__loading">
            <LoadingSpinner />
          </div>
        )}
        <Clicker
          className="quick-search-dropdown__search-button"
          onClick={handleOnSearchClick}
          iconName={Clicker.iconNames.searchWhite}
          iconSize={Clicker.iconSizes.medium}
          text={searchButtonText}
          textIsHidden={true}
        />
      </div>
      <div className="quick-search-dropdown__results">
        {isOpen && (
          <div className="quick-search-dropdown__menu">
            <ul className="quick-search-dropdown__list">
              {items?.slice(0, MAX_NUMBER_OF_RESULTS).map((item, index) => (
                <li key={index} className="quick-search-dropdown__item">
                  <SearchItem
                    isDisabled={isLoading}
                    {...item}
                    onClick={() => handleOnResultClick(item)}
                  />
                </li>
              ))}
            </ul>
            {showAllText && (
              <button
                className="quick-search-dropdown__show-all"
                onClick={handleOnSearchClick}
              >
                {showAllText}
              </button>
            )}
            {message && (
              <p className="quick-search-dropdown__message">{message}</p>
            )}
          </div>
        )}
      </div>
      <div className="quick-search-dropdown__filters">
        {brukereLabel && (
          <>
            <input
              className="quick-search-dropdown__checkbox"
              id="users"
              type="checkbox"
              onChange={() =>
                setActiveFilters({
                  ...activeFilters,
                  users: !activeFilters.users
                })
              }
              value="users"
              checked={activeFilters.users}
            />
            <label htmlFor="users" className="quick-search-dropdown__label">
              {brukereLabel}
            </label>
          </>
        )}
        {skolerLabel && (
          <>
            <input
              className="quick-search-dropdown__checkbox"
              id="schools"
              type="checkbox"
              onChange={() =>
                setActiveFilters({
                  ...activeFilters,
                  schools: !activeFilters.schools
                })
              }
              value="schools"
              checked={activeFilters.schools}
            />
            <label htmlFor="schools" className="quick-search-dropdown__label">
              {skolerLabel}
            </label>
          </>
        )}
        {kommunerLabel && (
          <>
            <input
              className="quick-search-dropdown__checkbox"
              id="municipalities"
              type="checkbox"
              onChange={() =>
                setActiveFilters({
                  ...activeFilters,
                  municipalities: !activeFilters.municipalities
                })
              }
              value="municipalities"
              checked={activeFilters.municipalities}
            />
            <label
              htmlFor="municipalities"
              className="quick-search-dropdown__label"
            >
              {kommunerLabel}
            </label>
          </>
        )}
      </div>
    </div>
  );
};

QuickSearchDropdown.propTypes = {
  isDisabled: PropTypes.bool, //exclude from backend
  onItemClick: PropTypes.func, //exclude from backend
  onSearchClick: PropTypes.func, //exclude from backend
  textInput: PropTypes.exact(TextInput.propTypes),
  searchButtonText: PropTypes.string,
  brukereLabel: PropTypes.string,
  skolerLabel: PropTypes.string,
  kommunerLabel: PropTypes.string,
  endpoint: PropTypes.string.isRequired //exclude from backend
};

export default QuickSearchDropdown;
