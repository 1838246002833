import React, { useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../../js/api-helper';

import Modal from '../modal';
import Clicker from '../../clicker';
import LoadingSpinner from '../../loading-spinner';

import CheckboxInput from 'components/checkbox-input/checkbox-input';

const RejectMoveStudentModal = ({
  isOpen,
  onClose,
  onConfirm,
  isDisabled,
  closeModalHiddenText,
  title,
  text,
  buttonText,
  payload,
  studentId,
  studentName,
  rejectMoveStudentEndpoint
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    isDisabled: false,
    payload,
    message: '',
    ids: []
  });

  const handleOnChecked = (checked, orgnr) => {
    if (checked) {
      setState({
        ...state,
        ids: [...state.ids, orgnr]
      });
    } else {
      setState({
        ...state,
        ids: state.ids.filter(item => item !== orgnr)
      });
    }
  };

  const handleOnConfirm = async () => {
    setIsLoading(true);
    var orgIds = state.ids;

    try {
      await api.execute(rejectMoveStudentEndpoint, { studentId, orgIds });

      onConfirm(studentId);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  return (
    <Modal
      themes={[Modal.themes.white]}
      isOpen={isOpen}
      onClose={onClose}
      isDisabled={isDisabled || isLoading}
      closeButtonLabel={closeModalHiddenText}
    >
      <div className="reject-move-student-modal">
        <div className="reject-move-student-modal__content">
          {title && (
            <h2 className="reject-move-student-modal__title">{title}</h2>
          )}
          {text && (
            <p className="reject-move-student-modal__lead">
              {text.replace('$elev', studentName)}
            </p>
          )}
          {state.payload.length > 0 && (
            <div className="reject-move-student-modal__assigned-list">
              <ul className="reject-move-student-modal__list">
                {state.payload.map((flytting, index) => {
                  const { onskesFlyttetTil, onskesFlyttetTilNavn } = flytting;

                  return (
                    <li key={index} className="reject-move-student-modal__item">
                      <div className="reject-move-student-modal__school">
                        <div className="reject-move-student-modal__accept-box">
                          <CheckboxInput
                            id={onskesFlyttetTil}
                            label={onskesFlyttetTilNavn}
                            onChange={checked =>
                              handleOnChecked(checked, onskesFlyttetTil)
                            }
                          />
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <div className="reject-move-student-modal__actions">
            <div className="reject-move-student-modal__action">
              <Clicker
                text={buttonText}
                theme={Clicker.themes.primary}
                onClick={handleOnConfirm}
              />
            </div>
            <div className="reject-move-student-modal__action">
              {isLoading && <LoadingSpinner />}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

RejectMoveStudentModal.propTypes = {
  isDisabled: PropTypes.bool, //exclude from backend
  onClose: PropTypes.func, //exclude from backend
  onConfirm: PropTypes.func, //exclude from backend
  isOpen: PropTypes.bool, //exclude from backend
  closeModalHiddenText: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  studentName: PropTypes.string, //exclude from backend
  studentId: PropTypes.string, //exclude from backend
  payload: PropTypes.exact({
    onskesFlyttetTil: PropTypes.string,
    onskesFlyttetTilNavn: PropTypes.string
  }),
  rejectMoveStudentEndpoint: PropTypes.string
};

RejectMoveStudentModal.defaultProps = {
  onConfirm: () => {},
  onClose: () => {}
};

export default RejectMoveStudentModal;
