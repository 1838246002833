import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ResourceTable from 'components/resource-table';
import LoadingSpinner from 'components/loading-spinner';
import SearchAdminTable from './search-admin-table';
import Tabs from 'components/tabs/tabs';

const SearchAdmin = ({ tabs, table, handleSearchItemClick }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return (
    <>
      <div className="search-admin">
        <div className="search-admin__content">
          {tabs && (
            <div className={'search-admin__tabs'}>
              <Tabs {...tabs} />
            </div>
          )}
          <div className="search-admin__row">
            <SearchAdminTable
              table={table}
              handleSearchItemClick={handleSearchItemClick}
            />
          </div>
        </div>
      </div>
      {isLoading && <LoadingSpinner shouldTakeOverScreen={true} />}
    </>
  );
};

SearchAdmin.propTypes = {
  tabs: PropTypes.exact(Tabs.propTypes),
  table: PropTypes.exact(ResourceTable.propTypes),
  handleSearchItemClick: PropTypes.func
};

SearchAdmin.defaultProps = {
  updateNestedValue: () => {}
};

export default SearchAdmin;
