import UdirRichTextEditor from 'components/rich-text-editor/rich-text-editor';
import TextInput from 'components/text-input';
import PropTypes from 'prop-types';
import React from 'react';

export default function KartleggingUtsagnLanguageItem({
  utsagnId,
  utsagnLanguageItem,
  uniqueNodeId,
  handleChange,
  placeholderText,
  labelText,
  sanityImageQuery,
  inEditMode,
  readOnly
}) {
  const hideTools = !inEditMode || readOnly;

  return (
    <>
      <h3 className={'redaktor-grensesnitt__language-title'}>
        {utsagnLanguageItem.languageName}
      </h3>
      <div className={'redaktor-grensesnitt__margin-bottom'}>
        <TextInput
          isDisabled={hideTools}
          id={utsagnLanguageItem.id}
          key={uniqueNodeId + 'text'}
          label={labelText.utsagnText}
          placeholder={placeholderText.utsagnText}
          theme={TextInput.themes.defaultDisabled}
          value={utsagnLanguageItem?.questionText?.text || ''}
          onChange={(value, id) =>
            handleChange(utsagnId, id, value, 'questionText')
          }
        />
      </div>
      <div className={'redaktor-grensesnitt__margin-bottom'}>
        <UdirRichTextEditor
          readOnly={hideTools}
          key={utsagnLanguageItem.id}
          label={labelText.tooltipText}
          placeholder={placeholderText.tooltipText}
          onChange={(id, value) => handleChange(utsagnId, id, value, 'tooltip')}
          id={utsagnLanguageItem.id}
          hasActionButtons={true}
          value={utsagnLanguageItem?.tooltip?.text || ''}
        />
      </div>
    </>
  );
}

KartleggingUtsagnLanguageItem.utsagnProps = {
  id: PropTypes.string,
  languageCode: PropTypes.string,
  languageName: PropTypes.string,
  questionText: PropTypes.shape({
    text: PropTypes.string
  }),
  tooltip: PropTypes.shape({
    text: PropTypes.string // html
  })
};

KartleggingUtsagnLanguageItem.propTypes = {
  utsagnId: PropTypes.string,
  utsagnLanguageItem: PropTypes.shape(
    KartleggingUtsagnLanguageItem.utsagnProps
  ),
  placeholderText: PropTypes.exact({
    utsagnText: PropTypes.string, // ignore backend
    tooltipText: PropTypes.string // ignore backend
  }),
  labelText: PropTypes.exact({
    utsagnText: PropTypes.string, // ignore backend
    tooltipText: PropTypes.string // ignore backend
  }),
  handleChange: PropTypes.func.isRequired, //Ignore backend
  uniqueNodeId: PropTypes.string.isRequired, // ignore backend
  sanityImageQuery: PropTypes.string, // ignore backend
  inEditMode: PropTypes.bool, //ignore backend
  readOnly: PropTypes.bool //ignore backend
};
