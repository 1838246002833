import PropTypes from 'prop-types';

const statuses = {
  green: 'green',
  red: 'red'
};

const Resource = () => {};

Resource.propTypes = {
  id: PropTypes.string.isRequired,
  userId: PropTypes.string,
  orgId: PropTypes.string,
  roleId: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  birthDate: PropTypes.string,
  menuItems: PropTypes.array, // ignore from backend
  link: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string
  }),
  isCurrentUser: PropTypes.bool,
  status: PropTypes.oneOf(Object.values(statuses)),
  requestDelete: PropTypes.bool,
  requestMoved: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.exact({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
      hasNotification: PropTypes.bool,
      notificationText: PropTypes.string
    })
  ).isRequired
};

Resource.statuses = statuses;

export default Resource;
