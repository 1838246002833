import React from 'react';
import PropTypes from 'prop-types';

import Questionnaire from '../questionnaire';

const Kartlegging = ({ questionnaires, saveEndpoint }) => {
  return (
    <div className="kartlegging">
      {questionnaires &&
        !!questionnaires.length &&
        questionnaires.map((questionnaire, index) => (
          <div key={index} className="kartlegging__row">
            <Questionnaire saveEndpoint={saveEndpoint} {...questionnaire} />
          </div>
        ))}
    </div>
  );
};

Kartlegging.propTypes = {
  saveEndpoint: PropTypes.string,
  questionnaires: PropTypes.arrayOf(PropTypes.exact(Questionnaire.propTypes))
    .isRequired
};

export default Kartlegging;
