import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
/**
 * Controls text input by itself, with optional onChange. If you dont want callbacks, use refs to retrieve values.
 * @param {*} param0
 */
export default function TextArea({
  maxLength,
  value,
  isDisabled,
  onChange,
  id,
  inputKey,
  label,
  placeholder
}) {
  const [warning, setWarning] = useState(false);
  const [currentInput, setCurrentInput] = useState(value);
  const setLimit = React.useMemo(() => typeof maxLength === 'number', [
    maxLength
  ]);
  const nearMaxLength = React.useMemo(
    () => maxLength && currentInput?.length > maxLength * 0.9,
    [maxLength, currentInput]
  );

  const handleChange = e => {
    if (isDisabled) return;
    const { value } = e.target;
    if (setLimit && e.target.value.length > maxLength) {
      setWarning(true);
    } else {
      setWarning(false);
      // Handle input here if necessary
      setCurrentInput(value);
      onChange(value, id);
    }
  };
  return (
    <div key={inputKey} className="text-area">
      {label && (
        <label className={'text-area__label'} htmlFor={id}>
          {label}
        </label>
      )}
      <textarea
        className={cn('text-area__input', {})}
        rows="4"
        id={id}
        placeholder={placeholder}
        value={currentInput}
        onChange={handleChange}
        disabled={isDisabled}
        maxlength={maxLength}
      />
      <div className="text-area__input text-area__print-only">
        {currentInput}
      </div>
      {nearMaxLength && (
        <p
          className={cn('text-area__input-counter', {
            'text-area__input-counter--warn': warning
          })}
        >
          {currentInput ? currentInput.length : 0}/{maxLength}
        </p>
      )}
    </div>
  );
}

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  inputKey: PropTypes.string,
  isDisabled: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func // ignore backend
};

TextArea.defaultProps = {
  onChange: () => {},
  value: '',
  id: '',
  placeholder: 'Start å skrive'
};
