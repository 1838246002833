import { useState } from 'react';
import useEvent from './use-event';

/** Executes the provided callback when a click event occurs outside of the provided ref */
const useClickOutside = (ref, callback = () => {}) => {
  const [clickedOutside, setClickedOutside] = useState(false);
  useEvent('mousedown', e => {
    if (
      ref.current &&
      e.target !== ref.current &&
      !ref.current.contains(e.target) &&
      e.clientX < document.body.scrollWidth
    ) {
      setClickedOutside(true);
      callback();
    } else {
      setClickedOutside(false);
    }
  });
  return clickedOutside;
};

export default useClickOutside;
