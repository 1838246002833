import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

const themes = {
  darkBackground: 'darkBackground'
};

const Toggle = ({
  leftText,
  rightText,
  isLeft,
  onToggle,
  show,
  toggleLabel,
  theme
}) => {
  const [currentIsLeft, setCurrentIsLeft] = React.useState(isLeft);

  const handleSlide = () => {
    setCurrentIsLeft(!currentIsLeft);

    setTimeout(() => {
      if (onToggle) {
        onToggle(currentIsLeft);
      }
    }, 500);
  };

  if (!show) return null;
  return (
    <fieldset
      className={cn('toggle', { [`toggle--${themes[theme]}`]: themes[theme] })}
    >
      <legend className="toggle__legend">{toggleLabel}</legend>
      <button
        className={cn('toggle__button', {
          'toggle__button--active': currentIsLeft
        })}
        onClick={handleSlide}
        aria-pressed={currentIsLeft}
      >
        {leftText}
      </button>
      <button
        className={cn('toggle__button', {
          'toggle__button--active': !currentIsLeft
        })}
        onClick={handleSlide}
        aria-pressed={!currentIsLeft}
      >
        {rightText}
      </button>
      <span
        className={cn('toggle__active-text', {
          'toggle__active-text--right': !currentIsLeft
        })}
      >
        {currentIsLeft ? leftText : rightText}
      </span>
    </fieldset>
  );
};

Toggle.propTypes = {
  rightText: PropTypes.string,
  leftText: PropTypes.string,
  isLeft: PropTypes.bool,
  show: PropTypes.bool,
  toggleLabel: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)), // ignore BE
  onToggle: PropTypes.func // ignore backend
};

Toggle.themes = themes;

export default Toggle;
