import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/checkbox/checkbox';
import Clicker from '../clicker';
import { useChartContext } from '../../../source/contexts/chart-context';

const DownloadKartleggingReport = ({
  endpoint,
  heading,
  text,
  checkbox,
  buttonText,
  onSubmit,
  onFinish
}) => {
  const [includeHistory, setIncludehistory] = useState(false);
  const chartContext = useChartContext();

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit();
    let svg = chartContext.SVGFromChart;
    let filename = '';
    fetch(endpoint, {
      method: 'post',
      body: JSON.stringify({
        svg: svg ? svg : undefined,
        includeHistory: includeHistory
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        filename = response.headers
          .get('Content-Disposition')
          .split('filename=')[1]
          .split(';')[0];
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        onFinish();
      })
      .catch(error => {
        onFinish();
        // eslint-disable-next-line no-console
        console.log('Error:', error);
      });
  };

  return (
    <form className="download-kartlegging-report">
      <h2 className="download-kartlegging-report__heading">{heading}</h2>
      <p className="download-kartlegging-report__text">{text}</p>
      <Checkbox
        {...checkbox}
        id="download-kartlegging-report-checkbox"
        checked={includeHistory}
        onChange={setIncludehistory}
      />
      <Clicker
        className="download-kartlegging-report__submit"
        theme={Clicker.themes.primary}
        onClick={handleSubmit}
        text={buttonText}
      />
    </form>
  );
};

DownloadKartleggingReport.propTypes = {
  endpoint: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
  checkbox: PropTypes.exact(Checkbox.propTypes),
  buttonText: PropTypes.string,
  onSubmit: PropTypes.func,
  onFinish: PropTypes.func
};

export default DownloadKartleggingReport;
