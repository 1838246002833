import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import reactStringReplace from 'react-string-replace';
import usePhrases from '../../hooks/use-phrases';
import useClickOutside from '../../hooks/use-click-outside';

import ArrowTooltip from '../arrow-tooltip/arrow-tooltip';

const DefinitionText = ({ text, definitions, children }) => {
  const [definitionContent, setDefinitionContent] = useState(null);

  const { moreInfo } = usePhrases;
  const tooltipRef = React.useRef();
  const refArray = React.useRef([]);
  useClickOutside(tooltipRef, () => setDefinitionContent(null));

  const handleSetDefinitionContent = (definition, index) => {
    if (definitionContent) setDefinitionContent(null);
    else {
      setDefinitionContent({ tooltip: definition, index });
    }
  };

  // TODO: Replace with modal?
  const renderTooltip = () => {
    if (definitionContent?.tooltip.matchWord) {
      const element = refArray?.current[definitionContent?.index];
      if (element) {
        const { offsetLeft, offsetTop } = element;

        return (
          <ArrowTooltip
            ref={tooltipRef}
            key={definitionContent.tooltip.matchWord}
            {...definitionContent.tooltip}
            isOpen={!!definitionContent.tooltip}
            onClose={() => setDefinitionContent(null)}
            top={offsetTop + 35}
            left={offsetLeft + 5}
          />
        );
      }
    }
  };

  const renderTextWithDefinition = () => {
    if (!definitions.length) return text;
    if (!text) return '';

    let output = text;

    definitions.forEach((definition, index) => {
      output = reactStringReplace(
        output,
        definition.matchWord,
        (match, i, offset) => {
          return (
            <span
              key={`${i}-${offset}-${match}`}
              role="button"
              tabIndex="0"
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ')
                  handleSetDefinitionContent(definition, index);
              }}
              aria-label={moreInfo}
              ref={ref => {
                refArray.current[index] = ref;
              }}
              className={cn('definition-text__clickable', {
                'definition-text__clickable--active':
                  definitionContent?.index === index
              })}
              onClick={() => handleSetDefinitionContent(definition, index)}
            >
              {`${match} `}
            </span>
          );
        }
      );
    });
    return output;
  };

  return (
    <div className="definition-text">
      <span className="definition-text__text">
        {renderTextWithDefinition()}
      </span>
      <span className="definition-text__tooltip-container">{children}</span>
      {renderTooltip()}
    </div>
  );
};

DefinitionText.propTypes = {
  underlineColorValue: PropTypes.string, // ignore from backend
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  text: PropTypes.string.isRequired,
  definitions: PropTypes.arrayOf(
    PropTypes.exact({
      matchWord: PropTypes.string,
      ...ArrowTooltip.textProps
    })
  )
};

DefinitionText.defaultProps = {
  definitions: [],
  text: ''
};

export default DefinitionText;
