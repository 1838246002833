import InputErrorMessage from 'components/input-error-message';
import KartleggingUtsagnLanguageItem from './kartlegging-utsagn-language-item';
import MenuButton from 'components/resource-table/menu-button';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

const getInitialUtsagnTexts = utsagn => {
  return utsagn?.languageItems?.flatMap(u => {
    return {
      questionText: u?.questionText?.text,
      tooltipText: u?.tooltip?.text
    };
  });
};

export default function KartleggingUtsagnItem({
  utsagn,
  uniqueNodeId,
  listLength,
  onDeleteCallback,
  fixedStructure,
  reorder,
  index,
  mestringsnivaNumber,
  handleChange,
  handleUndo,
  errorMessage,
  placeholderText,
  recentlySaved,
  labelText,
  buttonText,
  sanityImageQuery,
  readOnly
}) {
  const isNew = useMemo(() => {
    return utsagn?.languageItems?.every(
      item => item?.questionText?.text === '' && item?.tooltip?.text === ''
    );
  }, [utsagn]);

  const requiredInputRef = useRef(null);
  const [inEditMode, setInEditMode] = useState(isNew);
  const initialUtsagnTexts = useRef(getInitialUtsagnTexts(utsagn));

  useEffect(() => {
    if (recentlySaved) {
      setInEditMode(false);
      initialUtsagnTexts.current = getInitialUtsagnTexts(utsagn);
    }
  });

  const undoDisabled = useMemo(() => {
    if (
      initialUtsagnTexts?.current?.every(
        uT => uT?.questionText === '' && uT?.tooltipText === ''
      )
    )
      return true;
    else
      return initialUtsagnTexts?.current?.every(
        (uT, i) =>
          uT?.questionText === utsagn?.languageItems[i]?.questionText?.text &&
          uT?.tooltipText === utsagn?.languageItems[i]?.tooltip?.text
      );
  }, [
    inEditMode,
    initialUtsagnTexts,
    utsagn?.languageItems?.map(lI => [
      lI?.questionText?.text,
      lI?.tooltip?.text
    ])
  ]);

  useEffect(() => {
    if (requiredInputRef?.current && errorMessage) {
      requiredInputRef.current.scrollIntoView({
        behavior: 'smooth'
      });
      setInEditMode(true);
    } else if (requiredInputRef.current && isNew) {
      requiredInputRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [requiredInputRef, errorMessage, isNew]);

  return (
    <li
      className="redaktor-grensesnitt__data-content-group-item-wrapper"
      ref={requiredInputRef}
      key={uniqueNodeId + 'list'}
    >
      <div className={'redaktor-grensesnitt__data-content-group-item-left'}>
        <h2
          className={'redaktor-grensesnitt__data-content-group-number-index'}
        >{`${mestringsnivaNumber}.${index + 1}`}</h2>
      </div>
      <div className="redaktor-grensesnitt__data-content-group-item">
        {errorMessage && (
          <div style={{ marginBottom: '1rem' }}>
            <InputErrorMessage errors={[errorMessage]} />
          </div>
        )}
        {!readOnly && (
          <div style={{ position: 'absolute', right: '0.5rem', top: '0.5rem' }}>
            <MenuButton
              items={[
                {
                  text: inEditMode ? buttonText.cancelEdit : buttonText.edit,
                  onClick: () => setInEditMode(!inEditMode)
                },
                {
                  isDisabled: fixedStructure || index === 0,
                  text: buttonText.moveUp,
                  onClick: () => reorder(index, -1)
                },
                {
                  isDisabled: fixedStructure || index === listLength - 1,
                  onClick: () => reorder(index, 1),
                  text: buttonText.moveDown
                },
                {
                  isDisabled: fixedStructure,
                  onClick: () => onDeleteCallback(utsagn.id),
                  text: buttonText.delete
                },
                {
                  isDisabled: undoDisabled,
                  onClick: () => handleUndo(utsagn.id),
                  text: buttonText.cancel
                }
              ]}
            />
          </div>
        )}

        {utsagn?.languageItems?.map((utsagnLanguageItem, i) => {
          return (
            <KartleggingUtsagnLanguageItem
              key={'KartleggingsUtsagnLanguageItem-' + i}
              utsagnId={utsagn.id}
              utsagnLanguageItem={utsagnLanguageItem}
              uniqueNodeId={uniqueNodeId}
              handleChange={handleChange}
              errorMessage={errorMessage}
              placeholderText={placeholderText}
              recentlySaved={recentlySaved}
              labelText={labelText}
              sanityImageQuery={sanityImageQuery}
              inEditMode={inEditMode}
              readOnly={readOnly}
            />
          );
        })}
      </div>
    </li>
  );
}

KartleggingUtsagnItem.utsagnProps = {
  id: PropTypes.string,
  languageItems: PropTypes.arrayOf(
    KartleggingUtsagnLanguageItem.propTypes.utsagnLanguageItem
  )
};

KartleggingUtsagnItem.propTypes = {
  buttonText: PropTypes.exact({
    edit: PropTypes.string.isRequired, // ignore backend
    cancelEdit: PropTypes.string.isRequired, // ignore backend
    cancel: PropTypes.string.isRequired, // ignore backend
    moveDown: PropTypes.string.isRequired, // ignore backend
    moveUp: PropTypes.string.isRequired, // ignore backend
    delete: PropTypes.string.isRequired // ignore backend
  }).isRequired,
  placeholderText: PropTypes.exact({
    utsagnText: PropTypes.string, // ignore backend
    tooltipText: PropTypes.string // ignore backend
  }),
  labelText: PropTypes.exact({
    utsagnText: PropTypes.string, // ignore backend
    tooltipText: PropTypes.string // ignore backend
  }),
  recentlySaved: PropTypes.bool, // ignore backend
  readOnly: PropTypes.bool, // ignore backend
  fixedStructure: PropTypes.bool, // ignore backend
  listLength: PropTypes.number.isRequired, //Ignore backend
  handleChange: PropTypes.func.isRequired, //Ignore backend
  handleUndo: PropTypes.func.isRequired, //Ignore backend
  onDeleteCallback: PropTypes.func.isRequired, //Ignore backend
  reorder: PropTypes.func.isRequired, //Ignore backend
  mestringsnivaNumber: PropTypes.number.isRequired, //Ignore backend
  index: PropTypes.number.isRequired, //Ignore backend
  uniqueNodeId: PropTypes.string.isRequired, // ignore backend
  errorMessage: PropTypes.string, // ignore backend
  utsagn: PropTypes.shape(KartleggingUtsagnItem.utsagnProps),
  sanityImageQuery: PropTypes.string
};
