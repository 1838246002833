import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import 'core-js/stable';

import colors from 'js/kartlegging-colors';
import { useWindowSize } from '../../hooks/useWindowSize';

import Icon from '../icon';

const types = Object.freeze({
  bar: 'bar',
  icon: 'icon',
  letters: 'letters'
});

const trinnTypes = {
  barnehage: 'barnehage',
  grunnskole: 'grunnskole',
  videregaaende: 'videregaaende'
};

const GridCardIllustration = ({
  isActive,
  color,
  numAnswered,
  totalQuestions,
  letters,
  trinn,
  type
}) => {
  const barRef = useRef(null);
  const windowSize = useWindowSize();

  const renderIcon = trinn => {
    switch (trinn) {
      case trinnTypes.barnehage:
        return (
          <Icon
            name={isActive ? Icon.names.barnehageColored : Icon.names.barnehage}
            size={
              isActive && windowSize.width > 768
                ? Icon.sizes.xl
                : Icon.sizes.large
            }
          />
        );
      case trinnTypes.grunnskole:
        return (
          <Icon
            name={
              isActive ? Icon.names.grunnskoleColored : Icon.names.grunnskole
            }
            size={isActive ? Icon.sizes.xl : Icon.sizes.large}
          />
        );
      case trinnTypes.videregaaende:
        return (
          <Icon
            name={
              isActive
                ? Icon.names.videregaaendeColored
                : Icon.names.videregaaende
            }
            size={isActive ? Icon.sizes.xl : Icon.sizes.large}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (barRef.current) {
      const heightPercentage = Math.min(
        Math.max((numAnswered / totalQuestions) * 100, 0),
        100
      );

      barRef.current.style.setProperty(
        '--bar-fill-percentage',
        `${heightPercentage}%`
      );
    }
  }, [windowSize]);

  switch (type) {
    case types.bar:
      return (
        <div
          className="grid-card-illustration grid-card-illustration--bar"
          ref={barRef}
          role="figure"
          aria-label={`${numAnswered} besvarte spørsmål av ${totalQuestions}`}
        >
          {isActive && (
            <div
              className={cn('grid-card-illustration__score', {
                'grid-card-illustration__score--text-at-top':
                  (numAnswered / totalQuestions) * 100 > 50
              })}
              aria-hidden
            >
              <span className="grid-card-illustration__score-num-answer">
                {numAnswered}&nbsp;svar
              </span>
              <span className="grid-card-illustration__score-total-answer">
                av&nbsp;{totalQuestions}
              </span>
            </div>
          )}
          <div className="grid-card-illustration__bar" />
          {!isActive && <div className="grid-card-illustration__bar-line" />}
        </div>
      );
    case types.icon:
      return (
        <div className="grid-card-illustration">
          <div
            className={cn('grid-card-illustration__icon-wrapper', {
              'grid-card-illustration__icon-wrapper--active': isActive
            })}
          >
            {renderIcon(trinn)}
          </div>
        </div>
      );
    case types.letters:
      return (
        <div className="grid-card-illustration grid-card-illustration--letters">
          <div
            className={cn('grid-card-illustration__letters-wrapper', {
              [`grid-card-illustration__letters-wrapper--${color}`]: color,
              'grid-card-illustration__letters-wrapper--active': isActive
            })}
          >
            <div className="grid-card-illustration__letters" aria-hidden={true}>
              {letters}
            </div>
          </div>
        </div>
      );
    default:
      return null;
  }
};

GridCardIllustration.propTypes = {
  // FE
  isActive: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(types)),

  // BE
  letters: PropTypes.string,
  numAnswered: PropTypes.number,
  totalQuestions: PropTypes.number,
  color: PropTypes.oneOf(Object.keys(colors)),
  trinn: PropTypes.oneOf(Object.values(trinnTypes))
};

GridCardIllustration.defaultProps = {};

GridCardIllustration.types = types;

export default GridCardIllustration;
