import React from 'react';
import PropTypes from 'prop-types';

import MainLayout from 'components/main-layout/main-layout';
import StructuralLayout from 'components/structural-layout/structural-layout';
import UndervisningsPersonell from 'components/undervisnings-pesonell/undervisnings-pesonell';

const UndervisningsPersonellPage = ({
  structuralLayout,
  mainLayout,
  undervisningsPersonellContent
}) => {
  return (
    <StructuralLayout {...structuralLayout}>
      <MainLayout {...mainLayout}>
        <UndervisningsPersonell {...undervisningsPersonellContent} />
      </MainLayout>
    </StructuralLayout>
  );
};

UndervisningsPersonellPage.propTypes = {
  structuralLayout: PropTypes.exact(StructuralLayout.propTypes),
  mainLayout: PropTypes.exact(MainLayout.propTypes),
  undervisningsPersonellContent: PropTypes.exact(
    UndervisningsPersonell.propTypes
  )
};

export default UndervisningsPersonellPage;
