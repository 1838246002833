import React, { useState } from 'react';
import PropTypes from 'prop-types';

import apiHelper from 'js/api-helper';

import Clicker from 'components/clicker';
import Icon from 'components/icon';
import ConfirmationModal from 'components/modal/confirmation-modal';

const LINK_TOKEN = '$link';

const NoticeBar = ({
  infoText,
  link,
  archiveConfirmEndpoint,
  archiveConfirmationModal
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleArchiveConfirm = () => {
    apiHelper.get(archiveConfirmEndpoint);
  };

  const replaceTokens = (stringToParse, link) => {
    const split = stringToParse ? stringToParse.split(LINK_TOKEN) : [];

    if (split.length === 1 || !link) return stringToParse;

    split.splice(
      1,
      0,
      <Clicker
        key={link.text}
        className="notice-bar__link"
        theme={Clicker.themes.underlinedDecoration}
        onClick={e => {
          if (archiveConfirmEndpoint) {
            e.preventDefault();
            setModalIsOpen(true);
          }
        }}
        {...link}
      />
    );

    return split;
  };

  return (
    <>
      <div className="notice-bar">
        <Icon name={Icon.names.warningBlack} size={Icon.sizes.mediumLarge} />
        <p className="notice-bar__text">{replaceTokens(infoText, link)}</p>
      </div>
      {archiveConfirmationModal && (
        <ConfirmationModal
          {...archiveConfirmationModal}
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          onConfirm={handleArchiveConfirm}
        />
      )}
    </>
  );
};

NoticeBar.propTypes = {
  infoText: PropTypes.string,
  link: PropTypes.exact(Clicker.link),
  archiveConfirmEndpoint: PropTypes.string,
  archiveConfirmationModal: PropTypes.exact(ConfirmationModal.propTypes)
};

export default NoticeBar;
