import React from 'react';
import PropTypes from 'prop-types';
import AnonymousLayout from '../layouts/anonymous-layout';
import FrequentlyAskedQuestions from 'components/frequently-asked-questions/frequently-asked-questions';

const FaqPage = ({ layout, faq }) => {
  return (
    <AnonymousLayout theme={AnonymousLayout.themes.document} {...layout}>
      <FrequentlyAskedQuestions {...faq} />
    </AnonymousLayout>
  );
};

FaqPage.propTypes = {
  layout: PropTypes.exact(AnonymousLayout.propTypes),
  faq: PropTypes.exact(FrequentlyAskedQuestions.propTypes)
};

export default FaqPage;
