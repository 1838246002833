import React from 'react';
import PropTypes from 'prop-types';

import AdminLayout from '../layouts/admin-layout';
import OwnerAdmin from '../admin/owner-admin';

const OwnerAdminPage = ({ layout, admin }) => {
  return (
    <AdminLayout {...layout}>
      <OwnerAdmin {...admin} />
    </AdminLayout>
  );
};

OwnerAdminPage.propTypes = {
  layout: PropTypes.exact(AdminLayout.propTypes),
  admin: PropTypes.exact(OwnerAdmin.propTypes)
};

export default OwnerAdminPage;
