import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const SamtaleHeading = ({ title, showTitleWithUnderscore, lead }) => {
  return (
    <div className={'samtale-chapter__section-heading'}>
      {typeof title === 'string' && (
        <h2
          className={cn('samtale-chapter__header', {
            'samtale-chapter__header--with-border': showTitleWithUnderscore
          })}
        >
          {title}
        </h2>
      )}
      {typeof lead === 'string' && (
        <span
          dangerouslySetInnerHTML={{ __html: lead }}
          className="samtale-chapter__lead"
        />
      )}
    </div>
  );
};
SamtaleHeading.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
  showTitleWithUnderscore: PropTypes.bool
};

export default SamtaleHeading;
