import React from 'react';
import PropTypes from 'prop-types';

import BaseLayout from './base-layout';
import Footer from '../footer';
import Header from '../header';

const BasicLayout = ({ children, header, footer, ...rest }) => {
  return (
    <BaseLayout color={BaseLayout.colors.gray} {...rest}>
      <Header {...header} theme={Header.themes.dark} />
      <main id="main-content" className="basic-layout">
        {children}
      </main>
      <Footer {...footer} />
    </BaseLayout>
  );
};

BasicLayout.propTypes = {
  children: PropTypes.node, //excluded from backend
  footer: PropTypes.exact(Footer.propTypes),
  header: PropTypes.exact(Header.propTypes),
  ...BaseLayout.propTypes
};

export default BasicLayout;
