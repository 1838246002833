import React from 'react';
import PropTypes from 'prop-types';

import AnonymousLayout from '../layouts/anonymous-layout';
import Personvern from 'components/personvern/personvern';

const PersonvernPage = ({ layout, personvern }) => {
  return (
    <AnonymousLayout {...layout}>
      <Personvern {...personvern} />
    </AnonymousLayout>
  );
};

PersonvernPage.propTypes = {
  layout: PropTypes.exact(AnonymousLayout.propTypes),
  personvern: PropTypes.exact(Personvern.propTypes)
};

export default PersonvernPage;
