import React from 'react';
import PropTypes from 'prop-types';
import RadioInput from 'components/radio-button-input';

const RoleOption = ({ name, value, text, href, onChange }) => {
  return (
    <div className="role-option">
      <div className="role-option__input">
        <RadioInput
          id={value}
          name={name}
          groupId={name}
          label={text}
          onChange={() => onChange(href)}
        />
      </div>
    </div>
  );
};

RoleOption.propTypes = {
  name: PropTypes.string, // excluded from backend
  onChange: PropTypes.func, // excluded from backend
  value: PropTypes.string.isRequired, // excluded from backend
  text: PropTypes.string,
  href: PropTypes.string
};

RoleOption.defaultProps = {
  onChange: () => {}
};

export default RoleOption;
