import React from 'react';
import useDebounce from '../../hooks/use-debounce';
import NumberInput from './number-input';

const themes = {
  default: 'default',
  rowSmall: 'rowSmall'
};
const NumberInputWithDebounce = props => {
  const [debounceVal, setDebounceVal] = React.useState(props.value);

  const debouncedValue = useDebounce(debounceVal, 500);

  const handleDebounce = value => {
    setDebounceVal(value);
  };
  React.useEffect(() => {
    if (debounceVal !== props.value) {
      props.onChange(debouncedValue, props.id);
    }
  }, [debouncedValue, props.id]);

  return (
    <NumberInput {...props} value={debounceVal} onChange={handleDebounce} />
  );
};

NumberInputWithDebounce.propTypes = NumberInput.propTypes;

NumberInputWithDebounce.defaultProps = {
  theme: 'default',
  thousandSeparator: '',
  prefix: '',
  suffix: '',
  onBlur: () => {},
  onChange: () => {}
};

NumberInputWithDebounce.themes = themes;

export default NumberInputWithDebounce;
