import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../layouts/inspector-layout';
import InspectorProgression from 'components/inspector-progression/inspector-progression';

const InspectorPage = ({ layout, inspector }) => {
  return (
    <Layout {...layout}>
      <InspectorProgression {...inspector} />
    </Layout>
  );
};

InspectorPage.propTypes = {
  layout: PropTypes.exact(Layout.propTypes),
  inspector: PropTypes.exact(InspectorProgression.propTypes)
};

export default InspectorPage;
