import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import noNB from 'date-fns/locale/nb';

import Icon from 'components/icon';

const themes = {
  default: 'default',
  underlined: 'underlined'
};
const DateInput = ({
  label,
  value,
  id,
  inputKey,
  onChange,
  isDisabled,
  theme
}) => {
  const [startDate, setStartDate] = useState(value ? new Date(value) : null);

  const handleChangeDate = date => {
    if (!date) return;
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    const currentDay = date.getDate();

    const parsedDate = new Date(currentYear, currentMonth - 1, currentDay);
    const parsedISOString = parsedDate.toISOString();

    setStartDate(parsedDate);
    onChange(parsedISOString, id);
  };

  return (
    <div
      className={cn('date-input', {
        [`date-input--${themes[theme]}`]: themes[theme]
      })}
    >
      <label htmlFor={id} className="date-input__label">
        <span className="date-input__label-text">{label}</span>
        <DatePicker
          id={id}
          key={inputKey}
          locale={noNB}
          dateFormat="dd.MM.yyyy"
          disabled={isDisabled}
          className="date-input__input"
          selected={startDate}
          onChange={handleChangeDate}
          placeholderText={'Velg dato'}
        />
        <Icon
          name={Icon.names.calendar}
          className={'date-input__calendar-icon'}
        />
      </label>
    </div>
  );
};

DateInput.themes = themes;

DateInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string]),
  id: PropTypes.string,
  inputKey: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)), //exclude from backend
  onChange: PropTypes.func, // ignore backend
  isDisabled: PropTypes.bool // ignore backend
};

DateInput.defaultProps = {
  value: ''
};

export default DateInput;
