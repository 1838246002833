import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Clicker from '../clicker';
import RoleOption from './role-option';

import usePhrases from '../../hooks/use-phrases';

const RoleSelection = ({ title, lead, label, selections }) => {
  const [selectedHref, setSelectedHref] = useState('');

  const { continueOn } = usePhrases();

  const handleOnChange = href => {
    setSelectedHref(href);
  };

  return (
    <div className="role-selection">
      {title && <h1 className="role-selection__title">{title}</h1>}
      {lead && <p className="role-selection__lead">{lead}</p>}
      <div className="role-selection__selections">
        {label && <div className="role-selection__label">{label}</div>}
        <menu className="role-selection__list">
          {selections.map((selection, index) => {
            return (
              <li className="role-selection__item" key={index}>
                <RoleOption
                  {...selection}
                  name="role"
                  value={`role-${index}`}
                  onChange={handleOnChange}
                />
              </li>
            );
          })}
        </menu>
      </div>
      <div className="role-selection__actions">
        <div className="role-selection__action">
          <Clicker
            text={continueOn}
            href={selectedHref}
            theme={Clicker.themes.primary}
            isDisabled={!selectedHref}
          />
        </div>
      </div>
    </div>
  );
};

RoleSelection.propTypes = {
  title: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selections: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    })
  )
};

RoleSelection.defaultProps = {
  selections: []
};

export default RoleSelection;
