import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useClickOutside from '../../hooks/use-click-outside';

import Icon from 'components/icon';
import DropdownBubble from 'components/dropdown-bubble/dropdown-bubble';
import Clicker from 'components/clicker/clicker';
import Notification from 'components/notification/notification';

const NotificationsMenu = ({
  notificationsMenuLabel,
  notificationHeading,
  noNotificationsInfoText,
  notifications,
  isDisabled
}) => {
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [currentNotifications, setCurrentNotifications] = useState(
    notifications ?? []
  );

  const notificationRef = useRef();
  useClickOutside(notificationRef, () => setNotificationsOpen(false));

  const updateNotifications = id => {
    setCurrentNotifications(prevNotifcations =>
      prevNotifcations.filter(notifcation => notifcation.id !== id)
    );
  };

  useEffect(() => {
    setCurrentNotifications(notifications);
  }, [notifications]);

  return (
    <div className="notifications-menu" ref={notificationRef}>
      <Clicker
        disabled={isDisabled}
        aria-expanded={notificationsOpen}
        aria-label={
          notificationsMenuLabel +
          (currentNotifications.length ? ' ' + currentNotifications.length : '')
        }
        className={cn('notifications-menu__button', {
          'notifications-menu__button--active': notificationsOpen
        })}
        onClick={() => setNotificationsOpen(!notificationsOpen)}
        iconName={
          notificationsOpen
            ? currentNotifications.length > 0
              ? Icon.names.bellNotificationActive
              : Icon.names.bellActive
            : currentNotifications.length > 0
            ? Icon.names.bellNotification
            : Icon.names.bell
        }
        iconSize={Clicker.iconSizes.largePlus}
      >
        {currentNotifications.length > 0 && (
          <span className="notifications-menu__notification-number">
            {currentNotifications.length > 99
              ? '99'
              : currentNotifications.length}
          </span>
        )}
      </Clicker>

      <DropdownBubble
        className="notifications-menu__menu-bubble"
        isOpen={notificationsOpen}
        rightMargin=""
      >
        <div className="notifications-menu__header-wrapper">
          <h2 className="notifications-menu__heading">{notificationHeading}</h2>
        </div>
        <div className="notifications-menu__notifications">
          {currentNotifications && currentNotifications.length ? (
            currentNotifications.map(notification => (
              <Notification
                key={notification.id}
                updateNotifications={updateNotifications}
                {...notification}
              />
            ))
          ) : (
            <p className="notifications-menu__no-notifications">
              {noNotificationsInfoText}
            </p>
          )}
        </div>
      </DropdownBubble>
    </div>
  );
};

NotificationsMenu.propTypes = {
  notificationsMenuLabel: PropTypes.string,
  notificationHeading: PropTypes.string,
  noNotificationsInfoText: PropTypes.string,
  isDisabled: PropTypes.bool,
  notifications: PropTypes.arrayOf(PropTypes.exact(Notification.propTypes))
};

NotificationsMenu.defaultProps = {
  notifications: []
};

NotificationsMenu.propTypesMeta = {
  isDisabled: 'exclude'
};

export default NotificationsMenu;
