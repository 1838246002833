import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ContextProviderWrapper from '../../contexts';

const colors = {
  gray: 'gray',
  white: 'white'
};

const BaseLayout = ({ children, color, phrases }) => {
  return (
    <ContextProviderWrapper phrases={phrases}>
      <div
        id="main-layout"
        className={cn('base-layout', {
          [`base-layout--${colors[color]}`]: colors[color]
        })}
      >
        <div className="base-layout__content">{children}</div>
      </div>
    </ContextProviderWrapper>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node, //excluded from backend
  color: PropTypes.oneOf(Object.keys(colors)), //excluded from backend
  phrases: PropTypes.exact(ContextProviderWrapper.phrasesProps).isRequired
};

BaseLayout.defaultProps = {
  color: colors.gray
};

BaseLayout.colors = colors;

export default BaseLayout;
