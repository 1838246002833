import React from 'react';
import PropTypes from 'prop-types';

import Tab from './tab';

const Tabs = ({ tabs }) => {
  return (
    <div className="tabs">
      <ul className="tabs__list">
        {tabs.map((link, index) => (
          <li className="tabs__item" key={index}>
            <Tab {...link} />
          </li>
        ))}
      </ul>
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.exact(Tab.propTypes))
};

Tabs.defaultProps = {
  tabs: []
};

export default Tabs;
