import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import apiHelper from 'js/api-helper';
import useClickOutside from '../../hooks/use-click-outside';

import Icon from 'components/icon';
import { iconNames } from 'components/icon/icon.types';
import Clicker from 'components/clicker/';

const themes = { dark: 'dark', default: 'default' };

const LanguagePicker = ({ languages, theme, alignCenter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentLanguage, setCurrentLangage] = useState(
    languages.find(l => l.isCurrent)
  );
  const componentRef = useRef();

  useClickOutside(componentRef, () => setIsOpen(false));

  const handleChange = async lang => {
    if (currentLanguage === lang) return;
    setCurrentLangage(languages.find(l => l.text === lang.text));
    setIsOpen(false);
    try {
      await apiHelper.execute(lang.endpoint, {
        valgtSprak: lang.id
      });
      window.location.reload();
    } catch (_error) {
      setCurrentLangage(currentLanguage);
    }
  };
  return (
    <div
      className={cn('language-picker', {
        'language-picker--open': isOpen,
        [`language-picker--${themes[theme]}`]: themes[theme],
        'language-picker--align-center': alignCenter
      })}
      ref={componentRef}
    >
      <button
        className="language-picker__button"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <span className="language-picker__text">
          <Icon className="language-picker__icon" name={iconNames.arrowDown} />
          <span className="language-picker__name">
            {currentLanguage?.text || 'Velg språk'}
          </span>
        </span>
      </button>
      {isOpen && (
        <div className="language-picker__menu">
          <Clicker
            text="Lukk"
            textIsHidden={true}
            iconName={Clicker.iconNames.closeCircleBlack}
            iconSize={Clicker.iconSizes.smallPlus}
            onClick={() => setIsOpen(false)}
            className="language-picker__close-button"
          />
          <menu className="language-picker__menu-list">
            {languages?.map((lang, index) => {
              return (
                <li key={index}>
                  <button
                    onClick={() => handleChange(lang)}
                    className="language-picker__menu-button"
                    aria-current={currentLanguage.text === lang.text}
                  >
                    <span
                      className={cn('language-picker__text', {
                        'language-picker__text--current':
                          currentLanguage.text === lang.text
                      })}
                    >
                      {currentLanguage.text === lang.text && (
                        <span className={'language-picker__current-icon'} />
                      )}
                      <span className="language-picker__name">{lang.text}</span>
                    </span>
                  </button>
                </li>
              );
            })}
          </menu>
        </div>
      )}
    </div>
  );
};

LanguagePicker.propTypes = {
  theme: PropTypes.string, //ignore backend
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      id: PropTypes.string,
      endpoint: PropTypes.string,
      isCurrent: PropTypes.bool
    })
  )
};

LanguagePicker.defaultProps = {
  languages: [],
  theme: themes.default
};

LanguagePicker.themes = themes;

export default LanguagePicker;
